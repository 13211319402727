//Interface References
import { IApiPaginationOptions, IApiPaginationViewModel } from '../../../IndexOfInterfaces';

export interface IPaginationCalculator {

    currentPageMatch: (pageButtonLabel: number) => boolean;
    currentPageSize: number;

    hasPages: boolean;
    moreThanOnePage: boolean;
    totalPages: number;

    numberButtonsStartOffset: number;
    visibleButtonsLimit: number;
    prevEllipsisButtonNewOffset: number;
    nextEllipsisButtonNewOffset: number;

    nextPageNo: number;
    prevPageNo: number;

    maxPageSize: number;

    needsNextEllipsis: boolean;
    needsPrevEllipsis: boolean;

    onFirstPage: boolean;
    onLastPage: boolean;

    pageSizes: number[];

    validTargetPage: (targetPageNo: number, overrideCurrentPage?: boolean) => boolean;
}

const defaultPageSizes = [10, 25, 50, 100];

const paginationCalculatorFactory = (paginationOptions: IApiPaginationOptions, paginationViewModel: IApiPaginationViewModel): IPaginationCalculator => {

    const currentPageNo = paginationOptions.pageNumber || 1;
    const maxVisibleButtons = paginationOptions.maxVisiblePageButtons || 10;
    const totalPages = paginationViewModel && paginationViewModel.pageCount || 1;

    const isLongList = totalPages > maxVisibleButtons;

    const inLastSegment = (() => {

        const totalSegments = Math.ceil(totalPages / maxVisibleButtons);
        const startOfLastSegment = ((totalSegments - 1) * maxVisibleButtons) + 1;

        return currentPageNo >= startOfLastSegment;
    })();

    const numberButtonsStartOffset = (() => {

        if (!isLongList) {
            return 1;
        }

        const dividedValue = currentPageNo / maxVisibleButtons;

        let offsetValue = (Math.floor(dividedValue) * maxVisibleButtons);

        const modulus = currentPageNo % maxVisibleButtons;

        if (modulus !== 0) {
            offsetValue++;
        } else {
            offsetValue = (offsetValue - maxVisibleButtons) + 1;
        }

        return offsetValue;
    })();

    const visibleButtonsLimit = (() => {

        let visibleButtonsLimitVal: number;

        if (inLastSegment) {

            visibleButtonsLimitVal = totalPages + 1;

        } else if (isLongList) {

            visibleButtonsLimitVal = numberButtonsStartOffset + maxVisibleButtons;
        }
        else {
            visibleButtonsLimitVal = totalPages;
        }

        return visibleButtonsLimitVal;

    })();

    return {

        currentPageMatch: (pageButtonLabel: number): boolean => {
            return pageButtonLabel === currentPageNo;
        },
        currentPageSize: paginationOptions.pageSize,

        hasPages: totalPages > 0,
        moreThanOnePage: totalPages > 1,
        totalPages,

        numberButtonsStartOffset,
        visibleButtonsLimit,
        prevEllipsisButtonNewOffset: numberButtonsStartOffset - maxVisibleButtons,
        nextEllipsisButtonNewOffset: numberButtonsStartOffset + maxVisibleButtons,

        nextPageNo: currentPageNo + 1,
        prevPageNo: currentPageNo - 1,

        maxPageSize: 9999,

        needsNextEllipsis: isLongList && !inLastSegment,
        needsPrevEllipsis: currentPageNo > maxVisibleButtons,

        onFirstPage: currentPageNo === 1,
        onLastPage: currentPageNo === totalPages,

        pageSizes: defaultPageSizes.map(x => x),

        validTargetPage: (targetPageNo: number, overrideCurrentPage?: boolean): boolean => {

            const targetPageIsValid = targetPageNo > 0 && targetPageNo <= (paginationViewModel && paginationViewModel.pageCount || 1);

            const targetNotCurrentPage = !overrideCurrentPage ? targetPageNo !== paginationOptions.pageNumber : true;

            return targetPageIsValid && targetNotCurrentPage;
        }
    };
};

export { paginationCalculatorFactory as PaginationCalculator };
