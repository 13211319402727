// 3rd Party
import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';

// Utility References
import { IApiProgressFunctions } from 'Utility';
import { Handler } from 'Utility/IndexOfActions';
import { IAjaxInfo } from 'Utility/IndexOfInterfaces';
import { Notification } from 'Utility/IndexOfServices';

import { Actions } from 'App/Models/Base/Actions';

// App References
import {
    AllExceptionNotifier,
    IApiForgottenPasswordViewModel as IViewModel
} from 'App/IndexOfInterfaces';

// Service References
import { UserApi } from 'App/IndexOfServices';

// State
import { IForgottenPasswordState as IState } from './State';

// Model References
import { ForgottenPasswordActionTypes as ActionTypes} from './Models';

interface IActions {
    clear: Handler.Action;
    receiveForgottenPassword: Handler.Action1<IViewModel>;
    submit: Handler.Action1<IViewModel>;
}

class PasswordResetActions extends Actions {

    constructor(apiProgressFunctions: IApiProgressFunctions, public allExceptionNotifier: Handler.Action1<IAjaxInfo>) {
        super(apiProgressFunctions);
    }

    public clear = createAction(ActionTypes.reset.resetForm);

    public receiveForgottenPassword = createAction(
        ActionTypes.receive.forgottenPassword, (viewModel: IViewModel) => viewModel
    );

    public submit = (viewModel: IViewModel) => (dispatch: Handler.AnyForDispatch): void => {

        const onSuccess = () => {

            Notification.success.recordUpdated(`A reset link will be sent to the associated email address if the username exists.`, 'Password Reset');

            dispatch(this.clear());
        };

        this.api.call(UserApi.forgottenPassword(viewModel.username), onSuccess, this.allExceptionNotifier);
    }
}

const actionsFactory = (dispatch: Dispatch<IState>, progressFunctions: IApiProgressFunctions): IActions => {

    const notificationsTitle = 'Password Reset';
    const actions = new PasswordResetActions(progressFunctions, AllExceptionNotifier(notificationsTitle));

    return {
        clear: () => {
            dispatch(actions.clear());
        },
        receiveForgottenPassword: (viewModel: IViewModel) => {
            dispatch(actions.receiveForgottenPassword(viewModel));
        },
        submit: (viewModel: IViewModel) => {
            dispatch(actions.submit(viewModel));
        }
    };
};

export {
    actionsFactory as ForgottenPasswordActionsDispatcherFactory,
    IActions as IForgottenPasswordActions
};
