// 3rd Party References
import * as React from 'react';
import { hot } from 'react-hot-loader';

// Utility References
import { Handler } from 'Utility/IndexOfActions';
import {
    FormGroupWrapper,
    IFormGroupWrapperProps,
    ISimpleButtonProps,
    ISimpleStringInputProps,
    KeyIcon,
    SimpleStringInput,
    SuccessButton
} from 'Utility/IndexOfComponents';
import { ButtonStyle } from 'Utility/IndexOfEnums';
import { FormControlHelper, FormHelper, ObjectHelper } from 'Utility/IndexOfHelpers';
import { IContentWidths, IRequiredField } from 'utility/IndexOfInterfaces';
import { ModelUpdater } from 'Utility/IndexOfModels';

// App References
import {
    IApiChangePasswordViewModel as IViewModel
} from 'App/IndexOfInterfaces';
import { IPageActions, IPageState } from 'App/IndexOfModels';

interface IPropActions {
    onSubmit: Handler.Action1<IViewModel>;
    pageActions: IPageActions;
    receiveChangePassword: Handler.Action1<IViewModel>;
}

interface IPropData {
    changePassword: IViewModel;
    pageState: IPageState;
}

interface IChangePasswordFormProps {
    propActions: IPropActions;
    propData: IPropData;
}

class Form extends React.Component<IChangePasswordFormProps, {}> {

    private getFormPropertyNames = (viewModel: IViewModel) => {

        return {
            oldPassword: ObjectHelper.getPropertyName(() => viewModel.oldPassword),
            newPassword: ObjectHelper.getPropertyName(() => viewModel.newPassword),
            newPasswordConfirmation: ObjectHelper.getPropertyName(() => viewModel.newPasswordConfirmation)
        };
    }

    private handleOnSubmit = (propActions: IPropActions, propData: IPropData, viewModel: IViewModel): void => {

        const { pageActions: { clearAll, addError } } = propActions;

        if (FormHelper.areRequiredPropertiesValid(clearAll, addError, this.getRequiredFieldsForValidation(viewModel))) {

            propActions.onSubmit(viewModel);
        }
    }

    private isViewModelValid = (viewModel: IViewModel): boolean => {

        const hasViewModel = !ObjectHelper.isUndefinedOrNull(viewModel);

        return hasViewModel;
    }

    private getRequiredFieldsForValidation(viewModel: IViewModel): IRequiredField[] {

        const formProperties = this.getFormPropertyNames(viewModel);

        return [
            {
                errorMessage: 'Please enter your current password.',
                propertyName: formProperties.oldPassword,
                value: viewModel.oldPassword
            },
            {
                errorMessage: 'Please enter your new password.',
                propertyName: formProperties.newPassword,
                value: viewModel.newPassword
            },
            {
                errorMessage: 'Your current and new password should not be the same.',
                propertyName: formProperties.newPassword,
                value: viewModel.newPassword,
                validate: (value): boolean => viewModel.oldPassword !== value
            },
            {
                errorMessage: 'Please confirm your new password.',
                propertyName: formProperties.newPasswordConfirmation,
                value: viewModel.newPasswordConfirmation,
            },
            {
                errorMessage: 'Your passwords don\'t match.',
                propertyName: formProperties.newPasswordConfirmation,
                value: viewModel.newPasswordConfirmation,
                validate: (value): boolean => viewModel.newPassword === value
            }
        ];

    }

    render() {

        const { propActions, propData } = this.props;

        const viewModel = propData.changePassword;

        if (!this.isViewModelValid(viewModel)) {
            return null;
        }

        const formProperties = this.getFormPropertyNames(viewModel);

        const formGroupWrapperPropsDefaults = {
            contentWidths: FormControlHelper.getContentWidths(
                'children',
                'error',
                'input-wrapper',
                'sr-only'
            )
        };

        const formGroupWrapperProps = (
            displayName: string,
            propertyName: string,
            contentWidths?: IContentWidths,
            isRequired?: boolean): IFormGroupWrapperProps => {

            return FormControlHelper.getFormGroupWrapperProps(
                contentWidths || formGroupWrapperPropsDefaults.contentWidths,
                this.props.propData.pageState.modelState,
                displayName,
                propertyName,
                isRequired || false
            );
        };

        const genericSimpleStringInputProps = (propertyName: string, value: string, maxLength: number = 100, namespace?: string, isDisabled?: boolean): ISimpleStringInputProps => {
            return {
                isDisabled,
                maxLength,
                onChangeCallback: modelUpdater.for<string>((value, model) => {
                    return updateModel(model, value, propertyName, namespace);
                }),
                value
            };
        };

        const modelUpdater = ModelUpdater(propData.changePassword, propActions.receiveChangePassword);

        const updateModel = (viewModel: IViewModel, value: boolean | Date | number | string | string[], propertyName: string, namespace?: string): IViewModel => {

            const property = namespace ? viewModel[namespace] : viewModel;

            propActions.pageActions.removeEntry(property[propertyName]);
            property[propertyName] = value;

            return viewModel;
        };

        const oldPasswordProps: ISimpleStringInputProps = {
            ...genericSimpleStringInputProps(formProperties.oldPassword, viewModel.oldPassword, 100, null),
            ...{
                password: true,
                placeholder: 'Enter your current password'
            }
        };

        const newPasswordProps: ISimpleStringInputProps = {
            ...genericSimpleStringInputProps(formProperties.newPassword, viewModel.newPassword, 100, null),
            ...{
                password: true,
                placeholder: 'Enter your new password'
            }
        };

        const newPasswordConfirmationProps: ISimpleStringInputProps = {
            ...genericSimpleStringInputProps(formProperties.newPasswordConfirmation, viewModel.newPasswordConfirmation, 100, null),
            ...{
                password: true,
                placeholder: 'Re-enter your new password'
            }
        };

        const submitButtonProps: ISimpleButtonProps = {
            buttonOptions: {
                buttonStyle: ButtonStyle.SuccessOutline,
                buttonText: 'Change Password'
            },
            onClick: () => {

                this.handleOnSubmit(propActions, propData, viewModel);
            },
            processing: propData.pageState.processing
        };

        return (
            <form className="form-password-change col-md-4">

                <FormGroupWrapper {...formGroupWrapperProps('Current Password', formProperties.oldPassword, null, true)}>
                    <SimpleStringInput {...oldPasswordProps} />
                </FormGroupWrapper>

                <FormGroupWrapper {...formGroupWrapperProps('New Password', formProperties.newPassword, null, true)}>
                    <SimpleStringInput {...newPasswordProps} />
                </FormGroupWrapper>

                <FormGroupWrapper {...formGroupWrapperProps('Confirm New Password', formProperties.newPasswordConfirmation, null, true)}>
                    <SimpleStringInput {...newPasswordConfirmationProps} />
                </FormGroupWrapper>

                <SuccessButton {...submitButtonProps}>
                    <KeyIcon />
                </SuccessButton>
            </form>
        );
    }

}

const HotForm = hot(module)(Form);

export {
    HotForm as ChangePasswordForm,
    IChangePasswordFormProps
};
