// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from 'Utility/IndexOfActions';
import { CommonDictionary, ObjectHelper, StringHelper } from 'Utility/IndexOfHelpers';
import { IApiFilterBase, IApiItemOptionViewModel, IApiSortOptions } from 'Utility/IndexOfInterfaces';
import { IModelUpdater, ModelUpdater } from 'Utility/IndexOfModels';
import { Notification } from 'Utility/IndexOfServices';

// Local References
import InternalCommonSelect, { IInternalCommonSelectProps } from './Internal/InternalCommonSelect';

interface ISimpleSelectSortItemOptionProps extends React.Props<SimpleSelectSortItemOption> {
    alwaysUsePromptText?: boolean;
    disableSelfValueValidation?: boolean;
    displayName?: string;
    inputClassName?: string;
    isDisabled?: boolean;
    isFilter?: boolean;
    items: IApiItemOptionViewModel[];
    onBlurCallback?: Handler.SelectFocusEvent;
    onKeyPressCallback?: Handler.SelectKeyboardEvent;
    optionsModel: any;
    optionsUpdateFunc: <TOptions extends IApiFilterBase>(options: TOptions) => void;
    placeholder?: string;
    propertyName?: string;
    reportingValueCanBeZero?: boolean;
    searchFunc: <TOptions extends IApiFilterBase>(options: TOptions) => void;
    tabIndex?: number;
    value: IApiSortOptions;
}

class SimpleSelectSortItemOption extends React.Component<ISimpleSelectSortItemOptionProps, { valueReset: boolean }> {

    private primaryInput: InternalCommonSelect;

    constructor(props: ISimpleSelectSortItemOptionProps) {
        super(props);

        this.state = {
            valueReset: false
        };
    }

    public setFocus = (scroll?: number): void => {
        this.primaryInput.setFocus(scroll);
    }

    componentWillReceiveProps(nextProps: ISimpleSelectSortItemOptionProps) {

        // Need to validate that the value exists in the items
        const hasItemsAndValue = nextProps.items && !ObjectHelper.isUndefinedOrNull(nextProps.value);
        const { valueReset } = this.state;

        if (!valueReset && hasItemsAndValue && !nextProps.disableSelfValueValidation) {

            const valueDoesNotExistInItems = nextProps.items.filter(x => x.value === nextProps.value.toString()).length === 0;

            if (valueDoesNotExistInItems) {

                // https://www.youtube.com/watch?v=uACvFAm6JSM
                if (nextProps.propertyName) {

                    const notificationText = nextProps.displayName ? nextProps.displayName : StringHelper.toSentenceCase(nextProps.propertyName);
                    Notification.warning.clickToDismiss(`${CommonDictionary.ValueIsNowInvalid} ${CommonDictionary.Property} (${notificationText}). ${CommonDictionary.PleaseReselect}`, notificationText);
                }

                this.setState({ valueReset: true });
            }
        }
    }

    private handleOnChange = (modelUpdater: IModelUpdater<any>, searchFunc: <TOptions extends IApiFilterBase>(options: TOptions) => void, items: IApiItemOptionViewModel[], propertyName?: string) => (value: string) => {

        if (!ObjectHelper.isUndefinedOrNull(value) && value.split('_').length === 2) {

            const [sortField, sortDirection] = value.split('_');
            const sortOrderAsc = sortDirection === 'ASC';

            const sortOptions: IApiSortOptions = {
                sortField,
                sortOrderAsc
            };

            const updateFunc = modelUpdater.for<IApiSortOptions>((value, model) => {

                model.sortOptions = value;
                return model;
            }, searchFunc);

            updateFunc(sortOptions, propertyName);
        }
    }

    render() {

        const { alwaysUsePromptText, displayName, inputClassName, isDisabled, items, isFilter, onBlurCallback, onKeyPressCallback, optionsModel, optionsUpdateFunc, placeholder, propertyName, reportingValueCanBeZero, searchFunc, tabIndex, value } = this.props;

        const selectedValueAsString = ObjectHelper.isUndefinedOrNull(value)
            ? ''
            :  `${value.sortField}_${value.sortOrderAsc ? 'ASC' : 'DESC'}`;

        const modelUpdater = ModelUpdater(optionsModel, optionsUpdateFunc);

        const selectProps: IInternalCommonSelectProps = {
            alwaysUsePromptText,
            displayName,
            inputClassName,
            isDisabled,
            isFilter,
            items,
            onBlurCallback,
            onChangeCallback: this.handleOnChange(modelUpdater, searchFunc, items, propertyName),
            onKeyPressCallback,
            promptText: placeholder,
            propertyName,
            ref: (component: InternalCommonSelect) => { this.primaryInput = component; },
            reportingValueCanBeZero,
            tabIndex,
            value: selectedValueAsString
        };

        return <InternalCommonSelect {...selectProps} />;
    }
}

export {
    ISimpleSelectSortItemOptionProps,
    SimpleSelectSortItemOption
};
