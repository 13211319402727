// Utility References
import {
    IApiCall,
    IApiCallWithPayload,
    IGenericQueryApi
} from 'Utility/IndexOfInterfaces';

// App References
import {
    IApiSupplyRequestCollection as ICollection,
    IApiSupplyRequestListingOptions as IOptions,
    IApiSupplyRequestToolsFilter as IToolsFilter,
    IApiSupplyRequestToolsViewModel as ITools,
    IApiSupplyRequestUpsertViewModel as IUpsert
} from 'App/IndexOfInterfaces';

import { Actions, AppService, HttpVerbs } from 'App/IndexOfServices';

const controller = AppService().apiController('SupplyRequest');

const queryService: IGenericQueryApi<ICollection, IOptions, ITools, IUpsert> = {
    get: (id: number): IApiCall<IUpsert> => {
        return {
            method: HttpVerbs.GET,
            url: `${controller}/${id}`
        };
    },
    search: (): IApiCallWithPayload<IOptions, ICollection> => {
        return {
            method: HttpVerbs.POST,
            url: `${controller}/${Actions.SEARCH}`
        };
    },
    template: (): IApiCall<IUpsert> => {
        return {
            method: HttpVerbs.GET,
            url: `${controller}/${Actions.TEMPLATE}`
        };
    },
    tools: (): IApiCall<ITools> => {
        return {
            method: HttpVerbs.GET,
            url: `${controller}/${Actions.TOOLS}`
        };
    }
};

const actionService = {
    linkedTools: (): IApiCallWithPayload<IToolsFilter, ITools> => {
        return {
            method: HttpVerbs.POST,
            url: `${controller}/${Actions.LINKEDTOOLS}`
        };
    },
    save: (): IApiCallWithPayload<FormData, void> => {
        return {
            method: HttpVerbs.POST,
            url: `${controller}`
        };
    },
    update: (): IApiCallWithPayload<FormData, void> => {
        return {
            method: HttpVerbs.PUT,
            url: `${controller}`
        };
    }
};

export {
    actionService as SupplyRequestApi,
    queryService as SupplyRequestQueryService
};
