// Utility References
import {
    IApiCall,
    IApiCallWithPayload
} from 'Utility/IndexOfInterfaces';

import {
    IApiLoginViewModel,
    IApiResetPasswordViewModel,
    IAuthenticationInfoViewModel
} from 'App/IndexOfInterfaces';
import {
    AppService,
    HttpVerbs
} from 'App/IndexOfServices';
import { IApiChangePasswordViewModel, IApiImpersonationViewModel } from 'App/Interfaces/UserInterfaces';

const userController = AppService().apiController('user');

const actionService = {
    changePassword: (): IApiCallWithPayload<IApiChangePasswordViewModel, void> => {
        return {
            method: HttpVerbs.POST,
            url: `${userController}/changepassword`
        };
    },
    forgottenPassword: (email: string): IApiCall<void> => {
        return {
            method: HttpVerbs.PUT,
            url: `${userController}/forgottenPassword/${email}`,
            withoutAuth: true
        };
    },
    impersonate: (): IApiCallWithPayload<IApiImpersonationViewModel, IAuthenticationInfoViewModel> => {
        return {
            method: HttpVerbs.POST,
            url: `${userController}/impersonate`,
            withoutAuth: true
        };
    },
    login: (): IApiCallWithPayload<IApiLoginViewModel, IAuthenticationInfoViewModel> => {
        return {
            method: HttpVerbs.POST,
            url: `${userController}/authenticate`,
            withoutAuth: true
        };
    },
    resetPassword: (): IApiCallWithPayload<IApiResetPasswordViewModel, void> => {
        return {
            method: HttpVerbs.POST,
            url: `${userController}/resetpassword`,
            withoutAuth: true
        };
    }
};

export {
    actionService as UserApi
};
