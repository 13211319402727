// 3rd Party
import { reducer as toastrReducer } from 'react-redux-toastr';
import { combineReducers } from 'redux';

// Interface References
import {
    AccountDetailsReducer,
    BidHistoryReducer,
    ChangePasswordReducer,
    EnquiryReducer,
    ForgottenPasswordReducer,
    IAccountDetailsState,
    IBidHistoryState,
    IChangePasswordState,
    IEnquiryState,
    IForgottenPasswordState,
    ILoginState,
    IRegistrationState,
    IResetPasswordState,
    ISupplyRequestListingState,
    ISupplyRequestState,
    LoginReducer,
    RegistrationReducer,
    ResetPasswordReducer,
    SupplyRequestListingReducer,
    SupplyRequestReducer
} from 'App/IndexOfModels';

// Reducers
import { IPageState, PageReducer } from './Page/State';

export interface IAppState {
    accountDetails: IAccountDetailsState;
    bidHistory: IBidHistoryState;
    changePassword: IChangePasswordState;
    enquiry: IEnquiryState;
    forgottenPassword: IForgottenPasswordState;
    login: ILoginState;
    page: IPageState;
    registration: IRegistrationState;
    resetPassword: IResetPasswordState;
    supplyRequest: ISupplyRequestState;
    supplyRequestListing: ISupplyRequestListingState;
}

const areaStateReducer = combineReducers({
    accountDetails: AccountDetailsReducer,
    bidHistory: BidHistoryReducer,
    changePassword: ChangePasswordReducer,
    enquiry: EnquiryReducer,
    forgottenPassword: ForgottenPasswordReducer,
    login: LoginReducer,
    page: PageReducer,
    registration: RegistrationReducer,
    resetPassword: ResetPasswordReducer,
    supplyRequest: SupplyRequestReducer,
    supplyRequestListing: SupplyRequestListingReducer,
});

const rootReducer = combineReducers({
    appState: areaStateReducer,
    toastr: toastrReducer
});

export {
    rootReducer as RootReducer
};
