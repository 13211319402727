// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from 'Utility/IndexOfActions';
import { Modal, MyModal } from 'Utility/IndexOfComponents';
import { TranslationHelper } from 'Utility/IndexOfHelpers';
import { IModalService } from 'Utility/IndexOfServices';

interface IMessagePromptModalProps {
    buttonText: string;
    message: string;
    modalService: IModalService;
    onClose: Handler.BooleanValue;
    title: string;
}

class MessagePromptModal extends React.Component<IMessagePromptModalProps, {}> {

    private confirmSelection = (onClose: Handler.BooleanValue) => (confirmed: boolean) => (): void => {

        onClose(confirmed);
    }

    render() {

        const { buttonText, message, modalService, onClose, title } = this.props;

        const modalButtonText = TranslationHelper.translate(buttonText);
        const modalMessage = TranslationHelper.translate(message);
        const modalTitle = TranslationHelper.translate(title);

        const modalProps = {
            bsSize: 'small' as 'small', // Required hack to get around warning "Type 'string' is not assignable to type 'small' | 'sm' etc..."
            dialogClassName: 'custom-modal',
            onHide: modalService.toggleModal,
            show: modalService.isModalOpen()
        };

        const confirmSelectionHandler = this.confirmSelection(onClose);

        return (
            <MyModal {...modalProps}>
                <Modal.Header>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{modalMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn" onClick={confirmSelectionHandler(true)} type="button">
                        {modalButtonText}
                    </button>
                </Modal.Footer>
            </MyModal>
        );
    }
}

export {
    IMessagePromptModalProps,
    MessagePromptModal
};
