// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from 'Utility/IndexOfActions';
import { BlockedIcon } from 'Utility/IndexOfComponents';
import { TranslationHelper } from 'Utility/IndexOfHelpers';

export interface ISimpleInputGroupCheckboxProps extends React.Props<SimpleInputGroupCheckbox> {
    isDisabled?: boolean;
    isHidden?: boolean;
    label: string;
    onChangeCallback: Handler.BooleanProperty;
    value: boolean;
}

export class SimpleInputGroupCheckbox extends React.Component<ISimpleInputGroupCheckboxProps, {}> {

    private handleLabelClick = (isDisabled: boolean, onChangeCallback: Handler.BooleanValue, value: boolean) => (event: React.MouseEvent<HTMLDivElement>) => {

        event.stopPropagation();

        if (isDisabled) {
            return;
        }

        const newValue = !value;
        onChangeCallback(newValue);
    }

    private getCheckbox = (isDisabled: boolean, value: boolean): JSX.Element => {

        if (isDisabled) {
            return <BlockedIcon/>;
        }

        const checkboxInputProps = {
            checked: value || false,
            disabled: isDisabled,
            onChange: () => { /* Do Nothing */ },
            type: 'checkbox',
            value: 'true'
        };

        return <input {...checkboxInputProps}/>;
    }

    render() {

        const { isDisabled, isHidden, label, onChangeCallback, value } = this.props;

        const checkboxDivProps = {
            className: value ? 'input-group simple-input-group-checkbox active-option' : 'input-group simple-input-group-checkbox',
            onClick: this.handleLabelClick(isDisabled, onChangeCallback, value)
        };

        if (isDisabled) {
            checkboxDivProps.className = checkboxDivProps.className + ' input-disabled';
        }

        if (isHidden) {
            checkboxDivProps.className = checkboxDivProps.className + ' hidden';
        }

        const spanProps = {
            className: 'input-group-addon'
        };

        const translatedLabel = TranslationHelper.translate(label);

        const textboxProps = {
            className: 'form-control z-index-0',
            disabled: isDisabled,
            readOnly: true,
            tabIndex: -1,
            type: 'text',
            value: translatedLabel
        };

        const checkbox = this.getCheckbox(isDisabled, value);

        return (
            <div {...checkboxDivProps}>
                <span {...spanProps}>
                    {checkbox}
                </span>
                <input {...textboxProps} />
            </div>
        );
    }
}
