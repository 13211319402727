// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from '../../IndexOfActions';
import { ISimplePositiveIntegerInputProps, SimplePositiveIntegerInput } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
//import {  } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

export interface ISimpleOdometerInputProps extends React.Props<SimpleOdometerInput> {
    isDisabled?: boolean;
    onBlurCallback?: Handler.InputFocusEvent;
    onChangeCallback: Handler.NumberProperty;
    placeholder?: string;
    propertyName: string;
    tabIndex?: number;
    value: number;
}

export default class SimpleOdometerInput extends React.Component<ISimpleOdometerInputProps, {}> {

    private primaryInput: SimplePositiveIntegerInput;

    public setFocus = (scroll?: number): void => {
        this.primaryInput.setFocus(scroll);
    }

    render() {

        const { isDisabled, onBlurCallback, onChangeCallback, placeholder, propertyName, tabIndex, value } = this.props;

        const inputProps: ISimplePositiveIntegerInputProps = {
            disallowZero: true,
            isDisabled,
            max: 99999999,
            onBlurCallback,
            onChangeCallback,
            placeholder,
            propertyName,
            ref: (component: SimplePositiveIntegerInput) => { this.primaryInput = component; },
            tabIndex,
            value
        };

        return <SimplePositiveIntegerInput {...inputProps} />;
    }
}
