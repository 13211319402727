// 3rd Party References
import { createAction } from 'redux-actions';

// Utility References
import { IApiProgressFunctions } from 'Utility';
import { Handler } from 'Utility/IndexOfActions';
import { IAjaxInfo, IGenericQueryActions, IGenericQueryActionTypes, IGenericQueryApi } from 'Utility/IndexOfInterfaces';

import { Actions } from './Actions';

class GenericQueryActions<TCollection, TOptions, TTools, TUpsert> extends Actions implements IGenericQueryActions<TCollection, TOptions, TTools, TUpsert> {

    constructor(apiProgressFunctions: IApiProgressFunctions, public allExceptionNotifier: Handler.Action1<IAjaxInfo>, private genericApi: IGenericQueryApi<TCollection, TOptions, TTools, TUpsert>, public actionTypes: IGenericQueryActionTypes) {
        super(apiProgressFunctions);
    }

    public getAsync = (id: number) => (dispatch: Handler.AnyForDispatch): void => {

        const onSuccess = (upsert: TUpsert) => {
            dispatch(this.receiveUpsert(upsert));
        };

        const url = this.genericApi.get(id);
        this.api.call(url, onSuccess, this.allExceptionNotifier);
    }

    public getTemplateAsync = (id?: number) => (dispatch: Handler.AnyForDispatch): void => {

        const onSuccess = (upsert: TUpsert) => {
            dispatch(this.receiveUpsert(upsert));
        };

        const url = this.genericApi.template(id);
        this.api.call(url, onSuccess, this.allExceptionNotifier);
    }

    public getToolsAsync = () => (dispatch: Handler.AnyForDispatch): void => {

        const onSuccess = (tools: TTools) => {
            dispatch(this.receiveTools(tools));
        };

        const url = this.genericApi.tools();
        this.api.call(url, onSuccess, this.allExceptionNotifier);
    }

    public receiveCollection = createAction(
        this.actionTypes.receive.collection, (collection: TCollection) => collection
    );

    public receiveOptions = createAction(
        this.actionTypes.receive.options, (options: TOptions) => options
    );

    public receiveTools = createAction(
        this.actionTypes.receive.tools, (tools: TTools) => tools
    );

    public receiveUpsert = createAction(
        this.actionTypes.receive.upsert, (upsert: TUpsert) => upsert
    );

    public resetAll = createAction(
        this.actionTypes.reset.all
    );

    public searchAsync = (options: TOptions) => (dispatch: Handler.AnyForDispatch): void => {

        dispatch(this.receiveCollection(null));

        const onSuccess = (collection: TCollection) => {

            dispatch(this.receiveCollection(collection));
        };

        const url = this.genericApi.search();
        this.api.callWithPayload(url, options, onSuccess, this.allExceptionNotifier);
    }
}

export {
    GenericQueryActions
};
