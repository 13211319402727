// 3rd Party
import * as React from 'react';
import { hot } from 'react-hot-loader';

// Utility References
import { Handler } from 'Utility/IndexOfActions';
import {
    Callout,
    CalloutBody,
    CalloutTitle,
    Card,
    CardBody,
    CardTitle,
    ExclamationTriangleIcon,
    ISimpleButtonProps,
    ITimeRemainingCountdownProps,
    PrimaryButton,
    TimeRemainingCountdown
} from 'Utility/IndexOfComponents';
import { DateHelper, ObjectHelper } from 'Utility/IndexOfHelpers';
import { IModalService } from 'Utility/IndexOfServices';

// App References
import { toCurrencyDisplay } from 'App/Helpers/CurrencyHelper';
import {
    IApiSupplyRequestListingViewModel as IViewModel
} from 'App/IndexOfInterfaces';
import {
    BidHistoryList,
    BidHistorySummaryForm,
    Constants,
    EnquiryFormModal,
    IBidHistoryActions,
    IBidHistoryListProps,
    IBidHistoryState,
    IBidHistorySummaryFormProps,
    IEnquiryActions,
    IEnquiryFormModalProps,
    IEnquiryState,
    IPageActions,
    IPageState,
    IProxyBidFormProps,
    ISupplyRequestListingContactExchangeProps as ContactExchangeProps,
    ISupplyRequestListingInvoiceUploadFormProps as InvoiceUploadProps,
    ISupplyRequestListingPurchaseOrderUploadFormProps as PurchaseOrderUploadProps,
    ProxyBidForm,
    SupplyRequestListingContactExchange as ContactExchange,
    SupplyRequestListingInvoiceAlert as InvoiceAlert,
    SupplyRequestListingInvoiceUploadForm as InvoiceUpload,
    SupplyRequestListingPurchaseOrderUploadForm as PurchaseOrderUpload
} from 'App/IndexOfModels';
import { LocalStorageService } from 'App/IndexOfServices';
import { AppUrls } from 'App/IndexOfUrls';
import { CalloutStyle } from 'Utility/IndexOfEnums';

interface IPropActions {
    bidHistoryActions: IBidHistoryActions;
    closeAuctionAsync: Handler.ActionAsync1<IViewModel, void>;
    enquiryActions: IEnquiryActions;
    get: Handler.Action1<number>;
    pageActions: IPageActions;
    relistAuctionAsync: Handler.ActionAsync1<IViewModel, void>;
    resetAll: Handler.Action;
    uploadInvoice: Handler.ActionAsync1<IViewModel, void>;
    uploadPurchaseOrder: Handler.ActionAsync1<IViewModel, void>;
}

interface IPropData {
    bidhistoryState: IBidHistoryState;
    enquiryModal: IModalService;
    enquiryState: IEnquiryState;
    history: any;
    listingDetails: IViewModel;
    pageState: IPageState;
    supplyRequestListingId: number;
}

interface ISupplyRequestListingDetailsProps {
    propActions: IPropActions;
    propData: IPropData;
}

class Form extends React.Component<ISupplyRequestListingDetailsProps, {}>{

    componentDidMount() {

        const { propActions, propData } = this.props;

        propActions.resetAll();
        propActions.get(propData.supplyRequestListingId);

        propActions.bidHistoryActions.resetAll();
        propActions.bidHistoryActions.getTemplateAsync(propData.supplyRequestListingId);
    }

    private isViewModelValid = (viewModel: IViewModel): boolean => {

        const hasViewModel = !ObjectHelper.isUndefinedOrNull(viewModel);

        return hasViewModel;
    }

    render() {

        const { propActions, propData } = this.props;

        const viewModel = propData.listingDetails;

        if (!this.isViewModelValid(viewModel)) {
            return null;
        }

        const isInsurer = LocalStorageService.isInRole(Constants.RoleNames.Insurer);
        const isDealer = !isInsurer;

        const { isActive, isAwaitingInvoice, isDeclined, isProvisional, isRemoved, isSourced, isWon } = viewModel;

        const isLost = isDealer && isWon && (isSourced || isDeclined || isRemoved);

        const DetailsHeader = (): JSX.Element =>

            <div className="row">
                <div className="col-sm-10">
                    <CardTitle text={`Claim #: ${viewModel.claimReference}`} />
                </div>
                <div className="col-sm-2">
                    {viewModel.clientLogoUrl && <img className="img-thumbnail"style={{width: '100%'}} src={viewModel.clientLogoUrl} />}
                </div>
            </div>;

        const MainImage = (): JSX.Element =>

            <>
                {
                    // TODOxUI - set up to show default image once available
                    viewModel.marketingImageUrl ?
                    <img style={{width: '100%'}} src={viewModel.marketingImageUrl} /> :
                    <i>No image has been uploaded.</i>
                }
            </>;

        const MainSummary = (): JSX.Element =>

            <>
                <div className="p-1"><h3>{viewModel.manufacturerName} {viewModel.modelName} {viewModel.derivativeName} {viewModel.bodyTypeName}</h3></div>
                <div className="p-1"><h4>{viewModel.transmissionTypeName} | {viewModel.fuelTypeName}</h4></div>
                <div className="p-1"><h5>{viewModel.colourPreferences.filter(x => !ObjectHelper.isUndefinedOrNull(x)).join(' | ')}</h5></div>
                <div className="p-1"><h6>Deliver To: {viewModel.location}, {viewModel.postCode}</h6></div>
            </>;

        const bestCurrentOffer = viewModel.bestCurrentOffer && viewModel.bestCurrentOffer.bidAmount;

        const LowestOffer = (): JSX.Element =>

            <div className="p-1"><h6>{bestCurrentOffer > 0 ? `Lowest: ${toCurrencyDisplay(bestCurrentOffer)}` : `No offers yet`}</h6></div>;

        const DealerOffer = (): JSX.Element =>

            <div className="p-1"><h6><strong>Your Offer: {toCurrencyDisplay(viewModel.userCurrentBid.bidAmount)}</strong></h6></div>;

        const countdownProps: ITimeRemainingCountdownProps = {
            endText: 'No time',
            timerEndDate: viewModel.endDate
        };

        const TimeRemaining = (): JSX.Element =>

            isActive ?
            <>
                <div className="p-1"><h6><TimeRemainingCountdown {...countdownProps}/> remaining (ends at: {DateHelper.formatDate(viewModel.endDate)})</h6></div>
            </>
            :
            <div className="p-1">
                Ended At: {DateHelper.formatDate(viewModel.endDate)}
            </div>;

        const haveQuestionButtonProps: ISimpleButtonProps = {
            buttonText: 'Have a Question?',
            isDisabled: propData.pageState.processing,
            onClick: () => { propData.enquiryModal.toggleModal(); }
        };

        const closeAuctionButtonProps: ISimpleButtonProps = {
            appendedClassName: 'btn-block',
            buttonText: 'Close',
            isDisabled: propData.pageState.processing,
            onClick: () => {

                propActions.closeAuctionAsync(viewModel)
                    .then(() => {

                        window.setTimeout(() => {
                            propData.history.push(AppUrls.DashboardUrls.area);
                        }, 1000);
                    });
            }
        };

        const relistAuctionButtonProps: ISimpleButtonProps = {
            appendedClassName: 'btn-block',
            buttonText: 'Relist',
            isDisabled: propData.pageState.processing,
            onClick: () => {

                propActions.relistAuctionAsync(viewModel)
                    .then(() => {

                        window.setTimeout(() => {
                            propData.history.push(AppUrls.DashboardUrls.supplyRequestDetails(viewModel.supplyRequestId));
                        }, 1000);
                    });
            }
        };

        const AuctionDeclinedSection = (): JSX.Element =>

            <div className="col-sm-12">
                <div className="alert alert-info" role="alert">
                    <div className="row align-items-center">
                        <div className="col-sm-9">
                            This auction has ended with no bids or all of the bids have been declined. Please select whether to close the auction or relist. Relisting auction will require an approval from administrator team.
                        </div>
                        <div className="col-sm-3">
                            <div className="row">
                                <div className="col-sm-6">
                                    <PrimaryButton {...relistAuctionButtonProps}/>
                                </div>
                                <div className="col-sm-6">
                                    <PrimaryButton {...closeAuctionButtonProps}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>;

        const VehicleDetails = (): JSX.Element =>

            <>
                <div className="row mb-1">
                    <div className="col-sm-12">
                        <h4>Vehicle Details</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <dl className="row">
                            <dt className="col-sm-5">Auction Id:</dt>
                            <dd className="col-sm-7">{viewModel.supplyRequestListingId}</dd>
                        </dl>
                        <dl className="row">
                            <dt className="col-sm-5">Manufacturer:</dt>
                            <dd className="col-sm-7">{viewModel.manufacturerName}</dd>
                        </dl>
                        <dl className="row">
                            <dt className="col-sm-5">Model:</dt>
                            <dd className="col-sm-7">{viewModel.modelName}</dd>
                        </dl>
                        <dl className="row">
                            <dt className="col-sm-5">Derivative:</dt>
                            <dd className="col-sm-7">{viewModel.derivativeName}</dd>
                        </dl>
                        <dl className="row">
                            <dt className="col-sm-5">Body Type:</dt>
                            <dd className="col-sm-7">{viewModel.bodyTypeName}</dd>
                        </dl>
                        <dl className="row">
                            <dt className="col-sm-5">Fuel:</dt>
                            <dd className="col-sm-7">{viewModel.fuelTypeName}</dd>
                        </dl>
                        <dl className="row">
                            <dt className="col-sm-5">Registration Type:</dt>
                            <dd className="col-sm-7">{viewModel.registrationTypeName}</dd>
                        </dl>
                    </div>

                    <div className="col-sm-6">
                        <dl className="row">
                            <dt className="col-sm-5">Colours:</dt>
                            <dd className="col-sm-7">{viewModel.colourPreferences.join(', ')}</dd>
                        </dl>
                        <dl className="row">
                            <dt className="col-sm-5">Trim Colour:</dt>
                            <dd className="col-sm-7">{viewModel.trimColour}</dd>
                        </dl>
                        <dl className="row">
                            <dt className="col-sm-5">Number of Doors:</dt>
                            <dd className="col-sm-7">{viewModel.doors}</dd>
                        </dl>
                        <dl className="row">
                            <dt className="col-sm-5">Engine Size:</dt>
                            <dd className="col-sm-7">{viewModel.engineSize}</dd>
                        </dl>
                        <dl className="row">
                            <dt className="col-sm-5">Transmission:</dt>
                            <dd className="col-sm-7">{viewModel.transmissionTypeName}</dd>
                        </dl>
                        <dl className="row">
                            <dt className="col-sm-5">Original Vehicle Documents:</dt>
                            <dd className="col-sm-7">
                                <a href={viewModel.originalPurchaseDocumentUrl} target="_blank">{viewModel.originalPurchaseDocumentUrl ? 'View' : 'No document uploaded'}</a>
                            </dd>
                        </dl>
                    </div>
                </div>
            </>;

        const AdditionalComments = (): JSX.Element =>

            <>
                <div className="row mb-1">
                    <div className="col-sm-12">
                        <h4>Additional Comments</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <p>
                            {viewModel.comments || 'None'}
                        </p>
                    </div>
                </div>
            </>;

        const proxyBidFormProps: IProxyBidFormProps = {
            propActions: {
                onBidSubmitted: () => propActions.get(propData.supplyRequestListingId),
                pageActions: propActions.pageActions,
                receiveUpsert: propActions.bidHistoryActions.receiveUpsert,
                save: propActions.bidHistoryActions.saveAsync
            },
            propData: {
                currentUserBestOffer: propData.listingDetails.userCurrentBid,
                minUndercutValue: propData.listingDetails.minimumUnderCurrentBid,
                pageState: propData.pageState,
                terms: viewModel.clientTerms,
                upsert: propData.bidhistoryState.upsert
            }
        };

        const bidListProps: IBidHistoryListProps = {
            propActions: {
                bidHistoryActions: propActions.bidHistoryActions,
                onAccept: () => propActions.get(propData.supplyRequestListingId),
                onDecline: () => propActions.get(propData.supplyRequestListingId),
                pageActions: propActions.pageActions
            },
            propData: {
                adminComments: propData.listingDetails.adminComments,
                bidHistoryState: propData.bidhistoryState,
                pageState: propData.pageState,
                supplyRequestListingId: propData.supplyRequestListingId
            }
        };

        const bidSummaryFormProps: IBidHistorySummaryFormProps = {
            propActions: {
                get: propActions.bidHistoryActions.getAsync
            },
            propData: {
                cardTitle: `${isDealer ? 'Your' : 'Accepted'} Offer Details`,
                bidHistoryId: viewModel.acceptedOffer && viewModel.acceptedOffer.bidHistoryId,
                bidHistoryUpsert: propData.bidhistoryState.upsert
            }
        };

        const enquiryFormModalProps: IEnquiryFormModalProps = {
            propActions: {
                enquiryActions: this.props.propActions.enquiryActions,
                pageActions: this.props.propActions.pageActions
            },
            propData: {
                enquiryState: this.props.propData.enquiryState,
                listingId: viewModel.supplyRequestListingId,
                modalService: propData.enquiryModal,
                pageState: propData.pageState
            }
        };

        const invoiceUploadFormProps: InvoiceUploadProps = {
            propActions: {
                onInvoiceUploaded: () => propActions.get(propData.supplyRequestListingId),
                pageActions: propActions.pageActions,
                uploadInvoice: propActions.uploadInvoice
            },
            propData: {
                isDealer,
                pageState: propData.pageState,
                viewModel
            }
        };

        const purchaseOrderUploadFormProps: PurchaseOrderUploadProps = {
            propActions: {
                onPurchaseOrderUploaded: () => propActions.get(propData.supplyRequestListingId),
                pageActions: propActions.pageActions,
                uploadPurchaseOrder: propActions.uploadPurchaseOrder
            },
            propData: {
                isInsurer,
                pageState: propData.pageState,
                viewModel
            }
        };

        const contactExchangeProps: ContactExchangeProps = {
            propData: {
                cardTitle: `${isInsurer ? 'Supplying Dealer' : 'Insurer'} Information`,
                contactInfo: isInsurer ? viewModel.buyerContactInfo : viewModel.vendorContactInfo,
                pageState: propData.pageState
            }
        };

        return (
            <>
                <div className="col-sm-12 mb-2">
                    <Card borderColour="gray" className="listing-details">
                        <div className="listing-details-header">
                            <DetailsHeader />
                        </div>
                        <div className="listing-details-body bg-light">
                            <div className="row mr-0">

                                <div className="col-sm-7">
                                    <MainImage />
                                </div>

                                <div className="col-sm-5 pl-0">
                                    <div className="row mb-2 mt-2">
                                        <div className="col-sm-12">
                                            <MainSummary />
                                        </div>
                                    </div>
                                    <hr className="listing-details-divider"/>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                {
                                                    (isDealer && viewModel.userCurrentBid && viewModel.userCurrentBid.bidAmount > 0) &&
                                                    <div className="col-sm-6">
                                                        <DealerOffer />
                                                    </div>
                                                }
                                                <div className="col-sm-6">
                                                    <LowestOffer />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="listing-details-divider"/>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <TimeRemaining />
                                        </div>
                                    </div>
                                    <hr className="listing-details-divider"/>
                                    <div className="row mb-2 mt-4">
                                        <div className="col-sm-12">
                                            <PrimaryButton {...haveQuestionButtonProps}/>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="listing-details-extra">
                                <div className="row mb-2">
                                    <div className="col-sm-12">
                                        <Callout borderColour="gray" calloutStyle={CalloutStyle.Info} className="bg-white">
                                            <ExclamationTriangleIcon />
                                            <CalloutTitle text="Options/Accessories" />
                                            <CalloutBody>
                                                <p>{viewModel.specifications.length > 0 ? viewModel.specifications.join(', ') : 'None'}</p>
                                            </CalloutBody>
                                        </Callout>
                                    </div>
                                </div>

                                <div className="row mb-2">
                                {
                                    (isDealer && isActive) &&
                                    <div className="col-sm-12">
                                        <ProxyBidForm key={propData.supplyRequestListingId} {...proxyBidFormProps} />
                                    </div>
                                }
                                {
                                    (isInsurer && isProvisional) &&
                                    <div className="col-sm-12">
                                        <BidHistoryList {...bidListProps} />
                                    </div>
                                }
                                {
                                    isAwaitingInvoice && (isInsurer || isWon) &&
                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <InvoiceAlert showDealer={isDealer} showInsurer={isInsurer}/>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    ((isAwaitingInvoice || isSourced) && (isInsurer || isWon)) &&
                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="col-sm-7">
                                                <ContactExchange {...contactExchangeProps} />
                                            </div>
                                            <div className="col-sm-5">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <InvoiceUpload {...invoiceUploadFormProps} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <PurchaseOrderUpload {...purchaseOrderUploadFormProps} />
                                                    </div>
                                                </div>
                                                {
                                                    viewModel.acceptedOffer &&
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <BidHistorySummaryForm {...bidSummaryFormProps}/>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    (isDeclined && isInsurer) &&
                                    <AuctionDeclinedSection />
                                }
                                </div>

                                <div className="row mb-2 mt-4">
                                    <div className="col-sm-12">
                                        <Card borderColour="gray">
                                            <CardBody>
                                                <VehicleDetails />
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-sm-12">
                                        <Card borderColour="gray">
                                            <CardBody>
                                                <AdditionalComments />
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                <EnquiryFormModal {...enquiryFormModalProps} />
            </>
        );
    }
}

const HotForm = hot(module)(Form);

export {
    HotForm as SupplyRequestListingDetails,
    ISupplyRequestListingDetailsProps
};
