// Utility References
import { ExceptionHelper } from 'Utility/IndexOfHelpers';
import { IApiException, IApiExceptionGroup, IGenericDictionary } from 'Utility/IndexOfInterfaces';
import { Notification } from 'Utility/IndexOfServices';

const Exception = ExceptionHelper.ApiException;

interface IRegistrationExceptions extends IGenericDictionary<IApiException> {
    MissingValues: IApiException;
    NotFound: IApiException;
}

const registrationExceptions: IRegistrationExceptions = {
    MissingValues: Exception('There were some required values missing from the registration form. Please check and re-submit.', Notification.error.missingData),
    NotFound: Exception('Your username and/or password were not correct. Please check your details and try again.', Notification.error.handledException)
};

const registrationExceptionGroup: IApiExceptionGroup = {
    area: 'Registration',
    exceptions: registrationExceptions
};

export {
    registrationExceptions as RegistrationExceptions,
    registrationExceptionGroup as RegistrationExceptionGroup
};
