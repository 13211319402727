const actionTypes = {
    receive: {
        resetPassword: 'ResetPassword.Receive.ViewModel',
        submit: 'ResetPassword.Receive.Submit'
    },
    reset: {
        resetForm: 'ResetPassword.Reset.Form'
    }
};

export {
    actionTypes as ResetPasswordActionTypes
};
