// 3rd Party
import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';

// Utility References
import { IAjaxInfo, IApiProgressFunctions } from 'Utility';
import { Handler, NavigationActions } from 'Utility/IndexOfActions';
import { FormHelper } from 'Utility/IndexOfHelpers';
import { IGenericQueryActions } from 'Utility/IndexOfInterfaces';
import { Notification } from 'Utility/IndexOfServices';

// App References
import {
    AllExceptionNotifier,
    IApiSupplyRequestListingCollection as ICollection,
    IApiSupplyRequestListingOptions as IOptions,
    IApiSupplyRequestListingToolsViewModel as ITools,
    IApiSupplyRequestListingViewModel as IViewModel
} from 'App/IndexOfInterfaces';
import {
    GenericQueryActions,
    ISupplyRequestListingState as IState,
    SupplyRequestListingQueryActionTypes as QueryActionTypes,
} from 'App/IndexOfModels';
import {
    SupplyRequestListingActionService as ActionService,
    SupplyRequestListingQueryService as QueryService
} from 'App/IndexOfServices';

interface IActions extends IGenericQueryActions<ICollection, IOptions, ITools, IViewModel> {
    closeAuctionAsync: Handler.ActionAsync1<IViewModel, void>;
    relistAuctionAsync: Handler.ActionAsync1<IViewModel, void>;
    searchStrictAsync: Handler.Action1<IOptions>;
    uploadInvoiceAsync: Handler.ActionAsync1<IViewModel, void>;
    uploadPurchaseOrderAsync: Handler.ActionAsync1<IViewModel, void>;
}

class SupplyRequestListingActions extends GenericQueryActions<ICollection, IOptions, ITools, IViewModel> {

    public closeAuctionAsync = (viewModel: IViewModel) => (dispatch: Handler.AnyForDispatch): Promise<Handler.AnyForDispatch> => {

        const promise = new Promise<void>((resolve, reject) => {

            const onSuccess = () => {

                Notification.success.recordCreated('This auction has been successfully closed.');
                resolve();
            };

            const onFailure = (ajaxInfo: IAjaxInfo) => {

                this.allExceptionNotifier(ajaxInfo);
                reject();
            };

            this.api.callWithPayload(ActionService.closeAuction(), viewModel, onSuccess, onFailure, false);
        });

        return promise;
    }

    public relistAuctionAsync = (viewModel: IViewModel) => (dispatch: Handler.AnyForDispatch): Promise<Handler.AnyForDispatch> => {

        const promise = new Promise<void>((resolve, reject) => {

            const onSuccess = () => {

                Notification.success.recordCreated('This auction has been successfully relisted.');
                resolve();
            };

            const onFailure = (ajaxInfo: IAjaxInfo) => {

                this.allExceptionNotifier(ajaxInfo);
                reject();
            };

            this.api.callWithPayload(ActionService.relistAuction(), viewModel, onSuccess, onFailure, false);
        });

        return promise;
    }

    public searchStrictAsync = (options: IOptions) => (dispatch: Handler.AnyForDispatch): void => {

        dispatch(this.receiveCollection(null));

        const onSuccess = (collection: ICollection) => {
            dispatch(this.receiveCollection(collection));
        };

        if (options.endpoint) {

            this.api.callWithPayload(QueryService.searchStrict(options.endpoint), options, onSuccess, this.allExceptionNotifier);
        }
     }

    public uploadInvoiceAsync = (viewModel: IViewModel) => (dispatch: Handler.AnyForDispatch): Promise<Handler.AnyForDispatch> => {

        const promise = new Promise<void>((resolve, reject) => {

            const onSuccess = () => {

                Notification.success.recordCreated('Invoice has been successfully uploaded.');
                resolve();
            };

            const onFailure = (ajaxInfo: IAjaxInfo) => {

                this.allExceptionNotifier(ajaxInfo);
                reject();
            };

            const formData = FormHelper.objectToFormData(viewModel);

            this.api.callWithPayload(ActionService.uploadInvoice(), formData, onSuccess, onFailure, true);
        });

        return promise;
    }

    public uploadPurchaseOrderAsync = (viewModel: IViewModel) => (dispatch: Handler.AnyForDispatch): Promise<Handler.AnyForDispatch> => {

        const promise = new Promise<void>((resolve, reject) => {

            const onSuccess = () => {

                Notification.success.recordCreated('Purchase order has been successfully uploaded.');
                resolve();
            };

            const onFailure = (ajaxInfo: IAjaxInfo) => {

                this.allExceptionNotifier(ajaxInfo);
                reject();
            };

            const formData = FormHelper.objectToFormData(viewModel);

            this.api.callWithPayload(ActionService.uploadPurchaseOrder(), formData, onSuccess, onFailure, true);
        });

        return promise;
    }
}

const actionDispatcherFactory = (dispatch: Dispatch<IState>, progressFunctions: IApiProgressFunctions): IActions => {

    const notificationsTitle = 'Supply Request Listing';
    const actions = new SupplyRequestListingActions(progressFunctions, AllExceptionNotifier(notificationsTitle), QueryService, QueryActionTypes);

    return {
        closeAuctionAsync: (viewModel: IViewModel) => {
            return dispatch(actions.closeAuctionAsync(viewModel));
        },
        getAsync: (id: number) => {
            dispatch(actions.getAsync(id));
        },
        getTemplateAsync: null,
        getToolsAsync:  () => {
            dispatch(actions.getToolsAsync());
        },
        receiveCollection: (collection: ICollection) => {
            dispatch(actions.receiveCollection(collection));
        },
        receiveOptions: (options: IOptions) => {
            dispatch(actions.receiveOptions(options));
        },
        receiveTools: (tools: ITools) => {
            dispatch(actions.receiveTools(tools));
        },
        receiveUpsert: (upsert: IViewModel) => {
            dispatch(actions.receiveUpsert(upsert));
        },
        relistAuctionAsync: (viewModel: IViewModel) => {
            return dispatch(actions.relistAuctionAsync(viewModel));
        },
        resetAll: () => {
            dispatch(actions.resetAll());
        },
        searchAsync: (options: IOptions) => {
            dispatch(actions.searchAsync(options));
        },
        searchStrictAsync: (options: IOptions) => {
            dispatch(actions.searchStrictAsync(options));
        },
        uploadInvoiceAsync: (viewModel: IViewModel) => {
            return dispatch(actions.uploadInvoiceAsync(viewModel));
        },
        uploadPurchaseOrderAsync: (viewModel: IViewModel) => {
            return dispatch(actions.uploadPurchaseOrderAsync(viewModel));
        }
    };
};

export {
    actionDispatcherFactory as SupplyRequestListingActionsDispatcherFactory,
    IActions as ISupplyRequestListingActions
};
