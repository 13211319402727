// 3rd Party References
import { Action, handleActions } from 'redux-actions';

// Utility References
import { ReduxHelper } from 'Utility';

// App References
import {
    IApiRegistrationToolsViewModel as ITools,
    IApiRegistrationUpsertViewModel as IUpsert,
    IApiRegistrationViewModel as IViewModel
} from 'App/IndexOfInterfaces';
import {
    RegistrationAreaActionTypes as ActionTypes,
    RegistrationAreaQueryActionTypes as QueryActionTypes
} from 'App/IndexOfModels';

interface IRegistrationState {
    tools: ITools;
    upsert: IUpsert;
    usernameCollision: boolean;
}

const initialState = (): IRegistrationState => {
    return {
        tools: null,
        upsert: null,
        usernameCollision: false
    };
};

const actionWrapper = ReduxHelper.actionWrapper;

type Payload = boolean | ITools | IUpsert | IViewModel;

const registrationReducer = handleActions<IRegistrationState, Payload>({

    [ActionTypes.receive.usernameCollision]: (state: IRegistrationState, action: Action<boolean>): IRegistrationState => {
        return actionWrapper(state, action, (newState: IRegistrationState) => {
            newState.usernameCollision = action.payload;
        });
    },

    [QueryActionTypes.receive.upsert]: (state: IRegistrationState, action: Action<IUpsert>): IRegistrationState => {
        return actionWrapper(state, action, (newState: IRegistrationState) => {
            newState.upsert = action.payload;
        });
    }

}, initialState());

export {
    IRegistrationState,
    registrationReducer as RegistrationReducer
};
