// 3rd Party References
import * as React from 'react';

// Utility References
import { ObjectHelper, TranslationHelper } from 'Utility/IndexOfHelpers';

interface IToggleProps {
    closedInitially?: boolean;
    componentName: string;
    isAccordian?: boolean;
    name: string;
    title: string;
    triggerStyles?: string;
}

class Toggle extends React.Component<IToggleProps, {}> {

    render() {

        const { children, closedInitially, componentName, isAccordian, name, title, triggerStyles } = this.props;

        let labelClasses = 'toggle__trigger toggle__icon';

        if (!ObjectHelper.isUndefinedOrNull(triggerStyles) && triggerStyles.length > 0) {
            labelClasses += ' ' + triggerStyles;
        }

        let targetClasses = 'toggle__target toggle__target';
        let inputType = 'checkbox';

        if (closedInitially) {
            labelClasses += ' toggle__icon--closed';
            targetClasses += ' toggle__target--closed';
        }

        if (isAccordian) {
            inputType = 'radio';
            labelClasses += ' toggle__icon--closed';
            targetClasses += ' toggle__target--closed';
        }

        const translatedTitle = TranslationHelper.translate(title);

        return (
            <section className="u-mb-3">
                <input id={componentName} className="toggle" type={inputType} name={name} />
                <label htmlFor={componentName} className={labelClasses}>{translatedTitle}</label>
                <section className={targetClasses}>
                    {children}
                </section>
            </section>
        );
    }
}

export {
    IToggleProps,
    Toggle
};
