// 3rd Party References
import * as React from 'react';

// Utility References
//import {  } from '../../../IndexOfComponents';
//import {  } from '../../../IndexOfEnums';
//import {  } from '../../../IndexOfHelpers';
//import {  } from '../../../IndexOfInterfaces';
//import {  } from '../../../IndexOfModels';
//import {  } from '../../../IndexOfServices';

type UpdateFunc = (index: number) => void;

export interface IGridTabButtonsProps extends React.Props<GridTabButtons> {
    selectedTabIndex: number;
    tabNames: string[];
    tabOnChangeHandler: (index: number) => void;
}

export default class GridTabButtons extends React.Component<IGridTabButtonsProps, {}> {

    private handleClick = (onChange: UpdateFunc, selectedIndex: number) => () => {
        onChange(selectedIndex);
    }

    render() {

        const { selectedTabIndex, tabNames, tabOnChangeHandler } = this.props;

        const tabButtons = tabNames.map((buttonName: string, index: number) => {

            const isCurrentSelection = index === selectedTabIndex;
            const cssClassName = isCurrentSelection ? 'btn btn-primary active' : 'btn btn-primary';

            return (<button type="button" className={cssClassName} onClick={this.handleClick(tabOnChangeHandler, index)} key={buttonName}>{buttonName}</button>);
        });

        return (
            <div className="grid-tab-buttons btn-group btn-group-sm" role="group">
                {tabButtons}
            </div>
        );
    }
}
