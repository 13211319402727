// 3rd Party References
import * as moment from 'moment';
import * as React from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { hot } from 'react-hot-loader';

// Utility References
import { Handler } from 'Utility/IndexOfActions';
import { DateHelper } from 'Utility/IndexOfHelpers';
import { DateConverter } from 'Utility/IndexOfModels';

export interface ISimpleDateInputProps extends React.Props<SimpleDateInput> {
    dateFormat?: string;
    isClearable?: boolean;
    isDisabled?: boolean;
    isHidden?: boolean;
    isRequired?: boolean;
    maxDate?: moment.Moment | Date;
    minDate?: moment.Moment | Date;
    onBlurCallback?: Handler.InputFocusEvent;
    onChangeCallback: Handler.DateProperty;
    placeholder?: string;
    propertyName?: string;
    showMonthYearPicker?: boolean;
    showTimeSelect?: boolean;
    showYearDropdown?: boolean;
    tabIndex?: number;
    timeFormat?: string;
    timeIntervals?: number;
    value: moment.Moment | Date;
}

class SimpleDateInput extends React.Component<ISimpleDateInputProps, {}> {

    componentDidUpdate() {

        // our current datepicker is adding a close-icon to the control if clearable is set. unfortunetally this is taking the tab index focus on tabbing
        // set the tab index to -1 to avoid this
        if (this.props.isClearable) {
            const closeIcons = document.getElementsByClassName('close-icon');
            if (closeIcons.length > 0) {
                const closeIcon = closeIcons[0] as HTMLAnchorElement;
                closeIcon.tabIndex = -1;
            }
        }
    }

    render() {

        const {
            dateFormat,
            isClearable,
            isDisabled,
            isHidden,
            isRequired,
            maxDate,
            minDate,
            onBlurCallback,
            onChangeCallback,
            placeholder,
            propertyName,
            showMonthYearPicker,
            showTimeSelect,
            showYearDropdown,
            tabIndex,
            timeIntervals,
            timeFormat,
            value
        } = this.props;

        const dateFormatToApply = dateFormat || DateHelper.standardNativeDateFormatPattern();

        const maxDateToUse = DateConverter(maxDate).toJsDate();
        const minDateToUse = DateConverter(minDate).toJsDate();
        const selectedDateToUse = DateConverter(value).toJsDate();
        const timeFormatToApply = timeFormat || DateHelper.standardTimeFormatPattern();

        const reactDatePickerProps: ReactDatePickerProps = {
            className: isHidden ? 'form-control hidden' : 'form-control',
            dateFormat: dateFormatToApply,
            disabled: isDisabled,
            dropdownMode: 'select',
            isClearable,
            maxDate: maxDateToUse,
            minDate: minDateToUse,
            onBlur: (event: React.FocusEvent<HTMLInputElement>) => {

                if (onBlurCallback) {
                    onBlurCallback(event);
                }
            },
            onChange: (newValue: Date) => {

                if (newValue && moment(newValue).isValid()) {

                    onChangeCallback(newValue, propertyName);

                } else {
                    onChangeCallback(null, propertyName);
                }
            },
            placeholderText: placeholder,
            required: isRequired,
            selected: selectedDateToUse,
            showMonthYearPicker,
            showTimeSelect,
            showYearDropdown,
            tabIndex,
            timeFormat: timeFormatToApply,
            timeIntervals: timeIntervals || 5
        };

        return <ReactDatePicker {...reactDatePickerProps} />;
    }
}

const HotReactDatePicker = hot(module)(SimpleDateInput);

export {
    HotReactDatePicker as SimpleDateInput
};
