// 3rd Party References
import * as moment from 'moment';
import * as React from 'react';

// Utility References
import { Handler } from '../../IndexOfActions';
import { ISimpleDateInputProps, SimpleDateInput } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
import { ObjectHelper } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
import { DateConverter } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

export interface ISimpleMOTDateInputProps extends React.Props<SimpleMOTDateInput> {
    dateFormat?: string;
    isClearable?: boolean;
    isDisabled?: boolean;
    isHidden?: boolean;
    isRequired?: boolean;
    onBlurCallback?: Handler.InputFocusEvent;
    onChangeCallback: Handler.DateProperty;
    placeholder?: string;
    propertyName?: string;
    tabIndex?: number;
    value: moment.Moment | Date;
}

interface ISimpleMOTDateInputMergedProps extends ISimpleMOTDateInputProps {
    minDate: moment.Moment | Date;
}

export default class SimpleMOTDateInput extends React.Component<ISimpleMOTDateInputProps, {}> {

    render() {

        const { dateFormat, isClearable, isDisabled, isHidden, isRequired, onBlurCallback, onChangeCallback, placeholder, propertyName, tabIndex, value } = this.props;

        const minDateToUse = DateConverter(new Date()).toMomentDate() as moment.Moment | Date;

        const simpleDateInputProps: ISimpleDateInputProps = {
            dateFormat,
            isClearable,
            isDisabled,
            isHidden,
            isRequired,
            minDate: minDateToUse,
            onBlurCallback,
            onChangeCallback,
            placeholder,
            propertyName,
            tabIndex,
            value
        };

        return <SimpleDateInput {...simpleDateInputProps} />;
    }
}
