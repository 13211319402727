// Utility References
import { ExceptionHelper } from 'Utility/IndexOfHelpers';
import { IApiException, IApiExceptionGroup, IGenericDictionary } from 'Utility/IndexOfInterfaces';
import { Notification } from 'Utility/IndexOfServices';

const Exception = ExceptionHelper.ApiException;

interface IEmailServiceExceptions extends IGenericDictionary<IApiException> {
    SendFailure: IApiException;
}

const exceptions: IEmailServiceExceptions = {
    SendFailure: Exception('There was an error sending out email, please try again and if the issue persists contact MVR', Notification.error.handledException),
};

const exceptionGroup: IApiExceptionGroup = {
    area: 'EmailService',
    exceptions
};

export {
    exceptionGroup as EmailServiceExceptionGroup,
    exceptions as EmailServicedExceptions
};
