// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from 'Utility/IndexOfActions';
import { TranslationHelper } from 'Utility/IndexOfHelpers';
import { IModalService } from 'Utility/IndexOfServices';

// Local References
import { IInternalPromptModalProps, InternalPromptModal } from './Internal/InternalPromptModal';

interface IConfirmationPromptModalProps {
    actionOnConfirmed: Handler.Action1Optional<string>;
    bodyText: string;
    includeNote?: boolean;
    modalService: IModalService;
    okButtonText?: string;
    titleText?: string;
}

class ConfirmationPromptModal extends React.Component<IConfirmationPromptModalProps, {}> {

    private getOverrideText = (defaultText: string, overriddenText: string): string => {

        const textToUse = (overriddenText !== null && overriddenText !== undefined) ? overriddenText : defaultText;

        return TranslationHelper.translate(textToUse);
    }

    render() {

        const { actionOnConfirmed, bodyText, includeNote, modalService, okButtonText, titleText } = this.props;

        const handleOnClick = (actionOnConfirmed: Handler.Action1Optional<string>) => (isConfirmed: boolean, notes?: string) => {

            if (isConfirmed) {
                actionOnConfirmed(notes);
            }
        };

        const buttonTextDisplay = this.getOverrideText('Confirm', okButtonText);
        const titleTextDisplay = this.getOverrideText('Confirm', titleText);

        const internalPromptModalProps: IInternalPromptModalProps = {
            bodyText,
            includeNote,
            modalService,
            okButtonClass: 'btn-success',
            okButtonText: buttonTextDisplay,
            onClose: handleOnClick(actionOnConfirmed),
            titleText: titleTextDisplay
        };

        return (
            <InternalPromptModal {...internalPromptModalProps} />
        );
    }
}

export {
    ConfirmationPromptModal,
    IConfirmationPromptModalProps
};
