// 3rd Party
import * as React from 'react';
import { hot } from 'react-hot-loader';

// Utility References
import { Handler } from 'Utility/IndexOfActions';
import {
    ISimpleButtonProps,
    ISimpleCheckboxGridNumberProps,
    OkIcon,
    SimpleCheckboxGridNumber,
    SuccessButton
 } from 'Utility/IndexOfComponents';
import { ButtonStyle } from 'Utility/IndexOfEnums';
import { ObjectHelper } from 'Utility/IndexOfHelpers';
import { ModelUpdater } from 'Utility/IndexOfModels';

// App References
import {
    IApiAccountDetailsViewModel as IViewModel,
    IApiNotificationPreferenceToolsViewModel
} from 'App/IndexOfInterfaces';
import { IPageState } from 'App/IndexOfModels';

interface IPropActions {
    getAccountDetails: Handler.Action;
    getNotificationPreferenceOptions: Handler.Action;
    receiveAccountDetails: Handler.Action1<IViewModel>;
    resetAll: Handler.Action;
    updatePreferences: Handler.Action1<IViewModel>;
}

interface IPropData {
    accountDetails: IViewModel;
    notificationPreferenceOptions: IApiNotificationPreferenceToolsViewModel;
    pageState: IPageState;
}

interface IAccountPreferencesFormProps {
    propActions: IPropActions;
    propData: IPropData;
}

class Form extends React.Component<IAccountPreferencesFormProps, {}> {

    componentDidMount() {

        const { propActions, propData } = this.props;

        propActions.resetAll();
        propActions.getAccountDetails();
        propActions.getNotificationPreferenceOptions();
    }

    private handleOnSubmit = (propActions: IPropActions, viewModel: IViewModel): void => {

        propActions.updatePreferences(viewModel);
    }

    render() {

        const { propActions, propData } = this.props;

        const modelUpdater = ModelUpdater(propData.accountDetails, propActions.receiveAccountDetails);

        const checkboxGridProps: ISimpleCheckboxGridNumberProps = {
            gridClassName: 'checkbox-grid',
            hasSelectAll: false,
            isDisabled: propData.pageState.processing,
            itemClassName: 'checkbox-item col-sm-12',
            items: propData.notificationPreferenceOptions.notificationTypes,
            onSelectCallback: modelUpdater.for<number[]>((values, viewModel) => {

                viewModel.notificationPreferences = [];

                if (!ObjectHelper.isUndefinedOrNull(values) && values.length > 0) {
                    viewModel.notificationPreferences = values.map(x => x);
                }

                return viewModel;
            }),
            value: propData.accountDetails.notificationPreferences
        };

        const submitButtonProps: ISimpleButtonProps = {
            buttonOptions: {
                buttonStyle: ButtonStyle.SuccessOutline,
                buttonText: 'Update Preferences'
            },
            onClick: () => {

                this.handleOnSubmit(propActions, propData.accountDetails);
            },
            processing: propData.pageState.processing
        };

        return (
            <>
                <p>Please select which notifications you would like to receive</p>

                <form>
                    <SimpleCheckboxGridNumber {...checkboxGridProps} />

                    <SuccessButton {...submitButtonProps}>
                        <OkIcon />
                    </SuccessButton>
                </form>
            </>
        );
    }
}

const HotForm = hot(module)(Form);

export {
    HotForm as AccountPreferencesForm,
    IAccountPreferencesFormProps
};
