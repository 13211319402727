// 3rd Party References
import * as React from 'react';

// Utility References
import { Translation } from 'Utility/IndexOfComponents';
import { TranslationHelper } from 'Utility/IndexOfHelpers';

export interface IPanelHeadingProps extends React.HTMLProps<PanelHeading> {
    id?: string;
    isReadOnly?: boolean;
    panelTitle?: string;
    role?: string;
}

export class PanelHeading extends React.Component<IPanelHeadingProps, {}> {

    render() {

        const { id, isReadOnly, panelTitle, role } = this.props;

        const panelHeadingProps = {
            className: 'panel-heading clearfix',
            id,
            role
        };

        const readOnlyAlert = isReadOnly && (
            <div className="alert alert-warning panel-header-alert pull-right"><Translation>Read Only</Translation></div>
        );

        if (!!panelTitle) {

            const translatedTitle = TranslationHelper.translate(panelTitle);

            if (!!this.props.children) {

                return (
                    <div {...panelHeadingProps}>
                        <h3 className="panel-title panel-title-with-toolbar">{translatedTitle}</h3>
                        {readOnlyAlert}
                        <div className="btn-toolbar pull-right">{this.props.children}</div>
                    </div>
                );

            } else {

                return (
                    <div {...panelHeadingProps}>
                        <h3 className="panel-title">{translatedTitle}</h3>
                    </div>
                );
            }

        } else {

            if (!!this.props.children) {

                return (
                    <div {...panelHeadingProps}>
                        {readOnlyAlert}
                        <div className="btn-toolbar pull-right">{this.props.children}</div>
                    </div>
                );

            } else {

                return (
                    <div {...panelHeadingProps}></div>
                );
            }
        }
    }
}
