// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from 'Utility/IndexOfActions';
import {CommonDictionary, NumberHelper, StringHelper } from 'Utility/IndexOfHelpers';
import { IApiItemOptionViewModel } from 'Utility/IndexOfInterfaces';
import { Notification } from 'Utility/IndexOfServices';

// Local References
import InternalCommonSelect, { IInternalCommonSelectProps } from './Internal/InternalCommonSelect';

export interface ISimpleSelectNumericProps extends React.Props<SimpleSelectNumeric>  {
    alwaysUsePromptText?: boolean;
    disableSelfValueValidation?: boolean;
    displayName?: string;
    inputClassName?: string;
    isDisabled?: boolean;
    isFilter?: boolean;
    items: IApiItemOptionViewModel[];
    keepPleaseSelect?: boolean;
    onBlurCallback?: Handler.SelectFocusEvent;
    onChangeCallback: Handler.NumberProperty;
    onKeyPressCallback?: Handler.SelectKeyboardEvent;
    propertyName?: string;
    placeholder?: string;
    reportingValueCanBeZero?: boolean;
    tabIndex?: number;
    value: number;
}

export default class SimpleSelectNumeric extends React.Component<ISimpleSelectNumericProps, { valueReset: boolean }> {

    private primaryInput: InternalCommonSelect;

    constructor(props: ISimpleSelectNumericProps) {
        super(props);

        this.state = {
            valueReset: false
        };
    }

    public setFocus = (scroll?: number): void => {
        this.primaryInput.setFocus(scroll);
    }

    componentWillReceiveProps(nextProps: ISimpleSelectNumericProps) {

        // Need to validate that the value exists in the items
        const hasItemsAndValue = nextProps.items && !NumberHelper.isUndefinedNullOrZero(nextProps.value);
        const { valueReset } = this.state;

        if (!valueReset && hasItemsAndValue && !nextProps.disableSelfValueValidation) {

            const valueDoesNotExistInItems = nextProps.items.filter(x => x.value === nextProps.value.toString()).length === 0;

            if (valueDoesNotExistInItems) {

                // https://www.youtube.com/watch?v=uACvFAm6JSM
                if (nextProps.propertyName) {

                    const notificationText = nextProps.displayName ? nextProps.displayName : StringHelper.toSentenceCase(nextProps.propertyName);
                    Notification.warning.clickToDismiss(`${CommonDictionary.ValueIsNowInvalid} ${CommonDictionary.Property} (${notificationText}). ${CommonDictionary.PleaseReselect}`, notificationText);
                }

                this.props.onChangeCallback(null, nextProps.propertyName);

                this.setState({ valueReset: true });
            }
        }
    }

    private handleOnChange = (onChangeCallback: Handler.NumberProperty, propertyName?: string) => (value: string) => {

        if (value === undefined || value === null || value === '' || isNaN(parseInt(value, 10))) {

            onChangeCallback(null, propertyName);

        } else {

            onChangeCallback(parseInt(value, 10), propertyName);
        }
    }

    render() {

        const { alwaysUsePromptText, displayName, inputClassName, isDisabled, isFilter, items, keepPleaseSelect, onBlurCallback, onChangeCallback, onKeyPressCallback, placeholder, propertyName, reportingValueCanBeZero, tabIndex, value } = this.props;

        const valueIsNotUndefined = value !== undefined;
        const valueIsNotNull = value !== null;
        let valueIsNotZero = value !== 0;

        if (reportingValueCanBeZero) {
            valueIsNotZero = true;
        }

        const selectedValueAsString = valueIsNotUndefined && valueIsNotNull && valueIsNotZero
            // ReSharper disable once QualifiedExpressionMaybeNull
            // Can't be null cause I am assigning variable above! #rage
            ? value.toString()
            : null;

        const selectProps: IInternalCommonSelectProps = {
            alwaysUsePromptText,
            displayName,
            inputClassName,
            isDisabled,
            isFilter,
            items,
            keepPleaseSelect,
            onBlurCallback,
            onChangeCallback: this.handleOnChange(onChangeCallback, propertyName),
            onKeyPressCallback,
            promptText: placeholder,
            propertyName,
            reportingValueCanBeZero,
            ref: (component: InternalCommonSelect) => { this.primaryInput = component; },
            tabIndex,
            value: selectedValueAsString
        };

        return <InternalCommonSelect {...selectProps} />;
    }
}
