// 3rd Party References
import { Action, handleActions } from 'redux-actions';

// Utility References
import { ReduxHelper } from 'Utility';

// App References
import {
    IApiBidHistoryCollection as ICollection,
    IApiBidHistoryOptions as IOptions,
    IApiBidHistoryProvisionalViewModel as IProvisional,
    IApiBidHistoryRejectViewModel as IReject,
    IApiBidHistoryToolsViewModel as ITools,
    IApiBidHistoryUpsertViewModel as IUpsert
} from 'App/IndexOfInterfaces';
import {
    BidHistoryActionTypes as ActionTypes,
    BidHistoryQueryActionTypes as QueryActionTypes
} from 'App/IndexOfModels';

interface IState {
    collection: ICollection;
    options: IOptions;
    provisional: IProvisional;
    tools: ITools;
    upsert: IUpsert;
}

const initialState = (): IState => {

    return {
        collection: null,
        options: null,
        provisional: {
            bidHistoryId: 0,
            supplyRequestListingId: 0,
            rejections: []
        },
        tools: null,
        upsert: null
    };
};

const actionWrapper = ReduxHelper.actionWrapper;
type Payload = ICollection | IOptions | ITools | IUpsert | IProvisional | IReject[] | void;

const reducer = handleActions<IState, Payload>({

    [ActionTypes.receive.provisional]: (state: IState, action: Action<IProvisional>): IState => {
        return actionWrapper(state, action, (newState: IState) => {

            newState.provisional = action.payload;
        });
    },

    [ActionTypes.receive.rejections]: (state: IState, action: Action<IReject[]>): IState => {
        return actionWrapper(state, action, (newState: IState) => {

            if (newState.provisional){
                newState.provisional.rejections = action.payload;
            }
        });
    },

    [QueryActionTypes.receive.collection]: (state: IState, action: Action<ICollection>): IState => {
        return actionWrapper(state, action, (newState: IState) => {
            newState.collection = action.payload;
        });
    },

    [QueryActionTypes.receive.options]: (state: IState, action: Action<IOptions>): IState => {
        return actionWrapper(state, action, (newState: IState) => {
            newState.options = action.payload;
        });
    },

    [QueryActionTypes.receive.upsert]: (state: IState, action: Action<IUpsert>): IState => {
        return actionWrapper(state, action, (newState: IState) => {
            newState.upsert = action.payload;
        });
    },

    [QueryActionTypes.reset.all]: (): IState => {
        return initialState();
    }

}, initialState());

export {
    reducer as BidHistoryReducer,
    IState as IBidHistoryState
};
