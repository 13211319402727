import * as React from 'react';

// Utility References
import { ISimpleLabelProps, Modal, MyModal, SimpleLabel } from 'Utility/IndexOfComponents';
import { StringHelper, TranslationHelper } from 'Utility/IndexOfHelpers';
import { IModalService } from 'Utility/IndexOfServices';

type OnClose = (isConfirmed: boolean, notes?: string) => void;

interface IInternalPromptModalProps {
    bodyText: string;
    includeNote?: boolean;
    modalService: IModalService;
    okButtonClass: string;
    okButtonText: string;
    onClose: OnClose;
    titleText: string;
}

class InternalPromptModal extends React.Component<IInternalPromptModalProps, { hasError: boolean }> {

    constructor(props) {
        super(props);

        this.state = {
            hasError: false
        };
    }

    private notesInput: HTMLTextAreaElement;

    private confirmSelection = (modalService: IModalService, onClose: OnClose) => (confirmed: boolean) => (): void => {

        if (this.props.includeNote) {

            this.setState({ hasError: false });

            if (confirmed && !this.validate()) {
                return;
            }

            onClose(confirmed, this.notesInput.value);
        } else {

            onClose(confirmed);
        }

        modalService.toggleModal();
    }

    private validate = (): boolean => {

        let isValid = true;

        if (this.props.includeNote && StringHelper.isUndefinedNullOrEmpty(this.notesInput.value)) {
            isValid = false;
            this.setState({ hasError: true });
        }

        return isValid;
    }

    render() {

        const { modalService, includeNote, okButtonClass, okButtonText, onClose, bodyText, titleText } = this.props;

        const cancelButtonClass = 'btn btn-primary';
        const cancelButtonTextToUse = TranslationHelper.translate('Cancel');

        const okButtonClassToUse = `btn ${okButtonClass}`;
        const okButtonTextToUse = TranslationHelper.translate(okButtonText);

        const titleTextToUse = TranslationHelper.translate(titleText);
        const bodyTextToUse = TranslationHelper.translate(bodyText);

        const modalProps = {
            bsSize: 'small' as 'small', // Required hack to get around warning "Type 'string' is not assignable to type 'small' | 'sm' etc..."
            dialogClassName: 'custom-modal',
            onHide: modalService.toggleModal,
            show: modalService.isModalOpen()
        };

        const noteLabel: ISimpleLabelProps = {
            displayName: 'Comment:',
            fieldName: 'note-comment'
        };

        const noteClass = this.state.hasError ? 'form-group has-error' : 'form-group';
        const noteErrorMsg = this.state.hasError ? 'text-danger' : 'hidden';

        const confirmSelectionHandler = this.confirmSelection(modalService, onClose);

        return (
            <div id="myModal">
                <MyModal {...modalProps}>
                    <Modal.Header>
                        <Modal.Title>{titleTextToUse}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{bodyTextToUse}</p>
                        {
                            includeNote &&
                            <>
                                <div className={noteClass}>
                                    <SimpleLabel {...noteLabel} />
                                    <textarea className="form-control" id="note-comment" ref={(component: HTMLTextAreaElement) => { this.notesInput = component; }}></textarea>
                                    <p className={noteErrorMsg}>Please enter a note.</p>
                                </div>
                            </>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <button className={cancelButtonClass} onClick={confirmSelectionHandler(false)} type="button">
                            {cancelButtonTextToUse}
                        </button>

                        <button className={okButtonClassToUse} onClick={confirmSelectionHandler(true)} type="button">
                            {okButtonTextToUse}
                        </button>
                    </Modal.Footer>
                </MyModal>
            </div>
        );
    }
}

export {
    IInternalPromptModalProps,
    InternalPromptModal
};
