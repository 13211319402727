// 3rd Party
import * as React from 'react';
import { hot } from 'react-hot-loader';

// Navigation
import { AppUrls } from 'App/IndexOfUrls';

class Form extends React.Component<{}, {}> {

    render() {

        return (
            <>
                <a href={AppUrls.LoginUrls.changePassword}>Change your password</a>
            </>
        );
    }
}

const HotForm = hot(module)(Form);

export {
    HotForm as AccountSecurityForm
};
