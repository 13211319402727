// 3rd Party References
import * as React from 'react';

// Utility References
import { ITabItemProps, TabItem } from 'Utility/IndexOfComponents';
import { NumberHelper } from 'Utility/IndexOfHelpers';

export interface ITabMenuProps extends React.Props<TabMenu> {
    pills?: boolean;
    tabList: ITabItemProps[];
}

export class TabMenu extends React.Component<ITabMenuProps, {}> {

    render() {

        const { pills, tabList } = this.props;

        return (
            <ul className={`nav ${pills ? 'nav-pills' : 'nav-tabs'}`}>
                {
                    tabList.map((tab, index) => {
                        return <TabItem key={index} {...tab} />;
                    })
                }
            </ul>
        );
    }
}
