// Utility References
import { ExceptionHelper } from 'Utility/IndexOfHelpers';
import { IApiException, IApiExceptionGroup, IGenericDictionary } from 'Utility/IndexOfInterfaces';
import { Notification } from 'Utility/IndexOfServices';

const Exception = ExceptionHelper.ApiException;

interface IUserExceptions extends IGenericDictionary<IApiException> {
    NotFound: IApiException;
}

const exceptions: IUserExceptions = {
    NotFound: Exception('Your username and/or password were not correct. Please check your details and try again.', Notification.error.handledException)
};

const exceptionGroup: IApiExceptionGroup = {
    area: 'User',
    exceptions
};

export {
    exceptionGroup as UserExceptionGroup,
    exceptions as UserExceptions
};
