// 3rd Party References
import * as React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

// Utility References
import { Handler } from 'Utility/IndexOfActions';

export interface ITabItemProps extends React.Props<TabItem> {
    isDisabled?: boolean;
    navLinkProps: NavLinkProps;
    onClick?: Handler.Action;
    text: string;
}

export class TabItem extends React.Component<ITabItemProps, {}> {

    render() {

        const { isDisabled, onClick, navLinkProps, text } = this.props;

        const onClickEvent = isDisabled ? null : onClick;

        return (
            <li role="presentation" onClick={onClickEvent} className="nav-item">
                <NavLink {...navLinkProps} className={`nav-link${isDisabled ? ' disabled' : ''}`}>{text}</NavLink>
            </li>
        );
    }
}
