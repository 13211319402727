// 3rd Party References
import * as React from 'react';

// Utility References
import { BooleanConverter } from 'Utility/IndexOfModels';

export interface IYesNoDisplayProps {
    value: boolean;
}

const yesNoDisplay = (props: IYesNoDisplayProps): JSX.Element => {

    const { value } = props;
    const displayText = BooleanConverter(value).toYesNoDisplay();

    return (
        <>{displayText}</>
    );
};

export {
    yesNoDisplay as YesNoDisplay
};
