// 3rd Party References
import * as React from 'react';

// Utility References
import { RouterHelper, TranslationHelper } from 'Utility/IndexOfHelpers';

import GridLinkButton from '../Internal/GridLinkButton';

export interface IGridRowEditLinkButtonProps {
    blockButton?: boolean;
    href: string;
    isDisabled?: boolean;
    targetNewTab?: boolean;
}

export default class GridRowEditLinkButton extends React.Component<IGridRowEditLinkButtonProps, {}> {

    render() {

        const { blockButton, href, isDisabled, targetNewTab } = this.props;

        const buttonProps = {
            cssButtonClass: `btn-primary${ blockButton ? ' btn-block' : '' }`,
            cssIconClass: 'fa-pencil',
            href,
            isDisabled,
            standardLabel: TranslationHelper.translate('Edit'),
            targetNewTab
        };

        return (
            <GridLinkButton {...buttonProps} />
        );
    }
}
