// 3rd Party References
import * as React from 'react';
import { CSVLink } from 'react-csv';

interface ISimpleCsvDownloadLinkProps {
    className?: string;
    data: any;
    filename: string;
    headers: any;
    linkText?: string;
    linkRef?: any;
    visible?: boolean;
}

class SimpleCsvDownloadLink extends React.Component<ISimpleCsvDownloadLinkProps, {}> {

    render() {

        const { className, data, filename, headers, linkText, linkRef, visible } = this.props;

        const csvLinkProps = {
            className,
            data,
            filename,
            headers
        };

        return (
            <CSVLink {...csvLinkProps} style={{display: visible ? 'block' : 'none'}} ref={linkRef} target="_blank">{linkText}</CSVLink>
        );
    }
}

export {
    ISimpleCsvDownloadLinkProps,
    SimpleCsvDownloadLink
};
