interface IAppConfiguration {
    apiUrl: string;
}

const appGlobals = (window as any).appGlobals;

const getConfig = (): IAppConfiguration => {
    return {
        apiUrl: appGlobals.apiUrl
    };
};

const getApiControllerUrl = (endpoint: string): string => {
    return `${appGlobals.apiUrl}/api/${endpoint}`;
};

const appService = () => {

    return {
        config: getConfig(),
        apiController: (endpoint: string) => getApiControllerUrl(endpoint)
    };
};

export { appService as AppService };
