// 3rd Party
import * as React from 'react';
import { hot } from 'react-hot-loader';

// Base References
import { RoutedBaseView } from '../Base/View';

// App References
import { ISupplyRequestFormProps, SupplyRequestForm } from 'App/IndexOfModels';

class DetailsView extends RoutedBaseView {

    render() {

        const { appActions, appState, match } = this.props;

        const formProps: ISupplyRequestFormProps = {
            propActions: {
                get: appActions.supplyRequestActions.getAsync,
                getLinkedToolsAsync: appActions.supplyRequestActions.getLinkedToolsAsync,
                getTemplate: appActions.supplyRequestActions.getTemplateAsync,
                pageActions: appActions.pageActions,
                receiveUpsert: appActions.supplyRequestActions.receiveUpsert,
                resetAll: appActions.supplyRequestActions.resetAll,
                saveAsync: appActions.supplyRequestActions.saveAsync,
                updateAsync: appActions.supplyRequestActions.updateAsync
            },
            propData: {
                pageState: appState.page,
                supplyRequestId: parseInt(match.params.id),
                upsert: appState.supplyRequest.upsert
            }
        };

        return (
            <SupplyRequestForm {...formProps} />
        );
    }
}

const HotView = hot(module)(DetailsView);

export {
    HotView as SupplyRequestDetailsView
};
