// 3rd Party References
import * as classNames from 'classnames';
import * as React from 'react';

// Utility
import { StatusIndicator } from 'Utility/IndexOfEnums';

interface ISimpleIconProps {
    textClass?: string;
    isDisabled?: boolean;
    isErrored?: boolean;
    processing?: boolean;
    statusIndicator?: StatusIndicator;
}

const addIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-plus" {...props} />;
};

const alertIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-alert" {...props} />;
};

const blockedIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-ban" {...props} />;
};

const cancelIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-times" {...props} />;
};

const checkIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-check" {...props} />;
};

const chevronDownIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-chevron-down" {...props} />;
};

const chevronUpIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-chevron-up" {...props} />;
};

const clearIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-times-circle-o" {...props} />;
};

const clockIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-clock-o" {...props} />;
};

const cogIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-cog" {...props} />;
};

const currencyIcon = (props: ISimpleIconProps): JSX.Element => {

    //TODOxUI: Get money icon depending on auction and not user culture
    return <InternalSimpleIcon iconClass="fa-gbp" {...props} />;
};

const editIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-pencil" {...props} />;
};

const editInModalIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-edit" {...props} />;
};

const exclamationTriangleIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-exclamation-triangle" {...props} />;
};

const fileIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-file" {...props} />;
};

const flagIcon = (props: ISimpleIconProps): JSX.Element => {

    // TODOxUI: Look at converting textClass to a type and passing directly into the internal component
    return <InternalSimpleIcon iconClass={`fa-flag ${ props.textClass }`} {...props} />;
};

const holdIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-ban-circle" {...props} />;
};

const infoCircleIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-info" {...props} />;
};

const infoIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-info-sign" {...props} />;
};

const keyIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-key" {...props} />;
};

const listAltIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-list-alt" {...props} />;
};

const lockIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-lock" {...props} />;
};

const nextIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-chevron-right" {...props} />;
};

const okCircleIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-check-circle" {...props} />;
};

const okIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-check" {...props} />;
};

const prevIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-chevron-left" {...props} />;
};

const printIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-print" {...props} />;
};

const refreshIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-refresh" {...props} />;
};

const removeCircleIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-times-circle-o" {...props} />;
};

const removeIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-times" {...props} />;
};

const saveIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-save" {...props} />;
};

const saveExcelIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-save-file" {...props} />;
};

const saveFileIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-save-file" {...props} />;
};

const savePdfIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-save-file" {...props} />;
};

const searchIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-search" {...props} />;
};

const sendEmailIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-send" {...props} />;
};

const signInIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-sign-in" {...props} />;
};

const thumbsDownIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-thumbs-down" {...props} />;
};

const thumbsUpIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-thumbs-up" {...props} />;
};

const timeIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-time" {...props} />;
};

const transferIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-transfer" {...props} />;
};

const trashIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-trash" {...props} />;
};

const undoIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-undo" {...props} />;
};

const uncheckIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-unchecked" {...props} />;
};

const unlockIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-unlock" {...props} />;
};

const userCircleIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-user-circle" {...props} />;
};

const userCircleOutlineIcon = (props: ISimpleIconProps): JSX.Element => {

    return <InternalSimpleIcon iconClass="fa-user-circle-o" {...props} />;
};

export {
    addIcon as AddIcon,
    alertIcon as AlertIcon,
    blockedIcon as BlockedIcon,
    cancelIcon as CancelIcon,
    checkIcon as CheckIcon,
    chevronDownIcon as ChevronDownIcon,
    chevronUpIcon as ChevronUpIcon,
    clearIcon as ClearIcon,
    cogIcon as CogIcon,
    clockIcon as ClockIcon,
    currencyIcon as CurrencyIcon,
    editIcon as EditIcon,
    editInModalIcon as EditInModalIcon,
    exclamationTriangleIcon as ExclamationTriangleIcon,
    fileIcon as FileIcon,
    flagIcon as FlagIcon,
    holdIcon as HoldIcon,
    infoCircleIcon as InfoCircleIcon,
    infoIcon as InfoIcon,
    keyIcon as KeyIcon,
    listAltIcon as ListAltIcon,
    lockIcon as LockIcon,
    nextIcon as NextIcon,
    okCircleIcon as OkCircleIcon,
    okIcon as OkIcon,
    prevIcon as PrevIcon,
    printIcon as PrintIcon,
    refreshIcon as RefreshIcon,
    removeCircleIcon as RemoveCircleIcon,
    removeIcon as RemoveIcon,
    saveIcon as SaveIcon,
    saveExcelIcon as SaveExcelIcon,
    saveFileIcon as SaveFileIcon,
    savePdfIcon as SavePdfIcon,
    searchIcon as SearchIcon,
    sendEmailIcon as SendEmailIcon,
    signInIcon as SignInIcon,
    thumbsDownIcon as ThumbsDownIcon,
    thumbsUpIcon as ThumbsUpIcon,
    timeIcon as TimeIcon,
    transferIcon as TransferIcon,
    trashIcon as TrashIcon,
    uncheckIcon as UncheckIcon,
    undoIcon as UndoIcon,
    unlockIcon as UnlockIcon,
    userCircleIcon as UserCircleIcon,
    userCircleOutlineIcon as UserCircleOutlineIcon
};

// Internal Component
interface IInternalSimpleIconProps extends ISimpleIconProps {
    iconClass: string;
}

// ReSharper disable once InconsistentNaming
const InternalSimpleIcon = (props: IInternalSimpleIconProps): JSX.Element => {

    const { iconClass, isDisabled, isErrored, processing, textClass } = props;

    const iconProps = {
        className: classNames({
            'fa': true,
            'fa-ban': processing || isDisabled,
            'fa-alert': isErrored,
            [`${iconClass} ${textClass || ''}`]: !processing
        })
    };

    return <i {...iconProps}></i>;
};
