// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from '../../IndexOfActions';
import { BlockedIcon } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
//import {  } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

export interface ISimpleCheckboxInputProps extends React.Props<SimpleCheckboxInput> {
    id?: string;
    isDisabled?: boolean;
    isHidden?: boolean;
    onChangeCallback: Handler.BooleanProperty;
    value: boolean;
}

export class SimpleCheckboxInput extends React.Component<ISimpleCheckboxInputProps, {}> {

    private onChange = (isDisabled: boolean, onChangeCallback: Handler.BooleanValue, value: boolean) => (event: React.MouseEvent<HTMLInputElement>) => {

        event.stopPropagation();

        if (isDisabled) {
            return;
        }

        const newValue = !value;
        onChangeCallback(newValue);
    }

    render() {

        const { id, isDisabled, isHidden, onChangeCallback, value } = this.props;

        const inputProps: React.HTMLProps<HTMLInputElement> = {
            id,
            checked: value || false,
            disabled: isDisabled,
            onChange: this.onChange(isDisabled, onChangeCallback, value),
            type: 'checkbox',
            value: value ? 'true' : 'false'
        };

        if (isDisabled) {
            inputProps.className = ' input-disabled';
        }

        if (isHidden) {
            inputProps.className = ' hidden';
        }

        if (isDisabled) {
            return <BlockedIcon />;
        }

        return (
            <input {...inputProps} />
        );
    }
}
