// 3rd Party
import * as React from 'react';
import { hot } from 'react-hot-loader';

// Base References
import { BaseView } from '../Base/View';

// App References
import {
    ISupplyRequestFormProps,
    SupplyRequestForm
} from 'App/IndexOfModels';

const appGlobals = (window as any).appGlobals;

class View extends BaseView<{}> {

    render() {

        const { appActions, appState } = this.props;

        const formProps: ISupplyRequestFormProps = {
            propActions: {
                get: appActions.supplyRequestActions.getAsync,
                getLinkedToolsAsync: appActions.supplyRequestActions.getLinkedToolsAsync,
                getTemplate: appActions.supplyRequestActions.getTemplateAsync,
                pageActions: appActions.pageActions,
                receiveUpsert: appActions.supplyRequestActions.receiveUpsert,
                resetAll: appActions.supplyRequestActions.resetAll,
                saveAsync: appActions.supplyRequestActions.saveAsync,
                updateAsync: appActions.supplyRequestActions.updateAsync
            },
            propData: {
                pageState: appState.page,
                upsert: appState.supplyRequest.upsert
            }
        };

        return (
            <SupplyRequestForm {...formProps} />
        );
    }
}

const HotView = hot(module)(View);

export {
    HotView as SupplyRequestView
};
