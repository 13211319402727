// Utility References
import {
    IApiCall,
    IApiCallWithPayload,
    IGenericQueryApi
} from 'Utility/IndexOfInterfaces';

// App References
import {
    IApiRegistrationToolsViewModel as ITools,
    IApiRegistrationUpsertViewModel as IUpsert,
    IApiRegistrationViewModel as IViewModel
} from 'App/IndexOfInterfaces';

import { Actions, AppService, HttpVerbs } from 'App/IndexOfServices';

const registrationController = AppService().apiController('Registration');

const queryService: IGenericQueryApi<null, null, ITools, IUpsert> = {
    get: null,
    search: null,
    template: (): IApiCall<IUpsert> => {
        return {
            method: HttpVerbs.GET,
            url: `${registrationController}/${Actions.TEMPLATE}`,
            withoutAuth: true
        };
    },
    tools: null
};

const actionService = {
    save: (): IApiCallWithPayload<FormData, number> => {
        return {
            method: HttpVerbs.POST,
            url: `${registrationController}`,
            withoutAuth: true
        };
    },
    doesUsernameCollide: (username: string): IApiCall<boolean> => {
        return {
            method: HttpVerbs.GET,
            url: `${registrationController}/UsernameCollides/${username}`,
            withoutAuth: true
        };
    }
};

export {
    actionService as RegistrationApi,
    queryService as RegistrationQueryService
};
