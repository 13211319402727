// 3rd Party References
import * as React from 'react';
import * as ReactDOM from 'react-dom';

// Utility References
//import {  } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
//import {  } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

// Local References
//...

export class RenderInBody extends React.Component<React.HTMLProps<RenderInBody>, {}> {

    private htmlElement: HTMLDivElement;

    componentDidMount() {
        this.htmlElement = document.createElement('div');
        document.body.appendChild(this.htmlElement);
        this.renderLayer();
    }

    componentDidUpdate() {
        this.renderLayer();
    }

    componentWillUnmount() {
        ReactDOM.unmountComponentAtNode(this.htmlElement);
        document.body.removeChild(this.htmlElement);
    }

    private renderLayer = () => {
        ReactDOM.render(<div className="renderInBody">{this.props.children}</div>, this.htmlElement);
    }

    render() {
        const nullable: JSX.Element = null;
        // ReSharper disable once ExpressionIsAlwaysConst
        return nullable;
    }
}
