// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from '../../IndexOfActions';
import { RemoveIcon } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
//import {  } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

// Local References
// ..

export interface IWrapperProps {
    title: string;
}

export class Wrapper extends React.Component<IWrapperProps, {}> {

    render() {

        const { title } = this.props;

        return (
            <div className="simple-tag-text-wrapper">
                <span className="tag-set-title">{title}</span>{this.props.children}
            </div>
        );
    }
}

export interface IReadOnlyTagProps {
    isAltStyle?: boolean;
    text: string;
    value?: string;
}

export class ReadOnlyTag extends React.Component<IReadOnlyTagProps, {}> {

    private getValueDisplay = (value: string) => {

        return (
            <span className="simple-tag-text value-display">
                {value}
            </span>
        );
    }

    render() {

        const { isAltStyle, text, value } = this.props;

        const className = `simple-tag-text readonly-tag-text ${isAltStyle ? 'alt-style' : 'default-style'}`;

        return (
            <span className={className}>
                <span className="text-display">{text}</span>
                {!!value && this.getValueDisplay(value)}
            </span>
        );
    }
}

export interface IClickableTagProps {
    id: number;
    onClickCallback: Handler.Action1<number>;
    text: string;
    value?: string;
}

export class ClickableTag extends React.Component<IClickableTagProps, {}> {

    private handleOnClick = (id: number, onClickCallback: Handler.Action1<number>) => () => {
        onClickCallback(id);
    }

    private getValueDisplay = (value: string) => {

        return (
            <span className="simple-tag-text value-display">
                {value}
            </span>
        );
    }

    render() {

        const { id, onClickCallback, text, value } = this.props;

        return (
            <span className="simple-tag-text clickable-tag-text" onClick={this.handleOnClick(id, onClickCallback)}>
                <span className="text-display">{text}</span>
                {!!value && this.getValueDisplay(value)}
                <span className="icon-holder">
                    <RemoveIcon />
                </span>
            </span>
        );
    }
}
