// 3rd Party References
import * as React from 'react';

// Utility References
import { TextAlignment } from 'Utility/IndexOfEnums';
import { NumericConverter } from 'Utility/IndexOfModels';

export enum DistanceFormat {
    Standard,
    NoneFractional
}

export enum DistanceUnit {
    Kms,
    Miles
}

export interface IDistanceDisplayProps {
    alignment?: TextAlignment.Horizontal;
    displayOnFail?: string;
    emptyWhenNull?: boolean;
    format?: DistanceFormat;
    units?: DistanceUnit;
    value: number;
}

const distanceDisplay = (props: IDistanceDisplayProps): JSX.Element => {

    const { alignment, displayOnFail, format, emptyWhenNull, units, value } = props;

    if (emptyWhenNull && (value === null || value === undefined)) {
        return <div></div>;
    }

    let displayValue: string;
    const defaultValue = displayOnFail ? displayOnFail : '0';

    switch (format) {

        case DistanceFormat.NoneFractional:
            displayValue = NumericConverter(value, defaultValue).toNoneFractionalDisplay();
            break;

        case DistanceFormat.Standard:
        default:
            displayValue = NumericConverter(value, defaultValue).toStandardDisplay();
            break;
    }

    displayValue += units == null ? DistanceUnit[DistanceUnit.Kms].toLowerCase() : DistanceUnit[units].toLowerCase();

    const alignmentToUse = alignment || TextAlignment.Horizontal.Right;

    const divProps = {
        className: TextAlignment.appendToCssClass(alignmentToUse)
    };

    return (
        <div {...divProps}>
            {displayValue}
        </div>
    );
};

export {
    distanceDisplay as DistanceDisplay
};
