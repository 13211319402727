// 3rd Party
import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';

// Utility References
import { IAjaxInfo, IApiProgressFunctions } from 'Utility';
import { Handler } from 'Utility/IndexOfActions';
import { Notification } from 'Utility/IndexOfServices';

// App References
import {
    AllExceptionNotifier,
    IApiAccountDetailsViewModel as IViewModel,
    IApiNotificationPreferenceToolsViewModel as ITools
} from 'App/IndexOfInterfaces';
import { ClientContactApi} from 'App/IndexOfServices';
import { Actions } from 'App/Models/Base/Actions';

// State
import { IAccountDetailsState as IState } from './State';

// Model References
import { AccountDetailsActionTypes as ActionTypes } from './Models';

interface IAccountDetailsActions {
    getAccountDetails: Handler.Action;
    getNotificationPreferenceOptions: Handler.Action;
    receiveAccountDetails: Handler.Action1<IViewModel>;
    resetAll: Handler.Action;
    updatePreferences: Handler.Action1<IViewModel>;
}

class AccountDetailsActions extends Actions {

    constructor(apiProgressFunctions: IApiProgressFunctions, public allExceptionNotifier: Handler.Action1<IAjaxInfo>){
        super(apiProgressFunctions);
    }

    public getAccountDetails = () => (dispatch: Handler.AnyForDispatch): void => {

        const onSuccess = (viewModel: IViewModel) => {
            dispatch(this.receiveAccountDetails(viewModel));
        };

        this.api.callWithPayload(ClientContactApi.getAccountDetails(), null, onSuccess, this.allExceptionNotifier);
    }

    public getNotificationPreferenceOptions = () => (dispatch: Handler.AnyForDispatch): void => {

        const onSuccess = (viewModel: ITools) => {
            dispatch(this.receiveNotificationPreferenceOptions(viewModel));
        };

        this.api.callWithPayload(ClientContactApi.getNotificationPreferenceOptions(), null, onSuccess, this.allExceptionNotifier);
    }

    public receiveAccountDetails = createAction(
        ActionTypes.receive.accountDetails, (viewModel: IViewModel) => viewModel
    );

    public receiveNotificationPreferenceOptions = createAction(
        ActionTypes.receive.notificationPreferenceOptions, (viewModel: ITools) => viewModel
    );

    public resetAll = createAction(ActionTypes.reset.all);

    public updatePreferences = (viewModel: IViewModel) => (dispatch: Handler.AnyForDispatch): void => {

        const onSuccess = () => {

            Notification.success.importantRecordUpdated('Your notification preferences have been updated.', 'Account Details');

        };

        this.api.callWithPayload(ClientContactApi.updatePreferences(), viewModel, onSuccess, this.allExceptionNotifier);
    }
}

const accountDetailsActionsFactory = (dispatch: Dispatch<IState>, progressFunctions: IApiProgressFunctions): IAccountDetailsActions => {

    const notitifationsTitle = 'Account Details';
    const actions = new AccountDetailsActions(progressFunctions, AllExceptionNotifier(notitifationsTitle));

    return {
        getAccountDetails: () => {
            dispatch(actions.getAccountDetails());
        },
        getNotificationPreferenceOptions: () => {
            dispatch(actions.getNotificationPreferenceOptions());
        },
        receiveAccountDetails: (viewModel: IViewModel) => {
            dispatch(actions.receiveAccountDetails(viewModel));
        },
        resetAll: () => {
            dispatch(actions.resetAll());
        },
        updatePreferences: (viewModel: IViewModel) => {
            dispatch(actions.updatePreferences(viewModel));
        }
    };
};

export {
    accountDetailsActionsFactory as AccountDetailsActionsDispatcherFactory,
    IAccountDetailsActions as IAccountDetailsActions
};
