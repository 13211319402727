// 3rd Party References
import * as React from 'react';
import { hot } from 'react-hot-loader';

// Utility References
import { Handler } from 'Utility/IndexOfActions';
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    ISearchButtonToolbarProps,
    ISimpleStringInputProps,
    SearchButtonToolbar,
    SimpleStringInput
} from 'Utility/IndexOfComponents';
import { FormHelper } from 'Utility/IndexOfHelpers';
import { ModelUpdater } from 'Utility/IndexOfModels';

// App References
import {
    IApiSupplyRequestOptions as IOptions,
    IApiSupplyRequestToolsViewModel as ITools
} from 'App/IndexOfInterfaces';
import { IPageState } from 'App/IndexOfModels';

interface IPropActions {
    resetForm: Handler.Action;
    search: Handler.Action1<IOptions>;
    updateOptions: Handler.Action1<IOptions>;
}

interface IPropData {
    options: IOptions;
    pageState: IPageState;
    tools: ITools;
}

interface IFilterProps {
    propActions: IPropActions;
    propData: IPropData;
}

class Filter extends React.Component<IFilterProps> {

    render() {

        const { propActions, propData } = this.props;

        const options = propData.options;
        const filter = options && options.filterOptions;

        const modelUpdater = ModelUpdater(options, propActions.updateOptions);

        const searchButtonToolbarProps: ISearchButtonToolbarProps = {
            filterOptions: options,
            reset: propActions.resetForm,
            search: propActions.search,
            updateOptions: propActions.updateOptions
        };

        const searchClaimReferenceProps: ISimpleStringInputProps = {
            onChangeCallback: modelUpdater.for<string>((value, model) => {

                model.filterOptions.claimReference = value;
                return model;
            }),
            placeholder: 'Claim Reference',
            value: filter.claimReference
        };

        return (
            <div className="mb-3">
                <Card>
                    <CardHeader>
                        <CardTitle text="Search Options" />
                    </CardHeader>

                    <CardBody>
                        <form className="form-horizontal" onSubmit={FormHelper.preventFormSubmit}>

                            <div className="row">

                                <div className="col-md-3">
                                    <SimpleStringInput {...searchClaimReferenceProps} />
                                </div>

                                <div className="col d-flex h-100 justify-content-end align-self-center">
                                    <SearchButtonToolbar {...searchButtonToolbarProps} />
                                </div>
                            </div>

                        </form>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

const HotFilter = hot(module)(Filter);

export {
    HotFilter as SupplyRequestFilter,
    IFilterProps as ISupplyRequestFilterProps
};
