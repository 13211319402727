// 3rd Party References
//import {  } from 'react';
import * as React from 'react';

// Utility References
//import {  } from '../../IndexOfActions';
//import {  } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
//import {  } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
import { DateConverter } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

export enum TimeFormats {
    TwelveHourTimeDisplay,
    TwentyFourHourTimeDisplay
}

export interface ITimeDisplayProps {
    displayOnFail?: string;
    format?: TimeFormats;
    value: Date;
}

const timeDisplay = (props: ITimeDisplayProps): JSX.Element => {

    const { displayOnFail, format, value } = props;

    let displayValue: string;
    const defaultValue = displayOnFail ? displayOnFail : 'No Time';

    switch (format) {

        case TimeFormats.TwelveHourTimeDisplay:
            displayValue = DateConverter(value, defaultValue).to12HrTimeDisplay();
            break;

        case TimeFormats.TwentyFourHourTimeDisplay:
        default:
            displayValue = DateConverter(value, defaultValue).to24HrTimeDisplay();
            break;
    }

    return (
        <time>
            {displayValue}
        </time>
    );
};

export {
    timeDisplay as TimeDisplay,
};
