// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from 'Utility/IndexOfActions';
import { TranslationHelper } from 'Utility/IndexOfHelpers';

export interface ISimpleOptionPairProps {
    labelForTrue: string;
    labelForFalse: string;
    onChangeCallback: Handler.NullableBooleanValue;
    value: boolean;
}

export default class SimpleOptionPair extends React.Component<ISimpleOptionPairProps, {}> {

    private handleTrueOptionChange = (onChangeCallback: Handler.NullableBooleanValue) => (event: React.FormEvent<HTMLInputElement>) => {
        const option = event.target as HTMLInputElement;
        const value = option.checked ? true : null;

        onChangeCallback(value);
    }

    private handleFalseOptionChange = (onChangeCallback: Handler.NullableBooleanValue) => (event: React.FormEvent<HTMLInputElement>) => {
        const option = event.target as HTMLInputElement;
        const value = option.checked ? false : null;

        onChangeCallback(value);
    }

    private handleLabelClick = (onChangeCallback: Handler.NullableBooleanValue, value: boolean) => () => {
        onChangeCallback(value);
    }

    render() {

        const { onChangeCallback, labelForTrue, labelForFalse, value } = this.props;

        const handleTrueOptionChange = this.handleTrueOptionChange(onChangeCallback);
        const handleFalseOptionChange = this.handleFalseOptionChange(onChangeCallback);

        const trueListItemProps = {
            className: value ? 'list-group-item active-option' : 'list-group-item',
            onClick: this.handleLabelClick(onChangeCallback, true)
        };

        const falseListItemProps = {
            className: value === false ? 'list-group-item active-option' : 'list-group-item',
            onClick: this.handleLabelClick(onChangeCallback, false)
        };

        const trueOptionProps = {
            checked: value != null ? value : false,
            id: labelForTrue,
            name: labelForTrue,
            onChange: handleTrueOptionChange,
            type: 'radio',
            value: 'true'
        };

        const falseOptionProps = {
            checked: value !== null ? !value : false,
            id: labelForFalse,
            name: labelForFalse,
            onChange: handleFalseOptionChange,
            type: 'radio',
            value: 'false'
        };

        return (
            <ul className="list-group simple-option-pair">
                <li {...trueListItemProps} >
                    <input {...trueOptionProps} /> {TranslationHelper.translate(labelForTrue)}
                </li>
                <li {...falseListItemProps} >
                    <input {...falseOptionProps}/> {TranslationHelper.translate(labelForFalse)}
                </li>
            </ul>
        );
    }
}
