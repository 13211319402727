// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from '../../IndexOfActions';

interface ISimpleTextAreaProps extends React.Props<SimpleTextArea> {
    isDisabled?: boolean;
    isHidden?: boolean;
    maxLength?: number;
    onChangeCallback: Handler.StringProperty;
    onPasteCallback?: Handler.Action;
    placeholder?: string;
    propertyName?: string;
    rowCount?: number;
    value: string;
}

class SimpleTextArea extends React.Component<ISimpleTextAreaProps, {}> {

    private onChange = (onChangeCallback: Handler.StringProperty, propertyName?: string) => (event: React.FormEvent<HTMLTextAreaElement>) => {

        const target = event.target as HTMLTextAreaElement;
        onChangeCallback(target.value, propertyName);
    }

    render() {

        const { isDisabled, isHidden, maxLength, onChangeCallback, onPasteCallback, placeholder, propertyName, rowCount, value } = this.props;

        const containerDivProps = {
            className: ''
        };

        if (isHidden) {
            containerDivProps.className = containerDivProps.className + ' hidden';
        }

        const textareaProps = {
            className: 'form-control',
            disabled: isDisabled,
            id: propertyName,
            maxLength,
            onChange: this.onChange(onChangeCallback, propertyName),
            onPaste: onPasteCallback,
            placeholder: placeholder || '',
            rows: rowCount || 3,
            value: (value !== undefined && value !== null) ? value : ''
        };

        return (
            <div {...containerDivProps}> {/* React textarea uses value attribute not text node */}
                <textarea {...textareaProps} />
            </div>
        );
    }
}

const SimpleTextAreaHot = SimpleTextArea;

export {
    ISimpleTextAreaProps,
    SimpleTextAreaHot as SimpleTextArea
};
