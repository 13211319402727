// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from 'Utility/IndexOfActions';

// Local References
import { ISimpleTextAreaProps, SimpleTextArea } from '../Inputs/SimpleTextArea';

interface ISimpleMultiCommaInputProps {
    isDisabled?: boolean;
    isHidden?: boolean;
    onChangeCallback: Handler.StringProperty;
    placeholder?: string;
    propertyName?: string;
    value: string;
}

class SimpleMultiCommaInput extends React.Component <ISimpleMultiCommaInputProps, { isPasteEvent: boolean }> {

    constructor(props: ISimpleMultiCommaInputProps) {
        super(props);

        this.state = {
            isPasteEvent: false
        };
    }

    private handleOnChange = (onChangeCallback: Handler.StringProperty, isPasteEvent: boolean) => (value: string, propertyName: string) => {

        const whiteLineRegEx = /[\n\r]/g;

        const whiteLineMatch = value.match(whiteLineRegEx);
        const hasWhiteLines = whiteLineMatch && whiteLineMatch.length > 0;

        let modifiedValue = value.replace(whiteLineRegEx, ',').replace(/\s/g, '');

        if (isPasteEvent) {

            modifiedValue = modifiedValue.substring(0, hasWhiteLines ? modifiedValue.length - 1 : modifiedValue.length);

            this.setState({ isPasteEvent: false });
        }

        onChangeCallback(modifiedValue, propertyName);
    }

    private handleOnPaste = (): void => {
        this.setState({ isPasteEvent: true });
    }

    render() {

        const { isDisabled, isHidden, onChangeCallback, placeholder, propertyName, value } = this.props;
        const { isPasteEvent } = this.state;

        const inputProps: ISimpleTextAreaProps = {
            isDisabled,
            isHidden,
            onChangeCallback: this.handleOnChange(onChangeCallback, isPasteEvent),
            onPasteCallback: this.handleOnPaste,
            placeholder,
            propertyName,
            value
        };

        return <SimpleTextArea {...inputProps} />;
    }
}

export {
    ISimpleMultiCommaInputProps,
    SimpleMultiCommaInput
};
