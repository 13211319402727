// 3rd Party
import { Dispatch } from 'redux';

// App References
import {
    AccountDetailsActionsDispatcherFactory,
    BidHistoryActionsDispatcherFactory,
    ChangePasswordActionsDispatcherFactory,
    EnquiryActionsDispatcherFactory,
    ForgottenPasswordActionsDispatcherFactory,
    IAccountDetailsActions,
    IBidHistoryActions,
    IChangePasswordActions,
    IEnquiryActions,
    IForgottenPasswordActions,
    IImpersonationActions,
    ILoginActions,
    ImpersonationActionsDispatcherFactory,
    IRegistrationActions,
    IResetPasswordActions,
    ISupplyRequestActions,
    ISupplyRequestListingActions,
    LoginActionsDispatcherFactory,
    RegistrationActionsDispatcherFactory,
    ResetPasswordActionsDispatcherFactory,
    SupplyRequestActionsDispatcherFactory,
    SupplyRequestListingActionsDispatcherFactory
} from 'App/IndexOfModels';

// Local References
import { IAppState } from './AppState';
import { IPageActions, PageDispatcherFactory } from './Page/Actions';

export interface IAppActions {
    acountDetailsActions: IAccountDetailsActions;
    bidHistoryActions: IBidHistoryActions;
    changePasswordActions: IChangePasswordActions;
    enquiryActions: IEnquiryActions;
    forgottenPasswordActions: IForgottenPasswordActions;
    impersonationActions: IImpersonationActions;
    loginActions: ILoginActions;
    pageActions: IPageActions;
    registrationActions: IRegistrationActions;
    resetPasswordActions: IResetPasswordActions;
    supplyRequestActions: ISupplyRequestActions;
    supplyRequestListingActions: ISupplyRequestListingActions;
}

const actionsDispatcherFactory = (dispatch: Dispatch<IAppState>): IAppActions => {

    const pageActions = PageDispatcherFactory(dispatch);

    return {
        acountDetailsActions: AccountDetailsActionsDispatcherFactory(dispatch, pageActions),
        bidHistoryActions: BidHistoryActionsDispatcherFactory(dispatch, pageActions),
        changePasswordActions: ChangePasswordActionsDispatcherFactory(dispatch, pageActions),
        enquiryActions: EnquiryActionsDispatcherFactory(dispatch, pageActions),
        forgottenPasswordActions: ForgottenPasswordActionsDispatcherFactory(dispatch, pageActions),
        impersonationActions: ImpersonationActionsDispatcherFactory(dispatch, pageActions),
        loginActions: LoginActionsDispatcherFactory(dispatch, pageActions),
        pageActions,
        registrationActions: RegistrationActionsDispatcherFactory(dispatch, pageActions),
        resetPasswordActions: ResetPasswordActionsDispatcherFactory(dispatch, pageActions),
        supplyRequestActions: SupplyRequestActionsDispatcherFactory(dispatch, pageActions),
        supplyRequestListingActions: SupplyRequestListingActionsDispatcherFactory(dispatch, pageActions)
    };
};

export {
    actionsDispatcherFactory as ActionsDispatcherFactory
};
