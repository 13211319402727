// 3rd Party
import * as React from 'react';
import { hot } from 'react-hot-loader';

// Utility Reference
import { Handler } from 'Utility/IndexOfActions';
import {
    AddIcon,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    EditButton,
    FormGroupWrapper,
    IEditButtonProps,
    IFormGroupWrapperProps,
    ISimpleButtonProps,
    ISimpleNumericInputProps,
    ISimpleReadOnlyInputProps,
    ISimpleSelectItemOptionProps,
    ISimpleSelectNumericProps,
    ISimpleStringInputProps,
    ISimpleTagInputProps,
    ISimpleTextAreaProps,
    SaveIcon,
    SimpleFileUploadInput,
    SimpleNumericInput,
    SimpleReadOnlyInput,
    SimpleSelectItemOption,
    SimpleSelectNumeric,
    SimpleStringInput,
    SimpleTagInput,
    SimpleTextArea,
    SuccessButton
} from 'Utility/IndexOfComponents';
import {
    FormControlHelper,
    FormHelper,
    NumberHelper,
    ObjectHelper
} from 'Utility/IndexOfHelpers';
import {
    IApiItemOptionViewModel,
    IContentWidths,
    IRequiredField
} from 'Utility/IndexOfInterfaces';
import { ModelUpdater } from 'Utility/IndexOfModels';

// App References
import { CreateValidatableDocumentFields } from 'App/Helpers/DocumentUploadValidationHelper';
import {
    IApiSupplyRequestToolsFilter as IToolsFilter,
    IApiSupplyRequestUpsertViewModel as IUpsert,
    IApiSupplyRequestViewModel as IViewModel
} from 'App/IndexOfInterfaces';
import {
    Constants,
    IPageActions,
    IPageState
} from 'App/IndexOfModels';

interface IPropActions {
    get: Handler.Action1<number>;
    getLinkedToolsAsync: Handler.ActionAsync1<IToolsFilter, void>;
    getTemplate: Handler.Action;
    pageActions: IPageActions;
    receiveUpsert: Handler.Action1<IUpsert>;
    resetAll: Handler.Action;
    saveAsync: Handler.ActionAsync1<IViewModel, void>;
    updateAsync: Handler.ActionAsync1<IViewModel, void>;
}

interface IPropData {
    pageState: IPageState;
    supplyRequestId?: number;
    upsert: IUpsert;
}

interface IFormProps {
    propActions: IPropActions;
    propData: IPropData;
}

interface IFormState {
    editVehicleInfo: boolean;
    isEditMode: boolean;
}

class Form extends React.Component<IFormProps, IFormState> {

    private originalPurchaseDocumentFile: SimpleFileUploadInput;

    constructor(props: IFormProps){
        super(props);

        this.state = {
            editVehicleInfo: ObjectHelper.isUndefinedOrNull(props.propData.supplyRequestId),
            isEditMode: !ObjectHelper.isUndefinedOrNull(props.propData.supplyRequestId)
        };
    }

    componentDidMount() {

        const { propActions, propData } = this.props;
        propActions.resetAll();

        if (NumberHelper.valueIsUndefinedOrNullOrNan(propData.supplyRequestId)) {
            propActions.getTemplate();
        }
        else {
            propActions.get(propData.supplyRequestId);
        }
    }

    componentWillUnmount() {

        const { pageActions: { clearAll } } = this.props.propActions;

        clearAll();
    }

    private getFormPropertyNames = (viewModel: IViewModel) => {

        return {
            bodyType: ObjectHelper.getPropertyName(() => viewModel.bodyTypeId),
            claimReference: ObjectHelper.getPropertyName(() => viewModel.claimReference),
            colourPreferenceOne: ObjectHelper.getPropertyName(() => viewModel.colourPreferenceOne),
            colourPreferenceTwo: ObjectHelper.getPropertyName(() => viewModel.colourPreferenceTwo),
            colourPreferenceThree: ObjectHelper.getPropertyName(() => viewModel.colourPreferenceThree),
            comments: ObjectHelper.getPropertyName(() => viewModel.comments),
            derivativeName: ObjectHelper.getPropertyName(() => viewModel.derivativeName),
            doors: ObjectHelper.getPropertyName(() => viewModel.doors),
            engineSize: ObjectHelper.getPropertyName(() => viewModel.engineSize),
            fuelType: ObjectHelper.getPropertyName(() => viewModel.fuelTypeId),
            location: ObjectHelper.getPropertyName(() => viewModel.location),
            manufacturerName: ObjectHelper.getPropertyName(() => viewModel.manufacturerName),
            modelName: ObjectHelper.getPropertyName(() => viewModel.modelName),
            originalPurchaseDocument: ObjectHelper.getPropertyName(() => viewModel.originalPurchaseDocument),
            originalPurchaseDocumentUrl: ObjectHelper.getPropertyName(() => viewModel.originalPurchaseDocumentUrl),
            originalPurchasePrice: ObjectHelper.getPropertyName(() => viewModel.originalPurchasePrice),
            postCode: ObjectHelper.getPropertyName(() => viewModel.postCode),
            registrationType: ObjectHelper.getPropertyName(() => viewModel.registrationTypeId),
            specifications: ObjectHelper.getPropertyName(() => viewModel.specifications),
            state: ObjectHelper.getPropertyName(() => viewModel.stateId),
            transmissionType: ObjectHelper.getPropertyName(() => viewModel.transmissionTypeId),
            trimColour: ObjectHelper.getPropertyName(() => viewModel.trimColourId),
            year: ObjectHelper.getPropertyName(() => viewModel.year)
        };
    }

    private handleOnSave = (propActions: IPropActions, propData: IPropData, viewModel: IViewModel): void => {

        const { pageActions: { clearAll, addError } } = propActions;

        if (FormHelper.areRequiredPropertiesValid(
            clearAll,
            addError,
            this.getValidationInfo(viewModel)
        )) {

            if (NumberHelper.isUndefinedNullOrZero(viewModel.supplyRequestId)) {
                propActions.saveAsync(viewModel);
            }
            else {
                propActions.updateAsync(viewModel);
            }
        }
    }

    private isViewModelValid = (viewModel: IViewModel): boolean => {

        const hasViewModel = !ObjectHelper.isUndefinedOrNull(viewModel);

        return hasViewModel;
    }

    private getValidationInfo(viewModel: IViewModel): IRequiredField[] {

        const formProperties = this.getFormPropertyNames(viewModel);

        const fieldsWithRequirements: IRequiredField[] = [
            { errorMessage: 'Please select a body type.', propertyName: formProperties.bodyType, value: viewModel.bodyTypeId },
            { errorMessage: 'Please enter the claim reference.', propertyName: formProperties.claimReference, value: viewModel.claimReference },
            { errorMessage: 'Please enter primary colour preference.', propertyName: formProperties.colourPreferenceOne, value: viewModel.colourPreferenceOne },
            { errorMessage: 'Please enter the number of doors.', propertyName: formProperties.doors, value: viewModel.doors },
            { errorMessage: 'Please select the fuel type.', propertyName: formProperties.fuelType, value: viewModel.fuelTypeId },
            { errorMessage: 'Please enter the location.', propertyName: formProperties.location, value: viewModel.location },
            { errorMessage: 'Please select a make.', propertyName: formProperties.manufacturerName, value: viewModel.manufacturerName },
            { errorMessage: 'Please select a model.', propertyName: formProperties.modelName, value: viewModel.modelName },
            { errorMessage: 'Please enter the original purchase price.', propertyName: formProperties.originalPurchasePrice, value: viewModel.originalPurchasePrice },
            { errorMessage: 'Please enter the post code.', propertyName: formProperties.postCode, value: viewModel.postCode },
            { errorMessage: 'Please select the registration type.', propertyName: formProperties.registrationType, value: viewModel.registrationTypeId },
            { errorMessage: 'Please select a state.', propertyName: formProperties.state, value: viewModel.stateId },
            { errorMessage: 'Please select the transmission type.', propertyName: formProperties.transmissionType, value: viewModel.transmissionTypeId },
            { errorMessage: 'Please select the year.', propertyName: formProperties.year, value: viewModel.year }
        ];

        const validatableDocumentFields = CreateValidatableDocumentFields(() => viewModel.originalPurchaseDocument);

        return fieldsWithRequirements.concat(validatableDocumentFields);
    }

    render() {

        const { propActions, propData } = this.props;

        const viewModel = propData.upsert && propData.upsert.model;

        if (!this.isViewModelValid(viewModel)) {
            return null;
        }

        const formProperties = this.getFormPropertyNames(viewModel);

        const formGroupWrapperPropsDefaults = {
            contentWidths: FormControlHelper.getContentWidths(
                'children',
                'error',
                'col-xs-12 col-md-6',
                'label')
        };

        const formGroupWrapperProps = (
            displayName: string,
            propertyName: string,
            contentWidths?: IContentWidths,
            isRequired?: boolean,
            isHidden?: boolean): IFormGroupWrapperProps => {

            return FormControlHelper.getFormGroupWrapperProps(
                contentWidths || formGroupWrapperPropsDefaults.contentWidths,
                this.props.propData.pageState.modelState,
                displayName,
                propertyName,
                isRequired || false,
                isHidden);
        };

        const genericSimpleStringInputProps = (propertyName: string, value: string, maxLength: number = 100, namespace?: string, isDisabled?: boolean): ISimpleStringInputProps => {
            return {
                isDisabled,
                maxLength,
                onChangeCallback: modelUpdater.for<string>((value, model) => {
                    return updateModel(model, value, propertyName, namespace);
                }),
                value
            };
        };

        const modelUpdater = ModelUpdater(propData.upsert, propActions.receiveUpsert);

        const threeColumnWidths = FormControlHelper.getContentWidths(
            'children',
            'error',
            'col-xs-12 col-md-4',
            'label');

        const tools = propData.upsert && propData.upsert.tools;

        const updateModel = (upsert: IUpsert, value: boolean | Date | number | string | string[], propertyName: string, namespace?: string): IUpsert => {

            const property = namespace ? upsert.model[namespace] : upsert.model;

            propActions.pageActions.removeEntry(property[propertyName]);
            property[propertyName] = value;

            return upsert;
        };

        const bodyTypeSelectionProps: ISimpleSelectNumericProps = {
            isDisabled: propData.pageState.processing,
            items: tools.bodyTypes,
            onChangeCallback: modelUpdater.for<number>((value, upsert) => {

                upsert.model.bodyTypeId = value;

                return upsert;
            }),
            value: viewModel.bodyTypeId
        };

        const commentProps: ISimpleTextAreaProps = {
            isDisabled: propData.pageState.processing,
            maxLength: 254,
            onChangeCallback: modelUpdater.for<string>((value, upsert) => {

                upsert.model.comments = value;

                return upsert;
            }),
            value: viewModel.comments
        };

        const derivativeReadOnlyInputProps: ISimpleReadOnlyInputProps = {
            value: viewModel.derivativeName
        };

        const derivativeSelectionProps: ISimpleSelectItemOptionProps = {
            isDisabled: propData.pageState.processing || !viewModel.year,
            items: tools.derivatives,
            onChangeCallback: modelUpdater.for<IApiItemOptionViewModel>((itemOption, upsert) => {

                upsert.model.derivativeName = itemOption && itemOption.text;
                upsert.model.derivativeReference = itemOption && itemOption.value;

                return upsert;
            }),
            value: viewModel.derivativeReference
        };

        const doorSelectionProps: ISimpleSelectNumericProps = {
            isDisabled: propData.pageState.processing,
            items: tools.doors,
            onChangeCallback: modelUpdater.for<number>((value, upsert) => {

                upsert.model.doors = value;

                return upsert;
            }),
            value: viewModel.doors
        };

        const editVehicleInfoButtonProps: IEditButtonProps = {
            buttonOptions: {
                buttonText: 'Edit Vehicle Info'
            },
            onClick: () => {

                this.setState({editVehicleInfo: !this.state.editVehicleInfo});

                const toolsFilter: IToolsFilter = {
                    year: viewModel.year,
                    manufacturerId: viewModel.manufacturerReference,
                    modelId: viewModel.modelReference
                };

                propActions.getLinkedToolsAsync(toolsFilter);
            }
        };

        const fuelTypeSelectionProps: ISimpleSelectNumericProps = {
            isDisabled: propData.pageState.processing,
            items: tools.fuelTypes,
            onChangeCallback: modelUpdater.for<number>((value, upsert) => {

                upsert.model.fuelTypeId = value;

                return upsert;
            }),
            value: viewModel.fuelTypeId
        };

        const makeReadOnlyInputProps: ISimpleReadOnlyInputProps = {
            value: viewModel.manufacturerName
        };

        const modelReadOnlyInputProps: ISimpleReadOnlyInputProps = {
            value: viewModel.modelName
        };

        const makeSelectionProps: ISimpleSelectItemOptionProps = {
            isDisabled: propData.pageState.processing || !viewModel.year,
            items: tools.manufacturers,
            onChangeCallback: modelUpdater.for<IApiItemOptionViewModel>((itemOption, upsert) => {

                upsert.model.modelName = null;
                upsert.model.modelReference = null;
                upsert.model.manufacturerName = itemOption && itemOption.text;
                upsert.model.manufacturerReference = itemOption && itemOption.value;

                return upsert;
            }, (upsert: IUpsert) => {

                const toolsFilter: IToolsFilter = {
                    year: upsert.model.year,
                    manufacturerId: upsert.model.manufacturerReference,
                    modelId: null
                };

                propActions.getLinkedToolsAsync(toolsFilter);
            }),
            value: viewModel.manufacturerReference
        };

        const modelSelectionProps: ISimpleSelectItemOptionProps = {
            isDisabled: propData.pageState.processing || !viewModel.year,
            items: tools.models,
            onChangeCallback: modelUpdater.for<IApiItemOptionViewModel>((itemOption, upsert) => {

                upsert.model.derivativeName = null;
                upsert.model.derivativeReference = null;
                upsert.model.modelName = itemOption && itemOption.text;
                upsert.model.modelReference = itemOption && itemOption.value;

                return upsert;
            }, (upsert: IUpsert) => {

                const toolsFilter: IToolsFilter = {
                    year: upsert.model.year,
                    manufacturerId: upsert.model.manufacturerReference,
                    modelId: upsert.model.modelReference
                };

                propActions.getLinkedToolsAsync(toolsFilter);
            }),
            value: viewModel.modelReference
        };

        const originalPurchasePriceProps: ISimpleNumericInputProps = {
            isDisabled: propData.pageState.processing,
            max: 1000000,
            onChangeCallback: modelUpdater.for<number>((value, model) => {
                return updateModel(model, value, formProperties.originalPurchasePrice, null);
            }),
            value: viewModel.originalPurchasePrice
        };

        const registrationTypeSelectionProps: ISimpleSelectNumericProps = {
            isDisabled: propData.pageState.processing,
            items: tools.registrationTypes,
            onChangeCallback: modelUpdater.for<number>((value, upsert) => {

                upsert.model.registrationTypeId = value;

                return upsert;
            }),
            value: viewModel.registrationTypeId
        };

        const saveButtonProps: ISimpleButtonProps = {
            buttonOptions: { alignRight: true },
            buttonText: this.state.isEditMode ? 'Update Request' : 'Create Request',
            isDisabled: propData.pageState.processing,
            onClick: () => {

                const clonedModel = ObjectHelper.deepClone(viewModel);
                clonedModel.originalPurchaseDocument = this.originalPurchaseDocumentFile.getFiles()[0];

                this.handleOnSave(propActions, propData, clonedModel);
            },
            processing: propData.pageState.processing
        };

        const transmissionTypeSelectionProps: ISimpleSelectNumericProps = {
            isDisabled: propData.pageState.processing,
            items: tools.transmissionTypes,
            onChangeCallback: modelUpdater.for<number>((value, upsert) => {

                upsert.model.transmissionTypeId = value;

                return upsert;
            }),
            value: viewModel.transmissionTypeId
        };

        const stateSelectionProps: ISimpleSelectNumericProps = {
            isDisabled: propData.pageState.processing,
            items: tools.states,
            onChangeCallback: modelUpdater.for<number>((value, upsert) => {

                upsert.model.stateId = value;

                return upsert;
            }),
            value: viewModel.stateId
        };

        const trimColourSelectionProps: ISimpleSelectNumericProps = {
            isDisabled: propData.pageState.processing,
            items: tools.colourTypes,
            onChangeCallback: modelUpdater.for<number>((value, upsert) => {

                upsert.model.trimColourId = value;

                return upsert;
            }),
            value: viewModel.trimColourId
        };

        const yearSelectionProps: ISimpleSelectNumericProps = {
            isDisabled: propData.pageState.processing,
            items: tools.years,
            onChangeCallback: modelUpdater.for<number>((value, upsert) => {

                upsert.model.year = value;
                upsert.model.derivativeReference = null;
                upsert.model.manufacturerReference = null;
                upsert.model.modelReference = null;

                const toolsFilter: IToolsFilter = {
                    year: value,
                    manufacturerId: null,
                    modelId: null
                };

                propActions.getLinkedToolsAsync(toolsFilter);

                return upsert;
            }),
            value: viewModel.year
        };

        const optionsAccessoriesProps: ISimpleTagInputProps = {
            onChangeCallback: modelUpdater.for<string[]>((value, upsert) => {

                upsert.model.specifications = value;
                return upsert;
            }),
            placeholderText: 'Add Option',
            tagList: viewModel.specifications
        };

        return (
            <div className="col-sm-12">

                <div className="row">
                    <div className="col-sm-12">
                        <h1>{this.state.isEditMode ? 'Edit' : 'Create'} Supply Request</h1>
                        {
                            !this.state.isEditMode &&

                            <p>Set up a new supply request</p>

                        }
                    </div>
                </div>

                <div className="row mt-4 mb-3">
                    <div className="col-sm-12">
                        <Card>
                            <CardBody>
                                <div className="form-horizontal">
                                    <div className="form-row">
                                        <FormGroupWrapper {...formGroupWrapperProps('Claim Reference', formProperties.claimReference, null, true)}>
                                            <SimpleStringInput {...genericSimpleStringInputProps(formProperties.claimReference, viewModel.claimReference, 20, null, propData.pageState.processing)} />
                                        </FormGroupWrapper>
                                    </div>

                                    <div className="form-row">
                                        <FormGroupWrapper {...formGroupWrapperProps('Year', formProperties.year, null, true)}>
                                            <SimpleSelectNumeric {...yearSelectionProps} />
                                        </FormGroupWrapper>
                                    </div>

                                    <div className="form-row">
                                        <div className="col-md-6 mb-3">
                                            <i>Select a year to load related vehicle info</i>
                                        </div>
                                    </div>

                                    {
                                        !this.state.editVehicleInfo &&
                                        <>
                                            <div className="form-row">
                                                <FormGroupWrapper {...formGroupWrapperProps('Make', formProperties.manufacturerName, threeColumnWidths, true)}>
                                                    <SimpleReadOnlyInput {...makeReadOnlyInputProps} />
                                                </FormGroupWrapper>

                                                <FormGroupWrapper {...formGroupWrapperProps('Model', formProperties.modelName, threeColumnWidths, true)}>
                                                    <SimpleReadOnlyInput {...modelReadOnlyInputProps} />
                                                </FormGroupWrapper>

                                                <FormGroupWrapper {...formGroupWrapperProps('Derivative', formProperties.derivativeName, threeColumnWidths, false)}>
                                                    <SimpleReadOnlyInput {...derivativeReadOnlyInputProps} />
                                                </FormGroupWrapper>
                                            </div>

                                            <div className="form-row">
                                                <div className="col-md-6 mb-3">
                                                    <EditButton {...editVehicleInfoButtonProps} />
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {
                                        this.state.editVehicleInfo &&

                                        <div className="form-row">
                                            <FormGroupWrapper {...formGroupWrapperProps('Make', formProperties.manufacturerName, threeColumnWidths, true)}>
                                                <SimpleSelectItemOption {...makeSelectionProps} />
                                            </FormGroupWrapper>

                                            <FormGroupWrapper {...formGroupWrapperProps('Model', formProperties.modelName, threeColumnWidths, true)}>
                                                <SimpleSelectItemOption {...modelSelectionProps} />
                                            </FormGroupWrapper>

                                            <FormGroupWrapper {...formGroupWrapperProps('Derivative', formProperties.derivativeName, threeColumnWidths, false)}>
                                                <SimpleSelectItemOption {...derivativeSelectionProps} />
                                            </FormGroupWrapper>
                                        </div>
                                    }

                                    <div className="form-row">
                                        <FormGroupWrapper {...formGroupWrapperProps('Body Type', formProperties.bodyType, threeColumnWidths, true)}>
                                            <SimpleSelectNumeric {...bodyTypeSelectionProps} />
                                        </FormGroupWrapper>

                                        <FormGroupWrapper {...formGroupWrapperProps('Transmission Type', formProperties.transmissionType, threeColumnWidths, true)}>
                                            <SimpleSelectNumeric {...transmissionTypeSelectionProps} />
                                        </FormGroupWrapper>

                                        <FormGroupWrapper {...formGroupWrapperProps('Fuel Type', formProperties.fuelType, threeColumnWidths, true)}>
                                            <SimpleSelectNumeric {...fuelTypeSelectionProps} />
                                        </FormGroupWrapper>
                                    </div>

                                    <div className="form-row">
                                        <FormGroupWrapper {...formGroupWrapperProps('Colour 1', formProperties.colourPreferenceOne, threeColumnWidths, true)}>
                                            <SimpleStringInput {...genericSimpleStringInputProps(formProperties.colourPreferenceOne, viewModel.colourPreferenceOne, 50, null, propData.pageState.processing)} />
                                        </FormGroupWrapper>

                                        <FormGroupWrapper {...formGroupWrapperProps('Colour 2', formProperties.colourPreferenceTwo, threeColumnWidths, false)}>
                                            <SimpleStringInput {...genericSimpleStringInputProps(formProperties.colourPreferenceTwo, viewModel.colourPreferenceTwo, 50, null, propData.pageState.processing)} />
                                        </FormGroupWrapper>

                                        <FormGroupWrapper {...formGroupWrapperProps('Colour 3', formProperties.colourPreferenceThree, threeColumnWidths, false)}>
                                            <SimpleStringInput {...genericSimpleStringInputProps(formProperties.colourPreferenceThree, viewModel.colourPreferenceThree, 50, null, propData.pageState.processing)} />
                                        </FormGroupWrapper>
                                    </div>

                                    <div className="form-row">
                                        <FormGroupWrapper {...formGroupWrapperProps('Engine Size', formProperties.engineSize, threeColumnWidths, false)}>
                                            <SimpleStringInput {...genericSimpleStringInputProps(formProperties.engineSize, viewModel.engineSize, 10, null, propData.pageState.processing)} />
                                        </FormGroupWrapper>

                                        <FormGroupWrapper {...formGroupWrapperProps('Doors', formProperties.doors, threeColumnWidths, true)}>
                                            <SimpleSelectNumeric {...doorSelectionProps} />
                                        </FormGroupWrapper>

                                        <FormGroupWrapper {...formGroupWrapperProps('Trim Colour', formProperties.trimColour, threeColumnWidths, false)}>
                                            <SimpleSelectNumeric {...trimColourSelectionProps} />
                                        </FormGroupWrapper>
                                    </div>

                                    <div className="form-row">
                                        <FormGroupWrapper {...formGroupWrapperProps('Original Purchase Price ($)', formProperties.originalPurchasePrice, threeColumnWidths, true)}>
                                            <SimpleNumericInput {...originalPurchasePriceProps}/>
                                        </FormGroupWrapper>

                                        <FormGroupWrapper {...formGroupWrapperProps('Original Vehicle Documents', formProperties.originalPurchaseDocument, threeColumnWidths, false)}>
                                            <SimpleFileUploadInput accept={`${Constants.PDFType}, ${Constants.JPEGType}`} ref={(component) => this.originalPurchaseDocumentFile = component}/>
                                        </FormGroupWrapper>

                                        {
                                            this.state.isEditMode &&

                                            <FormGroupWrapper {...formGroupWrapperProps(this.state.isEditMode ? 'Currently Uploaded Vehicle Documents' : 'Original Vehicle Documents', formProperties.originalPurchaseDocumentUrl, threeColumnWidths, false)}>
                                                {
                                                    viewModel.originalPurchaseDocumentUrl ?
                                                    <a href={viewModel.originalPurchaseDocumentUrl} target="_blank">Download document</a> :
                                                    <i>No document has been uploaded.</i>
                                                }
                                            </FormGroupWrapper>
                                        }
                                    </div>

                                    <div className="form-row">
                                        <FormGroupWrapper {...formGroupWrapperProps('Location', formProperties.location, threeColumnWidths, true)}>
                                            <SimpleStringInput {...genericSimpleStringInputProps(formProperties.location, viewModel.location, 50, null, propData.pageState.processing)} />
                                        </FormGroupWrapper>

                                        <FormGroupWrapper {...formGroupWrapperProps('State', formProperties.state, threeColumnWidths, true)}>
                                            <SimpleSelectNumeric {...stateSelectionProps} />
                                        </FormGroupWrapper>

                                        <FormGroupWrapper {...formGroupWrapperProps('Post Code', formProperties.postCode, threeColumnWidths, true)}>
                                            <SimpleStringInput {...genericSimpleStringInputProps(formProperties.postCode, viewModel.postCode, 20, null, propData.pageState.processing)} />
                                        </FormGroupWrapper>
                                    </div>

                                    <div className="form-row">
                                        <FormGroupWrapper {...formGroupWrapperProps('Registration Type', formProperties.registrationType, null, true)}>
                                            <SimpleSelectNumeric {...registrationTypeSelectionProps} />
                                        </FormGroupWrapper>
                                    </div>

                                    <div className="form-row">
                                        <FormGroupWrapper {...formGroupWrapperProps('Comments', formProperties.comments, null, false)}>
                                            <SimpleTextArea {...commentProps} />
                                        </FormGroupWrapper>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-sm-12">
                        <Card>
                            <CardHeader>
                                <CardTitle text="Options/Accessories" />
                            </CardHeader>
                            <CardBody>
                                <SimpleTagInput {...optionsAccessoriesProps} />
                            </CardBody>
                        </Card>
                    </div>
                </div>

                <div className="row justify-content-end">
                    <div className="col-md-6">
                        <SuccessButton {...saveButtonProps} >
                            {this.state.isEditMode ? <SaveIcon /> : <AddIcon />}
                        </SuccessButton>
                    </div>
                </div>
            </div>
        );
    }
}

const HotForm = hot(module)(Form);

export {
    HotForm as SupplyRequestForm,
    IFormProps as ISupplyRequestFormProps
};
