// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from 'Utility/IndexOfActions';
import { CommonDictionary, ObjectHelper, StringHelper } from 'Utility/IndexOfHelpers';
import { IApiItemOptionViewModel } from 'Utility/IndexOfInterfaces';
import { Notification } from 'Utility/IndexOfServices';

// Local References
import InternalCommonSelect, { IInternalCommonSelectProps } from './Internal/InternalCommonSelect';

interface ISimpleSelectItemOptionProps extends React.Props<SimpleSelectItemOption>  {
    alwaysUsePromptText?: boolean;
    disableSelfValueValidation?: boolean;
    displayName?: string;
    inputClassName?: string;
    isDisabled?: boolean;
    isFilter?: boolean;
    items: IApiItemOptionViewModel[];
    onBlurCallback?: Handler.SelectFocusEvent;
    onChangeCallback: Handler.Action2Optional<IApiItemOptionViewModel, string>;
    onKeyPressCallback?: Handler.SelectKeyboardEvent;
    placeholder?: string;
    propertyName?: string;
    reportingValueCanBeZero?: boolean;
    tabIndex?: number;
    value: string;
}

class SimpleSelectItemOption extends React.Component<ISimpleSelectItemOptionProps, { valueReset: boolean }> {

    private primaryInput: InternalCommonSelect;

    constructor(props: ISimpleSelectItemOptionProps) {
        super(props);

        this.state = {
            valueReset: false
        };
    }

    public setFocus = (scroll?: number): void => {
        this.primaryInput.setFocus(scroll);
    }

    componentWillReceiveProps(nextProps: ISimpleSelectItemOptionProps) {

        // Need to validate that the value exists in the items
        const hasItemsAndValue = nextProps.items && !ObjectHelper.isUndefinedOrNull(nextProps.value);
        const { valueReset } = this.state;

        if (!valueReset && hasItemsAndValue && !nextProps.disableSelfValueValidation) {

            const valueDoesNotExistInItems = nextProps.items.filter(x => x.value === nextProps.value.toString()).length === 0;

            if (valueDoesNotExistInItems) {

                // https://www.youtube.com/watch?v=uACvFAm6JSM
                if (nextProps.propertyName) {

                    const notificationText = nextProps.displayName ? nextProps.displayName : StringHelper.toSentenceCase(nextProps.propertyName);
                    Notification.warning.clickToDismiss(`${CommonDictionary.ValueIsNowInvalid} ${CommonDictionary.Property} (${notificationText}). ${CommonDictionary.PleaseReselect}`, notificationText);
                }

                this.props.onChangeCallback(null, nextProps.propertyName);

                this.setState({ valueReset: true });
            }
        }
    }

    private handleOnChange = (onChangeCallback: Handler.Action2Optional<IApiItemOptionViewModel, string>, items: IApiItemOptionViewModel[], propertyName?: string) => (value: string) => {

        if (ObjectHelper.isUndefinedOrNull(value)) {

            onChangeCallback(null, propertyName);

        } else {

            const selectedItem = items.filter(x => x.value === value)[0];

            onChangeCallback(selectedItem, propertyName);
        }
    }

    render() {

        const { alwaysUsePromptText, displayName, inputClassName, isDisabled, isFilter, items, onBlurCallback, onChangeCallback, onKeyPressCallback, placeholder, propertyName, reportingValueCanBeZero, tabIndex, value } = this.props;

        const selectedValueAsString = ObjectHelper.isUndefinedOrNull(value)
            ? ''
            : value.toString();

        const selectProps: IInternalCommonSelectProps = {
            alwaysUsePromptText,
            displayName,
            inputClassName,
            isDisabled,
            isFilter,
            items,
            onBlurCallback,
            onChangeCallback: this.handleOnChange(onChangeCallback, items, propertyName),
            onKeyPressCallback,
            promptText: placeholder,
            propertyName,
            ref: (component: InternalCommonSelect) => { this.primaryInput = component; },
            reportingValueCanBeZero,
            tabIndex,
            value: selectedValueAsString
        };

        return <InternalCommonSelect {...selectProps} />;
    }
}

export {
    ISimpleSelectItemOptionProps,
    SimpleSelectItemOption
};
