// 3rd Party References
import * as React from 'react';

// Utility References
//import {  } from '../../../IndexOfComponents';
//import {  } from '../../../IndexOfEnums';
//import {  } from '../../../IndexOfHelpers';
//import {  } from '../../../IndexOfInterfaces';
//import {  } from '../../../IndexOfModels';
//import {  } from '../../../IndexOfServices';

interface IStubFilterProps extends React.Props<StubFilter> {
    key: string | number;
    label?: string;
}

export default class StubFilter extends React.Component<IStubFilterProps, {}> {

    render() {

        const { label } = this.props;

        return (
            <th>
                <div className="grid-stub-filter">{label ? label : ''}</div>
            </th>
        );
    }
}
