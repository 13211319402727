// 3rd Party References
import * as React from 'react';

// Utility References
import { TranslationHelper } from 'Utility/IndexOfHelpers';

export interface IGridRowEditActionButtonProps {
    buttonLabelOverride?: string;
    hideText?: boolean;
    onClick: () => void;
    isDisabled?: boolean;
}

export default class GridRowEditActionButton extends React.Component<IGridRowEditActionButtonProps, {}> {

    private handleClick = (onClick: () => void, isDisabled: boolean) => (): void => {

        if (onClick && !isDisabled) {
            onClick();
        }
    }

    render() {

        const { buttonLabelOverride, hideText, onClick, isDisabled } = this.props;

        const buttonClases = `btn btn-primary${isDisabled != null && isDisabled ? ' disabled' : ''}`;

        const getLabel = (defaultValue: string, hideText?: boolean, buttonLabelOverride?: string): string => {

            if (hideText) {
                return '';
            }

            const labelValue = buttonLabelOverride || defaultValue;
            return ` ${labelValue}`;
        };

        return (
            <button type="button" className={buttonClases} onClick={this.handleClick(onClick, isDisabled)}>
                <i className="fa fa-pencil" />
                {getLabel(TranslationHelper.translate('Edit'), hideText, buttonLabelOverride)}
            </button>
        );
    }
}
