// 3rd Party References
import * as React from 'react';
import { hot } from 'react-hot-loader';

interface IInvoiceAlertProps {
    showDealer: boolean;
    showInsurer: boolean;
}

class InvoiceAlert extends React.Component<IInvoiceAlertProps> {

    render() {

        const { showDealer, showInsurer } = this.props;

        if (!showDealer && !showInsurer){
            return null;
        }

        const dealerAlertText = 'Your bid for this supply request has been accepted and is now awaiting invoice document to be uploaded.';
        const insurerAlertText = 'This supply request has been sourced and is now awaiting invoice document to be uploaded.';

        return (
            <div className="alert alert-warning" role="alert">
                {showDealer && dealerAlertText}
                {showInsurer && insurerAlertText}
            </div>
        );
    }
}

const HotForm = hot(module)(InvoiceAlert);

export {
    HotForm as SupplyRequestListingInvoiceAlert,
    IInvoiceAlertProps as ISupplyRequestListingInvoiceAlertProps
};
