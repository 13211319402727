// Utility References
import {
    IApiCall,
    IApiCallWithPayload,
    IGenericQueryApi
} from 'Utility/IndexOfInterfaces';

// App References
import {
    IApiEnquiryToolsViewModel as ITools,
    IApiEnquiryUpsertViewModel as IUpsert
} from 'App/IndexOfInterfaces';

import { Actions, AppService, HttpVerbs } from 'App/IndexOfServices';

const controller = AppService().apiController('Enquiry');

const queryService: IGenericQueryApi<null, null, ITools, IUpsert> = {
    get: null,
    search: null,
    template: (): IApiCall<IUpsert> => {
        return {
            method: HttpVerbs.GET,
            url: `${controller}/${Actions.TEMPLATE}`
        };
    },
    tools: (): IApiCall<ITools> => {
        return {
            method: HttpVerbs.GET,
            url: `${controller}/${Actions.TOOLS}`
        };
    }
};

const actionService = {
    save: (): IApiCallWithPayload<FormData, void> => {
        return {
            method: HttpVerbs.POST,
            url: `${controller}`
        };
    }
};

export {
    queryService as EnquiryQueryService,
    actionService as EnquiryActionService
};
