// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from '../../../IndexOfActions';
//import {  } from '../../../IndexOfComponents';
//import {  } from '../../../IndexOfEnums';
import { AnimationHelper } from '../../../IndexOfHelpers';
//import {  } from '../../../IndexOfInterfaces';
//import {  } from '../../../IndexOfModels';
//import {  } from '../../../IndexOfServices';

export interface IInternalCommonIntegerInputProps extends React.Props<InternalCommonIntegerInput> {
    appendedInputClassName?: string;
    isDisabled: boolean;
    isHidden: boolean;
    max: number;
    onBlurCallback: Handler.InputFocusEvent;
    onChangeCallback: Handler.NumberProperty;
    onKeyPressCallback: Handler.InputKeyboardEvent;
    placeholder: string;
    propertyName: string;
    tabIndex: number;
    value: number;
}

export default class InternalCommonIntegerInput extends React.Component<IInternalCommonIntegerInputProps, {}> {

    private primaryInput: HTMLInputElement;

    public setFocus = (scroll?: number): void => {
        AnimationHelper.setFocus(this.primaryInput, scroll);
    }

    private handleOnChange = (max: number, onChangeCallback: Handler.NumberProperty, propertyName: string) => (event: React.FormEvent<HTMLInputElement>) => {

        const value = (event.target as HTMLInputElement).value;

        if (!!max && parseInt(value, 10) > max) {

            event.preventDefault();

        } else {

            const fieldValue = (event.target as HTMLInputElement).value;

            let valueAsNumber = parseInt(fieldValue, 10);

            if (isNaN(valueAsNumber)) {
                valueAsNumber = null;
            }

            onChangeCallback(valueAsNumber, propertyName);
        }
    }

    private getValueAsString = (value: number): string => {

        if (value === null || value === undefined) {
            return '';
        }

        let trimmedValue = value.toString();

        while (trimmedValue.charAt(0) === '0' && trimmedValue.length > 1) {
            trimmedValue = trimmedValue.substr(1);
        }

        return trimmedValue;
    }

    render() {

        const { appendedInputClassName, isDisabled, isHidden, max, onBlurCallback, onChangeCallback, onKeyPressCallback, placeholder, propertyName, tabIndex, value } = this.props;

        let inputClassName = 'simple-internal-common-integer-input form-control';

        if (isHidden) {
            inputClassName = inputClassName + ' hidden';
        }

        if (appendedInputClassName) {
            inputClassName = inputClassName + ' ' + appendedInputClassName;
        }

        const inputProps = {
            autoComplete: 'off',
            className: inputClassName,
            disabled: isDisabled,
            id: propertyName,
            max,
            onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
                if (onBlurCallback) {
                    onBlurCallback(event);
                }
            },
            onChange: this.handleOnChange(max, onChangeCallback, propertyName),
            onKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => {
                if (onKeyPressCallback) {
                    onKeyPressCallback(event);
                }
            },
            placeholder,
            ref: (component: HTMLInputElement) => { this.primaryInput = component; },
            tabIndex,
            type: 'number',
            value: this.getValueAsString(value)
        };

        return <input {...inputProps} />;
    }
}
