// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from '../../../IndexOfActions';
//import {  } from '../../../IndexOfComponents';
//import {  } from '../../../IndexOfEnums';
import { KeyboardEventHelper } from '../../../IndexOfHelpers';
//import {  } from '../../../IndexOfInterfaces';
import { IGridFilterContext } from '../../../IndexOfModels';
//import {  } from '../../../IndexOfServices';

// Local References
import ClearButton from './ClearButton';

type UpdateExpr = <TFilter, TValue>(filter: TFilter, value: TValue) => void;

interface ITextFilterProps extends React.Props<TextFilter> {
    cssClass?: string;
    gridFilterContext: IGridFilterContext;
    isDisabled?: boolean;
    key: string | number;
    label: string;
    maxLength?: number;
    updateExpr: UpdateExpr;
    value: string;
}

export default class TextFilter extends React.Component<ITextFilterProps, {}> {

    private clearButton: ClearButton;
    private inputField: HTMLInputElement;

    private handleOnBlur = (updateFunc: Handler.StringValue, maxLength: number) => (event: React.FocusEvent<HTMLInputElement>): void => {

        const input = event.currentTarget as HTMLInputElement;

        if (maxLength) {
            if (input.value.length > maxLength) {
                return;
            }
        }

        updateFunc(input.value);
    }

    private handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {

        if (KeyboardEventHelper.isEnterKeyPress(event)) {
            this.clearButton.activate();
            this.clearButton.setFocus();
        }
    }

    private reset = (updateFunc: Handler.StringValue) => () => {

        updateFunc(null);
        this.inputField.disabled = false;
        this.inputField.focus();
    }

    render() {

        const { cssClass, gridFilterContext, isDisabled, label, maxLength, updateExpr, value } = this.props;

        const updateFunc = gridFilterContext.filterHandler(updateExpr);

        const inputValue = (!!value) ? value : null;

        const inputProps = {
            className: `form-control grid-filter ${cssClass}`,
            disabled: isDisabled,
            onBlur: this.handleOnBlur(updateFunc, maxLength),
            onKeyPress: this.handleKeyPress,
            placeholder: label,
            ref: (component: HTMLInputElement) => { this.inputField = component; },
            type: 'text',
            value: inputValue
        };

        if (!!inputValue) {
            inputProps.className += ' active';
            inputProps.disabled = true;
        }

        const clearButtonProps = {
            isDisabled,
            ref: (component: ClearButton) => { this.clearButton = component; },
            resetClickHandler: this.reset(updateFunc),
            value
        };

        return (
            <th>
                <div className="input-group">
                    <input {...inputProps} />
                    <span className="input-group-btn">
                        <ClearButton {...clearButtonProps} />
                    </span>
                </div>
            </th>
        );
    }
}
