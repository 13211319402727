// 3rd Party
import * as React from 'react';
import { hot } from 'react-hot-loader';

// Utility References
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    FormGroupWrapper,
    IFormGroupWrapperProps,
    ISimpleReadOnlyInputProps,
    ISimpleTextAreaProps,
    SimpleReadOnlyInput,
    SimpleTextArea
} from 'Utility/IndexOfComponents';
import { DateHelper, FormControlHelper, ObjectHelper } from 'Utility/IndexOfHelpers';

// App References
import { toCurrencyDisplay } from 'App/Helpers/CurrencyHelper';
import {
    IApiBidHistoryUpsertViewModel as IUpsert,
    IApiBidHistoryViewModel as IViewModel
 } from 'App/IndexOfInterfaces';
import { Handler } from 'Utility/IndexOfActions';
import { IContentWidths } from 'Utility/IndexOfInterfaces';

interface IPropActions {
    get: Handler.Action1<number>;
}

interface IPropData {
    cardTitle: string;
    bidHistoryId: number;
    bidHistoryUpsert: IUpsert;
}

interface IBidHistorySummaryFormProps {
    propActions: IPropActions;
    propData: IPropData;
}

class Form extends React.Component<IBidHistorySummaryFormProps, {}>{

    componentDidMount(){

        const { propActions, propData } = this.props;

        propActions.get(propData.bidHistoryId);
    }

    private getReadOnlyInputProps = (value: any): ISimpleReadOnlyInputProps => {
        return {
            value: value || ''
        };
    }

    private isViewModelValid = (viewModel: IViewModel): boolean => {

        const hasViewModel = !ObjectHelper.isUndefinedOrNull(viewModel);

        return hasViewModel;
    }

    render() {

        const { propData } = this.props;

        const viewModel = propData && propData.bidHistoryUpsert && propData.bidHistoryUpsert.model;

        if (!this.isViewModelValid(viewModel)) {
            return null;
        }

        const formGroupWrapperPropsDefaults = {
            contentWidths: FormControlHelper.getContentWidths(
                'children',
                'error',
                'col-xs-12 col-md-12',
                'label')
        };

        const formGroupWrapperProps = (
            displayName: string,
            propertyName: string,
            contentWidths?: IContentWidths,
            isRequired?: boolean,
            isHidden?: boolean): IFormGroupWrapperProps => {

            return FormControlHelper.getFormGroupWrapperProps(
                contentWidths || formGroupWrapperPropsDefaults.contentWidths,
                null,
                displayName,
                propertyName,
                isRequired || false,
                isHidden);
        };

        const singleColumnWidths = FormControlHelper.getContentWidths(
            'children',
            'error',
            'col-sm-12',
            'label');

        const dealerCommentsAreaProps: ISimpleTextAreaProps = {
            isDisabled: true,
            onChangeCallback: null,
            value: viewModel.dealerComments
        };

        return (
            <Card>
                <CardHeader>
                    <CardTitle text={propData.cardTitle} />
                </CardHeader>

                <CardBody>
                    <div className="form-horizontal">
                        <div className="form-row">
                            <FormGroupWrapper {...formGroupWrapperProps('Final Drive Away Price', null, null)}>
                                <SimpleReadOnlyInput {...this.getReadOnlyInputProps(toCurrencyDisplay(viewModel.bidAmount))} />
                            </FormGroupWrapper>
                        </div>
                        <div className="form-row">
                            <FormGroupWrapper {...formGroupWrapperProps('Delivery Time Frame', null, null)}>
                                <SimpleReadOnlyInput {...this.getReadOnlyInputProps(viewModel.deliveryTimeFrame)} />
                            </FormGroupWrapper>
                        </div>
                        <div className="form-row">
                            <FormGroupWrapper {...formGroupWrapperProps('Supplying Colour Names', null, null)}>
                                <SimpleReadOnlyInput {...this.getReadOnlyInputProps(viewModel.supplyingColourNames.join(', '))} />
                            </FormGroupWrapper>
                        </div>
                        {
                            !ObjectHelper.isUndefinedOrNull(viewModel.dealerComments) &&
                            <div className="form-row">
                                <FormGroupWrapper {...formGroupWrapperProps('Comments', null, singleColumnWidths)}>
                                    <SimpleTextArea {...dealerCommentsAreaProps} />
                                </FormGroupWrapper>
                            </div>
                        }
                    </div>
                </CardBody>
            </Card>
        );
    }
}

const HotForm = hot(module)(Form);

export {
    HotForm as BidHistorySummaryForm,
    IBidHistorySummaryFormProps
};
