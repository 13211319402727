// 3rd Party References
import * as React from 'react';

// Utility References
import { CommonDictionary, StringHelper, TranslationHelper } from 'Utility/IndexOfHelpers';
import { DateComparer } from 'Utility/IndexOfModels';

interface IAgeDisplayProps {
    value: Date;
}

const ageDisplay = (props: IAgeDisplayProps): JSX.Element => {

    const { value } = props;

    const age = DateComparer(new Date()).difference(value);

    const ageDisplay = age < 1 ? `< 1 ${CommonDictionary.Day}` : `${age} ${TranslationHelper.translate(StringHelper.pluralize(age, 'day'))}`;

    return (
        <>{ageDisplay}</>
    );
};

export {
    ageDisplay as AgeDisplay,
    IAgeDisplayProps
};
