//3rd Party References
import * as React from 'react';

//Utility References
import { Handler } from '../../IndexOfActions';
import { OkIcon } from '../../IndexOfComponents';
import { ButtonStyle } from '../../IndexOfEnums';
import { AnimationHelper, ButtonHelper } from '../../IndexOfHelpers';
import { IButtonOptions, ICommonButtonProps } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

export interface IGoButtonProps extends ICommonButtonProps {
    buttonOptions?: IButtonOptions;
    isDisabled?: boolean;
    onClick: Handler.Action;
    processing?: boolean;
    tabIndex?: number;
}

export class GoButton extends React.Component<IGoButtonProps, {}> {

    private primaryInput: HTMLButtonElement;

    public setFocus = (scroll?: number): void => {
        AnimationHelper.setFocus(this.primaryInput, scroll);
    }

    render() {

        const { buttonOptions, isDisabled, onClick, processing, tabIndex } = this.props;

        const defaultButtonOptions: IButtonOptions = {
            buttonStyle: ButtonStyle.Primary,
            buttonText: 'Go'
        };

        const buttonProps = {
            className: ButtonHelper.getButtonClasses(defaultButtonOptions, buttonOptions),
            disabled: isDisabled,
            onClick: ButtonHelper.getButtonOnClickHandler(onClick, buttonOptions),
            ref: (component: HTMLButtonElement) => { this.primaryInput = component; },
            role: 'button',
            tabIndex,
            type: 'button'
        };

        const buttonTextDisplay = ButtonHelper.getButtonText(defaultButtonOptions, buttonOptions);

        return (
            <button {...buttonProps}>
                <OkIcon processing={processing} /> {buttonTextDisplay}
            </button>
        );
    }
}
