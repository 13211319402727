// 3rd Party References
import * as React from 'react';

interface ICardTitleProps {
    text: string;
}

const CardTitle = (props: ICardTitleProps): JSX.Element => {

    const { text } = props;

    return (
        <h3 className="card-title">
            {text}
        </h3>
    );
};

export {
    CardTitle
};
