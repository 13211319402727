// 3rd Party References
import * as React from 'react';

// Utility References
//import {  } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
import { CollectionHelper } from '../../IndexOfHelpers';
import { IApiListViewModel, IApiSearchViewModel } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

// Local References
import { GridEmptyRow } from './Internal/GridEmptyRow';

export interface IGridDataProps {
    collection: IApiSearchViewModel<IApiListViewModel>;
    dataRowFactory: (collection: IApiSearchViewModel<IApiListViewModel>) => JSX.Element[];
}

export default class GridData extends React.Component<IGridDataProps, {}> {

    render() {

        const { collection, dataRowFactory } = this.props;

        let innerBody: JSX.Element | JSX.Element[];

        if (CollectionHelper.hasNoRecords(collection)) {

            innerBody = <GridEmptyRow />;

        } else {

            innerBody = dataRowFactory(collection);
        }

        return <tbody>{innerBody}</tbody>;
    }
}
