// 3rd Party References
import * as React from 'react';

// Utility References
import { FormGroupRow, IFormGroupRowProps, ISimpleLabelProps, SimpleLabel } from 'Utility/IndexOfComponents';
import { IContentWidths, IModelState } from 'Utility/IndexOfInterfaces';

interface IFormGroupRowWrapperProps extends React.Props<JSX.Element> {
    className?: string;
    contentWidths?: IContentWidths;
    displayName?: string;
    isHidden?: boolean;
    isRequired?: boolean;
    modelState?: IModelState;
    propertyName: string;
}

const formGroupRowWrapper = (props: IFormGroupRowWrapperProps) => {

    const { className, contentWidths, displayName, isHidden, isRequired, modelState, propertyName } = props;

    if (isHidden) {
        return null;
    }

    const formGroupRowProps: IFormGroupRowProps = {
        className: `${className} ${(contentWidths && contentWidths.formGroup)}` || 'col-xs-12',
        propertyName,
        useErrorDisplay: false
    };

    if (modelState) {
        formGroupRowProps.modelState = modelState;
        formGroupRowProps.modelStateClassName = (contentWidths && contentWidths.error) || 'col-xs-12 col-md-5 col-md-offset-3';
        formGroupRowProps.useErrorDisplay = true;
    }

    const labelProps: ISimpleLabelProps = {
        cssClassName: (contentWidths && contentWidths.label) || 'col-xs-12 col-md-2 col-form-label',
        displayName: null,
        fieldName: propertyName,
        isRequired
    };

    if (displayName) {
        labelProps.displayName = displayName;
    }

    const divProps = {
        className: (contentWidths && contentWidths.children) || 'col-xs-12 col-md-10'
    };

    return (
        <FormGroupRow {...formGroupRowProps}>
            <SimpleLabel {...labelProps} />
            <div {...divProps}>
                {props.children}
            </div>
        </FormGroupRow>
    );
};

const formGroupRowWrapperHot = formGroupRowWrapper;

export {
    formGroupRowWrapperHot as FormGroupRowWrapper,
    IFormGroupRowWrapperProps
};
