// 3rd Party References
import * as React from 'react';

// Utility References
import { SearchIcon } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
//import {  } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

export interface IInspectionReportButtonProps {
    alignRight: boolean;
    buttonText?: string;
    inspectionId?: number;
    inspectionUrl: string;
    isDisabled: boolean;
}

export class InspectionReportButton extends React.Component<IInspectionReportButtonProps, {}> {

    render() {

        const { alignRight, buttonText, inspectionId, inspectionUrl, isDisabled } = this.props;

        let buttonTextstring = buttonText;

        if (!buttonTextstring) {
            buttonTextstring = 'Inspection Report';
        }

        if (inspectionId) {
            buttonTextstring = buttonTextstring + ' - ' + inspectionId;
        }

        const anchorProps: React.HTMLProps<HTMLAnchorElement> = {
            className: alignRight ? 'btn btn-info pull-right' : 'btn btn-info',
            href: inspectionUrl,
            target: '_blank'
        };

        if (isDisabled) {
            anchorProps.className = anchorProps.className + ' disabled';
            anchorProps.onClick = (event: React.MouseEvent<HTMLAnchorElement>) => { event.preventDefault(); };
        }

        return (
            <a {...anchorProps}><SearchIcon /> {buttonTextstring}</a>
        );
    }
}
