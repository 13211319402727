// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from '../../IndexOfActions';
//import {  } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
import { KeyboardEventHelper } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

// Local References
import InternalCommonTextInput, { IInternalCommonTextInputProps } from './Internal/InternalCommonTextInput';

interface ISimpleStringInputProps extends React.Props<SimpleStringInput> {
    appendedInputClassName?: string;
    isDisabled?: boolean;
    isHidden?: boolean;
    maxLength?: number;
    onBlurCallback?: Handler.InputFocusEvent;
    onChangeCallback: Handler.StringProperty;
    onKeyPressCallback?: Handler.InputKeyboardEvent;
    onSubmitCallback?: Handler.Action;
    password?: boolean;
    placeholder?: string;
    propertyName?: string;
    tabIndex?: number;
    uppercase?: boolean;
    value: string;
}

class SimpleStringInput extends React.Component<ISimpleStringInputProps, {}> {

    private primaryInput: InternalCommonTextInput;

    public setFocus = (scroll?: number): void => {
        this.primaryInput.setFocus(scroll);
    }

    private handleKeyPress = (onKeyPressCallback: Handler.InputKeyboardEvent, onSubmitCallback: Handler.Action) => (event: React.KeyboardEvent<HTMLInputElement>): void => {

        const isEnterKey = KeyboardEventHelper.isEnterKeyPress(event);

        if (isEnterKey && onSubmitCallback) {
            onSubmitCallback();
            return;
        }

        if (onKeyPressCallback) {
            onKeyPressCallback(event);
        }
    }

    render() {

        const { appendedInputClassName, isDisabled, isHidden, maxLength, onBlurCallback, onChangeCallback, onKeyPressCallback, onSubmitCallback, password, placeholder, propertyName, tabIndex, uppercase, value } = this.props;

        const commonInputProps: IInternalCommonTextInputProps = {
            appendedInputClassName: uppercase ? `${appendedInputClassName} text-uppercase` : appendedInputClassName,
            isDisabled,
            isHidden,
            maxLength,
            onBlurCallback: (event: React.FocusEvent<HTMLInputElement>) => {

                if (uppercase) {
                    const newValue = (event.target as HTMLInputElement).value;
                    onChangeCallback(newValue.toUpperCase(), propertyName);
                }

                if (onBlurCallback) {
                    onBlurCallback(event);
                }
            },
            onChangeCallback: (event: React.FormEvent<HTMLInputElement>) => {
                const newValue = (event.target as HTMLInputElement).value;
                onChangeCallback(newValue, propertyName);
            },
            onKeyPressCallback: this.handleKeyPress(onKeyPressCallback, onSubmitCallback),
            placeholder,
            propertyName,
            ref: (component: InternalCommonTextInput) => { this.primaryInput = component; },
            tabIndex,
            type: password ? 'password' : 'text',
            value
        };

        return <InternalCommonTextInput {...commonInputProps} />;
    }
}

export {
    ISimpleStringInputProps,
    SimpleStringInput
};
