// 3rd Party References
import { Action, handleActions } from 'redux-actions';

// Utility References
import { ReduxHelper } from 'Utility';

// App References
import {
    IApiSupplyRequestListingCollection as ICollection,
    IApiSupplyRequestListingOptions as IOptions,
    IApiSupplyRequestListingToolsViewModel as ITools,
    IApiSupplyRequestListingViewModel as IViewModel
} from 'App/IndexOfInterfaces';
import {
    SupplyRequestListingDefaultOptions as DefaultOptions,
    SupplyRequestListingQueryActionTypes as QueryActionTypes
} from 'App/IndexOfModels';

interface IState {
    collection: ICollection;
    options: IOptions;
    tools: ITools;
    viewModel: IViewModel;
}

const initialState = (): IState => {

    return {
        collection: null,
        options: DefaultOptions(null),
        tools: null,
        viewModel: null
    };
};

const actionWrapper = ReduxHelper.actionWrapper;
type Payload = ICollection | IOptions | ITools | IViewModel | void;

const reducer = handleActions<IState, Payload>({

    [QueryActionTypes.receive.collection]: (state: IState, action: Action<ICollection>): IState => {
        return actionWrapper(state, action, (newState: IState) => {
            newState.collection = action.payload;
        });
    },

    [QueryActionTypes.receive.options]: (state: IState, action: Action<IOptions>): IState => {
        return actionWrapper(state, action, (newState: IState) => {
            newState.options = action.payload;
        });
    },

    [QueryActionTypes.receive.tools]: (state: IState, action: Action<ITools>): IState => {
        return actionWrapper(state, action, (newState: IState) => {
            newState.tools = action.payload;
        });
    },

    [QueryActionTypes.receive.upsert]: (state: IState, action: Action<IViewModel>): IState => {
        return actionWrapper(state, action, (newState: IState) => {
            newState.viewModel = action.payload;
        });
    },

    [QueryActionTypes.reset.all]: (): IState => {
        return initialState();
    }

}, initialState());

export {
    reducer as SupplyRequestListingReducer,
    IState as ISupplyRequestListingState
};
