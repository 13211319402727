// 3rd Party References
import * as React from 'react';
import { hot } from 'react-hot-loader';

// Utility References
import { Handler } from 'Utility/IndexOfActions';
import {
    FormGroupRowWrapper,
    IFormGroupRowWrapperProps,
    SimpleReadOnlyInput,
} from 'Utility/IndexOfComponents';
import { FormControlHelper, ObjectHelper } from 'Utility/IndexOfHelpers';
import { IContentWidths } from 'Utility/IndexOfInterfaces';

// App References
import {
    IApiAccountDetailsViewModel as IViewModel
} from 'App/IndexOfInterfaces';
import { IPageState } from 'App/IndexOfModels';

interface IPropActions {
    getAccountDetails: Handler.Action;
    resetAll: Handler.Action;
}

interface IPropData {
    accountDetails: IViewModel;
    pageState: IPageState;
}

interface IAccountDetailsFormProps {
    propActions: IPropActions;
    propData: IPropData;
}

class Form extends React.Component<IAccountDetailsFormProps, {}> {

    componentDidMount() {

        this.props.propActions.resetAll();
        this.props.propActions.getAccountDetails();
    }

    private getFormPropertyNames = (viewModel: IViewModel) => {

        return {
            address: ObjectHelper.getPropertyName(() => viewModel.address),
            city: ObjectHelper.getPropertyName(() => viewModel.city),
            contactTelephone: ObjectHelper.getPropertyName(() => viewModel.contactTelephone),
            email: ObjectHelper.getPropertyName(() => viewModel.email),
            firstName: ObjectHelper.getPropertyName(() => viewModel.firstName),
            lastName: ObjectHelper.getPropertyName(() => viewModel.lastName),
            postCode: ObjectHelper.getPropertyName(() => viewModel.lastName),
            primaryContact: ObjectHelper.getPropertyName(() => viewModel.primaryContact),
            state: ObjectHelper.getPropertyName(() => viewModel.state)
        };
    }

    render() {

        const accountDetails = this.props.propData.accountDetails;

        const commonInputProps = (value) => {
            return {
                isDisabled: true,
                maxLength: 100,
                onChangeCallback: null,
                value: !ObjectHelper.isUndefinedOrNull(value) ? value : ''
            };
        };

        const formProperties = this.getFormPropertyNames(accountDetails);

        const formGroupWrapperPropsDefaults = {
            contentWidths: FormControlHelper.getContentWidths(
                'col-xs-12 col-md-9',
                'error',
                'col-xs-12',
                'col-xs-12 col-md-3 col-form-label')
        };

        const formGroupRowWrapperProps = (
            displayName: string,
            propertyName: string,
            contentWidths?: IContentWidths,
            isRequired?: boolean): IFormGroupRowWrapperProps => {

            return FormControlHelper.getFormGroupWrapperProps(
                contentWidths || formGroupWrapperPropsDefaults.contentWidths,
                this.props.propData.pageState.modelState,
                displayName,
                propertyName,
                isRequired || false
            );
        };

        return (
            <>
                <form className="form-account-details col-md-12">

                    <FormGroupRowWrapper {...formGroupRowWrapperProps('Firstname', formProperties.firstName, null, false)}>
                        <SimpleReadOnlyInput {...commonInputProps(accountDetails.firstName)}/>
                    </FormGroupRowWrapper>

                    <FormGroupRowWrapper {...formGroupRowWrapperProps('Lastname', formProperties.lastName, null, false)}>
                        <SimpleReadOnlyInput {...commonInputProps(accountDetails.lastName)}/>
                    </FormGroupRowWrapper>

                    <FormGroupRowWrapper {...formGroupRowWrapperProps('Email', formProperties.email, null, false)}>
                        <SimpleReadOnlyInput {...commonInputProps(accountDetails.email)}/>
                    </FormGroupRowWrapper>

                    <FormGroupRowWrapper {...formGroupRowWrapperProps('Address', formProperties.address, null, false)}>
                        <SimpleReadOnlyInput {...commonInputProps(accountDetails.address)}/>
                    </FormGroupRowWrapper>

                    <FormGroupRowWrapper {...formGroupRowWrapperProps('City', formProperties.city, null, false)}>
                        <SimpleReadOnlyInput {...commonInputProps(accountDetails.city)}/>
                    </FormGroupRowWrapper>

                    <FormGroupRowWrapper {...formGroupRowWrapperProps('Post Code', formProperties.postCode, null, false)}>
                        <SimpleReadOnlyInput {...commonInputProps(accountDetails.postCode)}/>
                    </FormGroupRowWrapper>

                    <FormGroupRowWrapper {...formGroupRowWrapperProps('State', formProperties.state, null, false)}>
                        <SimpleReadOnlyInput {...commonInputProps(accountDetails.state)}/>
                    </FormGroupRowWrapper>

                    <FormGroupRowWrapper {...formGroupRowWrapperProps('Contact Telephone', formProperties.contactTelephone, null, false)}>
                        <SimpleReadOnlyInput {...commonInputProps(accountDetails.contactTelephone)}/>
                    </FormGroupRowWrapper>

                </form>
            </>
        );
    }
}

const HotForm = hot(module)(Form);

export {
    HotForm as AccountDetailsForm,
    IAccountDetailsFormProps
};
