// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from '../../IndexOfActions';
import { SearchIcon } from '../../IndexOfComponents';
import { ButtonStyle } from '../../IndexOfEnums';
import { AnimationHelper, ButtonHelper } from '../../IndexOfHelpers';
import { IApiFilterBase, IButtonOptions } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

type FilterUpdateFunc = <TFilter extends IApiFilterBase>(options: TFilter) => void;

export interface ISearchButtonProps extends React.Props<SearchButton> {
    buttonOptions?: IButtonOptions;
    filterOptions: IApiFilterBase;
    isDisabled?: boolean;
    onClick: Handler.Action1<IApiFilterBase>;
    processing?: boolean;
    tabIndex?: number;
    updateOptions: FilterUpdateFunc;
}

export default class SearchButton extends React.Component<ISearchButtonProps, {}> {

    private primaryInput: HTMLButtonElement;

    public setFocus = (scroll?: number): void => {
        AnimationHelper.setFocus(this.primaryInput, scroll);
    }

    private onClick = (onClick: Handler.Action1<IApiFilterBase>, filterOptions: IApiFilterBase, updateOptions: FilterUpdateFunc) => () => {

        //TODOxUI: Throw away double click

        filterOptions.paginationOptions.pageNumber = 1;
        updateOptions(filterOptions);

        onClick(filterOptions);
    }

    render() {

        const { buttonOptions, filterOptions, isDisabled, onClick, processing, tabIndex, updateOptions } = this.props;

        const defaultButtonOptions: IButtonOptions = {
            buttonStyle: ButtonStyle.Primary,
            buttonText: 'Search'
        };

        const buttonProps = {
            className: ButtonHelper.getButtonClasses(defaultButtonOptions, buttonOptions),
            disabled: isDisabled,
            onClick: this.onClick(onClick, filterOptions, updateOptions),
            ref: (component: HTMLButtonElement) => { this.primaryInput = component; },
            role: 'button',
            tabIndex,
            type: 'submit'
        };

        const buttonTextDisplay = ButtonHelper.getButtonText(defaultButtonOptions, buttonOptions);

        return (
            <button {...buttonProps}>
                <SearchIcon processing={processing} /> {buttonTextDisplay}
            </button>
        );
    }
}
