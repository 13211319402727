// 3rd Party References
import * as React from 'react';

// Utility References
//import {  } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
//import {  } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

export interface IModelStateErrorProps extends React.Props<ModelStateError> {
    className?: string;
    propertyName: string;
    modelState?: any;
}

export class ModelStateError extends React.Component<IModelStateErrorProps, {}> {

    private hasModelStateError = (propertyName: string, modelState: any): boolean => {
        return modelState && modelState[propertyName];
    }

    private modelStateError = (propertyName: string, modelState: any): string => {
        if (this.hasModelStateError(propertyName, modelState)){
            return modelState[propertyName][0].toString();
        }
        return '';
    }

    render() {

        const { className, propertyName, modelState } = this.props;
        const baseClassName: string = className !== undefined ? className : '';

        return (
            <span className={this.hasModelStateError(propertyName, modelState) ? `text-danger ${baseClassName}` : 'hidden'}>
                {this.modelStateError(propertyName, modelState)}
            </span>
        );
    }
}
