// 3rd Party References
import * as React from 'react';

// Utility References
import { AlertIcon, InfoIcon } from '../../IndexOfComponents';
import { TranslationHelper } from '../../IndexOfHelpers';

export enum AlertFormats {
    Danger,
    Info,
    Success,
    Warning
}

interface IAlertDisplayProps {
    format: AlertFormats;
    message: string | string[];
}

const alertDisplay = (props: IAlertDisplayProps): JSX.Element => {

    const { format, message } = props;

    let alertClass: string;

    switch (format) {

        case AlertFormats.Danger:
            alertClass = 'alert-danger';
            break;

        case AlertFormats.Info:
            alertClass = 'alert-info';
            break;

        case AlertFormats.Success:
            alertClass = 'alert-success';
            break;

        case AlertFormats.Warning:
            alertClass = 'alert-warning';
            break;

        default:
            alertClass = 'alert-danger';
            break;
    }

    const divProps = {
        className: `alert ${alertClass}`
    };

    const icon = format === AlertFormats.Info
        ? <InfoIcon/>
        : <AlertIcon/>;

    const isMultiMessage = message instanceof Array;

    const getSingleMessage = (theMessage: string) => {

        const translatedMessage = TranslationHelper.translate(theMessage);

        if (!translatedMessage) {
            return <div></div>;
        }

        return (
            <div {...divProps}>
                {icon}&nbsp; &nbsp;{translatedMessage}
            </div>
        );
    };

    const getMultiMessage = (theMessages: string[]) => {

        if (!theMessages || theMessages.length === 0) {
            return <div></div>;
        }

        const translatedMessages = theMessages.map((theMessage: string) => {
            return TranslationHelper.translate(theMessage);
        });

        const content = translatedMessages.map((translatedMessage: string, index: number, array: string[]) => {

            const lastRow = index + 1 === array.length;

            if (lastRow) {
                return <div key={index}>{icon}&nbsp;&nbsp;{translatedMessage}</div>;
            }

            return <div key={index}>{icon}&nbsp;&nbsp;{translatedMessage}<br /><hr /></div>;
        });

        return (
            <div {...divProps}>
                {content}
            </div>
        );
    };

    return isMultiMessage ? getMultiMessage(message as string[]) : getSingleMessage(message as string);
};

export {
    alertDisplay as AlertDisplay,
    IAlertDisplayProps
};
