//3rd Party References
import * as React from 'react';

//Utility References
import { Handler } from 'Utility/IndexOfActions';
import { AnimationHelper, ObjectHelper, TranslationHelper } from 'Utility/IndexOfHelpers';

export interface IContinueButtonProps extends React.Props<ContinueButton> {
    accordionId: string;
    onClick?: Handler.Action;
    toggleTargetId: string;
}

export class ContinueButton extends React.Component<IContinueButtonProps, {}> {

    private primaryInput: HTMLButtonElement;

    public setFocus = (scroll?: number): void => {
        AnimationHelper.setFocus(this.primaryInput, scroll);
    }

    render() {

        const { accordionId, onClick, toggleTargetId } = this.props;

        let buttonProps = {
            role: 'button',
            className: 'btn btn-success pull-right',
            href: `#${toggleTargetId}`,
            onClick: undefined,
            ref: (component: HTMLButtonElement) => { this.primaryInput = component; }
        };

        if (onClick) {
            buttonProps = ObjectHelper.merge(buttonProps, { onClick });
        }

        const buttonText = TranslationHelper.translate('Continue');
        const iconClass = 'fa fa-arrow-down';

        return (
            <button {...buttonProps} aria-controls={toggleTargetId} aria-expanded="true" data-parent={`#${accordionId}`} data-toggle="collapse" >
                {buttonText} <span className={iconClass}></span>
            </button>
        );
    }
}
