// 3rd Party References
import * as React from 'react';
import { hot } from 'react-hot-loader';

// Utility References
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    FormGroupRowWrapper,
    IFormGroupRowWrapperProps,
    SimpleReadOnlyInput
} from 'Utility/IndexOfComponents';
import { FormControlHelper, ObjectHelper } from 'Utility/IndexOfHelpers';

// App References
import {
    IApiClientListingContactViewModel as IContact
} from 'App/IndexOfInterfaces';
import { IPageState } from 'App/IndexOfModels';

interface IPropData {
    cardTitle: string;
    contactInfo: IContact;
    pageState: IPageState;
}

interface IContactExchangeProps {
    propData: IPropData;
}

class ContactExchange extends React.Component<IContactExchangeProps> {

    private getFormPropertyNames = (viewModel: IContact) => {

        return {
            businessAddress: ObjectHelper.getPropertyName(() => viewModel.address),
            businessName: ObjectHelper.getPropertyName(() => viewModel.name),
            businessTelephone: ObjectHelper.getPropertyName(() => viewModel.telephone),
            contactEmail: ObjectHelper.getPropertyName(() => viewModel.primaryContactEmail),
            contactFirstName: ObjectHelper.getPropertyName(() => viewModel.primaryContactFirstName),
            contactLastName: ObjectHelper.getPropertyName(() => viewModel.primaryContactLastName),
            contactTelepehone: ObjectHelper.getPropertyName(() => viewModel.primaryContactTelephone)
        };
    }

    render() {

        const { propData } = this.props;

        const { contactInfo } = propData;

        if (ObjectHelper.isUndefinedOrNull(contactInfo)){
            return null;
        }

        const commonInputProps = (value) => {
            return {
                isDisabled: true,
                maxLength: 100,
                onChangeCallback: null,
                value: !ObjectHelper.isUndefinedOrNull(value) ? value : ''
            };
        };

        const formProperties = this.getFormPropertyNames(contactInfo);

        const formGroupWrapperPropsDefaults = {
            contentWidths: FormControlHelper.getContentWidths(
                'col-xs-12 col-md-7',
                'error',
                'col-xs-12',
                'col-xs-12 col-md-5 col-form-label')
        };

        const formGroupRowWrapperProps = (
            displayName: string,
            propertyName: string): IFormGroupRowWrapperProps => {

            return FormControlHelper.getFormGroupWrapperProps(
                formGroupWrapperPropsDefaults.contentWidths,
                this.props.propData.pageState.modelState,
                displayName,
                propertyName,
                false
            );
        };

        return (
            <div className="mb-3">
                <Card>
                    <CardHeader>
                        <CardTitle text={propData.cardTitle} />
                    </CardHeader>

                    <CardBody>
                        <div className="row">
                            <form className="col-md-12">

                                <FormGroupRowWrapper {...formGroupRowWrapperProps('Business Name', formProperties.businessName)}>
                                    <SimpleReadOnlyInput {...commonInputProps(contactInfo.name)}/>
                                </FormGroupRowWrapper>

                                <FormGroupRowWrapper {...formGroupRowWrapperProps('Business Address', formProperties.businessAddress)}>
                                    <SimpleReadOnlyInput {...commonInputProps(contactInfo.address)}/>
                                </FormGroupRowWrapper>

                                <FormGroupRowWrapper {...formGroupRowWrapperProps('Business Telephone', formProperties.businessTelephone)}>
                                    <SimpleReadOnlyInput {...commonInputProps(contactInfo.telephone)}/>
                                </FormGroupRowWrapper>

                                <FormGroupRowWrapper {...formGroupRowWrapperProps('Contact First Name', formProperties.contactFirstName)}>
                                    <SimpleReadOnlyInput {...commonInputProps(contactInfo.primaryContactFirstName)}/>
                                </FormGroupRowWrapper>

                                <FormGroupRowWrapper {...formGroupRowWrapperProps('Contact Last Name', formProperties.contactLastName)}>
                                    <SimpleReadOnlyInput {...commonInputProps(contactInfo.primaryContactLastName)}/>
                                </FormGroupRowWrapper>

                                <FormGroupRowWrapper {...formGroupRowWrapperProps('Contact Telephone', formProperties.contactTelepehone)}>
                                    <SimpleReadOnlyInput {...commonInputProps(contactInfo.primaryContactTelephone)}/>
                                </FormGroupRowWrapper>

                                <FormGroupRowWrapper {...formGroupRowWrapperProps('Contact Email', formProperties.contactEmail)}>
                                    <SimpleReadOnlyInput {...commonInputProps(contactInfo.primaryContactEmail)}/>
                                </FormGroupRowWrapper>

                            </form>
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

const HotForm = hot(module)(ContactExchange);

export {
    HotForm as SupplyRequestListingContactExchange,
    IContactExchangeProps as ISupplyRequestListingContactExchangeProps
};
