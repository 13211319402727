// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from '../../../IndexOfActions';
//import {  } from '../../../IndexOfComponents';
//import {  } from '../../../IndexOfComponents';
//import {  } from '../../../IndexOfEnums';
import { AnimationHelper } from '../../../IndexOfHelpers';
//import {  } from '../../../IndexOfInterfaces';
//import {  } from '../../../IndexOfModels';
//import {  } from '../../../IndexOfServices';

interface IClearButtonProps {
    isDisabled?: boolean;
    resetClickHandler: Handler.Action;
    value: Date | number | string;
}

export default class ClearButton extends React.Component<IClearButtonProps, {}> {

    private primaryButton: HTMLButtonElement;

    public activate = () => {
        this.primaryButton.disabled = false;
    }

    public setFocus = (scroll?: number): void => {
        AnimationHelper.setFocus(this.primaryButton, scroll);
    }

    private onClick = (resetClickHandler: Handler.Action) => () => {
        this.primaryButton.disabled = true;
        resetClickHandler();
    }

    render() {

        const { isDisabled, resetClickHandler, value } = this.props;

        const buttonIsDisabled = value === null || value === undefined || value === '' || isDisabled;

        const buttonProps = {
            className: 'btn btn-default fa fa-remove',
            disabled: buttonIsDisabled,
            onClick: this.onClick(resetClickHandler),
            ref: (component: HTMLButtonElement) => { this.primaryButton = component; },
            style: {
                paddingLeft: '5px',
                paddingRight: '5px',
                top: '0'
            },
            type: 'button'
        };

        return (
            <button {...buttonProps} />
        );
    }
}
