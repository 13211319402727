//3rd Party References
import * as React from 'react';

//Utility References
import { Handler } from 'Utility/IndexOfActions';
import { IApiItemOptionViewModel } from 'Utility/IndexOfInterfaces';

import InternalCommonSelect, { IInternalCommonSelectProps } from './Internal/InternalCommonSelect';

export interface ISimpleSelectStringProps extends React.Props<SimpleSelectString> {
    displayName?: string;
    inputClassName?: string;
    isDisabled?: boolean;
    isFilter?: boolean;
    items: IApiItemOptionViewModel[];
    onBlurCallback?: Handler.SelectFocusEvent;
    onChangeCallback: Handler.StringProperty;
    onKeyPressCallback?: Handler.SelectKeyboardEvent;
    placeholder?: string;
    propertyName?: string;
    tabIndex?: number;
    value: string;
}

export default class SimpleSelectString extends React.Component<ISimpleSelectStringProps, {}> {

    private primaryInput: InternalCommonSelect;

    public setFocus = (scroll?: number): void => {
        this.primaryInput.setFocus(scroll);
    }

    render() {

        const { displayName, inputClassName, isDisabled, isFilter, items, onBlurCallback, onChangeCallback, onKeyPressCallback, placeholder, propertyName, tabIndex, value } = this.props;

        const selectedValueAsString: string = value === '' || value === undefined || value === null ? null : value;

        const selectProps: IInternalCommonSelectProps = {
            displayName,
            inputClassName,
            isDisabled,
            isFilter,
            items,
            onBlurCallback,
            onChangeCallback,
            onKeyPressCallback,
            promptText: placeholder,
            propertyName,
            ref: (component: InternalCommonSelect) => { this.primaryInput = component; },
            tabIndex,
            value: selectedValueAsString
        };

        return <InternalCommonSelect {...selectProps} />;
    }
}
