// Utility References
import { ObjectHelper } from 'Utility/IndexOfHelpers';

export const Constants = {
    InsurerLogoFileSize: 1000000,
    JPEGType: 'image/jpeg',
    UploadDocumentFileSize: 5000000,
    PDFType: 'application/pdf',
    PNGType: 'image/png',
    RoleNames: {
        Admin: 'Admin',
        Dealer: 'Dealer',
        Insurer: 'Insurer'
    }
};

export const ModalNames = {
    BidHistoryDetailsModal: 'BidHistoryDetailsModal',
    EnquiryFormModal: 'EnquiryFormModal'
};
