// 3rd Party
import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';

// Utility References
import { IApiProgressFunctions } from 'Utility';
import { Handler } from 'Utility/IndexOfActions';
import { IGenericQueryActions } from 'Utility/IndexOfInterfaces';
import { Notification } from 'Utility/IndexOfServices';

// App References
import {
    AllExceptionNotifier,
    IApiEnquiryToolsViewModel as ITools,
    IApiEnquiryUpsertViewModel as IUpsert,
    IApiEnquiryViewModel as IViewModel
} from 'App/IndexOfInterfaces';
import {
    EnquiryActionTypes as ActionTypes,
    EnquiryQueryActionTypes as QueryActionTypes,
    GenericQueryActions,
    IEnquiryState as IState,
} from 'App/IndexOfModels';
import {
    EnquiryActionService as ApiActionService,
    EnquiryQueryService as QueryService
} from 'App/IndexOfServices';
import { FormHelper } from 'Utility/IndexOfHelpers';

interface IActions extends IGenericQueryActions<null, null, ITools, IUpsert> {
    saveAsync: Handler.Action1<IViewModel>;
}

class EnquiryActions extends GenericQueryActions<null, null, ITools, IUpsert> {

    public saveAsync = (viewModel: IViewModel) => (dispatch: Handler.AnyForDispatch): void => {

        const onSuccess = () => {

            Notification.success.recordCreated('Your enquiry has been successfully submitted');
            dispatch(this.resetEnquiryForm());
        };

        const formData = FormHelper.objectToFormData(viewModel);

        this.api.callWithPayload(ApiActionService.save(), formData, onSuccess, this.allExceptionNotifier, true);
    }

    public resetEnquiryForm = createAction(ActionTypes.reset.enquiryForm);
}

const actionDispatcherFactory = (dispatch: Dispatch<IState>, progressFunctions: IApiProgressFunctions): IActions => {

    const notificationsTitle = 'Enquiry';
    const actions = new EnquiryActions(progressFunctions, AllExceptionNotifier(notificationsTitle), QueryService, QueryActionTypes);

    return {
        getAsync: null,
        getTemplateAsync:  () => {
            dispatch(actions.getTemplateAsync());
        },
        getToolsAsync:  () => {
            dispatch(actions.getToolsAsync());
        },
        receiveCollection: null,
        receiveOptions: null,
        receiveTools: (tools: ITools) => {
            dispatch(actions.receiveTools(tools));
        },
        receiveUpsert: (upsert: IUpsert) => {
            dispatch(actions.receiveUpsert(upsert));
        },
        resetAll: () => {
            dispatch(actions.resetAll());
        },
        saveAsync: (viewModel: IViewModel) => {
            dispatch(actions.saveAsync(viewModel));
        },
        searchAsync: null
    };
};

export {
    actionDispatcherFactory as EnquiryActionsDispatcherFactory,
    IActions as IEnquiryActions
};
