// 3rd Party References
import * as React from 'react';

// Utility References
//import {  } from '../../IndexOfActions';
import { AlertIcon } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
import { TranslationHelper } from '../../IndexOfHelpers';
import { IApiUnselectable } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

export interface IUnselectableDisplayProps {
    unselectable: IApiUnselectable;
}

const unselectableDisplay = (props: IUnselectableDisplayProps): JSX.Element => {

    const { unselectable } = props;

    if (!unselectable) {
        return null;
    }

    const translatedMessage = TranslationHelper.translate(unselectable.reason);

    return (
        <div className="text-danger">
            <AlertIcon />&nbsp; &nbsp;{translatedMessage}
        </div>
    );
};

export {
    unselectableDisplay as UnselectableDisplay
};
