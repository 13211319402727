//3rd Party References
import * as React from 'react';

//Utility References
import { Translation } from 'Utility/IndexOfComponents';
import { KeyboardEventHelper, ObjectHelper } from 'Utility/IndexOfHelpers';
import { IApiFilterBase, IApiPaginationOptions, IApiPaginationViewModel, IApiSearchViewModelBase } from 'Utility/IndexOfInterfaces';
import { IGridFilterContext } from 'Utility/IndexOfModels';

// Local References
import { IPaginationCalculator, PaginationCalculator } from './PaginationCalc';

type UpdateFunc = (targetPageNumber: number, debounce?: boolean, pageSize?: number) => void;

export interface IGridPaginationProps {
    currentSearchOptions: IApiFilterBase;
    currentSearchViewModel: IApiSearchViewModelBase;
    gridFilterContext: IGridFilterContext;
    minPageItemsToDisplay?: number;
}

export default class GridPagination extends React.Component<IGridPaginationProps, {}> {

    private createPrevButton = (paginationCalculator: IPaginationCalculator, updateFunc: UpdateFunc) => {

        const prevButtonClass = paginationCalculator.onFirstPage ? 'disabled' : '';

        return (
            <li key="prev" onClick={this.prevButtonClicked(paginationCalculator, updateFunc)} className={prevButtonClass}>
                <span className="fa fa-chevron-left" aria-hidden="true"></span>
            </li>
        );
    }

    private createNextButton = (paginationCalculator: IPaginationCalculator, updateFunc: UpdateFunc) => {

        const nextButtonClass = paginationCalculator.onLastPage ? 'disabled' : '';

        return (
            <li key="next" onClick={this.nextButtonClicked(paginationCalculator, updateFunc)} className={nextButtonClass}>
                <span className="fa fa-chevron-right" aria-hidden="true"></span>
            </li>
        );
    }

    private createNumberButtons = (paginationCalculator: IPaginationCalculator, updateFunc: UpdateFunc) => {

        const numberButtons: JSX.Element[] = [];

        for (let i = paginationCalculator.numberButtonsStartOffset; i < paginationCalculator.visibleButtonsLimit; i++) {
            const numberButton = this.createNumberButton(paginationCalculator, i, updateFunc);
            numberButtons.push(numberButton);
        }

        return numberButtons;
    }

    private createNumberButton = (paginationCalculator: IPaginationCalculator, pageButtonLabel: number, updateFunc: UpdateFunc) => {

        const buttonClass = paginationCalculator.currentPageMatch(pageButtonLabel) ? 'button selected' : 'button';

        return (
            <li key={pageButtonLabel} className={buttonClass} onClick={this.numberButtonClicked(pageButtonLabel, updateFunc)}>
                {pageButtonLabel}
            </li>
        );
    }

    private createPrevEllipsisButton = (paginationCalculator: IPaginationCalculator, updateFunc: UpdateFunc) => {

        return (
            <li key="prevEllipsis" onClick={this.prevEllipsisButtonClicked(paginationCalculator, updateFunc)}>
                ...
            </li>
        );
    }

    private createNextEllipsisButton = (paginationCalculator: IPaginationCalculator, updateFunc: UpdateFunc) => {

        return (
            <li key="nextEllipsis" onClick={this.nextEllipsisButtonClicked(paginationCalculator, updateFunc)}>
                ...
            </li>
        );
    }

    private createGotoPageControl = (paginationCalculator: IPaginationCalculator, updateFunc: UpdateFunc) => {

        /* tslint:disable:jsx-no-string-ref */
        return (
            <div className="go-to-page">
                <span className="goto"><Translation>Go to</Translation></span>
                <input ref="gotoPage" type="number" onChange={this.gotoPageInput(updateFunc)} />
                <div>
                    <span className="of">of</span>
                    <span className="total">{paginationCalculator.totalPages}</span>
                </div>
            </div>
        );
        /* tslint:enable:jsx-no-string-ref */
    }

    private createPageSizeControl = (paginationCalculator: IPaginationCalculator, updateFunc: UpdateFunc) => {

        // Don't return control if the pagination size is the representation of no pagination
        if (paginationCalculator.currentPageSize === paginationCalculator.maxPageSize) {
            return null;
        }

        const pageSizes = paginationCalculator.pageSizes;

        return (
            <select className="pagesize-control" onChange={this.handlePageSizeChange(updateFunc)} value={paginationCalculator.currentPageSize}>
                {pageSizes.map((value, index) => <option key={index} value={value.toString()}>{value.toString()}</option>)}
            </select>
        );
    }

    private getRecordCount = (paginationViewModel: IApiPaginationViewModel) => {

        if (!paginationViewModel) {
            return;
        }

        // TODOxTJ: Add translations
        return (
            <strong>Displaying {paginationViewModel.recordCount} Result(s)</strong>
        );
    }

    //Event Handling

    private numberButtonClicked = (targetPageNo: number, updateFunc: UpdateFunc) => () => {

        (this.refs as any).gotoPage.value = '';
        updateFunc(targetPageNo);
    }

    private prevButtonClicked = (paginationCalculator: IPaginationCalculator, updateFunc: UpdateFunc) => (): void => {

        (this.refs as any).gotoPage.value = '';
        const targetPageNo = paginationCalculator.prevPageNo;

        updateFunc(targetPageNo);
    }

    private nextButtonClicked = (paginationCalculator: IPaginationCalculator, updateFunc: UpdateFunc) => (): void => {

        (this.refs as any).gotoPage.value = '';
        const targetPageNo = paginationCalculator.nextPageNo;

        updateFunc(targetPageNo);
    }

    private prevEllipsisButtonClicked = (paginationCalculator: IPaginationCalculator, updateFunc: UpdateFunc) => () => {

        updateFunc(paginationCalculator.prevEllipsisButtonNewOffset);
    }

    private nextEllipsisButtonClicked = (paginationCalculator: IPaginationCalculator, updateFunc: UpdateFunc) => () => {

        updateFunc(paginationCalculator.nextEllipsisButtonNewOffset);
    }

    private gotoPageInput = (updateFunc: UpdateFunc) => (event: React.FormEvent<HTMLInputElement>): void => {

        const inputControl = (event.target as HTMLInputElement);
        const inputPageNo = parseInt(inputControl.value, 10);
        const targetPageNo = (inputPageNo) ? inputPageNo : 1;

        updateFunc(targetPageNo, true /*debounce*/);
    }

    private handlePageSizeChange = (updateFunc: UpdateFunc) => (event: React.ChangeEvent<HTMLSelectElement>): void => {

        const pageSize = parseInt(event.currentTarget.value, 10);

        updateFunc(1 /*targetPageNo*/, false /*debounce*/, pageSize);
    }

    private updatePage = (gridFilterContext: IGridFilterContext, paginationCalculator: IPaginationCalculator, paginationOptions: IApiPaginationOptions) => (targetPageNo: number, debounced?: boolean, pageSize?: number) => {

        const overrideCurrentPage = (pageSize !== undefined && pageSize !== null);
        const validTargetPage = paginationCalculator.validTargetPage(targetPageNo, overrideCurrentPage);

        if (validTargetPage) {

            const currOptions = paginationOptions;
            currOptions.pageNumber = targetPageNo;

            if (pageSize) {
                currOptions.pageSize = pageSize;
            }

            if (debounced) {
                KeyboardEventHelper.delayCallback()(() => { gridFilterContext.paginationHandler(currOptions); }, 1000);
            } else {
                gridFilterContext.paginationHandler(currOptions);
            }
        }
    }

    render() {

        const { currentSearchOptions, currentSearchViewModel, gridFilterContext, minPageItemsToDisplay } = this.props;

        const paginationOptionsFromProp = currentSearchOptions && currentSearchOptions.paginationOptions;
        const paginationViewModelFromProp = currentSearchViewModel && currentSearchViewModel.pageData;

        const paginationOptions = ObjectHelper.deepClone(paginationOptionsFromProp);
        const paginationViewModel = ObjectHelper.deepClone(paginationViewModelFromProp);

        const paginationInfo = PaginationCalculator(paginationOptions, paginationViewModel);

        const updateFunc: UpdateFunc = this.updatePage(gridFilterContext, paginationInfo, paginationOptions);

        const currentPageItemCount = !ObjectHelper.isUndefinedOrNull(paginationViewModel) ? paginationViewModel.recordCount % paginationInfo.currentPageSize : 0;

        const shouldDisplay = ObjectHelper.isUndefinedOrNull(minPageItemsToDisplay) || minPageItemsToDisplay <= currentPageItemCount;

        const nextButton = paginationInfo.moreThanOnePage ? this.createNextButton(paginationInfo, updateFunc) : null;
        const previousButton = paginationInfo.moreThanOnePage ? this.createPrevButton(paginationInfo, updateFunc) : null;

        const numberButtons = paginationInfo.moreThanOnePage ? this.createNumberButtons(paginationInfo, updateFunc) : null;
        const nextEllipsisButton = paginationInfo.needsNextEllipsis ? this.createNextEllipsisButton(paginationInfo, updateFunc) : null;
        const prevEllipsisButton = paginationInfo.needsPrevEllipsis ? this.createPrevEllipsisButton(paginationInfo, updateFunc) : null;

        const gotoPageControl = paginationInfo.moreThanOnePage ? this.createGotoPageControl(paginationInfo, updateFunc) : null;

        const pageSizeControl = this.createPageSizeControl(paginationInfo, updateFunc);

        return (
            shouldDisplay &&
            <div>
                <div className="grid-pagination pull-right">
                    <ul>
                        {previousButton}
                        {prevEllipsisButton}
                        {numberButtons}
                        {nextEllipsisButton}
                        {nextButton}
                    </ul>
                    {gotoPageControl}
                    {pageSizeControl}
                </div>
                <div className="pull-left">{this.getRecordCount(paginationViewModel)}</div>
            </div>
        );
    }
}
