// 3rd Party References
import * as React from 'react';

// Utility References
//import {  } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
//import {  } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

export default class GridFooter extends React.Component<{}, {}> {

    render() {

        return (
            <div className="panel-footer clearfix">
                {this.props.children}
            </div>
        );
    }
}
