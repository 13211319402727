// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from 'Utility/IndexOfActions';
import { IModalService } from 'Utility/IndexOfServices';

// Local References
import { IMessagePromptModalProps, MessagePromptModal } from './Internal/MessagePromptModal';

interface IOkPromptModalProps {
    actionOnConfirmed: Handler.Action;
    message: string;
    modalService: IModalService;
    title: string;
}

class OkPromptModal extends React.Component<IOkPromptModalProps, {}> {

    render() {

        const { actionOnConfirmed, message, modalService, title } = this.props;

        const handleOnClick = (actionOnConfirmed: Handler.Action) => (isConfirmed: boolean) => {

            if (isConfirmed) {
                actionOnConfirmed();
            }
        };

        const messagePromptModalProps: IMessagePromptModalProps = {
            buttonText: 'OK',
            message,
            modalService,
            onClose: handleOnClick(actionOnConfirmed),
            title
        };

        return (
            <MessagePromptModal {...messagePromptModalProps} />
        );
    }
}

export {
    IOkPromptModalProps,
    OkPromptModal
};
