// 3rd Party
import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';

// Utility References
import { IApiProgressFunctions } from 'Utility';
import { Handler, NavigationActions } from 'Utility/IndexOfActions';
import { IGenericQueryActions } from 'Utility/IndexOfInterfaces';
import { Notification } from 'Utility/IndexOfServices';

// App References
import {
    AllExceptionNotifier,
    IApiLoginToolsViewModel as ITools,
    IApiLoginUpsertViewModel as IUpsert,
    IApiLoginViewModel as IViewModel,
    IAuthenticationInfoViewModel as IAuthViewModel
} from 'App/IndexOfInterfaces';
import {
    Constants,
    GenericQueryActions,
    ILoginState,
    LoginAreaQueryActionTypes as QueryActionTypes
} from 'App/IndexOfModels';

import { LocalStorageService, UserApi } from 'App/IndexOfServices';

import { AppUrls } from 'App/IndexOfUrls';

interface ILoginActions extends IGenericQueryActions<null, null, ITools, IUpsert> {
    checkAuthenticated: Handler.Action;
    loginAsync: Handler.Action1<IViewModel>;
}

class LoginActions extends GenericQueryActions<null, null, ITools, IUpsert> {

    public checkAuthenticated = () => (dispatch: Handler.AnyForDispatch): void => {

        const user = LocalStorageService.getUserInfo();
        if (user !== null) {

            NavigationActions.HardRedirectPageWithoutNotification(AppUrls.DashboardUrls.area, 0);
        }
    }

    public loginAsync = (viewModel: IViewModel) => (dispatch: Handler.AnyForDispatch): void => {

        const onSuccess = (authInfo: IAuthViewModel) => {

            Notification.success.authenticated();
            LocalStorageService.setAccessToken(authInfo.tokenInfo);
            LocalStorageService.setUserInfo(authInfo.user);

            window.history.pushState(null, null, '/');

            // will sort out other actions after
            if (LocalStorageService.isInRole(Constants.RoleNames.Insurer)) {
                NavigationActions.HardRedirectPageWithoutNotification(AppUrls.DashboardUrls.running, 0);
            }
            else {
                NavigationActions.HardRedirectPageWithoutNotification(AppUrls.SupplyRequestUrls.listings, 0);
            }
        };

        this.api.callWithPayload(UserApi.login(), viewModel, onSuccess, this.allExceptionNotifier);
    }
}

const actionDispatcherFactory = (dispatch: Dispatch<ILoginState>, progressFunctions: IApiProgressFunctions): ILoginActions => {

    const notificationsTitle = 'Login';
    const actions = new LoginActions(progressFunctions, AllExceptionNotifier(notificationsTitle), null, QueryActionTypes);

    return {
        checkAuthenticated: () => {
            dispatch(actions.checkAuthenticated());
        },
        getAsync: null,
        getTemplateAsync: null,
        getToolsAsync: null,
        receiveCollection: null,
        receiveOptions: null,
        receiveTools: null,
        receiveUpsert: (upsert: IUpsert) => {
            dispatch(actions.receiveUpsert(upsert));
        },
        resetAll: () => {
            dispatch(actions.resetAll());
        },
        loginAsync: (viewModel: IViewModel) => {
            dispatch(actions.loginAsync(viewModel));
        },
        searchAsync: null
    };
};

export {
    actionDispatcherFactory as LoginActionsDispatcherFactory,
    ILoginActions
};
