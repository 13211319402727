// 3rd Party References
import * as React from 'react';

//Local References
import { IModelStateErrorProps, ModelStateError } from '../../Components/FormElements/ModelStateError';
import { IModelState } from '../../Interfaces/PageUIInterfaces';

export interface IFormGroupProps extends React.Props<FormGroup> {
    className?: string;
    modelState?: IModelState;
    modelStateClassName?: string;
    propertyName: string;
    useErrorDisplay?: boolean;
}

export class FormGroup extends React.Component<IFormGroupProps, {}> {

    private hasModelStateError = (modelState: any, propertyName: string): boolean => {
        return modelState && modelState[propertyName];
    }

    private getModelStateErrorDisplay = (modelState: IModelState, modelStateClassName: string, propertyName: string) => {

        const modelStateErrorContainerProps = {
            className: modelStateClassName || 'col-xs-12'
        };

        const modelStateErrorProps: IModelStateErrorProps = {
            modelState,
            propertyName
        };

        return (
            <div {...modelStateErrorContainerProps}>
                <ModelStateError {...modelStateErrorProps}/>
            </div>
        );
    }

    render() {

        const { className, modelState, modelStateClassName, propertyName, useErrorDisplay } = this.props;

        const newClassName = `form-group ${className}`;

        return (
            <div className={this.hasModelStateError(modelState, propertyName) ? newClassName + ' has-error' : newClassName}>
                {this.props.children}
                {useErrorDisplay && modelState && this.getModelStateErrorDisplay(modelState, modelStateClassName, propertyName)}
            </div>
        );
    }
}
