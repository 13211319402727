// 3rd Party References
import * as React from 'react';
import { hot } from 'react-hot-loader';
import { NavLink } from 'react-router-dom';

// Utility References
import { Handler } from 'Utility/IndexOfActions';
import {
    FormGroupWrapper,
    IFormGroupWrapperProps,
    ISaveButtonProps,
    ISimpleStringInputProps,
    SaveButton,
    SimpleStringInput
} from 'Utility/IndexOfComponents';
import { ButtonSize } from 'Utility/IndexOfEnums';
import { FormControlHelper, ObjectHelper } from 'Utility/IndexOfHelpers';
import { IContentWidths } from 'Utility/IndexOfInterfaces';
import { ModelUpdater } from 'Utility/IndexOfModels';

// App References
import {
    IApiLoginUpsertViewModel as IUpsert,
    IApiLoginViewModel as IViewModel
} from 'App/IndexOfInterfaces';
import { IPageActions, IPageState } from 'App/IndexOfModels';
import { AppUrls } from 'App/IndexOfUrls';

interface IPropActions {
    checkAuthenticated: Handler.Action;
    onSubmit: Handler.Action1<IViewModel>;
    pageActions: IPageActions;
    receiveUpsert: Handler.Action1<IUpsert>;
}

interface IPropData {
    pageState: IPageState;
    upsert: IUpsert;
}

interface IFormProps extends React.Props<Form> {
    propActions: IPropActions;
    propData: IPropData;
}

class Form extends React.Component<IFormProps, {}> {

    componentDidMount() {
        const { propActions } = this.props;
        propActions.checkAuthenticated();
    }

    componentWillUnmount() {

        const { propActions } = this.props;

        propActions.pageActions.clearAll();
    }

    private getFormPropertyNames = (viewModel: IViewModel) => {

        return {
            password: ObjectHelper.getPropertyName(() => viewModel.password),
            username: ObjectHelper.getPropertyName(() => viewModel.username)
        };
    }

    private isViewModelValid = (viewModel: IViewModel): boolean => {

        const hasViewModel = !ObjectHelper.isUndefinedOrNull(viewModel);

        return hasViewModel;
    }

    private handleOnSubmit = (propActions: IPropActions, propData: IPropData, viewModel: IViewModel): void => {

        const clonedModel = ObjectHelper.deepClone(viewModel);

        if (this.validate(propActions, propData, viewModel)) {
            propActions.onSubmit(clonedModel);
        }
    }

    private validate = (propActions: IPropActions, propData: IPropData, viewModel: IViewModel): boolean => {

        let isValid: boolean = true;

        const { pageActions } = propActions;

        const formProperties = this.getFormPropertyNames(viewModel);

        pageActions.clearAll();

        if (!viewModel.username) {
            pageActions.addError(formProperties.username, 'Please enter a username.');
            isValid = false;
        }

        if (viewModel.username && viewModel.username.match(/[!@#$%^&*]/g)) {
            pageActions.addError(formProperties.username, 'Usernames cannot contain special characters such as ! @ # $ % ^ & *.');
            isValid = false;
        }

        if (viewModel.username && viewModel.username.match(/\s/g)) {
            pageActions.addError(formProperties.username, 'Usernames cannot contain spaces.');
            isValid = false;
        }

        const validations = [
            // TODO -xAS- : Tidy up once FormHelper is in
            this.validateProperty(pageActions, formProperties.password, viewModel.password, 'Please enter your password.'),
            this.validateProperty(pageActions, formProperties.username, viewModel.username, 'Please enter your username.')
        ];

        return isValid && validations.every(x => x);
    }

    private validateProperty = (pageActions: IPageActions, propertyName: string, value: any, errorMessage: string): boolean => {

        if (!value) {
            pageActions.addError(propertyName, errorMessage);
            return false;
        }

        return true;
    }

    render() {

        const { propActions, propData } = this.props;

        const upsert = propData.upsert;

        if (!this.isViewModelValid(upsert.model)) {
            return null;
        }

        const formProperties = this.getFormPropertyNames(upsert.model);

        const formGroupWrapperPropsDefaults = {
            contentWidths: FormControlHelper.getContentWidths(
                'children',
                'error',
                'input-wrapper',
                'sr-only')
        };

        const formGroupWrapperProps = (
            displayName: string,
            propertyName: string,
            contentWidths?: IContentWidths,
        ): IFormGroupWrapperProps => {

            return FormControlHelper.getFormGroupWrapperProps(
                contentWidths || formGroupWrapperPropsDefaults.contentWidths,
                this.props.propData.pageState.modelState,
                displayName,
                propertyName,
                true);
        };

        const modelUpdater = ModelUpdater(propData.upsert, propActions.receiveUpsert);

        const passwordProps: ISimpleStringInputProps = {
            maxLength: 100,
            onChangeCallback: modelUpdater.for<string>((value, model) => {

                model.model.password = value;

                return model;
            }),
            onKeyPressCallback: (e) => {

                if (e.key === 'Enter') {
                    this.handleOnSubmit(propActions, propData, upsert.model);
                }
            },
            password: true,
            placeholder: 'Password',
            value: upsert.model.password
        };

        const submitButtonProps: ISaveButtonProps = {
            buttonOptions: { appendedClassName: 'login-button', buttonText: 'Login', buttonSize: ButtonSize.Xl },
            onClick: () => {

                this.handleOnSubmit(propActions, propData, upsert.model);
            },
            processing: propData.pageState.processing
        };

        const userNameProps: ISimpleStringInputProps = {
            maxLength: 100,
            onChangeCallback: modelUpdater.for<string>((value, model) => {

                model.model.username = value;

                return model;
            }),
            placeholder: 'Username',
            value: upsert.model.username
        };

        return (
            <form className="form-login col-md-4">
                <FormGroupWrapper {...formGroupWrapperProps('Username', formProperties.username)}>
                    <SimpleStringInput {...userNameProps} />
                </FormGroupWrapper>

                <FormGroupWrapper {...formGroupWrapperProps('Password', formProperties.password)}>
                    <SimpleStringInput {...passwordProps} />
                </FormGroupWrapper>

                <div className="mb-3">
                    <a href={AppUrls.LoginUrls.forgottenPassword}>Forgotten your password?</a>
                </div>

                <div>
                    <SaveButton {...submitButtonProps} />
                </div>
            </form>
        );
    }
}

const HotForm = hot(module)(Form);

export {
    HotForm as LoginForm,
    IFormProps as ILoginFormProps
};
