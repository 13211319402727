// 3rd Party References
import * as React from 'react';
import { hot } from 'react-hot-loader';

// Utility References
import { Handler } from 'Utility/IndexOfActions';
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    FormGroupWrapper,
    IFormGroupWrapperProps,
    ISimpleButtonProps,
    PrimaryButton,
    SimpleFileUploadInput
} from 'Utility/IndexOfComponents';
import { FormControlHelper, FormHelper, ObjectHelper } from 'Utility/IndexOfHelpers';

// App References
import { CreateValidatableDocumentFields } from 'App/Helpers/DocumentUploadValidationHelper';
import { IApiSupplyRequestListingViewModel as IViewModel } from 'App/IndexOfInterfaces';
import { Constants, IPageActions, IPageState } from 'App/IndexOfModels';
import { IRequiredField } from 'Utility/IndexOfInterfaces';

interface IPropActions {
    onPurchaseOrderUploaded?: Handler.Action;
    pageActions: IPageActions;
    uploadPurchaseOrder: Handler.ActionAsync1<IViewModel, void>;
}

interface IPropData {
    isInsurer: boolean;
    pageState: IPageState;
    viewModel: IViewModel;
}

interface IPurchaseOrderUploadFormProps {
    propActions: IPropActions;
    propData: IPropData;
}

class PurchaseOrderUploadForm extends React.Component<IPurchaseOrderUploadFormProps> {

    private purchaseOrderDocumentFile: SimpleFileUploadInput;

    private isViewModelValid = (viewModel: IViewModel): boolean => {

        return !ObjectHelper.isUndefinedOrNull(viewModel);
    }

    private getRequiredFieldsForValidation(viewModel: IViewModel): IRequiredField[] {

        const requiredFields: IRequiredField[] = [];

        const validatableDocumentFields = CreateValidatableDocumentFields(() => viewModel.purchaseOrderDocument);

        return requiredFields.concat(validatableDocumentFields);
    }

    private handleOnSubmit = (propActions: IPropActions, propData: IPropData, viewModel: IViewModel): void => {

        const { pageActions: { clearAll, addError } } = propActions;

        if (FormHelper.areRequiredPropertiesValid(clearAll, addError, this.getRequiredFieldsForValidation(viewModel))) {

            propActions.uploadPurchaseOrder(viewModel).then(() => {

                if (!ObjectHelper.isUndefinedOrNull(propActions.onPurchaseOrderUploaded)){

                    propActions.onPurchaseOrderUploaded();
                }
            });
        }
    }

    render() {

        const { propActions, propData } = this.props;

        const { isInsurer, viewModel } = propData;

        if (!this.isViewModelValid(viewModel)){
            return null;
        }

        const purchaseOrderDocumentFormGroupWrapperProps = (): IFormGroupWrapperProps => {

            return FormControlHelper.getFormGroupWrapperProps(
                FormControlHelper.getContentWidths(
                    'children',
                    'error',
                    'input-wrapper',
                    'sr-only'
                ),
                this.props.propData.pageState.modelState,
                'Purchase Order Document',
                ObjectHelper.getPropertyName(() => viewModel.purchaseOrderDocument),
                true
            );
        };

        const submitButtonProps: ISimpleButtonProps = {
            appendedClassName: 'btn-block',
            buttonText: 'Upload Purchase Order',
            isDisabled: propData.pageState.processing,
            onClick: () => {

                const clonedModel = ObjectHelper.deepClone(viewModel);
                clonedModel.purchaseOrderDocument = this.purchaseOrderDocumentFile.getFiles()[0];

                this.handleOnSubmit(propActions, propData, clonedModel);
            }
        };

        return (
            <div className="mb-3">
                <Card>
                    <CardHeader>
                        <CardTitle text="Purchase Order" />
                    </CardHeader>

                    <CardBody>
                        {
                            (viewModel.isSourced || viewModel.isAwaitingInvoice)  &&
                            <div className={'row ' + ((viewModel.isAwaitingInvoice && isInsurer) && 'mb-2')}>
                                <div className="col-sm-12">
                                    {
                                        viewModel.purchaseOrderDocumentUrl ?
                                        <a href={viewModel.purchaseOrderDocumentUrl} target="_blank">Click here to view purchase order</a> :
                                        <i>Purchase order document has not been uploaded yet.</i>
                                    }
                                </div>
                            </div>
                        }
                        {
                            viewModel.isAwaitingInvoice &&
                            <form className="row">
                                <div className="col-sm-12">
                                    {
                                        isInsurer &&
                                        <>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <FormGroupWrapper {...purchaseOrderDocumentFormGroupWrapperProps()}>
                                                        <SimpleFileUploadInput accept={`${Constants.PDFType}, ${Constants.JPEGType}`} ref={(component) => this.purchaseOrderDocumentFile = component} />
                                                    </FormGroupWrapper>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <PrimaryButton {...submitButtonProps}/>
                                                </div>
                                            </div>
                                        </>
                                    }

                                </div>
                            </form>
                        }
                    </CardBody>
                </Card>
            </div>
        );
    }
}

const HotForm = hot(module)(PurchaseOrderUploadForm);

export {
    HotForm as SupplyRequestListingPurchaseOrderUploadForm,
    IPurchaseOrderUploadFormProps as ISupplyRequestListingPurchaseOrderUploadFormProps
};
