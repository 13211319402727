// 3rd Party References
import * as React from 'react';

// Utility References
//import {  } from '../../IndexOfActions';
//import {  } from '../../IndexOfComponents';
import { TextAlignment } from '../../IndexOfEnums';
//import {  } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
import { CurrencyConverter } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

export enum CurrencyFormats {
    Standard,
    NoneFractional
}

export interface ICurrencyDisplayProps {
    alignment?: TextAlignment.Horizontal;
    displayOnFail?: string;
    emptyWhenNull?: boolean;
    format?: CurrencyFormats;
    value: number;
}

const currencyDisplay = (props: ICurrencyDisplayProps): JSX.Element => {

    const { alignment, displayOnFail, format, emptyWhenNull, value } = props;

    if (emptyWhenNull && (value === null || value === undefined)) {
        return <div></div>;
    }

    let displayValue: string;
    const defaultValue = displayOnFail ? displayOnFail : '0';

    switch (format) {

        case CurrencyFormats.NoneFractional:
            displayValue = CurrencyConverter(value, defaultValue).toCurrencyNoneFractionalDisplay();
            break;

        case CurrencyFormats.Standard:
        default:
            displayValue = CurrencyConverter(value, defaultValue).toStandardCurrencyDisplay();
            break;
    }

    const standardClassName = 'simple-display-cell-currency';
    const currencyClassName = (value && value < 0) ? 'negative-currency-value' : 'positive-currency-value';
    const combinedClassName = standardClassName + ' ' + currencyClassName;

    const alignmentToUse = alignment || TextAlignment.Horizontal.Right;

    const divProps = {
        className: TextAlignment.appendToCssClass(alignmentToUse, combinedClassName)
    };

    return (
        <div {...divProps}>
            {displayValue}
        </div>
    );
};

export {
    currencyDisplay as CurrencyDisplay
};
