// 3rd Party
import { setTimeout } from 'timers';

// Utility References
import { Handler, NavigationActions } from 'Utility/IndexOfActions';
import { StringHelper } from 'Utility/IndexOfHelpers';

// App References
import { IApiAccessToken, IApiUserInfo } from 'App/IndexOfInterfaces';
import { IApplicationConfig, LocalStorageService } from 'App/IndexOfServices';

const authenticationTimes = {
    expireOffsetSeconds: 300, // 5 minutes.
    keepAliveCycleMilliseconds: 240000 // 4 minutes.
};

const isAuthenticated = (): boolean => {

    const accessToken = LocalStorageService.getAccessToken();

    return accessToken && !StringHelper.isUndefinedNullOrEmpty(accessToken.token);
};

const internalTokenIsAlive = (tokenInfo: IApiAccessToken): boolean => {

    const now = new Date();

    return now <= tokenInfo.tokenExpiry;
};

const internalAuthenticateAction = (bearerTokenCallback?: Handler.Action1<string>) => {

    const tokenInfo = LocalStorageService.getAccessToken();

    if (tokenInfo && !internalTokenIsAlive(tokenInfo)) {

        LocalStorageService.clearAccessToken();
        LocalStorageService.clearUserInfo();
        location.href = '/';

    } else {
        if (bearerTokenCallback) {
            bearerTokenCallback(tokenInfo && tokenInfo.token);
        }
    }
};

let isKeepAliveLoopRunning = false;

const keepAliveLoop = () => {

    isKeepAliveLoopRunning = true;

    internalAuthenticateAction();

    (function loop() {
        setTimeout(() => {

            internalAuthenticateAction((/*bearerToken: string*/) => {
                loop();
            });

        }, authenticationTimes.keepAliveCycleMilliseconds);
    })();
};

const authenticationService = {
    authenticateAndKeepAlive: (): void => {

        keepAliveLoop();
        internalAuthenticateAction();
    },
    authenticateWithBearerTokenCallback: (bearerTokenCallback: Handler.Action1<string>): void => {

        if (!isKeepAliveLoopRunning) {
            keepAliveLoop();
        }

        internalAuthenticateAction(bearerTokenCallback);
    },
    isAuthenticated
};

export { authenticationService as AuthenticationService };
