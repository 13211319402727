// 3rd Party References
import * as React from 'react';

// Utility References
//import {  } from '../../../IndexOfActions';
//import {  } from '../../../IndexOfComponents';
//import {  } from '../../../IndexOfEnums';
//import {  } from '../../../IndexOfHelpers';
//import {  } from '../../../IndexOfInterfaces';
import { IGridFilterContext } from '../../../IndexOfModels';
//import {  } from '../../../IndexOfServices';

// Local References
import TextFilter from './TextFilter';

type UpdateExpr = <TFilter, TValue>(filter: TFilter, value: TValue) => void;

interface IUnitIdentificationFilterProps extends React.Props<UnitIdentificationFilter> {
    gridFilterContext: IGridFilterContext;
    isDisabled?: boolean;
    maxLength?: number;
    updateExpr: UpdateExpr;
    value: string;
}

export default class UnitIdentificationFilter extends React.Component<IUnitIdentificationFilterProps, {}> {

    render() {

        const { gridFilterContext, isDisabled, maxLength, updateExpr, value } = this.props;

        const textFilterProps = {
            cssClass: 'regno-input',
            gridFilterContext,
            isDisabled,
            key: 'Reg/Ref No.',
            label: 'Reg/Ref No.',
            maxLength,
            updateExpr,
            value
        };

        return <TextFilter {...textFilterProps}/>;
    }
}
