//3rd Party References
import * as React from 'react';
import { Link } from 'react-router-dom';

// Utility References
import { AlertIcon, CustomLinkButton, ProcessingBar, RenderInBody } from 'Utility/IndexOfComponents';
import { ButtonStyle } from 'Utility/IndexOfEnums';
import { TranslationHelper } from 'Utility/IndexOfHelpers';
import { IBreadcrumbLink, IUtilityPageState } from 'Utility/IndexOfInterfaces';

interface IBreadcrumbProps extends React.Props<Breadcrumb> {
    linkData: IBreadcrumbLink[];
}

export class Breadcrumb extends React.Component<IBreadcrumbProps, {}> {

    private getLinks = (breadcrumbLinks: IBreadcrumbLink[]): JSX.Element[] => {

        const linkElements: JSX.Element[] = [];
        const linkDataLength = breadcrumbLinks.length;

        breadcrumbLinks.map((breadcrumbLink: IBreadcrumbLink, index: number) => {

            const lastLink = index + 1 === linkDataLength;

            const translatedBreadcrumbLink = TranslationHelper.translate(breadcrumbLink.label);

            if (breadcrumbLink.route && !lastLink) {
                linkElements.push(<Link to={breadcrumbLink.route}>{translatedBreadcrumbLink}</Link>);
            } else {
                linkElements.push(<span>{translatedBreadcrumbLink}</span>);
            }

        });

        return linkElements;
    }

    render() {

        const { linkData } = this.props;

        const listItems = this.getLinks(linkData).map((linkElement, index) => <li className="breadcrumb-item" key={index}>{linkElement}</li>);

        // ReSharper disable OlTagContainsNonLiElements
        return (
            <ol className="breadcrumb">
                {listItems}
            </ol>
        );
        // ReSharper restore OlTagContainsNonLiElements
    }
}

interface IErrorBannerProps extends React.Props<ErrorBanner> {
    pageState: IUtilityPageState;
}

class ErrorBanner extends React.Component<IErrorBannerProps, {}> {

    render() {

        const { pageState } = this.props;

        if (!pageState.errored) {
            return null;
        }

        // TODOxUI: Don't be a total css/html hack.
        const errorStyles = {
            backgroundColor: '#d9534f',
            borderRadius: 0,
            color: 'white',
            display: 'block',
            position: 'fixed' as 'fixed', // Required hack to get around warning "Type 'string' is not assignable to type 'fixed' | 'initial' etc..."
            top: 0,
            left: 0,
            width: '100%',
            zIndex: 50
        };

        const blockStyles = {
            backgroundColor: '#333',
            display: 'block',
            opacity: 0.4,
            position: 'fixed' as 'fixed', // Required hack to get around warning "Type 'string' is not assignable to type 'fixed' | 'initial' etc..."
            top: 0,
            left: 0,
            height: '3000px',
            width: '100%',
            zIndex: 49
        };

        return (
            <div>
                <ol className="error-banner breadcrumb" style={errorStyles}>
                    <li style={{ width: '100%' }}>
                        <div style={{ height: '100%' }}>
                            <AlertIcon />&nbsp; MCOE ERROR:
                        </div>
                        {pageState.errored}
                        <div style={{ float: 'right', height: '100%' }}>
                            <CustomLinkButton buttonOptions={{ alignRight: true, buttonText: 'Reload AIMS', buttonStyle: ButtonStyle.Danger }} href="/" />
                        </div>
                    </li>
                </ol>
                <div style={blockStyles}></div>
            </div>
        );
    }
}

// PageUIBundle

interface IGlobalPageStateDisplayProps extends React.Props<GlobalPageStateDisplay> {
    pageState: IUtilityPageState;
}

export class GlobalPageStateDisplay extends React.Component<IGlobalPageStateDisplayProps, {}> {

    render() {

        const { pageState } = this.props;

        return (
            <RenderInBody>
                <ProcessingBar processing={pageState .processing}/>
                <ErrorBanner pageState={pageState}/>
            </RenderInBody>
        );
    }
}

// Loading Banner

class InternalLoadingBanner extends React.Component <{}, {}> {

    render() {

        return (
            <ol className="breadcrumb" style={{ marginBottom: '10px' }}>
                <li>
                    <div className="text-center">Loading...</div>
                </li>
            </ol>
        );
    }
}

interface ILoadingBannerProps extends React.Props<LoadingBanner> {
    pageState: IUtilityPageState;
}

export class LoadingBanner extends React.Component<ILoadingBannerProps, {}> {

    render() {

        const { pageState } = this.props;

        if (!!pageState.errored) {
            return null;
        }

        return <InternalLoadingBanner />;
    }
}

export class NonReduxLoadingBanner extends React.Component<{}, {}> {

    render() {

        return <InternalLoadingBanner />;
    }
}
