// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from '../../../IndexOfActions';
//import {  } from '../../../IndexOfComponents';
//import {  } from '../../../IndexOfEnums';
import { KeyboardEventHelper } from '../../../IndexOfHelpers';
//import {  } from '../../../IndexOfInterfaces';
import { IGridFilterContext } from '../../../IndexOfModels';
//import {  } from '../../../IndexOfServices';

// Local References
import ClearButton from './ClearButton';

type UpdateExpr = <TFilter, TValue>(filter: TFilter, value: TValue) => void;

interface INumberFilterProps extends React.Props<NumberFilter> {
    gridFilterContext: IGridFilterContext;
    isDisabled?: boolean;
    key: string | number;
    label: string;
    maxLength?: number;
    updateExpr: UpdateExpr;
    value: number;
}

export default class NumberFilter extends React.Component<INumberFilterProps, {}> {

    private clearButton: ClearButton;
    private inputField: HTMLInputElement;

    private handleOnBlur = (updateFunc: Handler.NumberValue, maxLength: number) => (event: React.FocusEvent<HTMLInputElement>): void => {

        const input = event.currentTarget as HTMLInputElement;

        if (input.value === '') {
            updateFunc(null);
        }

        if (maxLength) {
            if (input.value.length > maxLength) {
                return;
            }
        }

        const inputAsNumber = parseInt(input.value, 10);
        const isValidNumber = !isNaN(inputAsNumber);

        if (isValidNumber) {
            updateFunc(inputAsNumber);
        } else {
            updateFunc(null);
        }
    }

    private handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {

        if (KeyboardEventHelper.isEnterKeyPress(event)) {
            this.clearButton.activate();
            this.clearButton.setFocus();
        }
    }

    private reset = (updateFunc: Handler.NumberValue) => () => {

        updateFunc(null);
        this.inputField.disabled = false;
        this.inputField.focus();
    }

    render() {

        const { gridFilterContext, isDisabled, label, maxLength, updateExpr, value } = this.props;

        const updateFunc = gridFilterContext.filterHandler(updateExpr);

        const inputValue = (value !== null && value !== undefined) ? value.toString() : null;

        const inputProps = {
            className: 'form-control grid-filter',
            disabled: isDisabled,
            onBlur: this.handleOnBlur(updateFunc, maxLength),
            onKeyPress: this.handleKeyPress,
            placeholder: label,
            ref: (component: HTMLInputElement) => { this.inputField = component; },
            type: 'number',
            value: inputValue
        };

        if (inputValue !== null && inputValue !== undefined) {
            inputProps.className += ' active';
            inputProps.disabled = true;
        }

        const clearButtonProps = {
            isDisabled,
            ref: (component: ClearButton) => { this.clearButton = component; },
            resetClickHandler: this.reset(updateFunc),
            value
        };

        return (
            <th>
                <div className="input-group">
                    <input {...inputProps} />
                    <span className="input-group-btn">
                        <ClearButton {...clearButtonProps} />
                    </span>
                </div>
            </th>
        );
    }
}
