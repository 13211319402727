// 3rd Party References
import * as React from 'react';

// Utility References
import { IModalService } from 'Utility/IndexOfServices';

// Local References
import { IInternalPromptModalProps, InternalPromptModal } from './Internal/InternalPromptModal';

type OnClose = (isConfirmed: boolean) => void;

export interface ISwapPromptModalProps extends React.Props<SwapPromptModal> {
    modalService: IModalService;
    onClose: OnClose;
}

export class SwapPromptModal extends React.Component<ISwapPromptModalProps, {}> {

    render() {

        const { modalService, onClose } = this.props;

        const internalPrompModalProps: IInternalPromptModalProps = {
            bodyText: 'Are you sure you want to swap these items?',
            modalService,
            okButtonClass: 'btn-success',
            okButtonText: 'Swap',
            onClose,
            titleText: 'Confirm Swap'
        };

        return (
            <InternalPromptModal {...internalPrompModalProps} />
        );
    }
}
