// 3rd Party References
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { hot } from 'react-hot-loader';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import ReduxToastr from 'react-redux-toastr';
import { Dispatch } from 'redux';

// Utility References
import { ReduxContainerBuilder } from 'Utility/Components/Internal/ReduxContainer';
import { ProcessingBar, ScrollToTop } from 'Utility/IndexOfComponents';

// Service References
import { IApplicationConfig, LocalStorageService } from 'App/IndexOfServices';

// App State
import { ActionsDispatcherFactory, IAppActions } from 'App/State/AppActions';
import { IAppState, RootReducer } from 'App/State/AppState';

// Navigation
import { AppUrls } from 'App/IndexOfUrls';

// Views
import {
    ChangePasswordView,
    ForgottenPasswordView,
    ImpersonateView,
    LoginView,
    ResetPasswordView
} from 'App/IndexOfViews';

interface IReduxComponentProps {
    appActions: IAppActions;
    appState: IAppState;
}

const appGlobals = ((window as any).appGlobals as {
    apiUrl: string;
    authCallbackUrl: string;
    authClientId: string;
    authorisationUrl: string;
    branchId: number;
    scope: string;
    userInfoUrl: string;
    userRoleId: number;
});

const applicationConfig: IApplicationConfig = {
    apiUrl: appGlobals.apiUrl
};

LocalStorageService.setApplicationConfig(applicationConfig);

class Index extends React.Component<IReduxComponentProps, { authenticated: boolean }>{

    constructor(props) {
        super(props);
    }

    render() {

        const { appActions, appState } = this.props;

        const applicationConfig = LocalStorageService.getApplicationConfig();

        return (
            <Router>
                <ScrollToTop>

                    <div className="mainContent row">
                        <Switch>
                            <Route exact path={AppUrls.LoginUrls.area} render={routedProps => <LoginView {...this.props} {...routedProps} />} />
                            <Route exact path={AppUrls.LoginUrls.forgottenPassword} render={routedProps => <ForgottenPasswordView {...this.props} {...routedProps} />} />
                            <Route exact path={AppUrls.LoginUrls.resetPassword} render={routedProps => <ResetPasswordView {...this.props} {...routedProps} />} />
                            <Route exact path={AppUrls.LoginUrls.changePassword} render={routedProps => <ChangePasswordView {...this.props} {...routedProps} />} />
                            <Route exact path={AppUrls.LoginUrls.impersonate} render={routedProps => <ImpersonateView {...this.props} {...routedProps} />} />
                        </Switch>
                    </div>

                        <ReduxToastr
                            timeOut={4000}
                            newestOnTop={false}
                            position="bottom-right"
                            preventDuplicates
                            transitionIn="fadeIn"
                            transitionOut="fadeOut"
                            progressBar />

                        <ProcessingBar processing={appState.page.processing} />
                </ScrollToTop>
            </Router>
        );
    }
}

const HotIndex = hot(module)(Index);

class IndexRedux extends React.Component<{}, {}>{

    async componentWillMount() {

        const applicationConfig = LocalStorageService.getApplicationConfig();
    }

    render() {

        // ReSharper disable once InconsistentNaming
        const ReduxWrapper = () => {

            return ReduxContainerBuilder().NoneProps(({
                mapDispatchToProps: (dispatch: Dispatch<IAppState>) => ({
                    appActions: ActionsDispatcherFactory(dispatch)
                }),
                mapStateToProps: (state: { appState: IAppState }) => ({
                    appState: state.appState
                }),
                pageComponent: HotIndex,
                rootReducer: RootReducer
            }) as any);
        };

        return (
            <ReduxWrapper />
        );
    }
}

ReactDOM.render(<IndexRedux />, document.getElementById('mountNode'));
