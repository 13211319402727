// 3rd Party References
import * as React from 'react';

// Utility References
import { AnimationHelper, CultureHelper, KeyboardEventHelper, NumberHelper, ObjectHelper } from 'Utility/IndexOfHelpers';
import { Handler } from '../../IndexOfActions';

export interface ISimpleMoneyInputProps extends React.Props<SimpleMoneyInput> {
    allowIntegersOnly?: boolean;
    allowNegatives?: boolean;
    disallowZero?: boolean;
    forceOnChangeAlways?: boolean;
    isDisabled?: boolean;
    isHidden?: boolean;
    maxValue?: number;
    onBlurCallback?: Handler.InputFocusEvent;
    onChangeCallback: Handler.NumberProperty;
    placeholder?: string;
    propertyName?: string;
    tabIndex?: number;
    value: number;
}

interface ISimpleMoneyInputState {
    actualValue: number;
    viewerValue: string;
    viewerVisible: boolean;
}

export class SimpleMoneyInput extends React.Component<ISimpleMoneyInputProps, ISimpleMoneyInputState> {

    private primaryInput: HTMLInputElement;

    public setFocus = (scroll?: number): void => {

        if (!this.props.isHidden) {

            const updatedState: ISimpleMoneyInputState = ObjectHelper.deepClone(this.state as ISimpleMoneyInputState);
            updatedState.viewerVisible = false;

            this.setState(updatedState, () => {
                AnimationHelper.setFocus(this.primaryInput, scroll);
            });
        }
    }

    public reset = (): void => {
        this.setState({ actualValue: null, viewerValue: '', viewerVisible: true });
    }

    constructor(props: ISimpleMoneyInputProps) {
        super(props);

        const decimalPlaces = props.allowIntegersOnly ? 0 : 2;

        this.state = {
            actualValue: NumberHelper.roundTo(props.value, decimalPlaces),
            viewerValue: NumberHelper.toMoneyDisplay(props.value, decimalPlaces),
            viewerVisible: true
        };
    }

    componentWillReceiveProps(nextProps: ISimpleMoneyInputProps) {

        const { allowIntegersOnly, value } = nextProps;

        const decimalPlaces = allowIntegersOnly ? 0 : 2;

        const updatedState: ISimpleMoneyInputState = ObjectHelper.deepClone(this.state as ISimpleMoneyInputState);
        updatedState.actualValue = NumberHelper.roundTo(value, decimalPlaces);
        updatedState.viewerValue = NumberHelper.toMoneyDisplay(value, decimalPlaces);

        this.setState(updatedState);
    }

    private handleOnChange = (allowIntegersOnly: boolean, disallowZero: boolean, forceOnChangeAlways: boolean, onChangeCallback: Handler.NumberProperty, propertyName: string) => (event: React.FormEvent<HTMLInputElement>): void => {

        const inputElement = (event.target as HTMLInputElement);

        // not allowing 0 as the first character
        if (disallowZero && inputElement.value.length === 1 && inputElement.value === '0') {
            (event.target as HTMLInputElement).value = null;
            return;
        }

        const decimalPlaces = allowIntegersOnly ? 0 : 2;

        const actualValue = NumberHelper.roundTo(inputElement.valueAsNumber, decimalPlaces);
        const viewerValue = inputElement.value;

        this.setState({ actualValue, viewerValue, viewerVisible: false });

        if (forceOnChangeAlways) {
            onChangeCallback(actualValue, propertyName);
        }
    }

    private handleOnBlur = (actualValue: number, allowIntegersOnly: boolean, allowNegatives: boolean, onBlurCallback: Handler.InputFocusEvent, onChangeCallback: Handler.NumberProperty, propertyName: string) => (event: React.FocusEvent<HTMLInputElement>) => {

        const valueOrNull = NumberHelper.adjustForNegatives(allowNegatives, actualValue);

        const decimalPlaces = allowIntegersOnly ? 0 : 2;

        const updatedState: ISimpleMoneyInputState = ObjectHelper.deepClone(this.state as ISimpleMoneyInputState);
        updatedState.actualValue = valueOrNull;
        updatedState.viewerValue = NumberHelper.toMoneyDisplay(valueOrNull, decimalPlaces);
        updatedState.viewerVisible = true;

        const postStateUpdateFunc = () => {

            onChangeCallback(valueOrNull, propertyName);

            if (onBlurCallback) {
                onBlurCallback(event);
            }
        };

        this.setState(updatedState, postStateUpdateFunc);
    }

    private handleViewerOnFocus = () => {

        const updatedState: ISimpleMoneyInputState = ObjectHelper.deepClone(this.state as ISimpleMoneyInputState);
        updatedState.viewerVisible = false;

        this.setState(updatedState, () => {
            this.primaryInput.focus();
            this.primaryInput.select();
        });
    }

    private handleKeyPress = (allowIntegersOnly: boolean, allowNegatives: boolean) => (event: React.KeyboardEvent<HTMLInputElement>) => {

        const isPlusKey = KeyboardEventHelper.isPlusKeyPress(event);
        const isLetterEKey = KeyboardEventHelper.isLetterEKeyPress(event);
        const isFailedIntegerAttempt = allowIntegersOnly && KeyboardEventHelper.isPeriodKeyPress(event);
        const isFailedNegativeAttempt = !allowNegatives && KeyboardEventHelper.isNegativeKeyPress(event);

        if (isPlusKey || isLetterEKey || isFailedIntegerAttempt || isFailedNegativeAttempt) {

            event.preventDefault();
            event.stopPropagation();
        }
    }

    render() {

        const { allowIntegersOnly, allowNegatives, disallowZero, forceOnChangeAlways, isDisabled, isHidden, maxValue, onBlurCallback, onChangeCallback, placeholder, propertyName, tabIndex } = this.props;
        const { actualValue, viewerValue, viewerVisible } = this.state;

        const editorCssClass = 'money-editor form-control';

        const editorInputProps: React.HTMLProps<HTMLInputElement> = {
            autoComplete: 'off',
            className: isHidden || viewerVisible ? editorCssClass + ' hidden' : editorCssClass,
            disabled: isDisabled,
            id: propertyName,
            max: maxValue,
            onBlur: this.handleOnBlur(actualValue, allowIntegersOnly, allowNegatives, onBlurCallback, onChangeCallback, propertyName),
            onChange: this.handleOnChange(allowIntegersOnly, disallowZero, forceOnChangeAlways, onChangeCallback, propertyName),
            onKeyPress: this.handleKeyPress(allowIntegersOnly, allowNegatives),
            placeholder,
            ref: (component: HTMLInputElement) => { this.primaryInput = component; },
            step: allowIntegersOnly ? '0' : '0.01',
            tabIndex,
            type: 'number',
            value: viewerValue !== undefined && viewerValue !== null ? viewerValue : ''
        };

        let viewerCssClass = 'money-viewer form-control';

        if (isHidden || !viewerVisible) {

            viewerCssClass = viewerCssClass + ' hidden';

        } else if (isDisabled) {

            viewerCssClass = viewerCssClass + ' isDisabled';

        } else {

            viewerCssClass = viewerCssClass + ' isActive';
        }

        const decimalPlaces = allowIntegersOnly ? 0 : 2;

        const viewerInputProps: React.HTMLProps<HTMLInputElement> = {
            className: viewerCssClass,
            disabled: isDisabled,
            onFocus: this.handleViewerOnFocus,
            placeholder,
            readOnly: true,
            tabIndex,
            value: NumberHelper.toMoneyDisplay(actualValue, decimalPlaces)
        };

        return (
            <div className="input-group simple-money-input">
                <div className="input-group-addon">{CultureHelper.getCultureCurrencySymbol()}</div>
                <input {...editorInputProps} />
                <input {...viewerInputProps} />
            </div>
        );
    }
}
