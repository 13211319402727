// Utility References
import {
    IApiCall,
    IApiCallWithPayload
} from 'Utility/IndexOfInterfaces';

// App References
import {
    IApiAccountDetailsViewModel,
    IApiNotificationPreferenceToolsViewModel as ITools
} from 'App/IndexOfInterfaces';

import { Actions, AppService, HttpVerbs } from 'App/IndexOfServices';

const controller = AppService().apiController('clientContact');

const actionService = {
    getAccountDetails: (): IApiCall<IApiAccountDetailsViewModel> => {
        return {
            method: HttpVerbs.GET,
            url: `${controller}/accountDetails`
        };
    },
    getNotificationPreferenceOptions: (): IApiCall<ITools> => {
        return {
            method: HttpVerbs.GET,
            url: `${controller}/tools`
        };
    },
    updatePreferences: (): IApiCallWithPayload<IApiAccountDetailsViewModel, void> => {
        return {
            method: HttpVerbs.PUT,
            url: `${controller}/notificationpreferences`
        };
    },
 };

export {
    actionService as ClientContactApi
};
