// 3rd Party
import * as React from 'react';
import { hot } from 'react-hot-loader';

// Utility References
import { Handler } from 'Utility/IndexOfActions';
import { GridPagination, IGridPaginationProps } from 'Utility/IndexOfComponents';
import { GridFilterContext } from 'Utility/IndexOfModels';

// App References
import {
    IApiSupplyRequestListingCollection as ICollection,
    IApiSupplyRequestListingOptions as IOptions,
    IApiSupplyRequestListingSummaryViewModel as ISummary
} from 'App/IndexOfInterfaces';
import {
    IPageState,
    ISupplyRequestListingListItemProps as IListItemProps,
    SupplyRequestListingListItem as IListItem
} from 'App/IndexOfModels';

interface IPropActions {
    search: Handler.Action1<IOptions>;
    updateOptions: Handler.Action1<IOptions>;
}

interface IPropData {
    collection: ICollection;
    detailsUrlFunc: (id?: number | string) => string;
    options: IOptions;
    pageState: IPageState;
}

interface IListProps {
    propActions: IPropActions;
    propData: IPropData;
}

class List extends React.Component<IListProps, {}>{

    private fetchCollection = (propActions: IPropActions) => (options: IOptions): void => {

        propActions.updateOptions(options);
        propActions.search(options);
    }

    private getListItems = (collection: ICollection, propData: IPropData): JSX.Element[] => {

        if (collection && collection.results) {

            return (

                collection.results.map((summary: ISummary) => {

                    const listItemProps: IListItemProps = {
                        propActions: { },
                        propData: {
                            detailsUrlFunc: propData.detailsUrlFunc,
                            listItem: summary,
                            pageState: propData.pageState
                        }
                    };

                    return (
                        <div key={summary.supplyRequestListingId} className="mb-2 listing-list-item">
                            <IListItem key={summary.supplyRequestListingId} {...listItemProps} />
                        </div>
                    );
                })
            );
        }
    }

    render() {

        const { propActions, propData } = this.props;
        const { collection, options } = propData;

        const collectionUpdateFunc = this.fetchCollection(propActions);

        const gridFilterContext = GridFilterContext(collectionUpdateFunc, options);

        const listItems = this.getListItems(collection, propData);

        const paginationProps: IGridPaginationProps = {
            currentSearchOptions: options,
            currentSearchViewModel: collection,
            gridFilterContext
        };

        const PaginatedList = (): JSX.Element =>

            <>
                <div className="row mb-2">
                    <div className="col">
                        <GridPagination {...paginationProps}/>
                    </div>
                </div>

                {listItems}

                <div className="row">
                    <div className="col">
                        <GridPagination minPageItemsToDisplay={3} {...paginationProps}/>
                    </div>
                </div>
            </>;

        const NoItemsToDisplay = (): JSX.Element =>

            !propData.pageState.processing &&
            <div className="row mt-5">
                <div className="col d-flex justify-content-center">
                    <h2>There are no items to display</h2>
                </div>
            </div>;

        return (
            listItems ? <PaginatedList /> : <NoItemsToDisplay />
        );
    }
}

const HotList = hot(module)(List);

export {
    HotList as SupplyRequestListingList,
    IListProps as ISupplyRequestListingListProps
};
