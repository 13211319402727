// 3rd Party References
import * as React from 'react';

// Utility References
import { LayoutHelper, StringHelper, TranslationHelper } from 'Utility/IndexOfHelpers';
import { IApiSortOptions, IGridColumnNameSet } from 'Utility/IndexOfInterfaces';
import { IGridFilterContext } from 'Utility/IndexOfModels';

interface IGridSortableColumnLabelProps extends React.Props<GridSortableColumnLabel> {
    currentSortOptions: IApiSortOptions;
    gridColumnNameSet: IGridColumnNameSet;
    gridFilterContext: IGridFilterContext;
    key: string;
}

export default class GridSortableColumnLabel extends React.Component<IGridSortableColumnLabelProps, {}> {

    private getIcon = (isCurrentSortField: boolean, sortOptions: IApiSortOptions): JSX.Element => {

        let iconClass = 'fa-sort-alpha-asc';

        if (isCurrentSortField) {
            iconClass = sortOptions.sortOrderAsc ? 'fa-sort-alpha-asc' : 'fa-sort-alpha-desc';
        }

        return (
            <span className="sort-icon-wrapper">
                {' '}<span className={`sort-icon fa ${iconClass}`}></span>
            </span>
        );
    }

    private handleSortClicked = (currentSortOptions: IApiSortOptions, gridFilterContext: IGridFilterContext, nameAsSortField: string) => () => {

        const isCurrentSortField = !!currentSortOptions ? currentSortOptions.sortField === nameAsSortField : false;
        const newSortOrder = currentSortOptions.sortOrderAsc === isCurrentSortField ? !currentSortOptions.sortOrderAsc : true;

        const newSortOptions: IApiSortOptions = {
            sortField: nameAsSortField,
            sortOrderAsc: newSortOrder
        };

        gridFilterContext.sortHandler(newSortOptions);
    }

    render() {

        const { currentSortOptions, gridColumnNameSet, gridFilterContext } = this.props;

        const isUISortOnly = currentSortOptions && currentSortOptions.isUIOnly;
        const nameAsSortField = isUISortOnly ? gridColumnNameSet.fieldName : StringHelper.toPascalCase(gridColumnNameSet.fieldName);
        const isCurrentSortField = !!currentSortOptions ? currentSortOptions.sortField === nameAsSortField : false;

        const sortIcon = this.getIcon(isCurrentSortField, currentSortOptions);
        const sortIconPositionedRight = LayoutHelper.sortIconPositionedRight(gridColumnNameSet.columnType);

        let thStyles = {};

        const columnTypeClass = LayoutHelper.getColumnTypeClass(gridColumnNameSet.columnType);
        const columnStyleWidth = LayoutHelper.getColumnStyleWidthFromSet(gridColumnNameSet);

        if (columnStyleWidth) {
            thStyles = columnStyleWidth;
        }

        const tableHeaderClass = `grid-sortable-column-label ${columnTypeClass}`;
        const anchorClass = isCurrentSortField ? 'selected-sort' : '';

        return (
            <th className={tableHeaderClass} style={thStyles}>
                <a className={anchorClass} onClick={this.handleSortClicked(currentSortOptions, gridFilterContext, nameAsSortField)}>
                    {!sortIconPositionedRight && sortIcon}
                    {TranslationHelper.translate(gridColumnNameSet.labelText)}
                    {sortIconPositionedRight && sortIcon}
                </a>
            </th>
        );
    }
}
