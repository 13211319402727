// 3rd Party References
import * as React from 'react';

// Utility References
import { ObjectHelper } from 'Utility/IndexOfHelpers';
import { GridBody, IGridPaginationProps, IGridTabButtonsProps } from '../../IndexOfComponents';

// Local References
import GridPagination from './Internal/GridPagination';
import GridTabButtons from './Internal/GridTabButtons';

export interface IGridMainProps extends React.Props<GridMain> {
    isStriped?: boolean;
    gridPaginationProps?: IGridPaginationProps;
    gridTabButtonsProps?: IGridTabButtonsProps;
    unresponsive?: boolean;
}

export default class GridMain extends React.Component<IGridMainProps, {}> {

    private getTabsAndPagination = (gridPaginationProps: IGridPaginationProps, gridTabButtonsProps: IGridTabButtonsProps, justPagination: boolean): JSX.Element => {

        if (!gridPaginationProps && !gridTabButtonsProps) {
            return null;
        }

        const gridPagination = gridPaginationProps ? <GridPagination {...gridPaginationProps} /> : null;

        if (justPagination) {
            return <GridBody>{gridPagination}</GridBody>;
        }

        const gridTabs = gridTabButtonsProps ? <GridTabButtons {...gridTabButtonsProps} /> : null;

        return <GridBody>{gridTabs}{gridPagination}</GridBody>;
    }

    render() {

        const { gridPaginationProps, gridTabButtonsProps, isStriped, unresponsive } = this.props;

        const tabsAndPaginationSection = this.getTabsAndPagination(gridPaginationProps, gridTabButtonsProps, false);
        const paginationOnlySection = this.getTabsAndPagination(gridPaginationProps, gridTabButtonsProps, true);

        const tableWrapperClass = unresponsive ? '' : 'table table-responsive';

        let appliedTableClassName = 'table table-condensed tableFont table-fixed grid-table';

        if (!ObjectHelper.isUndefinedOrNull(isStriped) ? isStriped : true){
            appliedTableClassName = `${appliedTableClassName} table-striped` ;
        }

        return (
            <div>
                {tabsAndPaginationSection}
                <div className={tableWrapperClass}>
                    <table className={appliedTableClassName}>
                        {this.props.children}
                    </table>
                </div>
                {paginationOnlySection}
            </div>
        );
    }
}
