// 3rd Party References
import { Action, handleActions } from 'redux-actions';

// Utility References
import { ReduxHelper } from 'Utility';

// App References
import {
    IApiEnquiryToolsViewModel as ITools,
    IApiEnquiryUpsertViewModel as IUpsert
} from 'App/IndexOfInterfaces';
import {
    EnquiryActionTypes as ActionTypes,
    EnquiryQueryActionTypes as QueryActionTypes
} from 'App/IndexOfModels';

interface IState {
    tools: ITools;
    upsert: IUpsert;
}

const initialState = (): IState => {

    return {
        tools: null,
        upsert: null
    };
};

const actionWrapper = ReduxHelper.actionWrapper;
type Payload = ITools | IUpsert | void;

const reducer = handleActions<IState, Payload>({

    [ActionTypes.reset.enquiryForm]: (state: IState, action: Action<ITools>): IState => {
        return actionWrapper(state, action, (newState: IState) => {
            newState.upsert.model.enquiryType = null;
            newState.upsert.model.message = null;
            newState.upsert.model.otherType = null;
            newState.upsert.model.supplyRequestListingId = null;
        });
    },

    [QueryActionTypes.receive.tools]: (state: IState, action: Action<ITools>): IState => {
        return actionWrapper(state, action, (newState: IState) => {
            newState.tools = action.payload;
        });
    },

    [QueryActionTypes.receive.upsert]: (state: IState, action: Action<IUpsert>): IState => {
        return actionWrapper(state, action, (newState: IState) => {
            newState.upsert = action.payload;
        });
    },

    [QueryActionTypes.reset.all]: (): IState => {
        return initialState();
    }

}, initialState());

export {
    reducer as EnquiryReducer,
    IState as IEnquiryState
};
