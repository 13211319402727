// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from 'Utility/IndexOfActions';
import {
    AnimationHelper,
    CommonDictionary,
    ItemOptionHelper,
    StringHelper,
    TranslationHelper
} from 'Utility/IndexOfHelpers';
import { IApiItemOptionViewModel } from 'Utility/IndexOfInterfaces';

export interface IInternalCommonSelectProps extends React.Props<InternalCommonSelect> {
    alwaysUsePromptText?: boolean;
    displayName?: string;
    inputClassName?: string;
    isDisabled?: boolean;
    isFilter?: boolean;
    items: IApiItemOptionViewModel[];
    keepPleaseSelect?: boolean;
    onBlurCallback?: Handler.SelectFocusEvent;
    onChangeCallback: Handler.StringProperty;
    onKeyPressCallback?: Handler.SelectKeyboardEvent;
    propertyName?: string;
    promptText?: string;
    reportingValueCanBeZero?: boolean;
    tabIndex?: number;
    value: string;
}

export default class InternalCommonSelect extends React.Component<IInternalCommonSelectProps, {}> {

    private primaryInput: HTMLSelectElement;

    public setFocus = (scroll?: number): void => {
        AnimationHelper.setFocus(this.primaryInput, scroll);
    }

    private handleOnChange = (onChangeCallback: Handler.StringProperty, propertyName: string) => (event: React.FormEvent<HTMLSelectElement>): void => {

        const selectedValue = (event.target as HTMLSelectElement).value;

        if (selectedValue === undefined || selectedValue === null || selectedValue === '') {

            onChangeCallback(null, propertyName);

        } else {

            onChangeCallback(selectedValue, propertyName);
        }
    }

    private getTranslatedDisplayName = (displayName: string): string => {

        return TranslationHelper.translate(displayName);
    }

    private getTranslatedPlaceholder = (displayName: string): string => {

        const placeholder = `${TranslationHelper.translate('Update')} ${displayName}`;
        return TranslationHelper.translate(placeholder);
    }

    private checkIsSelectDisabled = (items: IApiItemOptionViewModel[], selectIsDisabledProp: boolean): boolean => {

        const disabledDueToNoItems = !items || items.length === 0;

        if (disabledDueToNoItems) {
            return true;
        }

        return selectIsDisabledProp;
    }

    private getItemOptionElements = (defaultText: string, isCurrentValueNoValue: boolean, isFilter: boolean, items: IApiItemOptionViewModel[], keepNoValue: boolean): JSX.Element[] => {

        if (!items || items.length === 0) {
            return [<option key={'placeholder'} value="" disabled>{TranslationHelper.CommonDictionary.NoOptionsAvailable}</option>];
        }

        ItemOptionHelper(items).assertAllItemsUnique();

        const options = items.map(
            (item: IApiItemOptionViewModel) => {
                return <option key={item.value} value={item.value}>{item.text}</option>;
            }
        );

        if (isFilter) {

            const selectableVoidOption = (<option key={'filter-global'} value="">{defaultText}</option>);
            options.unshift(selectableVoidOption);

        } else if (isCurrentValueNoValue || keepNoValue) {

            const unselectableVoidOption = (<option key={'placeholder'} value="" disabled>{defaultText}</option>);
            options.unshift(unselectableVoidOption);

        }

        return options;
    }

    render() {

        const { alwaysUsePromptText, displayName, inputClassName, isDisabled, isFilter, items, keepPleaseSelect: keepNoValue, onBlurCallback, onChangeCallback, onKeyPressCallback, propertyName, promptText, reportingValueCanBeZero, tabIndex, value } = this.props;

        let translatedDisplayText: string;
        let placeholder: string;
        let title: string;

        const translatedPrompt = TranslationHelper.translate(promptText);

        if (!displayName && !propertyName) {

            translatedDisplayText = null;
            placeholder = null;
            title = promptText ? translatedPrompt : CommonDictionary.PleaseSelect;

        } else {

            const displayNameToUse = displayName || StringHelper.toLabelFormat(propertyName);
            translatedDisplayText = this.getTranslatedDisplayName(displayNameToUse);
            placeholder = this.getTranslatedPlaceholder(displayNameToUse);
            title = promptText ? translatedPrompt : `${CommonDictionary.PleaseSelect} ${translatedDisplayText}`;
        }

        const defaultText = isFilter && !alwaysUsePromptText ? CommonDictionary.Any : title;

        const isValueUndefined = value === undefined;
        const isValueNull = value === null;
        const isValueEmptyString = value === '';
        let isValueZero = value === '0';

        if (reportingValueCanBeZero) {
            isValueZero = false;
        }

        const isCurrentValueNoValue = (isValueUndefined || isValueNull || isValueEmptyString || isValueZero);

        const selectProps = {
            autoComplete: 'off',
            className: inputClassName ? inputClassName : 'form-control',
            disabled: this.checkIsSelectDisabled(items, isDisabled),
            id: propertyName,
            onBlur: (event: React.FocusEvent<HTMLSelectElement>) => {
                if (onBlurCallback) {
                    onBlurCallback(event);
                }
            },
            onChange: this.handleOnChange(onChangeCallback, propertyName),
            onKeyPress: (event: React.KeyboardEvent<HTMLSelectElement>) => {
                if (onKeyPressCallback) {
                    onKeyPressCallback(event);
                }
            },
            placeholder,
            ref: (component: HTMLSelectElement) => { this.primaryInput = component; },
            tabIndex,
            title,
            value: isCurrentValueNoValue ? '' : value
        };

        const optionElements = this.getItemOptionElements(defaultText, isCurrentValueNoValue, isFilter, items, keepNoValue);

        return <select {...selectProps}>{optionElements}</select>;
    }
}
