// Utility References
import {
    IApiCall,
    IApiCallWithPayload
} from 'Utility/IndexOfInterfaces';

// App References
import {
    IApiSupplyRequestListingCollection as ICollection,
    IApiSupplyRequestListingOptions as IOptions,
    IApiSupplyRequestListingQueryService as IQueryService,
    IApiSupplyRequestListingToolsViewModel as ITools,
    IApiSupplyRequestListingViewModel as IViewModel
} from 'App/IndexOfInterfaces';
import { Actions, AppService, HttpVerbs } from 'App/IndexOfServices';

const controller = AppService().apiController('SupplyRequestListing');

const queryService: IQueryService = {
    get: (id: number): IApiCall<IViewModel> => {
        return {
            method: HttpVerbs.GET,
            url: `${controller}/${id}`
        };
    },
    search: (): IApiCallWithPayload<IOptions, ICollection> => {
        return {
            method: HttpVerbs.POST,
            url: `${controller}/${Actions.SEARCH}`
        };
    },
    searchStrict: (statusEdnpoint: string): IApiCallWithPayload<IOptions, ICollection> => {
        return {
            method: HttpVerbs.POST,
            url: `${controller}/${statusEdnpoint}`
        };
    },
    template: null,
    tools: (): IApiCall<ITools> => {
        return {
            method: HttpVerbs.GET,
            url: `${controller}/${Actions.TOOLS}`
        };
    }
};

const actionService =
{
    closeAuction: (): IApiCallWithPayload<IViewModel, void> => {
        return {
            method: HttpVerbs.PUT,
            url: `${controller}/Close`
        };
    },
    relistAuction: (): IApiCallWithPayload<IViewModel, void> => {
        return {
            method: HttpVerbs.PUT,
            url: `${controller}/Relist`
        };
    },
    uploadInvoice: (): IApiCallWithPayload<FormData, void> => {
        return {
            method: HttpVerbs.PUT,
            url: `${controller}/Invoice`
        };
    },
    uploadPurchaseOrder: (): IApiCallWithPayload<FormData, void> => {
        return {
            method: HttpVerbs.PUT,
            url: `${controller}/PurchaseOrder`
        };
    }
};

export {
    actionService as SupplyRequestListingActionService,
    queryService as SupplyRequestListingQueryService
};
