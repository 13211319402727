// 3rd Party References
import * as React from 'react';
import { hot } from 'react-hot-loader';

// Utility References
import { Handler } from 'Utility/IndexOfActions';
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    GenericLabel,
    ISearchButtonToolbarProps,
    ISimpleSelectNumericProps,
    ISimpleStringInputProps,
    SearchButtonToolbar,
    SimpleSelectNumeric,
    SimpleStringInput
} from 'Utility/IndexOfComponents';
import { FormHelper } from 'Utility/IndexOfHelpers';
import { ModelUpdater } from 'Utility/IndexOfModels';

// App References
import {
    IApiSupplyRequestListingOptions as IOptions,
    IApiSupplyRequestListingToolsViewModel as ITools
} from 'App/IndexOfInterfaces';
import { IPageState } from 'App/IndexOfModels';

interface IPropActions {
    resetForm: Handler.Action;
    search: Handler.Action1<IOptions>;
    updateOptions: Handler.Action1<IOptions>;
}

interface IPropData {
    options: IOptions;
    pageState: IPageState;
    tools: ITools;
}

interface IFilterProps {
    propActions: IPropActions;
    propData: IPropData;
}

class Filter extends React.Component<IFilterProps> {

    render() {

        const { propActions, propData } = this.props;

        const options = propData.options;
        const filter = options && options.filterOptions;

        const tools = propData.tools;

        const modelUpdater = ModelUpdater(options, propActions.updateOptions);

        const searchButtonToolbarProps: ISearchButtonToolbarProps = {
            filterOptions: options,
            reset: propActions.resetForm,
            search: propActions.search,
            updateOptions: propActions.updateOptions
        };

        const searchClaimReferenceProps: ISimpleStringInputProps = {
            onChangeCallback: modelUpdater.for<string>((value, model) => {

                model.filterOptions.claimReference = value;
                return model;
            }),
            value: filter.claimReference
        };

        const searchManufacturerProps: ISimpleSelectNumericProps = {
            isFilter: true,
            items: tools && tools.manufacturers,
            onChangeCallback: modelUpdater.for<number>((value, model) => {

                model.filterOptions.manufacturerId = value;
                return model;
            }),
            value: filter.manufacturerId
        };

        const searchLocationProps: ISimpleStringInputProps = {
            onChangeCallback: modelUpdater.for<string>((value, model) => {

                model.filterOptions.location = value;
                return model;
            }),
            value: filter.location
        };

        return (
            <Card>
                <CardHeader>
                    <CardTitle text="Vehicle Search" />
                </CardHeader>

                <CardBody>
                    <form onSubmit={FormHelper.preventFormSubmit}>

                        <div className="form-row justify-content-center mb-3">
                            <div className="col">
                                <GenericLabel displayName="Claim Reference" fieldName="claimReference" />
                                <SimpleStringInput {...searchClaimReferenceProps} />
                            </div>
                        </div>

                        <div className="form-row justify-content-center mb-3">
                            <div className="col">
                                <GenericLabel displayName="Manufacturer" fieldName="manufacturer" />
                                <SimpleSelectNumeric {...searchManufacturerProps} />
                            </div>
                        </div>

                        <div className="form-row justify-content-center mb-3">
                            <div className="col">
                                <GenericLabel displayName="Location" fieldName="location" />
                                <SimpleStringInput {...searchLocationProps} />
                            </div>
                        </div>

                        <div className="form-row justify-content-center">
                            <div className="col text-center">
                                <SearchButtonToolbar {...searchButtonToolbarProps} />
                            </div>
                        </div>

                    </form>
                </CardBody>
            </Card>
        );
    }
}

const HotFilter = hot(module)(Filter);

export {
    HotFilter as SupplyRequestListingDealerFilter,
    IFilterProps as ISupplyRequestListingDealerFilterProps
};
