const actionTypes = {
    receive: {
        forgottenPassword: 'ForgottenPassword.Receive.ViewModel',
        submit: 'ForgottenPassword.Receive.Submit'
    },
    reset: {
        resetForm: 'ForgottenPassword.Reset.Form'
    }
};

export {
    actionTypes as ForgottenPasswordActionTypes
};
