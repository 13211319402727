// 3rd Party References
import * as React from 'react';
import { hot } from 'react-hot-loader';

// Utility References
import { Handler } from 'Utility/IndexOfActions';
import { ObjectHelper } from 'Utility/IndexOfHelpers';

// App References
import {
    IPageActions,
    IPageState
} from 'App/IndexOfModels';
import { InfoCircleIcon } from 'Utility/IndexOfComponents';

interface IPropActions {
    pageActions: IPageActions;
    refreshAction?: Handler.Action1<any>;
    shouldHighlightUpdate: (event: string, args?: any) => boolean;
}

interface IPropData {
    pageState: IPageState;
}

interface IHighlightUpdateProps {
    propActions: IPropActions;
    propData: IPropData;
}

class HighlightUpdate extends React.Component<IHighlightUpdateProps, {}> {

    constructor(props) {
        super(props);

        this.state = {
            shouldDisplay: true
        };
    }

    componentDidMount(){
        const { signalRConnect } = this.props.propActions.pageActions;
        const { signalRResetMessage } = this.props.propActions.pageActions;

        signalRResetMessage();
        signalRConnect();
    }

    shouldComponentUpdate(nextProps: IHighlightUpdateProps){

        const { shouldHighlightUpdate } = this.props.propActions;

        if (ObjectHelper.isUndefinedOrNull(nextProps.propData.pageState.signalRUpdateMessage)) {
            return true;
        }

        const { event, args } = nextProps.propData.pageState.signalRUpdateMessage;

        return shouldHighlightUpdate(event, args);
    }

    private refresh = () => {
        const { refreshAction } = this.props.propActions;
        const { signalRResetMessage } = this.props.propActions.pageActions;
        const { signalRUpdateMessage } = this.props.propData.pageState;

        if (ObjectHelper.isUndefinedOrNull(refreshAction)) {
            return;
        }

        refreshAction(ObjectHelper.isUndefinedOrNull(signalRUpdateMessage) ? null : signalRUpdateMessage.args);

        signalRResetMessage();
    }

    private dismiss = (e) => {

        const { signalRResetMessage } = this.props.propActions.pageActions;

        signalRResetMessage();
    }

    render() {

        const { propActions, propData } = this.props;
        const { signalRUpdateMessage } = propData.pageState;

        if (ObjectHelper.isUndefinedOrNull(signalRUpdateMessage)) {

            return null;
        }

        return (
            <div className="row">
                <div className="col">
                    <div className="alert alert-warning alert-dismissible">
                        <InfoCircleIcon textClass="warning mr-2 d-inline" />
                        <span>{signalRUpdateMessage.args.eventDescription}.</span>
                        <a className="alert-link" href="#" onClick={this.refresh}><i> Refresh to see them</i></a>
                        <button type="button" onClick={this.dismiss} className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

const HotComponent = hot(module)(HighlightUpdate);

export {
    HotComponent as HighlightUpdate,
    IHighlightUpdateProps
};
