// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from '../../IndexOfActions';
//import {  } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
import { KeyboardEventHelper } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

// Local References
import InternalCommonTextInput, { IInternalCommonTextInputProps } from '../Inputs/Internal/InternalCommonTextInput';

export interface ISimpleVrmInputProps extends React.Props<SimpleVrmInput> {
    isDisabled?: boolean;
    isHidden?: boolean;
    onBlurCallback?: Handler.InputFocusEvent;
    onChangeCallback: Handler.StringProperty;
    onKeyPressCallback?: Handler.InputKeyboardEvent;
    placeholder?: string;
    propertyName?: string;
    tabIndex?: number;
    value: string;
}

export default class SimpleVrmInput extends React.Component<ISimpleVrmInputProps, {}> {

    private primaryInput: InternalCommonTextInput;

    public setFocus = (scroll?: number): void => {
        this.primaryInput.setFocus(scroll);
    }

    private handleOnKeyPress = (onKeyPressCallback: Handler.InputKeyboardEvent) => (event: React.KeyboardEvent<HTMLInputElement>) => {

        if (!KeyboardEventHelper.isRegNumberInputAllowedKey(event)) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (onKeyPressCallback) {
            onKeyPressCallback(event);
        }
    }

    private handleOnChange = (onChangeCallback: Handler.StringProperty, propertyName: string) => (event: React.FormEvent<HTMLInputElement>) => {
        const newValue = (event.target as HTMLInputElement).value;

        const amendedValue = !!newValue ? newValue.toUpperCase().trim() : null;
        onChangeCallback(amendedValue, propertyName);
    }

    render() {

        const { isDisabled, isHidden, onBlurCallback, onChangeCallback, onKeyPressCallback, placeholder, propertyName, tabIndex, value } = this.props;

        const inputProps: IInternalCommonTextInputProps = {
            appendedInputClassName: 'regno-input',
            isDisabled,
            isHidden,
            maxLength: 10,
            onBlurCallback,
            onChangeCallback: this.handleOnChange(onChangeCallback, propertyName),
            onKeyPressCallback: this.handleOnKeyPress(onKeyPressCallback),
            placeholder,
            propertyName,
            ref: (component: InternalCommonTextInput) => { this.primaryInput = component; },
            tabIndex,
            value
        };

        return <InternalCommonTextInput {...inputProps} />;
    }
}
