// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from 'Utility/IndexOfActions';

interface ISimpleFileUploadInputProps extends React.Props<SimpleFileUploadInput> {
    accept?: string;
    isDisabled?: boolean;
    propertyName?: string;
    ref: Handler.Action1<SimpleFileUploadInput>;
}

class SimpleFileUploadInput extends React.Component<ISimpleFileUploadInputProps, {}> {

    public primaryInput: HTMLInputElement;

    public getFiles = (): FileList => {
        return this.primaryInput.files;
    }

    public reset = (): void => {
        this.primaryInput.value = null;
    }

    render() {

        const { accept, isDisabled } = this.props;

        const inputProps = {
            accept,
            disabled: isDisabled,
            ref: (component: HTMLInputElement) => { this.primaryInput = component; }
        };

        return <input className="form-control" type="file" {...inputProps} />;
    }
}

export {
    ISimpleFileUploadInputProps,
    SimpleFileUploadInput
};
