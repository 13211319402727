// 3rd Party
import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';

// Utility References
import { IApiProgressFunctions } from 'Utility';
import { Handler, NavigationActions } from 'Utility/IndexOfActions';
import { FormHelper, ObjectHelper, StringHelper } from 'Utility/IndexOfHelpers';
import { IAjaxInfo, IGenericQueryActions } from 'Utility/IndexOfInterfaces';
import { Notification } from 'Utility/IndexOfServices';

// App References
import {
    AllExceptionNotifier,
    IApiRegistrationToolsViewModel as ITools,
    IApiRegistrationUpsertViewModel as IUpsert,
    IApiRegistrationViewModel as IViewModel
} from 'App/IndexOfInterfaces';
import {
    GenericQueryActions,
    RegistrationAreaActionTypes as ActionTypes,
    RegistrationAreaQueryActionTypes as QueryActionTypes
} from 'App/IndexOfModels';
import {
    RegistrationApi,
    RegistrationQueryService
} from 'App/IndexOfServices';
import {
    AppUrls
} from 'App/IndexOfUrls';

// State
import { IRegistrationState } from 'App/IndexOfModels';

interface IRegistrationActions extends IGenericQueryActions<null, null, ITools, IUpsert> {
    saveAsync: Handler.Action1<IViewModel>;
    usernameCollisionCheckAsync: Handler.Action1<string>;
}

class RegistrationActions extends GenericQueryActions<null, null, ITools, IUpsert> {

    public saveAsync = (viewModel: IViewModel) => (dispatch: Handler.AnyForDispatch): void => {

        const onSuccess = () => {

            Notification.success.recordCreated();

            NavigationActions.HardRedirectPageWithoutNotification(AppUrls.RegistrationUrls.success, 0);
        };

        const formData = FormHelper.objectToFormData(viewModel);

        this.api.callWithPayload(RegistrationApi.save(), formData, onSuccess, this.allExceptionNotifier, true);
    }

    public usernameCollisionCheckAsync = (value: string) => (dispatch: Handler.AnyForDispatch): void => {

        const onSuccess = (userNameCollided: boolean): void => {
            dispatch(this.receiveUserNameCollision(userNameCollided));
        };

        if (!StringHelper.isUndefinedNullOrEmpty(value)) {
            this.api.call(RegistrationApi.doesUsernameCollide(value), onSuccess, this.allExceptionNotifier);
        }
    }

    public receiveUserNameCollision = createAction(
        ActionTypes.receive.usernameCollision, (userNameCollided: boolean) => userNameCollided
    );
}

const actionDispatcherFactory = (dispatch: Dispatch<IRegistrationState>, progressFunctions: IApiProgressFunctions): IRegistrationActions => {

    const notificationsTitle = 'Registration';
    const actions = new RegistrationActions(progressFunctions, AllExceptionNotifier(notificationsTitle), RegistrationQueryService, QueryActionTypes);

    return {
        getAsync: null,
        getTemplateAsync: (id: number) => {
            dispatch(actions.getTemplateAsync(id));
        },
        getToolsAsync: null,
        receiveCollection: null,
        receiveOptions: null,
        receiveTools: null,
        receiveUpsert: (upsert: IUpsert) => {
            dispatch(actions.receiveUpsert(upsert));
        },
        resetAll: () => {
            dispatch(actions.resetAll());
        },
        saveAsync: (viewModel: IViewModel) => {
            dispatch(actions.saveAsync(viewModel));
        },
        searchAsync: null,
        usernameCollisionCheckAsync: (username: string) => {
            dispatch(actions.usernameCollisionCheckAsync(username));
        }
    };
};

export {
    actionDispatcherFactory as RegistrationActionsDispatcherFactory,
    IRegistrationActions
};
