// 3rd Party References
import * as React from 'react';

interface ICalloutTitleProps {
    text: string;
}

const CalloutTitle = (props: ICalloutTitleProps): JSX.Element => {

    const { text } = props;

    return (
        <h5 className="bd-callout-title">
            {text}
        </h5>
    );
};

export {
    CalloutTitle
};
