// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from '../../../IndexOfActions';
//import {  } from '../../../IndexOfComponents';
import { ButtonSize, ButtonStyle } from '../../../IndexOfEnums';
import { ButtonHelper } from '../../../IndexOfHelpers';
import { IButtonOptions } from '../../../IndexOfInterfaces';
//import {  } from '../../../IndexOfModels';
//import {  } from '../../../IndexOfServices';

interface ISimpleButtonOriginalProps extends React.Props<SimpleButton> {
    /**
     * Optional. css class that is appended after standard button styles
     */
    appendedClassName?: string;
    /**
     * Optional. Standard set of Button Options. Overrides older properties.
     */
    buttonOptions?: IButtonOptions;
    /**
     * Optional. button size enum to set css size
     */
    buttonSize?: ButtonSize;
    /**
     * Optional. button text. Either set property or add as child
     */
    buttonText?: string;
    /**
     * Optional. disables the button if true
     */
    isDisabled?: boolean;
    /**
     * Optional. adds a hidden css class to hide the button
     */
    isHidden?: boolean;
    /**
     * Handler.Action
     */
    onClick: Handler.Action;
    /**
     * Optional. disables button when processing
     */
    processing?: boolean;
}

interface IInternalSimpleButtonProps extends ISimpleButtonOriginalProps {
    buttonStyle: ButtonStyle;
}

class SimpleButton extends React.Component<IInternalSimpleButtonProps, {}> {

    render() {

        const { appendedClassName, buttonOptions, buttonSize, buttonStyle, buttonText, isDisabled, isHidden, onClick, processing } = this.props;

        const defaultButtonOptions: IButtonOptions = {
            buttonSize: buttonSize || ButtonSize.Md, // Simplify once buttonSize prop phased out.
            buttonStyle: buttonStyle || ButtonStyle.Primary,
            buttonText: buttonText || '' // Simplify once buttonText prop phased out.
        };

        let appliedClassName = ButtonHelper.getButtonClasses(defaultButtonOptions, buttonOptions);

        if (appendedClassName) {

            appliedClassName = appliedClassName + ' ' + appendedClassName; // Simplify once cssClass prop phased out.
        }

        if (isHidden) {
            appliedClassName += ' hidden';
        }

        const disabled = isDisabled || processing;

        const buttonProps = {
            className: appliedClassName,
            disabled: ButtonHelper.getButtonIsDisabledState(disabled, buttonOptions),
            onClick: ButtonHelper.getButtonOnClickHandler(onClick, null as IButtonOptions),
            role: 'button',
            title: ButtonHelper.getButtonTitle(buttonOptions),
            type: 'button'
        };

        const buttonTextDisplay = ButtonHelper.getButtonText(defaultButtonOptions, buttonOptions);

        return (
            <button {...buttonProps}>
                {this.props.children}{this.props.children ? ' ' : ''}{buttonTextDisplay}
            </button>
        );
    }
}

export {
    IInternalSimpleButtonProps,
    ISimpleButtonOriginalProps as ISimpleButtonProps,
    SimpleButton
};
