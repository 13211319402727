// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from '../../IndexOfActions';
//import {  } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
import { KeyboardEventHelper, ObjectHelper } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
import { NumericConverter } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

// Local References
import InternalCommonMixedInput, { IInternalCommonMixedInputProps } from './Internal/InternalCommonMixedInput';

export interface ISimpleNumericInputProps extends React.Props<SimpleNumericInput> {
    disallowZero?: boolean;
    isDisabled?: boolean;
    isHidden?: boolean;
    max?: number;
    onBlurCallback?: Handler.InputFocusEvent;
    onChangeCallback: Handler.NumberProperty;
    onKeyPressCallback?: Handler.InputKeyboardEvent;
    placeholder?: string;
    propertyName?: string;
    tabIndex?: number;
    value: number;
}

interface ISimpleNumericInputState {
    inputText: string;
}

export class SimpleNumericInput extends React.Component<ISimpleNumericInputProps, ISimpleNumericInputState> {

    private primaryInput: InternalCommonMixedInput;

    public setFocus = (scroll?: number): void => {
        this.primaryInput.setFocus(scroll);
    }

    public reset = (): void => {
        this.setState({ inputText: '' });
    }

    constructor(props: ISimpleNumericInputProps) {
        super(props);

        this.state = {
            inputText: NumericConverter(props.value).toOriginalDisplay()
        };
    }

    componentWillReceiveProps(nextProps: ISimpleNumericInputProps): void {

        const currentValue = this.state.inputText;
        const nextValue = nextProps.value;

        if ((nextValue === null || nextValue === undefined) && currentValue !== null) {

            this.setState(ObjectHelper.merge(this.state, { inputText: null }));

        } else if (nextValue !== null && nextValue !== undefined && currentValue !== nextValue.toString()) {

            this.setState(ObjectHelper.merge(this.state, { inputText: nextValue.toString() }));
        }
    }

    private howManyLettersFromTheDotAndCountingTheDotAndEverythingAfterItButNotCountingWhatCameBeforeTheDot = (numberyText: string): number => {

        const wayDatDotAt = numberyText.indexOf('.');

        if (wayDatDotAt === -1) {
            return -1;
        }

        return numberyText.substr(wayDatDotAt).length;
    }

    private handleOnKeyPress = (onKeyPressCallback?: Handler.InputKeyboardEvent) => (event: React.KeyboardEvent<HTMLInputElement>) => {

        // allow - as first value in input
        const input: HTMLInputElement = event.currentTarget as HTMLInputElement;
        const isFirstChar: boolean = input.value.length === 0;
        const hasDecimalPoint: boolean = input.value.indexOf('.') > -1;
        const isNegativeKey: boolean = event.which === 45;
        const isDecimalPointKey: boolean = event.which === 46;
        const isNumericKey: boolean = KeyboardEventHelper.isNumericKeyPress(event);

        const isInvalidFirstChar = isFirstChar && !isNumericKey && !isNegativeKey && !isDecimalPointKey;
        const isInvalidOtherChar = !isFirstChar && !isNumericKey && !isDecimalPointKey;
        const isInvalidDecimalPoint = isDecimalPointKey && hasDecimalPoint;

        if (isInvalidFirstChar || isInvalidOtherChar || isInvalidDecimalPoint) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (onKeyPressCallback) {
            onKeyPressCallback(event);
        }
    }

    private handleChange = (disallowZero: boolean, property: string, onChangeCallback: Handler.NumberProperty) => (event: React.FormEvent<HTMLInputElement>) => {

        const fieldValue = (event.target as HTMLInputElement).value;

        // not allowing 0 as the first character
        if (disallowZero && fieldValue.length === 1 && fieldValue === '0') {
            (event.target as HTMLInputElement).value = null;
            return;
        }

        const howManyLettersFromTheDotAndCountingTheDotAndEverythingAfterItButNotCountingWhatCameBeforeTheDot = this.howManyLettersFromTheDotAndCountingTheDotAndEverythingAfterItButNotCountingWhatCameBeforeTheDot(fieldValue);

        if (howManyLettersFromTheDotAndCountingTheDotAndEverythingAfterItButNotCountingWhatCameBeforeTheDot > 3) {
            return;
        }

        const valueAsNumber = parseFloat(fieldValue);
        onChangeCallback(valueAsNumber, property);

        this.setState({ inputText: fieldValue });
    }

    render() {

        const { disallowZero, isDisabled, isHidden, max, onBlurCallback, onChangeCallback, onKeyPressCallback, placeholder, propertyName, tabIndex } = this.props;
        const { inputText } = this.state;

        const newValue = inputText !== undefined && inputText !== null ? inputText : '';

        const commonInputProps: IInternalCommonMixedInputProps = {
            isDisabled,
            isHidden,
            max,
            onBlurCallback,
            onChangeCallback: this.handleChange(disallowZero, propertyName, onChangeCallback),
            onKeyPressCallback: this.handleOnKeyPress(onKeyPressCallback),
            placeholder,
            propertyName,
            ref: (component: InternalCommonMixedInput) => { this.primaryInput = component; },
            tabIndex,
            value: newValue
        };

        return <InternalCommonMixedInput {...commonInputProps} />;
    }
}
