// 3rd Party References
import { HubConnectionBuilder } from '@aspnet/signalr';
import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';

// Utility References
import { IApiProgressFunctions } from 'Utility';
import { Handler } from 'Utility/IndexOfActions';
import { TranslationHelper } from 'Utility/IndexOfHelpers';
import { IApiFilterBase, IGenericDictionary, IModelState } from 'Utility/IndexOfInterfaces';
import { IModalService, ModalService } from 'Utility/IndexOfServices';

// App References
import { ISignalRUpdateMessage } from 'App/IndexOfInterfaces';
import { AppService, LocalStorageService } from 'App/IndexOfServices';

// State
import { ActionTypes, IPageState, SignalRHubEvents } from './State';

type InitialiseStickyOptionsAction = (reset: boolean, defaultOptions: IApiFilterBase, currentOptions: IApiFilterBase, dispatch?: Dispatch<IPageState>) => IApiFilterBase;

interface IPageActions extends IApiProgressFunctions {
    addError: Handler.Action2<string, string>;
    addErrors: Handler.Action2<string, string[]>;
    addModelState: Handler.Action1<IModelState>;
    clearAll: Handler.Action;
    closeModals: Handler.Action;
    createModalService: (modalName: string, pageModals: IGenericDictionary<string>, dispatch?: Dispatch<IPageState>) => IModalService;
    initialiseStickyOptions: InitialiseStickyOptionsAction;
    receiveCurrentOptions: Handler.Action1<IApiFilterBase>;
    resetCurrentOptions: Handler.Action;
    removeEntry: Handler.StringValue;
    signalRConnect: Handler.Action;
    signalRResetMessage: Handler.Action;
    toggleModal: (modalName: string) => void;
}

class PageActions implements IPageActions {

    public addError = (propertyName: string, error: string) => (dispatch: Dispatch<IPageState>) => {

        const modelState = {} as IModelState;

        modelState[propertyName] = [TranslationHelper.translate(error)];

        dispatch(this.addModelState(modelState));
    }

    public addErrors = (propertyName: string, errors: string[]) => (dispatch: Dispatch<IPageState>) => {

        const modelState = {} as IModelState;

        modelState[propertyName] = errors.map(x => TranslationHelper.translate(x));

        dispatch(this.addModelState(modelState));
    }

    public addModelState = createAction(
        ActionTypes.addError, (modelState: IModelState) => modelState
    );

    private clearModelState = createAction(ActionTypes.clearAll);

    public clearAll = () => (dispatch: Dispatch<IPageState>) => {
        dispatch(this.clearModelState());
    }

    private closeStateModals = createAction(ActionTypes.closeModals);

    public closeModals = () => (dispatch: Dispatch<IPageState>) => {
        dispatch(this.closeStateModals());
    }

    public createModalService = (modalName: string, pageModals: IGenericDictionary<string>, dispatch: Dispatch<IPageState>): IModalService => {

        return new ModalService(dispatch, this.toggleModal, pageModals, modalName);
    }

    private decrement = createAction(ActionTypes.decrement);

    public decrementProgress = () => (dispatch: Dispatch<IPageState>) => {
        dispatch(this.decrement());
    }

    private increment = createAction(ActionTypes.increment);

    public incrementProgress = () => (dispatch: Dispatch<IPageState>) => {
        dispatch(this.increment());
    }

    public initialiseStickyOptions = (reset: boolean, defaultOptions: IApiFilterBase, currentOptions: IApiFilterBase, dispatch: Dispatch<IPageState>): IApiFilterBase => {

        if (!reset && defaultOptions && currentOptions && defaultOptions.type === currentOptions.type) {

            return currentOptions;

        } else {

            dispatch(this.resetCurrentOptions());

            return defaultOptions;
        }
    }

    public receiveCurrentOptions = createAction(
        ActionTypes.receive.currentOptions, (options: any) => options
    );

    public receiveSignalRMessageUpdate = createAction(
        ActionTypes.receive.signalRUpdateMessage, (message: ISignalRUpdateMessage) => message
    );

    public resetCurrentOptions = createAction(
        ActionTypes.reset.currentOptions
    );

    public removeEntry = (property: string) => (dispatch: Dispatch<IPageState>) => {
        dispatch(this.removeModelState(property));
    }

    private removeModelState = createAction(
        ActionTypes.removeEntry, (property: string) => property
    );

    public signalRResetMessage = createAction(ActionTypes.reset.signalRUpdateMessage);

    public signalRConnect = () => (dispatch: Handler.AnyForDispatch): void => {

        const connection = new HubConnectionBuilder()
        .withUrl(
            `${AppService().config.apiUrl}/updateHub`,
            {
                accessTokenFactory: () => {
                    return LocalStorageService.getAccessToken().token;
                }
            }).build();

        connection.start();

        connection.on(SignalRHubEvents.clientUpdated, (id?: number) => {
            dispatch(this.receiveSignalRMessageUpdate({ event: SignalRHubEvents.clientUpdated, args: id }));
        });

        connection.on(SignalRHubEvents.supplyRequestListingUpdated, (id?: number) => {
            dispatch(this.receiveSignalRMessageUpdate({ event: SignalRHubEvents.supplyRequestListingUpdated, args: id }));
        });

        connection.on(SignalRHubEvents.supplyRequestUpdated, (id?: number) => {
            dispatch(this.receiveSignalRMessageUpdate({ event: SignalRHubEvents.supplyRequestUpdated, args: id }));
        });

        connection.onclose(() => setTimeout(this.signalRConnect(), 5000));
    }

    public toggleModal = createAction(ActionTypes.toggleModal, (modalName: string) => modalName);
}

const dispatcherFactory = (dispatch: Dispatch<IPageState>): IPageActions => {

    const actions = new PageActions();

    return {
        addError: (propertyName: string, error: string) => {
            dispatch(actions.addError(propertyName, error));
        },
        addErrors: (propertyName: string, errors: string[]) => {
            dispatch(actions.addErrors(propertyName, errors));
        },
        addModelState: (modelState: IModelState) => {
            dispatch(actions.addModelState(modelState));
        },
        clearAll: () => {
            dispatch(actions.clearAll());
        },
        closeModals: () => {
            dispatch(actions.closeModals());
        },
        createModalService: (modalName: string, pageModals: IGenericDictionary<string>) => {
            return actions.createModalService(modalName, pageModals, dispatch);
        },
        decrementProgress: () => {
            dispatch(actions.decrementProgress());
        },
        incrementProgress: () => {
            dispatch(actions.incrementProgress());
        },
        initialiseStickyOptions: (reset: boolean, defaultOptions: IApiFilterBase, currentOptions: IApiFilterBase) => {
            return actions.initialiseStickyOptions(reset, defaultOptions, currentOptions, dispatch);
        },
        receiveCurrentOptions: (options: any) => {
            dispatch(actions.receiveCurrentOptions(options));
        },
        removeEntry: (propertyName: string) => {
            dispatch(actions.removeEntry(propertyName));
        },
        resetCurrentOptions: () => {
            dispatch(actions.resetCurrentOptions());
        },
        signalRConnect: () => {
            dispatch(actions.signalRConnect());
        },
        signalRResetMessage: () => {
            dispatch(actions.signalRResetMessage());
        },
        toggleModal: (modalName: string) => {
            dispatch(actions.toggleModal(modalName));
        }
    };
};

export {
    dispatcherFactory as PageDispatcherFactory,
    IPageActions,
    PageActions
};
