// 3rd Party References
import * as React from 'react';

// Utility References
//import {  } from '../../IndexOfActions';
//import {  } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
import { TranslationHelper } from '../../IndexOfHelpers';
import { IErrorException, IModelState, INotFound, IUtilityPageState } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

export interface IModelErrorDisplayProps extends React.Props<ModelErrorDisplay> {
    operationName: string;
    pageState: IUtilityPageState;
}

export class ModelErrorDisplay extends React.Component<IModelErrorDisplayProps, {}> {

    private getErrorData = (pageState: IUtilityPageState): string[] => {

        const modelState: IModelState = pageState.modelState;
        const notFoundError: INotFound = pageState.notFound;

        let errorData: string[] = [];

        if (notFoundError) {

            errorData = notFoundError.errors.map((error: IErrorException) => {
                return error.errorMessage;
            });

        }

        if (modelState && modelState.error) {
            errorData.push(modelState.error.toString());
        }

        return errorData;
    }

    render() {

        const { operationName, pageState } = this.props;

        const errorData = this.getErrorData(pageState);

        if (errorData.length === 0) {
            return null;
        }

        const errorListItems = errorData.map((message: string, index: number) => {
            return <li key={index}>{message}</li>;
        });

        const errorListHeader: string = TranslationHelper.translate(operationName + ' errors:');

        return (

            // ReSharper disable once UlTagContainsNonLiElements - Resharper false warning here.
            <div className="row">
                <div className="col-xs-12">
                    <ul className={'visibleAriaLabel validationErrorDisplay'} aria-label={errorListHeader}>
                        {errorListItems}
                    </ul>
                </div>
            </div>
        );
    }
}
