// 3rd Party References
import * as React from 'react';

export interface ITextDisplayProps {
    value: string;
}

const textDisplay = (props: ITextDisplayProps): JSX.Element => {

    const { value } = props;

    return (
        <input className="simple-display-text" type="text" readOnly={true} defaultValue={value} />
    );
};

export {
    textDisplay as TextDisplay
};
