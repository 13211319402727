// 3rd Party References
import * as React from 'react';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';

//import {  } from '../../IndexOfActions';
import { /*RenderInBody*/ } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
import { StringHelper, TranslationHelper } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

interface IToolTipProps extends React.Props<ToolTipWrapper> {
    bottom?: number;
    left?: number;
    linkTextStyle?: string;
    onMouseOut?: (event: React.MouseEvent<any>) => void;
    onMouseOver?: (event: React.MouseEvent<any>) => void;
    position?: ToolTipPosition;
    right?: number;
    show?: boolean;
    top?: number;
}

interface IToolTipState {
    bottom: number;
    left: number;
    right: number;
    show: boolean;
    top: number;
}

export enum ToolTipPosition {
    Bottom = 1,
    Left,
    Right,
    Top
}

class ToolTipWrapper extends React.Component<IToolTipProps, IToolTipState> {

    render() {

        const toolTipLink: ToolTipLink = this.props.children[0];
        const toolTipDisplay: ToolTipDisplay = this.props.children[1];

        const popoverHoverFocus = (
            <Popover id="popover-trigger-focus">
                {toolTipDisplay}
            </Popover>
        );

        return (
            <OverlayTrigger
                overlay={popoverHoverFocus}
                trigger={['hover', 'focus']}>
                {toolTipLink}
            </OverlayTrigger>
        );
    }
}

class ToolTipLink extends React.Component<IToolTipProps, {}> {

    render() {

        const { linkTextStyle, onMouseOver, onMouseOut } = this.props;

        const anchorStyle = `tooltip-link ${linkTextStyle ? linkTextStyle : ''}`;

        return (
            <a className={anchorStyle} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
                {this.props.children}
            </a>
        );
    }
}

class ToolTipLinkButton extends React.Component<IToolTipProps, {}> {

    render() {

        const { linkTextStyle, onMouseOver, onMouseOut } = this.props;

        const anchorStyle = `btn btn-primary tooltip-link ${linkTextStyle ? linkTextStyle : ''}`;

        return (
            <button className={anchorStyle} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
                {this.props.children}
            </button>
        );
    }
}

class ToolTipDisplay extends React.Component<IToolTipProps, {}>{

    render() {

        return (
            <div>
                {this.props.children}
            </div>
        );
    }
}

interface ISimpleToolTipProps extends React.HTMLProps<SimpleToolTip> {
    tooltip: string;
}

class SimpleToolTip extends React.Component<ISimpleToolTipProps, {}> {

    render() {

        const { children, tooltip } = this.props;

        const translatedTooltip = TranslationHelper.translate(tooltip);
        const id = StringHelper.newGuid();

        const overlay = (
            <Tooltip id={id}><span>{translatedTooltip}</span></Tooltip>
        );

        return (
            <OverlayTrigger overlay={overlay} delay={{show: 300 , hide: 150}}>
                {children}
            </OverlayTrigger>
        );
    }
}

const toolTip = {
    Button: ToolTipLinkButton,
    Display: ToolTipDisplay,
    Link: ToolTipLink,
    SimpleToolTip,
    ToolTipPosition,
    Wrapper: ToolTipWrapper,
    renderToolTip: (position: ToolTipPosition, toolTipLink: string | JSX.Element, toolTipDisplay: string | JSX.Element): JSX.Element => {

        return (
            <ToolTipWrapper position={position}>
                <ToolTipLink>
                    {toolTipLink}
                </ToolTipLink>
                <ToolTipDisplay>
                    {toolTipDisplay}
                </ToolTipDisplay>
            </ToolTipWrapper>
        );
    }
};

export {
    ISimpleToolTipProps,
    toolTip as ToolTip
};
