//3rd Party References
import * as React from 'react';

//Utility References
import { Handler } from '../../IndexOfActions';
import { OkIcon } from '../../IndexOfComponents';
import { ButtonStyle } from '../../IndexOfEnums';
import { AnimationHelper, ButtonHelper } from '../../IndexOfHelpers';
import { IButtonOptions, ICommonButtonProps } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

export interface IOkButtonProps extends ICommonButtonProps {
    buttonOptions?: IButtonOptions;
    buttonText?: string;
    cssClass?: string;
    isDisabled?: boolean;
    isHidden?: boolean;
    onClick: Handler.Action;
    processing?: boolean;
    tabIndex?: number;
}

export default class OkButton extends React.Component<IOkButtonProps, {}> {

    private primaryInput: HTMLButtonElement;

    public setFocus = (scroll?: number): void => {
        AnimationHelper.setFocus(this.primaryInput, scroll);
    }

    render() {

        const { buttonOptions, buttonText, cssClass, isDisabled, isHidden, onClick, processing, tabIndex } = this.props;

        const defaultButtonOptions: IButtonOptions = {
            buttonStyle: ButtonStyle.Success,
            buttonText: buttonText || 'OK'
        };

        let appliedClassName = ButtonHelper.getButtonClasses(defaultButtonOptions, buttonOptions);

        if (cssClass) {

            appliedClassName = appliedClassName + ' ' + cssClass; //Simplify once cssClass prop phased out.
        }

        if (isHidden) {
            appliedClassName += ' hidden';
        }

        const buttonProps = {
            className: appliedClassName,
            disabled: isDisabled,
            onClick: ButtonHelper.getButtonOnClickHandler(onClick, buttonOptions),
            ref: (component: HTMLButtonElement) => { this.primaryInput = component; },
            role: 'button',
            tabIndex,
            type: 'button'
        };

        const buttonTextDisplay = ButtonHelper.getButtonText(defaultButtonOptions, buttonOptions);

        return (
            <button {...buttonProps}>
                <OkIcon processing={processing} /> {buttonTextDisplay}
            </button>
        );
    }
}
