// 3rd Party References
import * as React from 'react';

// Utility References
//import {  } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
import { ReactHelper, StringHelper, TranslationHelper } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

export interface ISimpleLabelProps extends React.Props<SimpleLabel> {
    cssClassName?: string;
    displayName?: string;
    fieldName: string;
    isDisabled?: boolean;
    isRequired?: boolean;
}

export class SimpleLabel extends React.Component<ISimpleLabelProps, {}> {

    private getDisplayText = (displayName: string): string => {
        return TranslationHelper.translate(displayName);
    }

    private getLabelText = (displayName: string, isRequired: boolean): string => {

        const translatedLabelText = this.getDisplayText(displayName);
        return isRequired ? translatedLabelText + ' *' : translatedLabelText;
    }

    render() {

        const { cssClassName, displayName, fieldName, isDisabled, isRequired } = this.props;
        const newDisplayName = displayName || StringHelper.toLabelFormat(fieldName);
        const formattedLabelText = this.getLabelText(newDisplayName, isRequired);

        let labelProps: React.HTMLProps<HTMLLabelElement> = {
            className: cssClassName ? cssClassName + ' control-label' : 'control-label',
            disabled: isDisabled
        };

        if (isRequired) {

            labelProps.required = true;
            labelProps = ReactHelper.setAttribute(labelProps).data('data-msg-required', '* required');
        }

        return (
            <label {...labelProps} htmlFor={fieldName} >{formattedLabelText}</label>
        );
    }
}

export {
    ISimpleLabelProps as IGenericLabelProps,
    SimpleLabel as GenericLabel
};
