// 3rd Party References
import * as React from 'react';

// Utility References
import { TranslationHelper } from 'Utility/IndexOfHelpers';
import { Notification } from 'Utility/IndexOfServices';

const translation = (props: React.Props<{}>): JSX.Element => {

    if (typeof (props.children) !== 'string') {

        Notification.error.uiException('Translation display can only contain strings.');
        return;
    }

    return <>{TranslationHelper.translate(props.children as string)}</>;
};

export {
    translation as Translation
};
