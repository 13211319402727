// 3rd Party References
import * as React from 'react';

// Utility References
//import {  } from '../../IndexOfComponents';
import { GridColumnWidth } from '../../IndexOfEnums';
import {  } from '../../IndexOfHelpers';
import { IApiSortOptions, IGridColumnNameSet } from '../../IndexOfInterfaces';
import { IGridFilterContext } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

// Local References
import GridFilterRow from './Internal/GridFilterRow';
import GridLabelRow from './Internal/GridLabelRow';

export interface IGridDataHeadProps {
    actionsColumnWidth?: GridColumnWidth | GridColumnWidth[];
    columnNameSets: IGridColumnNameSet[];
    currentSortOptions?: IApiSortOptions;
    gridFilterContext?: IGridFilterContext;
}

export default class GridDataHead extends React.Component<IGridDataHeadProps, {}> {

    render() {

        const { actionsColumnWidth, columnNameSets, currentSortOptions, gridFilterContext } = this.props;

        const gridLabelProps = {
            actionsColumnWidth,
            columnNameSets,
            currentSortOptions,
            gridFilterContext
        };

        if (this.props.children) {

            let actionsColumnCount = 0;

            if (!!actionsColumnWidth) {
                actionsColumnCount = actionsColumnWidth instanceof Array ? actionsColumnWidth.length : 1;
            }

            return (
                <thead>
                    <GridFilterRow actionsColumnCount={actionsColumnCount}>
                        {this.props.children}
                    </GridFilterRow>
                    <GridLabelRow {...gridLabelProps} />
                </thead>
            );
        }

        return (
            <thead>
                <GridLabelRow {...gridLabelProps} />
            </thead>
        );
    }
}
