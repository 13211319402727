// 3rd Party
import * as React from 'react';
import { hot } from 'react-hot-loader';

// Base References
import { BaseView } from '../Base/View';

// App References
import {
    ForgottenPasswordForm,
    IForgottenPasswordFormProps
} from 'App/IndexOfModels';

class View extends BaseView<{}> {

    render() {

        const { appActions, appState } = this.props;

        const resetFormProps: IForgottenPasswordFormProps = {
            propActions: {
                onSubmit: appActions.forgottenPasswordActions.submit,
                pageActions: appActions.pageActions,
                receiveForgottenPassword: appActions.forgottenPasswordActions.receiveForgottenPassword
            },
            propData: {
                forgottenPassword: appState.forgottenPassword.forgottenPassword,
                pageState: appState.page
            }
        };

        return (
            <>
                <div className="col-sm-12 mb-3">
                    <h1>Forgotten Password</h1>
                    <p>Please enter your username to request a password reset link</p>
                </div>

                <ForgottenPasswordForm {...resetFormProps} />
            </>
        );
    }
}

const HotView = hot(module)(View);

export {
    HotView as ForgottenPasswordView
};
