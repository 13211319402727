// 3rd Party
import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';

// Utility References
import { IAjaxInfo, IApiProgressFunctions } from 'Utility';
import { Handler, NavigationActions } from 'Utility/IndexOfActions';
import { IGenericQueryActions } from 'Utility/IndexOfInterfaces';
import { Notification } from 'Utility/IndexOfServices';

// App References
import {
    AllExceptionNotifier,
    IApiBidHistoryCollection as ICollection,
    IApiBidHistoryOptions as IOptions,
    IApiBidHistoryProvisionalViewModel as IProvisional,
    IApiBidHistoryRejectViewModel as IReject,
    IApiBidHistoryToolsViewModel as ITools,
    IApiBidHistoryUpsertViewModel as IUpsert,
    IApiBidHistoryViewModel as IViewModel
} from 'App/IndexOfInterfaces';
import {
    BidHistoryActionTypes as ActionTypes,
    BidHistoryQueryActionTypes as QueryActionTypes,
    GenericQueryActions,
    IBidHistoryState as IState,
} from 'App/IndexOfModels';
import {
    BidHistoryActionService as ApiActionService,
    BidHistoryQueryService as QueryService
} from 'App/IndexOfServices';
import { FormHelper } from 'Utility/IndexOfHelpers';

interface IActions extends IGenericQueryActions<ICollection, IOptions, ITools, IUpsert> {
    acceptAsync: Handler.ActionAsync1<IProvisional, void>;
    declineAsync: Handler.ActionAsync1<IProvisional, void>;
    receiveProvisionalAsync: Handler.ActionAsync1<IProvisional, void>;
    receiveRejectionsAsync: Handler.Action1<IReject[]>;
    saveAsync: Handler.ActionAsync1<IViewModel, void>;
}

class BidHistoryActions extends GenericQueryActions<ICollection, IOptions, ITools, IUpsert> {

    public acceptAsync = (viewModel: IProvisional) => (dispatch: Handler.AnyForDispatch): Promise<Handler.AnyForDispatch> => {

        const promise = new Promise<void>((resolve, reject) => {

            const onSuccess = () => {

                Notification.success.recordUpdated('You have successfully accepted the bid');
                resolve();
            };

            const onFailure = (ajaxInfo: IAjaxInfo) => {

                this.allExceptionNotifier(ajaxInfo);
                reject();
            };

            const formData = FormHelper.objectToFormData(viewModel);

            this.api.callWithPayload(ApiActionService.accept(), formData, onSuccess, onFailure, true);
        });

        return promise;
    }

    public declineAsync = (viewModel: IProvisional) => (dispatch: Handler.AnyForDispatch): Promise<Handler.AnyForDispatch> => {

        const promise = new Promise<void>((resolve, reject) => {

            const onSuccess = () => {

                Notification.success.recordUpdated('You have successfully declined the bids.');
                resolve();
            };

            const onFailure = (ajaxInfo: IAjaxInfo) => {

                this.allExceptionNotifier(ajaxInfo);
                reject();
            };

            const formData = FormHelper.objectToFormData(viewModel);

            this.api.callWithPayload(ApiActionService.decline(), formData, onSuccess, onFailure, true);
        });

        return promise;
    }

    public receiveProvisional = createAction(
        ActionTypes.receive.provisional, (provisionalViewModel: IProvisional) => provisionalViewModel
    );

    public receiveAcceptAsync = (provisionalViewModel: IProvisional) => (dispatch: Handler.AnyForDispatch): Promise<Handler.AnyForDispatch> => {

        const promise = new Promise((resolve) => {

            resolve(dispatch(this.receiveProvisional(provisionalViewModel)));
        });

        return promise;
    }

    public receiveRejectionsAsync = createAction(
        ActionTypes.receive.rejections, (rejections: IReject[]) => rejections
    );

    public saveAsync = (viewModel: IViewModel) => (dispatch: Handler.AnyForDispatch): Promise<Handler.AnyForDispatch> => {

        const promise = new Promise<void>((resolve, reject) => {

            const onSuccess = () => {

                dispatch(this.getTemplateAsync(viewModel.supplyRequestListingId));
                Notification.success.recordCreated('Your bid has been successfully submitted');
                resolve();
            };

            const onFailure = (ajaxInfo: IAjaxInfo) => {

                this.allExceptionNotifier(ajaxInfo);
                reject();
            };

            const formData = FormHelper.objectToFormData(viewModel);

            this.api.callWithPayload(ApiActionService.save(), formData, onSuccess, onFailure, true);
        });

        return promise;
    }
}

const actionDispatcherFactory = (dispatch: Dispatch<IState>, progressFunctions: IApiProgressFunctions): IActions => {

    const notificationsTitle = 'BidHistory';
    const actions = new BidHistoryActions(progressFunctions, AllExceptionNotifier(notificationsTitle), QueryService, QueryActionTypes);

    return {
        acceptAsync: (viewModel: IProvisional) => {
            return dispatch(actions.acceptAsync(viewModel));
        },
        declineAsync: (viewModel: IProvisional) => {
            return dispatch(actions.declineAsync(viewModel));
        },
        getAsync: (id: number) => {
            dispatch(actions.getAsync(id));
        },
        getTemplateAsync:  (listingId: number) => {
            dispatch(actions.getTemplateAsync(listingId));
        },
        getToolsAsync: null,
        receiveProvisionalAsync: (viewModel: IProvisional) => {
            return dispatch(actions.receiveAcceptAsync(viewModel));
        },
        receiveCollection: (collection: ICollection) => {
            dispatch(actions.receiveCollection(collection));
        },
        receiveRejectionsAsync: (rejections: IReject[]) => {
            dispatch(actions.receiveRejectionsAsync(rejections));
        },
        receiveOptions: (options: IOptions) => {
            dispatch(actions.receiveOptions(options));
        },
        receiveTools: null,
        receiveUpsert: (upsert: IUpsert) => {
            dispatch(actions.receiveUpsert(upsert));
        },
        resetAll: () => {
            dispatch(actions.resetAll());
        },
        saveAsync: (viewModel: IViewModel) => {
            return dispatch(actions.saveAsync(viewModel));
        },
        searchAsync: (options: IOptions) => {
            dispatch(actions.searchAsync(options));
        }
    };
};

export {
    actionDispatcherFactory as BidHistoryActionsDispatcherFactory,
    IActions as IBidHistoryActions
};
