// 3rd Party
import * as React from 'react';
import { hot } from 'react-hot-loader';

// Base References
import { BaseView } from '../Base/View';

// App References
import {
    ILoginFormProps,
    LoginForm
} from 'App/IndexOfModels';

class View extends BaseView<{}> {

    render() {

        const { appActions, appState } = this.props;

        const loginFormProps: ILoginFormProps = {
            propActions: {
                checkAuthenticated: appActions.loginActions.checkAuthenticated,
                onSubmit: appActions.loginActions.loginAsync,
                pageActions: appActions.pageActions,
                receiveUpsert: appActions.loginActions.receiveUpsert
            },
            propData: {
                pageState: appState.page,
                upsert: appState.login.upsert
            }
        };

        return (
            <>
                <div className="col-sm-12 mb-3">
                    <h1>Login</h1>
                    <p>Please enter your details below to login to Motor Vehicle Replacement Website</p>
                </div>

                <LoginForm {...loginFormProps} />
            </>
        );
    }
}

export {
    View as LoginView
};
