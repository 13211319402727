// Utility References
import { GridColumnWidth } from 'Utility/IndexOfEnums';
import {
    IApiPaginationOptions, IApiSortOptions, IGenericQueryActionTypes, IGridColumnNameSet,
} from 'Utility/IndexOfInterfaces';

// App References
import {
    IApiSupplyRequestFilter as IFilter,
    IApiSupplyRequestOptions as IOptions
} from 'App/IndexOfInterfaces';
import { CultureHelper } from 'Utility/IndexOfHelpers';

const actionTypes = {
    receive: {
        linkedTools: 'SupplyRequest.Receive.LinkedTools'
    }
};

const columnNames = (): IGridColumnNameSet[] => {
    return [
        { columnWidth: GridColumnWidth.Short, fieldName: 'claimReference', labelText: 'Reference' },
        { columnWidth: GridColumnWidth.Short, fieldName: 'manufacturerName', labelText: 'Manufacturer' },
        { columnWidth: GridColumnWidth.Short, fieldName: 'modelName', labelText: 'Model' },
        { columnWidth: GridColumnWidth.Short, fieldName: 'derivativeName', labelText: 'Derivative' },
        { columnWidth: GridColumnWidth.Short, fieldName: 'postCode', labelText: 'Postcode' }
    ];
};

const defaultOptions = (): IOptions => {

    const defaultFilterOptions: IFilter = {
        supplyRequestStatus: 1
    };

    const defaultPaginationOptions: IApiPaginationOptions = {
        maxVisiblePageButtons: 5,
        pageNumber: 1,
        pageSize: 10
    };

    const defaultSortOptions: IApiSortOptions = {
        sortField: 'CreatedDate',
        sortOrderAsc: false
    };

    const defaultOptions: IOptions = {
        cultureOptions: CultureHelper.getCultureInfo(),
        filterOptions: defaultFilterOptions,
        paginationOptions: defaultPaginationOptions,
        sortOptions: defaultSortOptions,
        type: 'IApiSupplyRequestOptions'
    };

    return defaultOptions;
};

const queryActionTypes: IGenericQueryActionTypes = {
    receive: {
        collection: 'SupplyRequest.Management.Collection',
        options: 'SupplyRequest.Management.Options',
        tools: 'SupplyRequest.Management.Tools',
        upsert: 'SupplyRequest.Management.Upsert'
    },
    reset: {
        all: 'SupplyRequest.Management.Reset'
    }
};

export {
    actionTypes as SupplyRequestActionTypes,
    columnNames as SupplyRequestColumnNames,
    defaultOptions as SupplyRequestDefaultOptions,
    queryActionTypes as SupplyRequestQueryActionTypes
};
