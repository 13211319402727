// 3rd Party References
import * as React from 'react';

// Utility References
//import {  } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
import { TranslationHelper } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

export interface IWrappingLabelProps extends React.HTMLProps<WrappingLabel> {
    rightSide?: boolean;
    text: string;
}

export class WrappingLabel extends React.Component<IWrappingLabelProps, {}> {

    render() {

        const { rightSide, text } = this.props;

        const translatedText = TranslationHelper.translate(text);

        if (rightSide) {

            return (
                <div className="input-group ui-wrapping-label">
                    {this.props.children}
                    <span className="input-group-addon">{translatedText}</span>
                </div>
            );
        }

        return (
            <div className="input-group ui-wrapping-label">
                <span className="input-group-addon">{translatedText}</span>
                {this.props.children}
            </div>
        );
    }
}
