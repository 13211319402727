// 3rd Party References
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

// App References
import { IAppActions, IAppState } from 'App/State';

export interface IAppProps {
    appActions: IAppActions;
    appState: IAppState;
}

export class BaseView<TS> extends React.Component<IAppProps, TS> { }

interface IIndexOfIds {
    id: string;
}

export interface IRoutedAppProps extends RouteComponentProps<IIndexOfIds> {
    appActions: IAppActions;
    appState: IAppState;
    isReadOnly?: boolean;
}

export class RoutedBaseView extends React.Component<IRoutedAppProps, {}> {

    componentWillUnmount(): void {

        const { appActions } = this.props;
    }
}

export class RoutedBaseStateView<TS> extends React.Component<IRoutedAppProps, TS> {

    componentWillUnmount(): void {

        const { appActions } = this.props;
    }
}
