// 3rd Party
import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';

// Utility References
import { IAjaxInfo, IApiProgressFunctions } from 'Utility';
import { Handler, NavigationActions } from 'Utility/IndexOfActions';
import { FormHelper } from 'Utility/IndexOfHelpers';
import { IApiSuggestionViewModel, IGenericQueryActions } from 'Utility/IndexOfInterfaces';
import { Notification } from 'Utility/IndexOfServices';

// App References
import {
    AllExceptionNotifier,
    IApiSupplyRequestCollection as ICollection,
    IApiSupplyRequestOptions as IOptions,
    IApiSupplyRequestToolsFilter as IToolsFilter,
    IApiSupplyRequestToolsViewModel as ITools,
    IApiSupplyRequestUpsertViewModel as IUpsert,
    IApiSupplyRequestViewModel as IViewModel
} from 'App/IndexOfInterfaces';
import {
    GenericQueryActions,
    ISupplyRequestState as IState,
    SupplyRequestActionTypes as ActionTypes,
    SupplyRequestQueryActionTypes as QueryActionTypes,
} from 'App/IndexOfModels';
import {
    SupplyRequestApi as ApiService,
    SupplyRequestQueryService as QueryService
} from 'App/IndexOfServices';
import { AppUrls } from 'App/IndexOfUrls';

interface IActions extends IGenericQueryActions<ICollection, IOptions, ITools, IUpsert> {
    getLinkedToolsAsync: Handler.ActionAsync1<IToolsFilter, void>;
    saveAsync: Handler.ActionAsync1<IViewModel, void>;
    updateAsync: Handler.ActionAsync1<IViewModel, void>;
}

class SupplyRequestActions extends GenericQueryActions<ICollection, IOptions, ITools, IUpsert> {

    public getLinkedToolsAsync = (toolsFilter: IToolsFilter) => (dispatch: Handler.AnyForDispatch): Promise<Handler.AnyForDispatch> => {

        return new Promise<void>((resolve, reject) => {

            const onFailure = (ajaxInfo: IAjaxInfo) => {

                this.allExceptionNotifier(ajaxInfo);
                reject();
            };

            const onSuccess = (tools: ITools) => {

                dispatch(this.receiveLinkedTools(tools));

                resolve();
            };

            this.api.callWithPayload(ApiService.linkedTools(), toolsFilter, onSuccess, onFailure);
        });
    }

    public receiveLinkedTools = createAction(
        ActionTypes.receive.linkedTools, (tools: ITools) => tools
    );

    public saveAsync = (viewModel: IViewModel) => (dispatch: Handler.AnyForDispatch): Promise<Handler.AnyForDispatch> => {

        return new Promise<void>((resolve, reject) => {

            const onFailure = (ajaxInfo: IAjaxInfo) => {

                this.allExceptionNotifier(ajaxInfo);
                reject();
            };

            const onSuccess = () => {

                Notification.success.recordCreated();

                resolve();

                NavigationActions.HardRedirectPageWithoutNotification(AppUrls.DashboardUrls.area, 0); // TODOxUI - This should be changed to redirect to main supply request page
            };

            const formData = FormHelper.objectToFormData(viewModel);

            this.api.callWithPayload(ApiService.save(), formData, onSuccess, onFailure, true);
        });
    }

    public updateAsync = (viewModel: IViewModel) => (dispatch: Handler.AnyForDispatch): Promise<Handler.AnyForDispatch> => {

        return new Promise<void>((resolve, reject) => {

            const onFailure = (ajaxInfo: IAjaxInfo) => {

                this.allExceptionNotifier(ajaxInfo);
                reject();
            };

            const onSuccess = () => {

                Notification.success.recordUpdated();
                resolve();
            };

            const formData = FormHelper.objectToFormData(viewModel);

            this.api.callWithPayload(ApiService.update(), formData, onSuccess, onFailure, true);
        });
    }
}

const actionDispatcherFactory = (dispatch: Dispatch<IState>, progressFunctions: IApiProgressFunctions): IActions => {

    const notificationsTitle = 'Supply Request';
    const actions = new SupplyRequestActions(progressFunctions, AllExceptionNotifier(notificationsTitle), QueryService, QueryActionTypes);

    return {
        getAsync: (id: number) => {
            dispatch(actions.getAsync(id));
        },
        getLinkedToolsAsync: (toolsFilter: IToolsFilter) => {
            return dispatch(actions.getLinkedToolsAsync(toolsFilter));
        },
        getTemplateAsync: () => {
            dispatch(actions.getTemplateAsync());
        },
        getToolsAsync: () => {
            dispatch(actions.getToolsAsync());
        },
        receiveCollection: (collection: ICollection) => {
            dispatch(actions.receiveCollection(collection));
        },
        receiveOptions: (options: IOptions) => {
            dispatch(actions.receiveOptions(options));
        },
        receiveTools: (tools: ITools) => {
            dispatch(actions.receiveTools(tools));
        },
        receiveUpsert: (upsert: IUpsert) => {
            dispatch(actions.receiveUpsert(upsert));
        },
        resetAll: () => {
            dispatch(actions.resetAll());
        },
        saveAsync: (viewModel: IViewModel) => {
            return dispatch(actions.saveAsync(viewModel));
        },
        searchAsync: (options: IOptions) => {
            dispatch(actions.searchAsync(options));
        },
        updateAsync: (viewModel: IViewModel) => {
            return dispatch(actions.updateAsync(viewModel));
        }
    };
};

export {
    actionDispatcherFactory as SupplyRequestActionsDispatcherFactory,
    IActions as ISupplyRequestActions
};
