// 3rd Party References
import * as React from 'react';

const CardBody = (props: React.Props<null>): JSX.Element => {

    return (
        <div className="card-body">
            {props.children}
        </div>
    );
};

export {
    CardBody
};
