// 3rd Party
import * as React from 'react';
import { hot } from 'react-hot-loader';

// Utility References
import { Handler } from 'Utility/IndexOfActions';
import {
    GridData,
    GridDataHead,
    GridDataRow,
    GridMain,
    IGridDataHeadProps,
    IGridDataRowProps,
    ISimpleSelectNumericProps,
    SimpleSelectNumeric
} from 'Utility/IndexOfComponents';
import { ObjectHelper } from 'Utility/IndexOfHelpers';
import { IApiItemOptionViewModel, IApiSearchViewModel } from 'Utility/IndexOfInterfaces';

// App References
import { toCurrencyDisplay } from 'App/Helpers/CurrencyHelper';
import {
    IApiBidHistoryRejectViewModel,
    IApiBidHistorySummaryViewModel as ISummary
} from 'App/IndexOfInterfaces';
import { BidHistoryColumnNames } from 'App/IndexOfModels';
import { GridColumnWidth } from 'Utility/IndexOfEnums';

interface IPropActions {
    updateRejectionsFunc: Handler.Action1<IApiBidHistoryRejectViewModel[]>;
}

interface IPropData {
    rejectedBids: ISummary[];
    rejections: IApiBidHistoryRejectViewModel[];
    rejectOptions: IApiItemOptionViewModel[];
    showHeader: boolean;
}

interface ILooserRejectProps {
    propActions: IPropActions;
    propData: IPropData;
}

class LooserReject extends React.Component<ILooserRejectProps, {}>{

    private dataRow = (summary: ISummary): IGridDataRowProps => {

        return {
            dataPoints: [
                toCurrencyDisplay(summary.bidAmount),
                summary.supplyingColourNames.join(', '),
                summary.deliveryTimeFrame,
                summary.dealerName
            ],
            hasActions: true,
            key: summary.bidHistoryId,
            rowId: summary.bidHistoryId,
        };
    }

    private getGridDataRowFactory = (propActions: IPropActions, propData: IPropData) => (rejectedBids: IApiSearchViewModel<ISummary>): JSX.Element[] => {

        if (rejectedBids){

            return (

                rejectedBids.results.map((summary: ISummary) => {

                    const rejection = propData.rejections.find(x => x.bidHistoryId === summary.bidHistoryId);

                    const rejectReasonSelectProps: ISimpleSelectNumericProps = {
                        displayName: 'reason',
                        items: propData.rejectOptions,
                        keepPleaseSelect: true,
                        onChangeCallback: (value: number) => {

                            rejection.reasonId = value;
                            propActions.updateRejectionsFunc(propData.rejections);
                        },
                        value: rejection.reasonId
                    };

                    return (
                        <GridDataRow className="alert-warning" {...this.dataRow(summary)}>
                            <div className="col-sm-12">
                                <SimpleSelectNumeric {...rejectReasonSelectProps} />
                            </div>
                        </GridDataRow>
                    );
                })
            );
        }
    }

    render() {

        const { propActions, propData } = this.props;

        const { rejectedBids, rejections, rejectOptions } = propData;

        if (ObjectHelper.isUndefinedOrNull(rejectOptions) || ObjectHelper.isUndefinedOrNull(rejections) || ObjectHelper.isUndefinedOrNull(rejectedBids)){
            return null;
        }

        if (rejections.length === 0 || rejectOptions.length === 0 || rejectedBids.length === 0) {
            return null;
        }

        const rejectedBidCollection: IApiSearchViewModel<ISummary> = {
            pageData: null,
            results: rejectedBids
        };

        const gridDataHeadProps: IGridDataHeadProps = {
            actionsColumnWidth: GridColumnWidth.Tiny,
            columnNameSets: BidHistoryColumnNames()
        };

        return (
            <div className="row">
                <div className="col-sm-12">
                    <GridMain isStriped={false}>
                        {
                            propData.showHeader &&
                            <GridDataHead {...gridDataHeadProps} />
                        }
                        <GridData collection={rejectedBidCollection} dataRowFactory={this.getGridDataRowFactory(propActions, propData)} />
                    </GridMain>
                </div>
            </div>
        );
    }
}

const HotList = hot(module)(LooserReject);

export {
    HotList as BidHistoryLooserReject,
    ILooserRejectProps as IBidHistoryLooserRejectProps
};
