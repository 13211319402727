// 3rd Party References
import * as moment from 'moment';
import * as React from 'react';

// Utility References
import { Handler } from '../../IndexOfActions';
//import {  } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
import { AnimationHelper, ObjectHelper } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
import { DateConverter } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

export interface ISimpleTimeInputProps extends React.Props<SimpleTimeInput> {
    isDisabled?: boolean;
    isHidden?: boolean;
    onBlurCallback?: Handler.InputFocusEvent;
    onChangeCallback: Handler.DateProperty;
    placeholder?: string;
    propertyName?: string;
    tabIndex?: number;
    value: Date;
}

interface ISimpleTimeInputState {
    actualValue: Date;
    viewerValue: string;
    viewerVisible: boolean;
}

export class SimpleTimeInput extends React.Component<ISimpleTimeInputProps, ISimpleTimeInputState> {

    private primaryInput: HTMLInputElement;

    public setFocus = (scroll?: number): void => {

        if (!this.props.isHidden) {

            const stateClone = this.getStateClone();
            stateClone.viewerVisible = false;

            this.setState(stateClone, () => {
                AnimationHelper.setFocus(this.primaryInput, scroll);
            });
        }
    }

    public reset = (): void => {
        this.setState({ actualValue: null, viewerValue: '', viewerVisible: true });
    }

    constructor(props: ISimpleTimeInputProps) {
        super(props);

        this.state = {
            actualValue: props.value,
            viewerValue: this.timeAsDateToString(props.value),
            viewerVisible: true
        };
    }

    componentWillReceiveProps(nextProps: ISimpleTimeInputProps) {

        const { value } = nextProps;

        const stateClone = this.getStateClone();
        stateClone.actualValue = value;
        stateClone.viewerValue = this.timeAsDateToString(value);

        this.setState(stateClone);
    }

    private getStateClone = (): ISimpleTimeInputState => ObjectHelper.deepClone(this.state);

    private timeAsDateToString = (timeValueAsDate: Date): string => {

        return timeValueAsDate !== undefined && timeValueAsDate !== null ? DateConverter(timeValueAsDate, 'INVALID').to24HrTimeDisplay() : '';
    }

    private timeAsStringToDate = (timeValueAsString: string): Date => {

        if (timeValueAsString === null || timeValueAsString === undefined || timeValueAsString === '') {
            return null;
        }

        const momentValue = moment(timeValueAsString, 'HH:mm');

        if (momentValue.isValid() === false) {
            return null;
        }

        return momentValue.toDate();
    }

    private handleOnChange = (event: React.FormEvent<HTMLInputElement>): void => {

        const inputElement = (event.target as HTMLInputElement);

        if (this.validateInputValue()) {

            const actualValue = this.timeAsStringToDate(inputElement.value);

            this.setState({ actualValue, viewerValue: inputElement.value, viewerVisible: false });
        }
    }

    private validateInputValue = (): boolean => {

        return true;

        /* This is retained for posterity. It works but makes mid-character edits difficult.
        const lastInput = value.substr(value.length - 1, 1);

        if (['0','1','2','3','4','5','6','7','8','9',':',''].indexOf(lastInput) === -1) {
            return false;
        }

        const validateHourTens = (value: string) => {

            const firstPart = parseInt(value.substr(0, 1));

            if (firstPart === NaN || (firstPart < 0 || firstPart > 2)) {
                return false;
            }

            return true;
        }

        const validateHours = (value: string) => {

            const firstPart = parseInt(value.substr(0, 2));

            if (firstPart === NaN || (firstPart < 0 || firstPart > 23)) {
                return false;
            }

            return true;
        }

        const validateSeparator = (value: string) => {

            return value.charAt(2) === ':';
        }

        const validateMinuteTens = (value: string) => {

            const secondPart = parseInt(value.substr(3, 1));

            if (secondPart === NaN || (secondPart < 0 || secondPart > 5)) {
                return false;
            }

            return true;
        }

        const validateMinutes = (value: string) => {

            const secondPart = parseInt(value.substr(3, 2));

            if (secondPart === NaN || (secondPart < 0 || secondPart > 59)) {
                return false;
            }

            return true;
        }

        if (!!value) {

            if (value.length === 1) {

                return validateHourTens(value);

            } else if (value.length === 2) {

                return validateHours(value);

            } else if (value.length === 3) {

                return validateHours(value) && validateSeparator(value);

            } else if (value.length === 4) {

                return validateHours(value) && validateSeparator(value) && validateMinuteTens(value);

            } else if (value.length === 5) {

                return validateHours(value) && validateSeparator(value) && validateMinutes(value);

            } else if (value.length > 5) {

                return false;
            }
        }

        return true; */
    }

    private guardValue = (value: Date) => {

        if (value === undefined || value === null) {
            return null;
        }

        return value;
    }

    private handleOnBlur = (actualValue: Date, onBlurCallback: Handler.InputFocusEvent, onChangeCallback: Handler.DateProperty, propertyName: string) => (event: React.FocusEvent<HTMLInputElement>) => {

        const valueOrNull = this.guardValue(actualValue);

        const stateClone = this.getStateClone();
        stateClone.actualValue = valueOrNull;
        stateClone.viewerValue = this.timeAsDateToString(valueOrNull);
        stateClone.viewerVisible = true;

        const postStateUpdateFunc = () => {

            onChangeCallback(valueOrNull, propertyName);

            if (onBlurCallback) {
                onBlurCallback(event);
            }
        };

        this.setState(stateClone, postStateUpdateFunc);
    }

    private handleViewerOnFocus = () => {

        const stateClone = this.getStateClone();
        stateClone.viewerVisible = false;

        this.setState(stateClone, () => {
            this.primaryInput.focus();
            this.primaryInput.select();
        });
    }

    render() {

        const { isDisabled, isHidden, onBlurCallback, onChangeCallback, placeholder, propertyName, tabIndex } = this.props;
        const { actualValue, viewerValue, viewerVisible } = this.state;

        const editorCssClass = 'time-editor form-control';

        const editorInputProps: React.HTMLProps<HTMLInputElement> = {
            autoComplete: 'off',
            className: isHidden || viewerVisible ? editorCssClass + ' hidden' : editorCssClass,
            disabled: isDisabled,
            id: propertyName,
            onBlur: this.handleOnBlur(actualValue, onBlurCallback, onChangeCallback, propertyName),
            onChange: this.handleOnChange,
            placeholder,
            ref: (component: HTMLInputElement) => { this.primaryInput = component; },
            tabIndex,
            type: 'string',
            value: viewerValue !== undefined && viewerValue !== null ? viewerValue : ''
        };

        let viewerCssClass = 'time-viewer form-control';

        if (isHidden || !viewerVisible) {

            viewerCssClass = viewerCssClass + ' hidden';

        } else if (isDisabled) {

            viewerCssClass = viewerCssClass + ' isDisabled';

        } else {

            viewerCssClass = viewerCssClass + ' isActive';
        }

        const viewerInputProps: React.HTMLProps<HTMLInputElement> = {
            className: viewerCssClass,
            disabled: isDisabled,
            onFocus: this.handleViewerOnFocus,
            readOnly: true,
            value: this.timeAsDateToString(actualValue)
        };

        return (
            <div className="simple-time-input">
                <input {...editorInputProps} />
                <input {...viewerInputProps} />
            </div>
        );
    }
}
