//3rd Party References
import * as React from 'react';

//Utility References
import { Handler } from '../../IndexOfActions';
import { NextIcon } from '../../IndexOfComponents';
import { ButtonStyle } from '../../IndexOfEnums';
import { AnimationHelper, ButtonHelper } from '../../IndexOfHelpers';
import { IButtonOptions, ICommonButtonProps } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

export interface INextButtonProps extends ICommonButtonProps {
    buttonOptions?: IButtonOptions;
    isDisabled?: boolean;
    isErrored?: boolean;
    onClick: Handler.Action;
    processing?: boolean;
    tabIndex?: number;
}

export default class NextButton extends React.Component<INextButtonProps, {}> {

    private primaryInput: HTMLButtonElement;

    public setFocus = (scroll?: number): void => {
        AnimationHelper.setFocus(this.primaryInput, scroll);
    }

    render() {

        const { buttonOptions, isDisabled, isErrored, onClick, processing, tabIndex } = this.props;

        const defaultButtonOptions: IButtonOptions = {
            buttonStyle: ButtonStyle.Primary,
            buttonText: 'Next'
        };

        const buttonProps = {
            className: ButtonHelper.getButtonClasses(defaultButtonOptions, buttonOptions),
            disabled: ButtonHelper.getButtonIsDisabledState(isDisabled, buttonOptions) || isErrored,
            onClick: ButtonHelper.getButtonOnClickHandler(onClick, buttonOptions),
            ref: (component: HTMLButtonElement) => { this.primaryInput = component; },
            role: 'button',
            tabIndex,
            type: 'button'
        };

        const buttonTextDisplay = ButtonHelper.getButtonText(defaultButtonOptions, buttonOptions);

        return (
            <button {...buttonProps}>
                {buttonTextDisplay}&nbsp;&nbsp;<NextIcon isDisabled={isDisabled} isErrored={isErrored} processing={processing} />
            </button>
        );
    }
}
