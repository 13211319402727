// Utility References
import { Handler } from 'Utility/IndexOfActions';
import { AjaxInfoStatusCodes } from 'Utility/IndexOfEnums';
import { ExceptionHelper } from 'Utility/IndexOfHelpers';
import { IAjaxInfo, IApiExceptionGroup } from 'Utility/IndexOfInterfaces';

// Exception References
import { AuthenticationExceptionGroup } from './AuthenticationExceptions';
import { BiddingExceptionGroup } from './BiddingExceptions';
import { ChangePasswordExceptionGroup } from './ChangePasswordExceptions';
import { EmailServiceExceptionGroup } from './EmailServiceExceptions';
import { RegistrationExceptionGroup } from './RegistrationExceptions';
import { ResetPasswordExceptionGroup } from './ResetPasswordExceptions';
import { UserExceptionGroup } from './UserExceptions';

const exceptionGroups: IApiExceptionGroup[] = [
    AuthenticationExceptionGroup,
    BiddingExceptionGroup,
    ChangePasswordExceptionGroup,
    EmailServiceExceptionGroup,
    RegistrationExceptionGroup,
    ResetPasswordExceptionGroup,
    UserExceptionGroup
];

const allExceptionNotifierInternal = (notificationsTitle: string) => (ajaxInfo: IAjaxInfo): void => {
    ExceptionHelper.exceptionGroupsNotifier(ajaxInfo, exceptionGroups, notificationsTitle);
};

const exceptionFilter = (allExceptionNotifier: Handler.Action1<IAjaxInfo>) => {

    const internalExceptionFilter = (statusCodesToIgnore: [AjaxInfoStatusCodes]) => (ajaxInfo: IAjaxInfo) => {

        const shouldNotIgnoreCode = statusCodesToIgnore.indexOf(ajaxInfo.statusCode) === -1;

        if (shouldNotIgnoreCode) {
            allExceptionNotifier(ajaxInfo);
        }
    };

    return {
        IgnoreByCode: (statusCodeToIgnore: AjaxInfoStatusCodes) => { internalExceptionFilter([statusCodeToIgnore]); },
        IgnoreByCodes: internalExceptionFilter,
        IgnoreNotFound: internalExceptionFilter([AjaxInfoStatusCodes.HttpNotFound])
    };
};

export {
    allExceptionNotifierInternal as AllExceptionNotifier,
    exceptionFilter as ExceptionFilter
};
