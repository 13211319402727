// 3rd Party References
import * as React from 'react';

// Utility References
import { DateWithTimeDisplay, IDateWithTimeDisplayProps, ISimpleButtonProps, LinkButton } from 'Utility/IndexOfComponents';
import { NoteType } from 'Utility/IndexOfEnums';
import { ObjectHelper } from 'Utility/IndexOfHelpers';

interface INotesProps extends React.Props<Notes> {
    dateModified?: Date;
    noteBody: string;
    noteHeader?: string;
    noteType: NoteType;
    userCreated?: string;
}

class Notes extends React.Component<INotesProps, { showFullBody: boolean }> {

    constructor(props) {
        super(props);

        this.state = {
            showFullBody: false
        };
    }

    private handleToggle = (): void => {
        this.setState({
            showFullBody: !this.state.showFullBody
        });
    }

    render() {

        const { dateModified, noteBody, noteHeader, noteType, userCreated } = this.props;

        let noteClass = 'note';

        let noteBodyClass = 'note__body';
        let noteHeaderClass = 'note__header h3';
        let noteShowMore = 'hidden';
        let userCreatedClass = '';

        switch (noteType) {
            case NoteType.Plain:
                noteClass = `${noteClass} note--plain`;
                break;
            case NoteType.Highlight:
                noteClass = `${noteClass} note--highlight`;
                break;
            case NoteType.Important:
                noteClass = `${noteClass} note--important`;
                break;
            default:
                noteClass = `${noteClass} note--plain`;
                break;
        }

        if (ObjectHelper.isUndefinedOrNull(noteHeader) || noteHeader.length === 0) {
            noteHeaderClass = `${noteHeaderClass} hidden`;
        }

        if (ObjectHelper.isUndefinedOrNull(userCreated) || userCreated.length === 0) {
            userCreatedClass = 'hidden';
        }

        if (noteBody.length > 50) {
            noteBodyClass = `${noteBodyClass} note__body--fade`;
            noteShowMore = `note__show-more`;
        }

        const dateAndTime: IDateWithTimeDisplayProps = {
            value: dateModified
        };

        const showMoreBtnProps: ISimpleButtonProps = {
            onClick: this.handleToggle,
            buttonText: 'Show More'
        };

        if (this.state.showFullBody) {

            noteBodyClass = 'note__body';
            showMoreBtnProps.buttonText = 'Show Less';
        }

        return (
            <div className={noteClass}>
                <header className={noteHeaderClass}>{noteHeader}</header>
                <p className={noteBodyClass}>
                    {noteBody}
                </p>
                <div className={noteShowMore}>
                    <LinkButton {...showMoreBtnProps} />
                </div>
                <footer className="note__footer">
                    <DateWithTimeDisplay {...dateAndTime} /><span className={userCreatedClass}> by {userCreated}</span>
                </footer>
            </div>
        );
    }
}

export {
    INotesProps,
    Notes
};
