// Utility References
import {
    IApiCall,
    IApiCallWithPayload,
    IGenericQueryApi
} from 'Utility/IndexOfInterfaces';

// App References
import {
    IApiBidHistoryCollection as ICollection,
    IApiBidHistoryOptions as IOptions,
    IApiBidHistoryProvisionalViewModel as IProvisional,
    IApiBidHistoryUpsertViewModel as IUpsert
} from 'App/IndexOfInterfaces';

import { Actions, AppService, HttpVerbs } from 'App/IndexOfServices';

const controller = AppService().apiController('BidHistory');

const queryService: IGenericQueryApi<ICollection, IOptions, null, IUpsert> = {
    get: (id: number): IApiCall<IUpsert> => {
        return {
            method: HttpVerbs.GET,
            url: `${controller}/${id}`
        };
    },
    search: (): IApiCallWithPayload<IOptions, ICollection> => {
        return {
            method: HttpVerbs.POST,
            url: `${controller}/${Actions.SEARCH}`
        };
    },
    template: (listingId: number): IApiCall<IUpsert> => {
        return {
            method: HttpVerbs.GET,
            url: `${controller}/${Actions.TEMPLATE}/${listingId}`
        };
    },
    tools: null
};

const actionService = {
    accept: (): IApiCallWithPayload<FormData, void> => {
        return {
            method: HttpVerbs.POST,
            url: `${controller}/Accept`
        };
    },
    decline: (): IApiCallWithPayload<FormData, void> => {
        return {
            method: HttpVerbs.POST,
            url: `${controller}/Decline`
        };
    },
    save: (): IApiCallWithPayload<FormData, void> => {
        return {
            method: HttpVerbs.POST,
            url: `${controller}`
        };
    }
};

export {
    queryService as BidHistoryQueryService,
    actionService as BidHistoryActionService
};
