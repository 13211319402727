// 3rd Party References
import * as React from 'react';

interface ICardFooterProps extends React.Props<ICardFooterProps> {
    mutedText?: boolean;
}

const CardFooter = (props: ICardFooterProps): JSX.Element => {

    const { mutedText } = props;

    const mutedTextClass = mutedText ? 'text-muted' : '';

    return (
        <div className={`card-footer ${mutedTextClass}`}>
            {props.children}
        </div>
    );
};

export {
    CardFooter,
    ICardFooterProps
};
