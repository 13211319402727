// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from '../../IndexOfActions';
//import {  } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
//import {  } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

// Components
import { ISimpleDateTimeInputProps, SimpleDateTimeInput } from '../Inputs/SimpleDateTimeInput';

interface ISimpleDateRangeInputProps extends React.Props<SimpleDateRangeInput> {
    isDisabled?: boolean;
    fromClassName: string;
    fromDate: Date;
    fromDateChange: Handler.Action1<Date>;
    showTimeSelect?: boolean;
    toClassName: string;
    toDate: Date;
    toDateChange: Handler.Action1<Date>;
    wrappingClassName: string;
}

class SimpleDateRangeInput extends React.Component<ISimpleDateRangeInputProps, {}> {

    render() {

        const { isDisabled, fromClassName, fromDate, fromDateChange, showTimeSelect, toClassName, toDate, toDateChange, wrappingClassName } = this.props;

        const fromDateProps: ISimpleDateTimeInputProps = {
            isDisabled,
            maxDate: toDate,
            onChangeCallback: fromDateChange,
            placeholder: 'From',
            showTimeSelect,
            value: fromDate
        };

        const toDateProps: ISimpleDateTimeInputProps = {
            isDisabled,
            minDate: fromDate,
            onChangeCallback: toDateChange,
            placeholder: 'To',
            showTimeSelect,
            value: toDate
        };

        return (
            <div className={wrappingClassName}>
                <div className={fromClassName}>
                    <SimpleDateTimeInput {...fromDateProps} />
                </div>
                <div className={toClassName}>
                    <SimpleDateTimeInput {...toDateProps} />
                </div>
            </div>
        );
    }
}

export {
    ISimpleDateRangeInputProps,
    SimpleDateRangeInput
};
