import { PanelSize } from 'Utility/IndexOfEnums';

// 3rd Party References
import * as React from 'react';

export interface IPanelProps extends React.Props<IPanelProps>{
    panelSize?: PanelSize;
}

const panel = (props: IPanelProps) => {

    const { panelSize } = props;

    let defaultClasses = 'panel panel-default';

    switch (panelSize) {
        case PanelSize.Narrow:
            defaultClasses += ' panel--narrow';
            break;
        default:
            break;
    }

    return (
        <div className={defaultClasses}>
            {props.children}
        </div>
    );
};

const panelBody = (props: IPanelProps) => {

    return (
        <div className="panel-body">
            {props.children}
        </div>
    );
};

const panelFooter = (props: IPanelProps) => {

    return (
        <div className="panel-footer clearfix">
            {props.children}
        </div>
    );
};

export {
    panel as Panel,
    panelBody as PanelBody,
    panelFooter as PanelFooter
};
