// 3rd Party
import * as React from 'react';
import { hot } from 'react-hot-loader';

// Utility References
import { Handler } from 'Utility/IndexOfActions';
import {
    Callout,
    CalloutBody,
    CalloutTitle,
    CancelButton,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    ExclamationTriangleIcon,
    FormGroupRowWrapper,
    GridData,
    GridDataHead,
    GridDataRow,
    GridMain,
    ICancelButtonProps,
    IFormGroupWrapperProps,
    IGridDataHeadProps,
    IGridDataRowProps,
    InfoButton,
    InfoCircleIcon,
    IOkButtonProps,
    ISimpleButtonProps,
    OkButton,
    PrimaryButton,
    SimpleFileUploadInput,
    ToolTip
} from 'Utility/IndexOfComponents';
import { CalloutStyle, GridColumnWidth } from 'Utility/IndexOfEnums';
import { FormControlHelper, FormHelper, ObjectHelper } from 'Utility/IndexOfHelpers';
import { GridFilterContext } from 'Utility/IndexOfModels';
import { IModalService } from 'Utility/IndexOfServices';

// App References
import { toCurrencyDisplay } from 'App/Helpers/CurrencyHelper';
import { CreateValidatableDocumentFields } from 'App/Helpers/DocumentUploadValidationHelper';
import {
    IApiBidHistoryCollection as ICollection,
    IApiBidHistoryProvisionalViewModel as IViewModel,
    IApiBidHistoryRejectViewModel as IReject,
    IApiBidHistorySummaryViewModel as ISummary
} from 'App/IndexOfInterfaces';
import {
    BidHistoryColumnNames as ColumnNames,
    BidHistoryDefaultOptions,
    BidHistoryDetailsModal,
    BidHistoryLooserReject,
    Constants,
    IBidHistoryActions,
    IBidHistoryDetailsModalProps,
    IBidHistoryLooserRejectProps,
    IBidHistoryState,
    IPageActions,
    IPageState,
    ModalNames
} from 'App/IndexOfModels';
import { IRequiredField } from 'Utility/IndexOfInterfaces';

interface IPropActions {
    bidHistoryActions: IBidHistoryActions;
    onAccept: Handler.Action;
    onDecline: Handler.Action;
    pageActions: IPageActions;
}

interface IPropData {
    adminComments: string;
    bidHistoryState: IBidHistoryState;
    pageState: IPageState;
    supplyRequestListingId: number;
}

interface IListProps {
    propActions: IPropActions;
    propData: IPropData;
}

interface IListState {
    acceptedBidHistoryId: number;
    declineSelected: boolean;
    selectedBidHistoryId: number;
}

class List extends React.Component<IListProps, IListState>{

    private purchaseOrderDocumentFile: SimpleFileUploadInput;

    constructor(props: IListProps){
        super(props);

        this.state = {
            acceptedBidHistoryId: null,
            declineSelected: false,
            selectedBidHistoryId: 0
        };
    }

    componentDidMount() {

        const { propActions, propData } = this.props;

        this.initialiseSearch(propActions.bidHistoryActions, propActions.pageActions, propData.supplyRequestListingId, propData.pageState);

        const { provisional: accept } = propData.bidHistoryState;

        accept.supplyRequestListingId = propData.supplyRequestListingId;
        propActions.bidHistoryActions.receiveProvisionalAsync(accept);
    }

    private initialiseSearch = (actions: IBidHistoryActions, pageActions: IPageActions, listingId: number, page: IPageState, reset?: boolean) => {

        let updatedOptions = BidHistoryDefaultOptions(listingId);

        updatedOptions = pageActions.initialiseStickyOptions(reset, updatedOptions, page.currentOptions);

        actions.receiveOptions(updatedOptions);
        actions.searchAsync(updatedOptions);
    }

    private dataRow = (summary: ISummary): IGridDataRowProps => {

        return {
            dataPoints: [
                toCurrencyDisplay(summary.bidAmount),
                summary.supplyingColourNames.join(', '),
                summary.deliveryTimeFrame,
                summary.dealerName
            ],
            hasActions: true,
            key: summary.bidHistoryId,
            rowId: summary.bidHistoryId,
        };
    }

    private getRequiredFieldsForValidation(viewModel: IViewModel): IRequiredField[] {

        const requiredFields: IRequiredField[] = [];

        const validatableDocumentFields = CreateValidatableDocumentFields(() => viewModel.purchaseOrderDocument, false);

        return requiredFields.concat(validatableDocumentFields);
    }

    private getGridDataRowFactory = (modalService: IModalService, propActions: IPropActions, propData: IPropData) => (collection: ICollection): JSX.Element[] => {

        if (collection) {

            const { provisional } = propData.bidHistoryState;

            let clonedCollection = ObjectHelper.deepClone(collection.results);

            if (!ObjectHelper.isUndefinedOrNull(this.state.acceptedBidHistoryId)){

                clonedCollection = clonedCollection.filter((summary: ISummary) => summary.bidHistoryId === this.state.acceptedBidHistoryId);
           }

            return (

                clonedCollection.map((summary: ISummary) => {

                    const detailButtonProps: ISimpleButtonProps = {
                        buttonText: 'Info',
                        isDisabled: propData.pageState.processing,
                        onClick: () => {

                            this.setState({ selectedBidHistoryId: summary.bidHistoryId });

                            modalService.toggleModal();
                        }
                    };

                    const acceptButtonProps: ISimpleButtonProps = {
                        buttonText: 'Accept',
                        isDisabled: propData.pageState.processing,
                        isHidden: !ObjectHelper.isUndefinedOrNull(this.state.acceptedBidHistoryId),
                        onClick: () => {

                            this.setState({ acceptedBidHistoryId: summary.bidHistoryId });

                            provisional.bidHistoryId = summary.bidHistoryId;
                            provisional.rejections = clonedCollection
                                .filter(x => x.bidHistoryId !== summary.bidHistoryId)
                                .map(x => {

                                    const rejection: IReject = {
                                        bidHistoryId: x.bidHistoryId,
                                        reasonId: null
                                    };

                                    return rejection;
                                });

                            propActions.bidHistoryActions
                                .receiveProvisionalAsync(provisional);
                        }
                    };

                    return (
                        <GridDataRow className={!ObjectHelper.isUndefinedOrNull(this.state.acceptedBidHistoryId) ? 'alert-success' : ''} {...this.dataRow(summary)}>
                                <div className="row mr-0 ml-0">
                                    {
                                        summary.dealReversed &&
                                        <div className="col-sm-3 pr-0 align-self-center">
                                            <ToolTip.Wrapper position={ToolTip.ToolTipPosition.Left}>
                                                <span className="d-inline-block text-warning">
                                                    <ExclamationTriangleIcon textClass="fa-2x"/>
                                                </span>
                                                <ToolTip.Display>
                                                    <div>
                                                        This offer has been previously reversed.
                                                    </div>
                                                </ToolTip.Display>
                                            </ToolTip.Wrapper>
                                        </div>
                                    }
                                    {
                                        ObjectHelper.isUndefinedOrNull(this.state.acceptedBidHistoryId) &&
                                        <div className="col-sm-4 pl-1">
                                            <PrimaryButton {...acceptButtonProps} />
                                        </div>
                                    }
                                    <div className="col-sm-4">
                                        <InfoButton {...detailButtonProps} >
                                            <InfoCircleIcon />
                                        </InfoButton>
                                    </div>
                                </div>
                        </GridDataRow>
                    );
                })
            );
        }
    }

    render() {

        const { propActions, propData } = this.props;
        const { collection, options } = propData.bidHistoryState;

        const { provisional: accept } = propData.bidHistoryState;

        const gridFilterContext = GridFilterContext(null, options);

        const bidDetailsModal = propActions.pageActions.createModalService(ModalNames.BidHistoryDetailsModal, propData.pageState.pageModals);

        const bidDetailsModalProps: IBidHistoryDetailsModalProps = {
            propActions: {
                get: propActions.bidHistoryActions.getAsync
            },
            propData: {
                bidHistoryUpsert: propData.bidHistoryState.upsert,
                bidHistoryId: this.state.selectedBidHistoryId,
                modalService: bidDetailsModal,
            }
        };

        const gridDataHeadProps: IGridDataHeadProps = {
            actionsColumnWidth: GridColumnWidth.Tiny,
            columnNameSets: ColumnNames(),
            gridFilterContext
        };

        const confirmAcceptButtonProps: IOkButtonProps = {
            cssClass: 'btn-block',
            buttonText: 'Confirm Accept',
            isDisabled: propData.pageState.processing,
            isHidden: ObjectHelper.isUndefinedOrNull(this.state.acceptedBidHistoryId),
            onClick: () => {

                const { pageActions: { clearAll, addError } } = propActions;

                const clonedModel = ObjectHelper.deepClone(accept);
                clonedModel.purchaseOrderDocument = this.purchaseOrderDocumentFile.getFiles()[0];

                if (FormHelper.areRequiredPropertiesValid(clearAll, addError, this.getRequiredFieldsForValidation(clonedModel))) {

                    propActions.bidHistoryActions
                        .acceptAsync(clonedModel)
                        .then(() => {

                            if (!ObjectHelper.isUndefinedOrNull(propActions.onAccept)){
                                propActions.onAccept();
                            }
                        });
                }
            }
        };

        const confirmDeclineButtonProps: IOkButtonProps = {
            cssClass: 'btn-block',
            buttonText: 'Confirm Decline',
            isDisabled: propData.pageState.processing,
            isHidden: !this.state.declineSelected,
            onClick: () => {

                propActions.bidHistoryActions
                    .declineAsync(propData.bidHistoryState.provisional)
                    .then(() => {

                        if (!ObjectHelper.isUndefinedOrNull(propActions.onDecline)){
                            propActions.onDecline();
                        }
                    });
            }
        };

        const cancelActionButtonProps: ICancelButtonProps = {
            cssClass: 'btn-block',
            buttonText: 'Cancel',
            isDisabled: propData.pageState.processing,
            onClick: () => {

                this.setState({ acceptedBidHistoryId: null });
                this.setState({ declineSelected: false });

                accept.bidHistoryId = null;
                accept.rejections = [];
                propActions.bidHistoryActions.receiveProvisionalAsync(accept);
            }
        };

        const declineAllButtonProps: ISimpleButtonProps = {
            buttonText: 'Decline All',
            isDisabled: propData.pageState.processing,
            isHidden: this.state.declineSelected || !ObjectHelper.isUndefinedOrNull(this.state.acceptedBidHistoryId),
            onClick: () => {

                this.setState({ acceptedBidHistoryId: null });
                this.setState({ declineSelected: true });

                accept.rejections = collection.results
                    .map(x => {

                        const rejection: IReject = {
                            bidHistoryId: x.bidHistoryId,
                            reasonId: null
                        };

                        return rejection;
                    });

                propActions.bidHistoryActions
                    .receiveProvisionalAsync(accept);
            }
        };

        const looserRejectProps: IBidHistoryLooserRejectProps = {
            propActions: {
                updateRejectionsFunc: propActions.bidHistoryActions.receiveRejectionsAsync
            },
            propData: {
                rejectedBids: collection && collection.results.filter(x => x.bidHistoryId !== accept.bidHistoryId),
                rejections: accept.rejections,
                rejectOptions: propData.bidHistoryState.upsert && propData.bidHistoryState.upsert.tools.bidRejectReasonOptions,
                showHeader: this.state.declineSelected
            }
        };

        const purchaseOrderDocumentFormGroupWrapperProps = (): IFormGroupWrapperProps => {

            return FormControlHelper.getFormGroupWrapperProps(
                FormControlHelper.getContentWidths(
                    'col-xs-12 col-md-6',
                    'error',
                    'col-xs-12',
                    'col-xs-12 col-md-6 col-form-label'
                ),
                this.props.propData.pageState.modelState,
                'Purchase Order Document',
                ObjectHelper.getPropertyName(() => accept.purchaseOrderDocument),
                false
            );
        };

        const BidHistoryGrid = (): JSX.Element =>

            <GridMain isStriped={this.state.acceptedBidHistoryId === 0}>
                <GridDataHead {...gridDataHeadProps} />
                <GridData collection={collection} dataRowFactory={this.getGridDataRowFactory(bidDetailsModal, propActions, propData)} />
            </GridMain>;

        const NoItemsToDisplay = (): JSX.Element =>

            !propData.pageState.processing &&
                <div className="row mt-5">
                    <div className="col d-flex justify-content-center">
                        <h2>No bids have been received for this listing.</h2>
                    </div>
                </div>;

        const ReceivedBids = (): JSX.Element =>

            collection && collection.results.length > 0 ? <BidHistoryGrid /> : <NoItemsToDisplay />;

        return (
            <>
                <Card>
                    <CardHeader>
                        <div className="row">
                            <div className="col-sm-10">
                                <CardTitle text="Received Bids"/>
                            </div>
                            <div className="col-sm-2">
                                <PrimaryButton {...declineAllButtonProps} />
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody>
                        {
                            propData.adminComments &&
                            <Callout calloutStyle={CalloutStyle.Info}>
                                <InfoCircleIcon />
                                <CalloutTitle text="MVR Admin Comments" />
                                <CalloutBody>
                                    <p>{propData.adminComments}</p>
                                </CalloutBody>
                            </Callout>
                        }
                        {
                            !this.state.declineSelected && <ReceivedBids />
                        }
                        {
                            accept.rejections.length > 0 &&
                            <>
                                {
                                    !this.state.declineSelected &&
                                    <hr />
                                }
                                <BidHistoryLooserReject {...looserRejectProps}/>
                            </>
                        }
                        {
                            !ObjectHelper.isUndefinedOrNull(this.state.acceptedBidHistoryId) &&
                            <div className="row mt-2 justify-content-center">
                                <div className="col-sm-6">
                                    <FormGroupRowWrapper {...purchaseOrderDocumentFormGroupWrapperProps()}>
                                        <SimpleFileUploadInput accept={`${Constants.PDFType}, ${Constants.JPEGType}`} ref={(component) => this.purchaseOrderDocumentFile = component} />
                                    </FormGroupRowWrapper>
                                </div>
                            </div>
                        }
                        {
                            (!ObjectHelper.isUndefinedOrNull(this.state.acceptedBidHistoryId) || this.state.declineSelected) &&
                            <div className="row mt-2 mb-2 justify-content-center">
                                <div className="col-sm-3">
                                    <CancelButton {...cancelActionButtonProps} />
                                </div>
                                <div className="col-sm-3">
                                    <OkButton {...(this.state.declineSelected ? confirmDeclineButtonProps : confirmAcceptButtonProps)} />
                                </div>
                            </div>
                        }
                    </CardBody>
                </Card>
                <BidHistoryDetailsModal {...bidDetailsModalProps} />
            </>
        );
    }
}

const HotList = hot(module)(List);

export {
    HotList as BidHistoryList,
    IListProps as IBidHistoryListProps
};
