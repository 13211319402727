// Utility References
import { CultureHelper } from 'Utility/IndexOfHelpers';
import {
    IApiPaginationOptions,
    IApiSortOptions,
    IGenericQueryActionTypes
} from 'Utility/IndexOfInterfaces';

// App References
import {
    IApiSupplyRequestListingFilter as IFilter,
    IApiSupplyRequestListingOptions as IOptions
} from 'App/IndexOfInterfaces';

const defaultOptions = (listingEndpoint: string): IOptions => {

    const defaultFilterOptions: IFilter = {
        listingStatus: 10
    };

    const defaultPaginationOptions: IApiPaginationOptions = {
        maxVisiblePageButtons: 5,
        pageNumber: 1,
        pageSize: 10
    };

    const defaultSortOptions: IApiSortOptions = {
        sortField: 'EndDate',
        sortOrderAsc: true
    };

    const defaultOptions: IOptions = {
        cultureOptions: CultureHelper.getCultureInfo(),
        endpoint: listingEndpoint,
        filterOptions: defaultFilterOptions,
        paginationOptions: defaultPaginationOptions,
        sortOptions: defaultSortOptions,
        type: 'IApiSupplyRequestListingOptions'
    };

    return defaultOptions;
};

const queryActionTypes: IGenericQueryActionTypes = {
    receive: {
        collection: 'SupplyRequestListing.Management.Collection',
        options: 'SupplyRequestListing.Management.Options',
        tools: 'SupplyRequestListing.Management.Tools',
        upsert: 'SupplyRequestListing.Management.Upsert'
    },
    reset: {
        all: 'SupplyRequestListing.Management.Reset'
    }
};

export {
    defaultOptions as SupplyRequestListingDefaultOptions,
    queryActionTypes as SupplyRequestListingQueryActionTypes
};
