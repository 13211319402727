// 3rd Party References
import * as React from 'react';

// Utility References
//import {  } from '../../IndexOfActions';
//import {  } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
import { NumberHelper } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
import { NumericConverter } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

export enum NumericFormats {
    /**
     * 1000 becomes 1,000.00
     */
    Standard,
    /**
     * No formatting of the number, 1000 is displayed as 1000
     */
    None,
    /**
     * 1000 becomes 1,000
     */
    NoneFractional
}

export interface INumericDisplayProps {
    displayOnFail?: string;
    emptyWhenNull?: boolean;
    format?: NumericFormats;
    value: number;
}

const numericDisplay = (props: INumericDisplayProps): JSX.Element => {

    const { displayOnFail, format, emptyWhenNull, value } = props;

    if (emptyWhenNull && (value === undefined || value === null)) {
        return <div></div>;
    }

    let displayValue: string;
    const defaultValue = displayOnFail ? displayOnFail : '0';

    switch (format) {
        case NumericFormats.None:
            displayValue = NumberHelper.isUndefinedNullOrZero(value) ? displayOnFail : value && value.toString();
            break;

        case NumericFormats.NoneFractional:
            displayValue = NumericConverter(value, defaultValue).toNoneFractionalDisplay();
            break;

        case NumericFormats.Standard:
        default:
            displayValue = NumericConverter(value, defaultValue).toStandardDisplay();
            break;
    }

    const standardClassName = 'simple-display-cell-numeric';
    const valueClassName = (value && value < 0) ? 'negative-value' : 'positive-value';

    const divProps = {
        className: `${ standardClassName } ${ valueClassName }`
    };

    return (
        <div {...divProps}>
            {displayValue}
        </div>
    );
};

export {
    numericDisplay as NumericDisplay
};
