// 3rd Party
import * as React from 'react';
import { hot } from 'react-hot-loader';

// Utility References
import {
    FormGroupWrapper,
    IFormGroupWrapperProps,
    ISimpleReadOnlyInputProps,
    ISimpleTextAreaProps,
    Modal,
    MyModal,
    SimpleReadOnlyInput,
    SimpleTextArea
} from 'Utility/IndexOfComponents';
import { DateHelper, FormControlHelper, ObjectHelper } from 'Utility/IndexOfHelpers';
import { IModalService } from 'Utility/IndexOfServices';

// App References
import { toCurrencyDisplay } from 'App/Helpers/CurrencyHelper';
import {
    IApiBidHistoryUpsertViewModel as IUpsert,
    IApiBidHistoryViewModel as IViewModel
 } from 'App/IndexOfInterfaces';
import { Handler } from 'Utility/IndexOfActions';
import { IContentWidths } from 'Utility/IndexOfInterfaces';

interface IPropActions {
    get: Handler.Action1<number>;
}

interface IPropData {
    bidHistoryId: number;
    bidHistoryUpsert: IUpsert;
    modalService: IModalService;
}

interface IBidHistoryDetailsModalProps {
    propActions: IPropActions;
    propData: IPropData;
}

class Form extends React.Component<IBidHistoryDetailsModalProps, {}>{

    componentDidUpdate(prevProps: IBidHistoryDetailsModalProps){

        const { propActions, propData } = this.props;

        if (prevProps.propData && (prevProps.propData.bidHistoryId !== propData.bidHistoryId)) {

            propActions.get(propData.bidHistoryId);
        }
    }

    private getReadOnlyInputProps = (value: any): ISimpleReadOnlyInputProps => {
        return {
            value: value || ''
        };
    }

    private isViewModelValid = (viewModel: IViewModel): boolean => {

        const hasViewModel = !ObjectHelper.isUndefinedOrNull(viewModel);

        return hasViewModel;
    }

    render() {

        const { propData } = this.props;

        const viewModel = propData && propData.bidHistoryUpsert && propData.bidHistoryUpsert.model;

        if (!this.isViewModelValid(viewModel)) {
            return null;
        }

        const formGroupWrapperPropsDefaults = {
            contentWidths: FormControlHelper.getContentWidths(
                'children',
                'error',
                'col-xs 12 col-md-6',
                'label')
        };

        const formGroupWrapperProps = (
            displayName: string,
            propertyName: string,
            contentWidths?: IContentWidths,
            isRequired?: boolean,
            isHidden?: boolean): IFormGroupWrapperProps => {

            return FormControlHelper.getFormGroupWrapperProps(
                contentWidths || formGroupWrapperPropsDefaults.contentWidths,
                null,
                displayName,
                propertyName,
                isRequired || false,
                isHidden);
        };

        const singleColumnWidths = FormControlHelper.getContentWidths(
            'children',
            'error',
            'col-sm-12',
            'label');

        const threeColumnWidths = FormControlHelper.getContentWidths(
            'children',
            'error',
            'col-sm-4',
            'label');

        const dealerCommentsAreaProps: ISimpleTextAreaProps = {
            isDisabled: true,
            onChangeCallback: null,
            value: viewModel.dealerComments
        };

        return (
            <MyModal
                dialogClassName="modal-dialog-centered"
                onHide={propData.modalService.toggleModal}
                show={propData.modalService.isModalOpen()}
                size="lg">
                <Modal.Header>
                    <Modal.Title>Bid Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-horizontal">
                        <div className="form-row">
                            <FormGroupWrapper {...formGroupWrapperProps('Final Drive Away Price', null, null)}>
                                <SimpleReadOnlyInput {...this.getReadOnlyInputProps(toCurrencyDisplay(viewModel.bidAmount))} />
                            </FormGroupWrapper>

                            <FormGroupWrapper {...formGroupWrapperProps('Dealer Name', null, null)}>
                                <SimpleReadOnlyInput {...this.getReadOnlyInputProps(viewModel.dealerName)} />
                            </FormGroupWrapper>
                        </div>
                        <div className="form-row">
                            <FormGroupWrapper {...formGroupWrapperProps('Delivery Time Frame', null, null)}>
                                <SimpleReadOnlyInput {...this.getReadOnlyInputProps(viewModel.deliveryTimeFrame)} />
                            </FormGroupWrapper>

                            <FormGroupWrapper {...formGroupWrapperProps('Contact Fullname', null, null)}>
                                <SimpleReadOnlyInput {...this.getReadOnlyInputProps(`${viewModel.primaryContactFirstName} ${viewModel.primaryContactLastName}`)} />
                            </FormGroupWrapper>

                        </div>
                        <div className="form-row">
                            <FormGroupWrapper {...formGroupWrapperProps('Supplying Colour Names', null, null)}>
                                <SimpleReadOnlyInput {...this.getReadOnlyInputProps(viewModel.supplyingColourNames.join(', '))} />
                            </FormGroupWrapper>

                            <FormGroupWrapper {...formGroupWrapperProps('Contact Telephone', null, null)}>
                                <SimpleReadOnlyInput {...this.getReadOnlyInputProps(viewModel.primaryContactTelephone)} />
                            </FormGroupWrapper>

                        </div>
                        <div className="form-row">
                            <FormGroupWrapper {...formGroupWrapperProps('Build Date', null, null)}>
                                <SimpleReadOnlyInput {...this.getReadOnlyInputProps(DateHelper.formatDate(viewModel.buildDate, 'MM/YYYY'))} />
                            </FormGroupWrapper>

                            <FormGroupWrapper {...formGroupWrapperProps('Compliance Date', null, null)}>
                                <SimpleReadOnlyInput {...this.getReadOnlyInputProps(DateHelper.formatDate(viewModel.complianceDate, 'MM/YYYY'))} />
                            </FormGroupWrapper>
                        </div>
                        <div className="form-row">
                            <FormGroupWrapper {...formGroupWrapperProps('Dealer Email', null, singleColumnWidths)}>
                                <SimpleReadOnlyInput {...this.getReadOnlyInputProps(viewModel.bidderEmail)} />
                            </FormGroupWrapper>
                        </div>
                        <div className="form-row">
                            <FormGroupWrapper {...formGroupWrapperProps('Discount off RRP', null, threeColumnWidths)}>
                                <SimpleReadOnlyInput {...this.getReadOnlyInputProps(toCurrencyDisplay(viewModel.discountAmount))} />
                            </FormGroupWrapper>

                            <FormGroupWrapper {...formGroupWrapperProps('Options/Accessories Total', null, threeColumnWidths)}>
                                <SimpleReadOnlyInput {...this.getReadOnlyInputProps(toCurrencyDisplay(viewModel.accessoriesOptionsTotal))} />
                            </FormGroupWrapper>

                            <FormGroupWrapper {...formGroupWrapperProps('Pre-Delivery', null, threeColumnWidths)}>
                                <SimpleReadOnlyInput {...this.getReadOnlyInputProps(toCurrencyDisplay(viewModel.preDelivery))} />
                            </FormGroupWrapper>
                        </div>
                        <div className="form-row">
                            <FormGroupWrapper {...formGroupWrapperProps('Registration 12 Months', null, threeColumnWidths)}>
                                <SimpleReadOnlyInput {...this.getReadOnlyInputProps(toCurrencyDisplay(viewModel.registration12Months))} />
                            </FormGroupWrapper>

                            <FormGroupWrapper {...formGroupWrapperProps('Stamp Duty', null, threeColumnWidths)}>
                                <SimpleReadOnlyInput {...this.getReadOnlyInputProps(toCurrencyDisplay(viewModel.stampDuty))} />
                            </FormGroupWrapper>

                            <FormGroupWrapper {...formGroupWrapperProps('GST', null, threeColumnWidths)}>
                                <SimpleReadOnlyInput {...this.getReadOnlyInputProps(toCurrencyDisplay(viewModel.gst))} />
                            </FormGroupWrapper>
                        </div>
                        <div className="form-row">
                            <FormGroupWrapper {...formGroupWrapperProps('Comments', null, singleColumnWidths)}>
                                <SimpleTextArea {...dealerCommentsAreaProps} />
                            </FormGroupWrapper>
                        </div>
                        <div className="form-row">
                            <FormGroupWrapper {...formGroupWrapperProps('Quotation Document', null, null)}>
                            {
                                viewModel.quotationDocumentUrl ?
                                <a href={viewModel.quotationDocumentUrl} target="_blank">Click here to open</a> :
                                <i>No document has been uploaded.</i>
                            }
                            </FormGroupWrapper>
                        </div>
                    </div>
                </Modal.Body>
            </MyModal>
        );
    }
}

const HotForm = hot(module)(Form);

export {
    HotForm as BidHistoryDetailsModal,
    IBidHistoryDetailsModalProps
};
