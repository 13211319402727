// 3rd Party References
import * as React from 'react';

export interface ITextAreaDisplayProps {
    value: string;
}

const textAreaDisplay = (props: ITextAreaDisplayProps): JSX.Element => {

    const { value } = props;

    return (
        <p className="form-control-static simple-display-text-area">{value}</p>
    );
};

export {
    textAreaDisplay as TextAreaDisplay
};
