const actionTypes = {
    receive: {
        changePassword: 'ChangePassword.Receive.ViewModel',
        submit: 'ChangePassword.Receive.Submit'
    },
    reset: {
        resetForm: 'ChangePassword.Reset.Form'
    }
};

export {
    actionTypes as ChangePasswordActionTypes
};
