// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from '../../IndexOfActions';
//import {  } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
import { HTMLHelper } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

// Local References
import InternalCommonTextInput, { IInternalCommonTextInputProps } from './Internal/InternalCommonTextInput';

export interface ISimpleTelephoneInputProps extends React.Props<SimpleTelephoneInput> {
    appendedInputClassName?: string;
    isDisabled?: boolean;
    isHidden?: boolean;
    onBlurCallback?: (value: string, isValid: boolean, event: React.FocusEvent<HTMLInputElement>) => void;
    onChangeCallback: Handler.StringProperty;
    onKeyPressCallback?: Handler.InputKeyboardEvent;
    placeholder?: string;
    propertyName?: string;
    tabIndex?: number;
    value: string;
}

export class SimpleTelephoneInput extends React.Component<ISimpleTelephoneInputProps, {}> {

    private primaryInput: InternalCommonTextInput;

    public setFocus = (scroll?: number): void => {
        this.primaryInput.setFocus(scroll);
    }

    public isValid = (): boolean => {
        //TODOxUI: Implement this.
        return true;
    }

    render() {

        const { appendedInputClassName, isDisabled, isHidden, onBlurCallback, onChangeCallback, onKeyPressCallback, placeholder, propertyName, tabIndex, value } = this.props;

        const commonInputProps: IInternalCommonTextInputProps = {
            appendedInputClassName,
            isDisabled,
            isHidden,
            maxLength: 30,
            onBlurCallback: (event: React.FocusEvent<HTMLInputElement>) => {

                if (onBlurCallback) {
                    const newValue = (event.target as HTMLInputElement).value;
                    onBlurCallback(newValue, this.isValid(), event);
                }
            },
            onChangeCallback: (event: React.FormEvent<HTMLInputElement>) => {

                const newValue = (event.target as HTMLInputElement).value;
                onChangeCallback(newValue, propertyName);
            },
            onKeyPressCallback,
            placeholder,
            propertyName,
            ref: (component: InternalCommonTextInput) => { this.primaryInput = component; },
            tabIndex,
            type: HTMLHelper.getHtmlInputTypes().tel,
            value
        };

        return <InternalCommonTextInput {...commonInputProps} />;
    }
}
