// Utility References
import { ExceptionHelper } from 'Utility/IndexOfHelpers';
import { IApiException, IApiExceptionGroup, IGenericDictionary } from 'Utility/IndexOfInterfaces';
import { Notification } from 'Utility/IndexOfServices';

const Exception = ExceptionHelper.ApiException;

interface IAuthenticationExceptions extends IGenericDictionary<IApiException> {
    InvalidRoleClaim: IApiException;
    PermissionDenied: IApiException;
    RegistrationNotProcessed: IApiException;
    UserNotAuthenticated: IApiException;
}

const exceptions: IAuthenticationExceptions = {
    InvalidRoleClaim: Exception('You don\'t have permissions to access the back office. Please speak to your administrator to grant access.', Notification.error.handledException),
    PermissionDenied: Exception('Access denied. Please speak to your administrator to grant access.', Notification.error.handledException),
    RegistrationNotProcessed: Exception('Your registration request is still being prcessed, we shall be in touch shortly.', Notification.error.handledException),
    UserNotAuthenticated: Exception('Your username and/or password were not correct. Please check your details and try again.', Notification.error.handledException)
};

const exceptionGroup: IApiExceptionGroup = {
    area: 'Authorization',
    exceptions
};

export {
    exceptionGroup as AuthenticationExceptionGroup,
    exceptions as AuthenticationExceptions
};
