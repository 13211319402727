// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from '../../IndexOfActions';
//import {  } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
import { AnimationHelper } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

export interface ISimpleRadioInputProps extends React.Props<SimpleRadioInput> {
    checked: boolean;
    isDisabled?: boolean;
    isHidden?: boolean;
    name: string;
    onChangeCallback: Handler.BooleanProperty;
    propertyName?: string;
    tabIndex?: number;
    value: boolean;
}

export class SimpleRadioInput extends React.Component<ISimpleRadioInputProps, {}> {

    private primaryInput: HTMLInputElement;

    public setFocus = (scroll?: number): void => {
        AnimationHelper.setFocus(this.primaryInput, scroll);
    }

    render() {

        const { checked, isDisabled, isHidden, name, onChangeCallback, propertyName, tabIndex, value } = this.props;

        const inputProps = {
            checked: checked || false,
            disabled: isDisabled,
            hidden: isHidden,
            onChange: (event: React.FormEvent<HTMLInputElement>) => {
                const newValue = (event.target as HTMLInputElement).value;
                onChangeCallback(newValue === 'true', propertyName);
            },
            id: propertyName,
            name,
            ref: (component: HTMLInputElement) => { this.primaryInput = component; },
            tabIndex,
            value: value && value.toString()
        };

        return <input type="radio" {...inputProps} />;
    }
}
