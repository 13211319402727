// 3rd Party References
import * as React from 'react';

interface IGridPanelProps { }

const gridPanel = (props: React.Props<IGridPanelProps>) => {

    return (
        <div className="panel panel-default">
            {props.children}
        </div>
    );
};

export {
    gridPanel as GridPanel
};
