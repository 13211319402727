// 3rd Party References
import * as React from 'react';

const CalloutBody = (props: React.Props<null>): JSX.Element => {

    return (
        <div className="bd-callout-body">
            {props.children}
        </div>
    );
};

export {
    CalloutBody
};
