// 3rd Party
import * as React from 'react';
import { hot } from 'react-hot-loader';

// Base References
import { BaseView } from '../Base/View';

// App References
import {
    ChangePasswordForm,
    IChangePasswordFormProps
} from 'App/IndexOfModels';

class View extends BaseView<{}> {

    render() {

        const { appActions, appState } = this.props;

        const changePasswordFormProps: IChangePasswordFormProps = {
            propActions: {
                onSubmit: appActions.changePasswordActions.submit,
                pageActions: appActions.pageActions,
                receiveChangePassword: appActions.changePasswordActions.receiveChangePassword
            },
            propData: {
                changePassword: appState.changePassword.changePassword,
                pageState: appState.page
            }
        };

        return (
            <>
                <div className="col-sm-12 mb-3">
                    <h1>Change password</h1>
                    <p>Please enter your current and new password</p>
                </div>

                <ChangePasswordForm {...changePasswordFormProps} />
            </>
        );
    }
}

export {
    View as ChangePasswordView
};
