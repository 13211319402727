// 3rd Party
import * as React from 'react';
import { hot } from 'react-hot-loader';

// Utility References
import {
    FormGroupWrapper,
    IFormGroupWrapperProps,
    ISimpleButtonProps,
    ISimpleSelectStringProps,
    ISimpleStringInputProps,
    ISimpleTextAreaProps,
    Modal,
    MyModal,
    PrimaryButton,
    SimpleSelectString,
    SimpleStringInput,
    SimpleTextArea
} from 'Utility/IndexOfComponents';
import { FormControlHelper, FormHelper, ObjectHelper} from 'Utility/IndexOfHelpers';
import { IRequiredField } from 'Utility/IndexOfInterfaces';
import { ModelUpdater } from 'Utility/IndexOfModels';
import { IModalService } from 'Utility/IndexOfServices';
import { IEnquiryActions } from '../Classes/Actions';

// App References
import {
    IApiEnquiryUpsertViewModel as IUpsert,
    IApiEnquiryViewModel as IViewModel
} from 'App/IndexOfInterfaces';
import { IEnquiryState, IPageActions, IPageState } from 'App/IndexOfModels';

interface IPropActions {
    enquiryActions: IEnquiryActions;
    pageActions: IPageActions;
}

interface IPropData {
    enquiryState: IEnquiryState;
    listingId: number;
    modalService: IModalService;
    pageState: IPageState;
}

interface IEnquiryFormModalProps {
    propActions: IPropActions;
    propData: IPropData;
}

class Form extends React.Component<IEnquiryFormModalProps, {}>{

    componentDidMount() {

        const { propActions } = this.props;

        propActions.enquiryActions.resetAll();
        propActions.enquiryActions.getTemplateAsync();
    }

    private isOtherEnquiryType = (enquiryType: string): boolean => {
        return !ObjectHelper.isUndefinedOrNull(enquiryType) && enquiryType.includes('Other');
    }

    private isUpsertValid = (upsert: IUpsert): boolean => {

        return !ObjectHelper.isUndefinedOrNull(upsert) && !ObjectHelper.isUndefinedOrNull(upsert.model) && !ObjectHelper.isUndefinedOrNull(upsert.tools);
    }

    private getEnquiryForm = (propActions: IPropActions, propData: IPropData): JSX.Element => {

        const { pageState, enquiryState } = propData;
        const { enquiryActions } = propActions;

        const upsert = enquiryState.upsert;

        if (!this.isUpsertValid(upsert)) {
            return null;
        }

        const formProperties = this.getFormPropertyNames(upsert.model);

        const enquiryTypeFormGroupWrapperProps = (): IFormGroupWrapperProps => {

            return FormControlHelper.getFormGroupWrapperProps(
                FormControlHelper.getContentWidths(
                    'children',
                    'error',
                    'input-wrapper',
                    'label'
                ),
                this.props.propData.pageState.modelState,
                'Enquiry Type',
                formProperties.enquiryType,
                true
            );
        };

        const messageFormGroupWrapperProps = (): IFormGroupWrapperProps => {

            return FormControlHelper.getFormGroupWrapperProps(
                FormControlHelper.getContentWidths(
                    'children',
                    'error',
                    'input-wrapper',
                    'sr-only'
                ),
                this.props.propData.pageState.modelState,
                'Message',
                formProperties.enquiryMessage,
                true
            );
        };

        const otherTypeFormGroupWrapperProps = (): IFormGroupWrapperProps => {

            return FormControlHelper.getFormGroupWrapperProps(
                FormControlHelper.getContentWidths(
                    'children',
                    'error',
                    'input-wrapper',
                    'sr-only'
                ),
                this.props.propData.pageState.modelState,
                'Other Type',
                formProperties.otherType,
                true
            );
        };

        const modelUpdater = ModelUpdater(enquiryState.upsert, enquiryActions.receiveUpsert);

        const enquiryTypeSelectProps: ISimpleSelectStringProps = {
            items: enquiryState.upsert.tools.enquiryTypes,
            onChangeCallback: modelUpdater.for<string>((value, upsert) => {

                upsert.model.enquiryType = value;

                return upsert;
            }),
            value: enquiryState.upsert.model.enquiryType
        };

        const otherTypeInputProps: ISimpleStringInputProps = {
            onChangeCallback: modelUpdater.for<string>((value, upsert) => {

                upsert.model.otherType = value;
                return upsert;
            }),
            value: enquiryState.upsert.model.otherType
        };

        const messageInputProps: ISimpleTextAreaProps = {
            onChangeCallback: modelUpdater.for<string>((value, upsert) => {

                upsert.model.message = value;

                return upsert;
            }),
            placeholder: 'Enter your message',
            value: enquiryState.upsert.model.message
        };

        const submitButtonProps: ISimpleButtonProps = {
            appendedClassName: 'btn-block',
            buttonText: 'Submit',
            isDisabled: pageState.processing,
            onClick: () => {

                const clonedModel = ObjectHelper.deepClone(upsert.model);
                this.handleOnSubmit(propActions, propData, clonedModel);
            }
        };

        return (
            <form className="col-sm-12">

                <div className="row mb-2">
                    <div className="col-sm-12">
                        <FormGroupWrapper {...enquiryTypeFormGroupWrapperProps()}>
                            <SimpleSelectString {...enquiryTypeSelectProps} />
                        </FormGroupWrapper>
                    </div>
                </div>
                {
                    this.isOtherEnquiryType(enquiryState.upsert.model.enquiryType) &&
                    <div className="row mb-2">
                        <div className="col-sm-12">
                            <FormGroupWrapper {...otherTypeFormGroupWrapperProps()}>
                                <SimpleStringInput {...otherTypeInputProps} />
                            </FormGroupWrapper>
                        </div>
                    </div>
                }
                <div className="row mb-3">
                    <div className="col-sm-12">
                        <FormGroupWrapper {...messageFormGroupWrapperProps()}>
                            <SimpleTextArea {...messageInputProps} />
                        </FormGroupWrapper>
                    </div>
                </div>
                <div className="row mb-2 justify-content-center">
                    <div className="col-sm-5">
                        <PrimaryButton {...submitButtonProps}/>
                    </div>
                </div>

            </form>
        );
    }

    private getFormPropertyNames = (viewModel: IViewModel) => {

        return {
            enquiryType: ObjectHelper.getPropertyName(() => viewModel.enquiryType),
            enquiryMessage: ObjectHelper.getPropertyName(() => viewModel.message),
            otherType: ObjectHelper.getPropertyName(() => viewModel.otherType)
        };
    }

    private getRequiredFieldsForValidation(viewModel: IViewModel): IRequiredField[] {

        const formProperties = this.getFormPropertyNames(viewModel);

        const requiredFields: IRequiredField[] = [
            {
                errorMessage: 'Please select enquiry type.',
                propertyName: formProperties.enquiryType,
                value: viewModel.enquiryType
            },
            {
                errorMessage: 'Please enter enquiry message.',
                propertyName: formProperties.enquiryMessage,
                value: viewModel.message
            }
        ];

        if (this.isOtherEnquiryType(viewModel.enquiryType)) {

            requiredFields.push({
                errorMessage: 'Please enter enquiry type',
                propertyName: formProperties.otherType,
                value: viewModel.otherType
            });
        }

        return requiredFields;
    }

    private handleOnSubmit = (propActions: IPropActions, propData: IPropData, viewModel: IViewModel): void => {

        const { pageActions: { clearAll, addError } } = propActions;

        if (FormHelper.areRequiredPropertiesValid(clearAll, addError, this.getRequiredFieldsForValidation(viewModel))) {

            viewModel.supplyRequestListingId = propData.listingId;

            if (this.isOtherEnquiryType(viewModel.enquiryType)){
                viewModel.enquiryType = viewModel.otherType;
            }

            propActions.enquiryActions.saveAsync(viewModel);

            if (propData.modalService.isModalOpen()) {
                propData.modalService.toggleModal();
            }
        }
    }

    render() {

        const { propActions, propData } = this.props;

        const enquiryForm = this.getEnquiryForm(propActions, propData);

        return (
            <MyModal
                dialogClassName="modal-dialog-centered"
                onHide={propData.modalService.toggleModal}
                show={propData.modalService.isModalOpen()}>
                <Modal.Header>
                    <Modal.Title>Enquiry</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {enquiryForm}
                </Modal.Body>
            </MyModal>
        );
    }
}

const HotForm = hot(module)(Form);

export {
    HotForm as EnquiryFormModal,
    IEnquiryFormModalProps
};
