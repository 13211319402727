// 3rd Party References
import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';

// Utility References
import { ReactHelper, TranslationHelper } from 'Utility/IndexOfHelpers';
import { IGenericDictionary } from 'Utility/IndexOfInterfaces';

type MouseEvent = (event: React.MouseEvent<HTMLAnchorElement>) => void;

export interface IGridLinkButtonProps extends IGenericDictionary<string | boolean | MouseEvent> {
    ariaDescribedBy?: string;
    buttonLabelOverride?: string;
    cssButtonClass: string;
    cssIconClass: string;
    hideText?: boolean;
    href: string;
    isDisabled?: boolean;
    onMouseOut?: MouseEvent;
    onMouseOver?: MouseEvent;
    standardLabel: string;
    targetNewTab?: boolean;
    useRouter?: boolean;
}

export default class GridLinkButton extends React.Component<IGridLinkButtonProps, {}> {

    private generateLink = (anchorProps: React.HTMLProps<HTMLAnchorElement>, iconClassName: string, buttonTextDisplay: string, useRouter: boolean) => {

        if (useRouter) {

            const linkProps = anchorProps as LinkProps;
            linkProps.to = anchorProps.href;

            return (
                <Link {...linkProps}>
                    <i className={iconClassName}></i>{' '}{buttonTextDisplay}
                </Link>
            );
        }
        else {

            return (
                <a {...anchorProps}>
                    <i className={iconClassName}></i>{' '}{buttonTextDisplay}
                </a>
            );
        }
    }

    private getLabel = (standardLabel: string, buttonLabelOverride?: string, hideText?: boolean) => {

        if (hideText) {
            return '';
        }

        return buttonLabelOverride || standardLabel;
    }

    render() {

        const { ariaDescribedBy, buttonLabelOverride, cssButtonClass, cssIconClass, hideText, href, isDisabled, standardLabel, targetNewTab, onMouseOut, onMouseOver, useRouter } = this.props;

        const buttonLabel = this.getLabel(standardLabel, buttonLabelOverride, hideText);

        let anchorProps: React.HTMLProps<HTMLAnchorElement> = {
            className: `btn btn-sm ${cssButtonClass}`,
            href,
            onMouseOut,
            onMouseOver
        };

        if (isDisabled) {
            anchorProps.className = anchorProps.className + ' disabled';
            anchorProps.onClick = (event: React.MouseEvent<HTMLAnchorElement>) => { event.preventDefault(); };
        }

        if (targetNewTab) {
            anchorProps.target = '_blank';
        }

        anchorProps = ReactHelper.setAttribute(anchorProps).aria('aria-describedby', ariaDescribedBy);

        const iconClassName = `fa ${cssIconClass}`;

        const buttonTextDisplay = TranslationHelper.translate(buttonLabel);

        return (
            this.generateLink(anchorProps, iconClassName, buttonTextDisplay, useRouter || true)
        );
    }
}
