//3rd Party References
import * as React from 'react';

//Utility References
import { Handler } from '../../IndexOfActions';
import { CheckIcon, UncheckIcon } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
import { AnimationHelper, ButtonHelper } from '../../IndexOfHelpers';
import { IButtonOptions } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

export interface ISelectAllButtonProps extends React.Props<SelectAllButton> {
    buttonOptions?: IButtonOptions;
    isDisabled?: boolean;
    onClearAllClick: Handler.Action;
    onSelectAllClick: Handler.Action;
    tabIndex?: number;
}

export default class SelectAllButton extends React.Component<ISelectAllButtonProps, {}> {

    private primaryInput: HTMLButtonElement;

    public setFocus = (scroll?: number): void => {
        AnimationHelper.setFocus(this.primaryInput, scroll);
    }

    private getClearAllToggle = (isDisabled: boolean, onClearAllClick: Handler.Action, tabIndex: number): JSX.Element => {

        const buttonProps = {
            className: 'btn btn-primary btn-xs',
            disabled: isDisabled,
            onClick: onClearAllClick,
            role: 'button',
            tabIndex,
            type: 'button'
        };

        const icon = <UncheckIcon />;

        return <button {...buttonProps}>{icon}</button>;
    }

    private getSelectAllToggle = (isDisabled: boolean, onSelectAllClick: Handler.Action, tabIndex: number): JSX.Element => {

        const buttonProps = {
            className: 'btn btn-primary btn-xs',
            disabled: isDisabled,
            onClick: onSelectAllClick,
            role: 'button',
            ref: (component: HTMLButtonElement) => { this.primaryInput = component; },
            tabIndex,
            type: 'button'
        };

        const icon = <CheckIcon />;

        return <button {...buttonProps}>{icon}</button>;
    }

    render() {

        const { buttonOptions, isDisabled, onClearAllClick, onSelectAllClick, tabIndex } = this.props;

        const defaultButtonOptions: IButtonOptions = {
            buttonText: ''
        };

        const clearAllToggle = this.getClearAllToggle(isDisabled, onClearAllClick, tabIndex);
        const selectAllToggle = this.getSelectAllToggle(isDisabled, onSelectAllClick, tabIndex);

        const divProps = {
            className: ButtonHelper.getButtonClasses(defaultButtonOptions, buttonOptions, 'select-all-button')
        };

        const buttonTextDisplay = ButtonHelper.getButtonText(defaultButtonOptions, buttonOptions);

        return (
            <div {...divProps}>{buttonTextDisplay}
                <div className="btn-group">
                    <div className="btn btn-xs all-label">All: </div>{selectAllToggle}{clearAllToggle}
                </div>
            </div>
        );
    }
}
