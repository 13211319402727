// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from '../../../IndexOfActions';
//import {  } from '../../../IndexOfComponents';
//import {  } from '../../../IndexOfEnums';
//import {  } from '../../../IndexOfHelpers';
import { IApiItemOptionViewModel } from '../../../IndexOfInterfaces';
import { IGridFilterContext } from '../../../IndexOfModels';
//import {  } from '../../../IndexOfServices';

// Local References
import GenericNumberDropdown from '../Internal/GenericNumberDropdown';

type UpdateExpr = <TFilter, TValue>(filter: TFilter, value: TValue) => void;

interface IDropdownFilterProps extends React.Props<DropdownFilter> {
    gridFilterContext: IGridFilterContext;
    isDisabled?: boolean;
    items: IApiItemOptionViewModel[];
    key: string | number;
    label: string;
    updateExpr: UpdateExpr;
    value: number;
}

export default class DropdownFilter extends React.Component<IDropdownFilterProps, {}> {

    private handleChange = (updateFunc: Handler.NumberValue) => (selectedValue: number): void => {

        updateFunc(selectedValue);
    }

    render() {

        const { gridFilterContext, isDisabled, items, label, updateExpr, value } = this.props;

        const updateFunc = gridFilterContext.filterHandler(updateExpr);

        const dropdownProps = {
            displayName: label,
            isDisabled,
            isRequired: false,
            items,
            onChangeCallback: this.handleChange(updateFunc),
            selectClassName: 'form-control grid-filter',
            selectableEmptyValue: 'Any',
            selectedValue: value
        };

        const style = {
            minWidth: '100px'
        };

        return (
            <th>
                <div style={style}>
                    <GenericNumberDropdown {...dropdownProps} />
                </div>
            </th>
        );
    }
}
