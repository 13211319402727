// Utility References
import {
    IGenericQueryActionTypes
} from 'Utility/IndexOfInterfaces';

const actionTypes = {
    reset: {
        enquiryForm: 'Enquiry.Management.ResetEnquiryForm'
    }
};

const queryActionTypes: IGenericQueryActionTypes = {
    receive: {
        collection: 'Enquiry.Management.Collection',
        options: 'Enquiry.Management.Options',
        tools: 'Enquiry.Management.Tools',
        upsert: 'Enquiry.Management.Upsert'
    },
    reset: {
        all: 'Enquiry.Management.Reset'
    }
};

export {
    actionTypes as EnquiryActionTypes,
    queryActionTypes as EnquiryQueryActionTypes
};
