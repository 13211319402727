//3rd Party References
import * as React from 'react';

//Utility References
import { Handler } from 'Utility/IndexOfActions';
import { AnimationHelper, StringHelper, TranslationHelper } from 'Utility/IndexOfHelpers';

export interface IInternalCommonTextInputProps extends React.Props<InternalCommonTextInput> {
    appendedInputClassName?: string;
    isDisabled: boolean;
    isHidden: boolean;
    maxLength: number;
    onBlurCallback: Handler.InputFocusEvent;
    onChangeCallback: Handler.InputFormEvent;
    onKeyPressCallback: Handler.InputKeyboardEvent;
    placeholder: string;
    propertyName: string;
    tabIndex: number;
    type?: string;
    value: string;
}

export default class InternalCommonTextInput extends React.Component<IInternalCommonTextInputProps, {}> {

    private primaryInput: HTMLInputElement;

    public setFocus = (scroll?: number): void => {
        AnimationHelper.setFocus(this.primaryInput, scroll);
    }

    render() {

        const { appendedInputClassName, isDisabled, isHidden, maxLength, onBlurCallback, onChangeCallback, onKeyPressCallback, placeholder, propertyName, tabIndex, type, value } = this.props;

        let inputClassName = isHidden ? 'form-control hidden' : 'form-control';

        if (appendedInputClassName) {
            inputClassName = inputClassName + ' ' + appendedInputClassName;
        }

        const translatedPlaceholder = TranslationHelper.translate(placeholder);
        const internalStringValue = StringHelper.isUndefinedNullOrEmpty(value) ? '' : value;

        const inputProps = {
            autoComplete: 'off',
            className: inputClassName,
            disabled: isDisabled,
            id: propertyName,
            maxLength,
            onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
                if (onBlurCallback) {
                    onBlurCallback(event);
                }
            },
            onChange: onChangeCallback,
            onKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => {
                if (onKeyPressCallback) {
                    onKeyPressCallback(event);
                }
            },
            placeholder: translatedPlaceholder,
            ref: (component: HTMLInputElement) => { this.primaryInput = component; },
            tabIndex,
            type: type || 'text',
            value: internalStringValue
        };

        return <input {...inputProps} />;
    }
}
