// 3rd Party References
import * as React from 'react';
import { hot } from 'react-hot-loader';

// Utility References
import { Handler } from 'Utility/IndexOfActions';
import {
    FormGroupWrapper,
    IFormGroupWrapperProps,
    ISimpleButtonProps,
    ISimpleStringInputProps,
    KeyIcon,
    SimpleStringInput,
    SuccessButton
} from 'Utility/IndexOfComponents';
import { ButtonStyle } from 'Utility/IndexOfEnums';
import { FormControlHelper, FormHelper, ObjectHelper } from 'Utility/IndexOfHelpers';
import { IContentWidths, IRequiredField } from 'Utility/IndexOfInterfaces';
import { ModelUpdater } from 'Utility/IndexOfModels';

// App References
import {
    IApiResetPasswordViewModel as IViewModel
} from 'App/IndexOfInterfaces';
import {
    IPageActions,
    IPageState
} from 'App/IndexOfModels';

interface IPropActions {
    onSubmit: Handler.Action1<IViewModel>;
    pageActions: IPageActions;
    receiveResetPassword: Handler.Action1<IViewModel>;
}

interface IPropData {
    resetPassword: IViewModel;
    pageState: IPageState;
}

interface IResetPasswordFormProps {
    propActions: IPropActions;
    propData: IPropData;
}

class Form extends React.Component<IResetPasswordFormProps, {}> {

    private token: string;
    private userId: number;

    componentDidMount() {

        const urlSearchParams = new URLSearchParams(window.location.search.substring(1));

        this.token = urlSearchParams.get('t');
        this.userId = parseInt(urlSearchParams.get('u'));
    }

    private getFormPropertyNames = (viewModel: IViewModel) => {

        return {
            password: ObjectHelper.getPropertyName(() => viewModel.password),
            passwordConfirmation: ObjectHelper.getPropertyName(() => viewModel.passwordConfirmation)
        };
    }

    private handleOnSubmit = (propActions: IPropActions, propData: IPropData, viewModel: IViewModel): void => {

        const { pageActions: { clearAll, addError } } = propActions;

        if (FormHelper.areRequiredPropertiesValid(clearAll, addError, this.getRequiredFieldsForValidation(viewModel))) {

            viewModel.token = this.token;
            viewModel.userId = this.userId;

            propActions.onSubmit(viewModel);
        }
    }

    private isViewModelValid = (viewModel: IViewModel): boolean => {

        const hasViewModel = !ObjectHelper.isUndefinedOrNull(viewModel);

        return hasViewModel;
    }

    private getRequiredFieldsForValidation(viewModel: IViewModel): IRequiredField[] {

        const formProperties = this.getFormPropertyNames(viewModel);

        return [
            {
                errorMessage: 'Please enter a new password.',
                propertyName: formProperties.password,
                value: viewModel.password
            },
            {
                errorMessage: 'Please confirm your password.',
                propertyName: formProperties.passwordConfirmation,
                value: viewModel.passwordConfirmation
            },
            {
                errorMessage: 'Your passwords don\'t match.',
                propertyName: formProperties.passwordConfirmation,
                value: viewModel.passwordConfirmation,
                validate: (value): boolean => viewModel.password === value
            }
        ];
    }

    render() {

        const { propActions, propData } = this.props;

        const viewModel = propData.resetPassword;

        if (!this.isViewModelValid(viewModel)) {
            return null;
        }

        const formProperties = this.getFormPropertyNames(viewModel);

        const formGroupWrapperPropsDefaults = {
            contentWidths: FormControlHelper.getContentWidths(
                'children',
                'error',
                'input-wrapper',
                'sr-only')
        };

        const formGroupWrapperProps = (
            displayName: string,
            propertyName: string,
            contentWidths?: IContentWidths,
            isRequired?: boolean): IFormGroupWrapperProps => {

            return FormControlHelper.getFormGroupWrapperProps(
                contentWidths || formGroupWrapperPropsDefaults.contentWidths,
                this.props.propData.pageState.modelState,
                displayName,
                propertyName,
                isRequired || false);
        };

        const genericSimpleStringInputProps = (propertyName: string, value: string, maxLength: number = 100, namespace?: string, isDisabled?: boolean): ISimpleStringInputProps => {
            return {
                isDisabled,
                maxLength,
                onChangeCallback: modelUpdater.for<string>((value, model) => {
                    return updateModel(model, value, propertyName, namespace);
                }),
                value
            };
        };

        const modelUpdater = ModelUpdater(propData.resetPassword, propActions.receiveResetPassword);

        const updateModel = (viewModel: IViewModel, value: boolean | Date | number | string | string[], propertyName: string, namespace?: string): IViewModel => {

            const property = namespace ? viewModel[namespace] : viewModel;

            propActions.pageActions.removeEntry(property[propertyName]);
            property[propertyName] = value;

            return viewModel;
        };

        const passwordProps: ISimpleStringInputProps = {
            ...genericSimpleStringInputProps(formProperties.password, viewModel.password, 100, null),
            ...{
                password: true,
                placeholder: 'Enter your new password'
            }
        };

        const passwordConfirmProps: ISimpleStringInputProps = {
            ...genericSimpleStringInputProps(formProperties.passwordConfirmation, viewModel.passwordConfirmation, 100, null),
            ...{
                password: true,
                placeholder: 'Re-enter your new password'
            }
        };

        const submitButtonProps: ISimpleButtonProps = {
            buttonOptions: {
                buttonStyle: ButtonStyle.SuccessOutline,
                buttonText: 'Change Password'
            },
            onClick: () => {

                this.handleOnSubmit(propActions, propData, viewModel);
            },
            processing: propData.pageState.processing
        };

        return (
            <form className="form-password-reset col-md-4">

                <FormGroupWrapper {...formGroupWrapperProps('Password', formProperties.password, null, true)}>
                    <SimpleStringInput {...passwordProps} />
                </FormGroupWrapper>

                <FormGroupWrapper {...formGroupWrapperProps('Confirm Password', formProperties.passwordConfirmation, null, true)}>
                    <SimpleStringInput {...passwordConfirmProps} />
                </FormGroupWrapper>

                <SuccessButton {...submitButtonProps}>
                    <KeyIcon />
                </SuccessButton>

            </form>
        );
    }
}

const HotForm = hot(module)(Form);

export {
    HotForm as ResetPasswordForm,
    IResetPasswordFormProps
};
