// 3rd Party References
import * as React from 'react';

// Utility References
import { FormGroup, IFormGroupProps, ISimpleLabelProps, SimpleLabel } from 'Utility/IndexOfComponents';
import { IContentWidths, IModelState } from 'Utility/IndexOfInterfaces';

interface IFormGroupWrapperProps extends React.Props<JSX.Element> {
    className?: string;
    contentWidths?: IContentWidths;
    displayName?: string;
    isHidden?: boolean;
    isRequired?: boolean;
    modelState?: IModelState;
    propertyName: string;
}

const formGroupWrapper = (props: IFormGroupWrapperProps) => {

    const { className, contentWidths, displayName, isHidden, isRequired, modelState, propertyName } = props;

    if (isHidden) {
        return null;
    }

    const formGroupProps: IFormGroupProps = {
        className: `${className} ${(contentWidths && contentWidths.formGroup)}` || 'col-xs-12',
        propertyName,
        useErrorDisplay: false
    };

    if (modelState) {
        formGroupProps.modelState = modelState;
        formGroupProps.modelStateClassName = (contentWidths && contentWidths.error) || 'col-xs-12 col-md-5 col-md-offset-3';
        formGroupProps.useErrorDisplay = true;
    }

    const labelProps: ISimpleLabelProps = {
        cssClassName: (contentWidths && contentWidths.label) || 'col-xs-12 col-md-3',
        displayName: null,
        fieldName: propertyName,
        isRequired
    };

    if (displayName) {
        labelProps.displayName = displayName;
    }

    const divProps = {
        className: (contentWidths && contentWidths.children) || 'col-xs-12 col-md-9'
    };

    return (
        <FormGroup {...formGroupProps}>
            <SimpleLabel {...labelProps} />
            <div {...divProps}>
                {props.children}
            </div>
        </FormGroup>
    );
};

const formGroupWrapperHot = formGroupWrapper;

export {
    formGroupWrapperHot as FormGroupWrapper,
    IFormGroupWrapperProps
};
