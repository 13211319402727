// Utility References
import { GridColumnWidth } from 'Utility/IndexOfEnums';
import {
    IApiPaginationOptions,
    IApiSortOptions,
    IGenericQueryActionTypes,
    IGridColumnNameSet
} from 'Utility/IndexOfInterfaces';

// App References
import {
    IApiBidHistoryFilter as IFilter,
    IApiBidHistoryOptions as IOptions
} from 'App/IndexOfInterfaces';
import { CultureHelper } from 'Utility/IndexOfHelpers';

const columnNames = (): IGridColumnNameSet[] => {
    return [
        { columnWidth: GridColumnWidth.Short, fieldName: 'bidAmount', labelText: 'Bid Amount' },
        { columnWidth: GridColumnWidth.Short, fieldName: 'colours', labelText: 'Colours' },
        { columnWidth: GridColumnWidth.Short, fieldName: 'deliveryTimeFrame', labelText: 'Availability' },
        { columnWidth: GridColumnWidth.Short, fieldName: 'dealer', labelText: 'Dealer' },
    ];
};

const defaultOptions = (listingId: number): IOptions => {

    const defaultFilterOptions: IFilter = {
        supplyRequestListingId: listingId
    };

    const defaultPaginationOptions: IApiPaginationOptions = {
        maxVisiblePageButtons: 5,
        pageNumber: 1,
        pageSize: 1000
    };

    const defaultSortOptions: IApiSortOptions = {
        sortField: 'BidAmount',
        sortOrderAsc: true
    };

    const defaultOptions: IOptions = {
        cultureOptions: CultureHelper.getCultureInfo(),
        filterOptions: defaultFilterOptions,
        paginationOptions: defaultPaginationOptions,
        sortOptions: defaultSortOptions,
        type: 'IApiBidHistoryOptions'
    };

    return defaultOptions;
};

const actionTypes = {
    receive: {
        provisional: 'BidHistory.Management.ProvisionalModel',
        rejections: 'BidHistory.Management.Rejections',
    }
};

const queryActionTypes: IGenericQueryActionTypes = {
    receive: {
        collection: 'BidHistory.Management.Collection',
        options: 'BidHistory.Management.Options',
        tools: 'BidHistory.Management.Tools',
        upsert: 'BidHistory.Management.Upsert'
    },
    reset: {
        all: 'BidHistory.Management.Reset'
    }
};

export {
    actionTypes as BidHistoryActionTypes,
    columnNames as BidHistoryColumnNames,
    defaultOptions as BidHistoryDefaultOptions,
    queryActionTypes as BidHistoryQueryActionTypes
};
