//3rd Party References
import * as React from 'react';

//Utility References
import { Handler } from '../../IndexOfActions';
import { TrashIcon } from '../../IndexOfComponents';
import { ButtonStyle } from '../../IndexOfEnums';
import { AnimationHelper, ButtonHelper } from '../../IndexOfHelpers';
import { IButtonOptions, ICommonButtonProps } from '../../IndexOfInterfaces';

export interface IDeleteButtonProps extends ICommonButtonProps {
    buttonOptions?: IButtonOptions;
    cssClass?: string;
    isDisabled?: boolean;
    onClick: Handler.Action;
    processing?: boolean;
    tabIndex?: number;
}

export default class DeleteButton extends React.Component<IDeleteButtonProps, {}> {

    private primaryInput: HTMLButtonElement;

    public setFocus = (scroll?: number): void => {
        AnimationHelper.setFocus(this.primaryInput, scroll);
    }

    render() {

        const { buttonOptions, cssClass, isDisabled, onClick, processing, tabIndex } = this.props;

        const defaultButtonOptions: IButtonOptions = {
            buttonStyle: ButtonStyle.Danger,
            buttonText: 'Delete'
        };

        let appliedClassName = ButtonHelper.getButtonClasses(defaultButtonOptions, buttonOptions);

        if (cssClass) {

            appliedClassName = appliedClassName + ' ' + cssClass;
        }

        const buttonProps = {
            className: appliedClassName,
            disabled: isDisabled,
            onClick: ButtonHelper.getButtonOnClickHandler(onClick, buttonOptions),
            ref: (component: HTMLButtonElement) => { this.primaryInput = component; },
            role: 'button',
            tabIndex,
            title: buttonOptions && buttonOptions.title,
            type: 'button'
        };

        const buttonTextDisplay = ButtonHelper.getButtonText(defaultButtonOptions, buttonOptions);

        return (
            <button {...buttonProps}>
                <TrashIcon processing={processing} /> {buttonTextDisplay}
            </button>
        );
    }
}
