// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from '../../IndexOfActions';
import {
    IResetButtonProps,
    ISearchButtonProps,
    ResetButton,
    SearchButton
} from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
import { ObjectHelper } from '../../IndexOfHelpers';
import { IApiFilterBase, IButtonOptions } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

export interface ISearchButtonToolbarProps extends React.Props<SearchButtonToolbar> {
    buttonOptions?: IButtonOptions;
    filterOptions: IApiFilterBase;
    isDisabled?: boolean;
    reset: Handler.Action;
    search: Handler.Action1<IApiFilterBase>;
    updateOptions: Handler.Action1<IApiFilterBase>;
}

export class SearchButtonToolbar extends React.Component<ISearchButtonToolbarProps, {}>{

    render() {

        const { buttonOptions, filterOptions, isDisabled, search, reset, updateOptions } = this.props;

        const defaultButtonOptions: IButtonOptions = {
            alignRight: true,
            appendedClassName: 'mr-2'
        };

        const mergedOptions = ObjectHelper.merge(defaultButtonOptions, buttonOptions);

        const searchButtonProps: ISearchButtonProps = {
            buttonOptions: mergedOptions,
            isDisabled,
            filterOptions,
            onClick: search,
            updateOptions
        };

        const resetButtonProps: IResetButtonProps = {
            buttonOptions: mergedOptions,
            isDisabled,
            onClick: reset
        };

        return (
            <>
                <ResetButton {...resetButtonProps} />
                <SearchButton {...searchButtonProps} />
            </>
        );
    }
}
