// Utility References
import { ObjectHelper } from 'Utility/IndexOfHelpers';

export function toCurrencyDisplay(value: number) {

    if (ObjectHelper.isUndefinedOrNull(value)) {
        return null;
    }

    const formatter = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
    });

    return formatter.format(value);
}
