// 3rd Party
import * as React from 'react';
import { hot } from 'react-hot-loader';

// Utility References
import { Handler } from 'Utility/IndexOfActions';
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    GridData,
    GridDataHead,
    GridDataRow,
    GridMain,
    GridRowEditLinkButton,
    IGridDataHeadProps,
    IGridDataRowProps,
    IGridMainProps,
    IGridPaginationProps,
    IGridRowEditLinkButtonProps
} from 'Utility/IndexOfComponents';
import { GridColumnWidth } from 'Utility/IndexOfEnums';
import { GridFilterContext } from 'Utility/IndexOfModels';

// App References
import {
    IApiSupplyRequestCollection as ICollection,
    IApiSupplyRequestOptions as IOptions,
    IApiSupplyRequestSummaryViewModel as ISummary
} from 'App/IndexOfInterfaces';
import {
    SupplyRequestColumnNames as ColumnNames
} from 'App/IndexOfModels';
import { AppUrls } from 'App/IndexOfUrls';
import { IPageState } from 'App/State/Page/State';

interface IPropActions {
    search: Handler.Action1<IOptions>;
    updateOptions: Handler.Action1<IOptions>;
}

interface IPropData {
    collection: ICollection;
    options: IOptions;
    pageState: IPageState;
}

interface IListProps {
    propActions: IPropActions;
    propData: IPropData;
}

class List extends React.Component<IListProps, {}>{

    private dataRow = (summary: ISummary): IGridDataRowProps => {

        return {
            dataPoints: [
                summary.claimReference,
                summary.manufacturerName,
                summary.modelName,
                summary.derivativeName,
                summary.postCode
            ],
            hasActions: true,
            key: summary.supplyRequestId,
            rowId: summary.supplyRequestId
        };
    }

    private getGridDataRowFactory = () => (collection: ICollection): JSX.Element[] => {

        if (collection) {

            return (

                collection.results.map((summary: ISummary) => {

                    const detailProps: IGridRowEditLinkButtonProps = {
                        href: AppUrls.DashboardUrls.supplyRequestDetails(summary.supplyRequestId)
                    };

                    return (
                        <GridDataRow {...this.dataRow(summary)}>
                            <GridRowEditLinkButton {...detailProps} />
                        </GridDataRow>
                    );
                })
            );
        }
    }

    private fetchInventoryCollection = (propActions: IPropActions) => (options: IOptions): void => {

        propActions.updateOptions(options);
        propActions.search(options);
    }

    render() {

        const { propActions, propData } = this.props;
        const { collection, options } = propData;

        const collectionUpdateFunc = this.fetchInventoryCollection(propActions);
        const gridFilterContext = GridFilterContext(collectionUpdateFunc, options);

        const gridDataHeadProps: IGridDataHeadProps = {
            actionsColumnWidth: GridColumnWidth.Tiny,
            columnNameSets: ColumnNames(),
            gridFilterContext
        };

        const gridPaginationProps: IGridPaginationProps = {
            currentSearchOptions: options,
            currentSearchViewModel: collection,
            gridFilterContext
        };

        const gridMainProps: IGridMainProps = {
            gridPaginationProps
        };

        const PaginatedGrid = (): JSX.Element =>

            <div className="mb-4">
                <Card>
                    <CardBody>
                        <GridMain {...gridMainProps}>
                            <GridDataHead {...gridDataHeadProps} />
                            <GridData collection={collection} dataRowFactory={this.getGridDataRowFactory()} />
                        </GridMain>
                    </CardBody>
                </Card>
            </div>;

        const NoItemsToDisplay = (): JSX.Element =>

            !propData.pageState.processing &&
            <div className="row mt-5">
                <div className="col d-flex justify-content-center">
                    <h2>There are no items to display</h2>
                </div>
            </div>;

        return (
            collection && collection.results.length > 0 ? <PaginatedGrid /> : <NoItemsToDisplay />
        );
    }
}

const HotList = hot(module)(List);

export {
    HotList as SupplyRequestList,
    IListProps as ISupplyRequestListProps
};
