// Utility References
import { IGenericQueryActionTypes } from 'Utility/IndexOfInterfaces';

const queryActionTypes: IGenericQueryActionTypes = {
    receive: {
        collection: '',
        options: '',
        tools: '',
        upsert: 'Registration.Management.Upsert'
    },
    reset: {
        all: 'Registration.Management.Reset'
    }
};

const registrationAreaActionTypes = {
    receive: {
        usernameCollision: 'Registration.Receive.UsernameCollision',
        viewModel: 'Registration.Receive.ViewModel'
    }
};

export {
    registrationAreaActionTypes as RegistrationAreaActionTypes,
    queryActionTypes as RegistrationAreaQueryActionTypes
};
