// Utility References
import { IGenericQueryActionTypes } from 'Utility/IndexOfInterfaces';

const queryActionTypes: IGenericQueryActionTypes = {
    receive: {
        collection: '',
        options: '',
        tools: '',
        upsert: 'Login.Management.Upsert'
    },
    reset: {
        all: 'Login.Management.Reset'
    }
};

export {
    queryActionTypes as LoginAreaQueryActionTypes
};
