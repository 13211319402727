const actionTypes = {
    receive: {
        accountDetails: 'AccountDetails.Receive.ViewModel',
        notificationPreferenceOptions: 'NotificationPreferences.Receive.ViewModel',
        updatePreferences: 'AccountDetails.Receive.UpdatePrerences'
    },
    reset: {
        all: 'AccountDetails.Management.Reset'
    }
};

export {
    actionTypes as AccountDetailsActionTypes
};
