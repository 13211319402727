// 3rd Party References
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

class ScrollToTop extends React.Component<RouteComponentProps, {}> {

    componentDidUpdate(prevProps: RouteComponentProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return this.props.children;
    }
}

const scrollWithRouter = withRouter(ScrollToTop);

export {
    scrollWithRouter as ScrollToTop
};
