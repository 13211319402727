// 3rd Party References
import * as React from 'react';

// Utility References
//import {  } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
//import {  } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

// Local References
import { GridEmptyRow } from './Internal/GridEmptyRow';

export interface IGridContentProps {
    contentRows: JSX.Element[];
}

export default class GridContent extends React.Component<IGridContentProps, {}> {

    render() {

        const { contentRows } = this.props;

        if (contentRows === null || contentRows === undefined || contentRows.length === 0) {

            return (
                <tbody>
                    <GridEmptyRow />
                </tbody>
            );
        }

        return <tbody>{contentRows}</tbody>;
    }
}
