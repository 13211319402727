// 3rd Party
import * as React from 'react';

// Base References
import { BaseView, IAppProps } from '../Base/View';

// App References
import {
    IPageActions,
    IPageState,
    ISupplyRequestActions,
    ISupplyRequestFilterProps as IFilterProps,
    ISupplyRequestListProps as IListProps,
    SignalRHubEvents as HubEvents,
    SupplyRequestDefaultOptions,
    SupplyRequestFilter,
    SupplyRequestList
} from 'App/IndexOfModels';
import { HighlightUpdate, IHighlightUpdateProps } from 'App/Models/SignalR/Components/HighlightUpdate';
import { ObjectHelper } from 'Utility/IndexOfHelpers';

class View extends BaseView<{}> {

    componentDidMount() {

        const { appActions, appState } = this.props;

        this.initialiseSearch(appActions.supplyRequestActions, appActions.pageActions, appState.page);
    }

    private initialiseSearch = (actions: ISupplyRequestActions, pageActions: IPageActions, page: IPageState, reset?: boolean) => {

        let updatedOptions = SupplyRequestDefaultOptions();

        actions.resetAll();

        updatedOptions = pageActions.initialiseStickyOptions(reset, updatedOptions, page.currentOptions);

        actions.receiveOptions(updatedOptions);
        actions.searchAsync(updatedOptions);
    }

    render() {

        const { appActions, appState } = this.props;

        const filterProps: IFilterProps = {
            propActions: {
                resetForm: () => {
                    this.initialiseSearch(appActions.supplyRequestActions, appActions.pageActions, appState.page);
                },
                search: appActions.supplyRequestActions.searchAsync,
                updateOptions: appActions.supplyRequestActions.receiveOptions
            },
            propData: {
                options: appState.supplyRequest.options,
                pageState: appState.page,
                tools: appState.supplyRequest.tools
            }
        };

        const highlightUpdateProps: IHighlightUpdateProps = {
            propActions: {
                pageActions: appActions.pageActions,
                refreshAction: (args) => {

                    appActions.supplyRequestActions.searchAsync(appState.supplyRequest.options);
                },
                shouldHighlightUpdate: (event, args) => {

                    if (event !== HubEvents.supplyRequestUpdated || ObjectHelper.isUndefinedOrNull(appState.supplyRequest.collection)) {
                        return false;
                    }

                    return appState.supplyRequest.collection.results.some((item) => {
                        return item.supplyRequestId === args.id;
                    });
                }
            },
            propData: {
                pageState: appState.page
            }
        };

        const listProps: IListProps = {
            propActions: {
                search: appActions.supplyRequestActions.searchAsync,
                updateOptions: appActions.supplyRequestActions.receiveOptions
            },
            propData: {
                collection: appState.supplyRequest.collection,
                options: appState.supplyRequest.options,
                pageState: appState.page
            }
        };

        return (
            <>
                <HighlightUpdate {...highlightUpdateProps} />

                <SupplyRequestFilter {...filterProps}/>

                <SupplyRequestList {...listProps}/>
            </>
        );
    }
}

export {
    View as SupplyRequestListView
};
