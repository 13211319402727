// 3rd Party References
import * as React from 'react';

// Utility References
//import {  } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
//import {  } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

interface IGridFilterRowProps extends React.Props<GridFilterRow> {
    actionsColumnCount: number;
}

export default class GridFilterRow extends React.Component<IGridFilterRowProps, {}> {

    render() {

        const { actionsColumnCount } = this.props;

        const actionColumns: JSX.Element[] = [];

        for (let i = 0; i < actionsColumnCount; i++) {
            // no key! (╯°□°）╯︵ ┻━┻
            // key added, it is ok now ┬──┬◡ﾉ(° -°ﾉ)
            actionColumns.push(<th key={i}></th>);
        }

        return (
            <tr className="grid-filter-row" key={'grid-filter-row'}>
                {this.props.children}
                {actionColumns.length > 0 ? actionColumns : null}
            </tr>
        );
    }
}
