// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from '../../IndexOfActions';
//import {  } from '../../IndexOfComponents';
import { ButtonStyle } from '../../IndexOfEnums';
import { AnimationHelper, ButtonHelper, ObjectHelper, RouterHelper } from '../../IndexOfHelpers';
import { IButtonOptions } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

//#region Internal Button

interface IInternalButtonProps extends React.Props<InternalButton> {
    /**
     * Optional. button options overrides: size, style etc.
     */
    buttonOptions?: IButtonOptions;
    /**
     * Optional. disables the button if true
     */
    isDisabled?: boolean;
    /**
     * Optional. disables button when processing
     */
    processing?: boolean;
    /**
     * Optional. tabindex, innit
     */
    tabIndex?: number;
}

class InternalButton extends React.Component<IInternalButtonProps, { menuOpen: boolean }> {

    private primaryInput: HTMLButtonElement;

    public setFocus = (scroll?: number): void => {
        AnimationHelper.setFocus(this.primaryInput, scroll);
    }

    render() {

        const { buttonOptions, isDisabled, processing, tabIndex } = this.props;

        const defaultButtonOptions: IButtonOptions = {
            buttonStyle: ButtonStyle.Primary,
            buttonText: ''
        };

        const buttonProps = {
            className: ButtonHelper.getButtonClasses(defaultButtonOptions, buttonOptions, 'btn dropdown-toggle'),
            disabled: isDisabled || processing,
            ref: (component: HTMLButtonElement) => { this.primaryInput = component; },
            role: 'button',
            tabIndex,
            title: buttonOptions && buttonOptions.title,
            type: 'button'
        };

        const buttonTextDisplay = ButtonHelper.getButtonText(defaultButtonOptions, buttonOptions);

        return (
            <button {...buttonProps} aria-haspopup="true" aria-expanded="false" data-toggle="dropdown">
                {this.props.children}{this.props.children ? ' ' : ''}{buttonTextDisplay} <i className="caret"></i>
            </button>
        );
    }
}

//#endregion

//#region Button Set

export interface IButtonSetProps extends React.Props<ButtonSet> {
    buttonOptions?: IButtonOptions;
    isDisabled?: boolean;
    processing?: boolean;
    tabIndex?: number;
}

export class ButtonSet extends React.Component<IButtonSetProps, {}> {

    private primaryInput: InternalButton;

    public setFocus = (scroll?: number): void => {
        this.primaryInput.setFocus(scroll);
    }

    render() {

        const { buttonOptions, isDisabled, processing, tabIndex } = this.props;

        let wrapperDivClass = 'dropdown-link-set btn-group';

        const internalButtonProps: IInternalButtonProps = {
            buttonOptions,
            isDisabled,
            processing,
            ref: (component: InternalButton) => { this.primaryInput = component; },
            tabIndex
        };

        if (!!buttonOptions) {

            if (buttonOptions.alignLeft) {

                wrapperDivClass = `${wrapperDivClass} pull-left`;

            } else if (buttonOptions.alignRight) {

                wrapperDivClass = `${wrapperDivClass} pull-right`;
            }

            if (buttonOptions.buttonBlock) {

                wrapperDivClass = `${wrapperDivClass} btn-block`;
            }

            // Wiping values used for wrapper, passing through remainder.
            const buttonOptionsClone = ObjectHelper.deepClone(buttonOptions);
            buttonOptions.alignLeft = undefined;
            buttonOptions.alignRight = undefined;
            buttonOptions.buttonBlock = undefined;

            internalButtonProps.buttonOptions = buttonOptionsClone;
        }

        return (
            <div className={wrapperDivClass}>
                <InternalButton {...internalButtonProps} />
                <ul className="dropdown-menu">
                    {this.props.children}
                </ul>
            </div>
        );
    }
}

//#endregion

//#region Shared Button Props

interface IButtonBaseProps {
    isDisabled?: boolean;
    label: string;
}

//#endregion

//#region Action Button

export interface IActionButtonProps extends React.Props<ActionButton>, IButtonBaseProps {
    onClick: Handler.Action;
}

export class ActionButton extends React.Component<IActionButtonProps, {}> {

    render() {

        const { isDisabled, label, onClick } = this.props;

        const anchorProps: React.HTMLProps<HTMLAnchorElement> = {
            href: '#'
        };

        if (isDisabled) {

            anchorProps.className = 'not-selectable';
            anchorProps.onClick = (event) => { event.preventDefault(); };

        } else {

            anchorProps.onClick = (event) => {
                event.preventDefault();
                onClick();
            };
        }

        return <li key={label}><a {...anchorProps}>{label}</a></li>;
    }
}

//#endregion

//#region Link Button

export interface ILinkButtonProps extends React.Props<LinkButton>, IButtonBaseProps {
    href: string;
    isRawHref?: boolean;
    targetNewTab?: boolean;
}

export class LinkButton extends React.Component<ILinkButtonProps, {}> {

    render() {

        const { isDisabled, label, href, isRawHref, targetNewTab } = this.props;

        const anchorProps: React.HTMLProps<HTMLAnchorElement> = {
            href
        };

        if (targetNewTab) {
            anchorProps.target = '_blank';
        }

        if (isDisabled) {
            anchorProps.className = 'not-selectable';
            anchorProps.href = '#';
            anchorProps.onClick = (event) => { event.preventDefault(); };
        }

        return <li key={label}><a {...anchorProps}>{label}</a></li>;
    }
}

//#endregion
