// 3rd Party
import * as React from 'react';
import { hot } from 'react-hot-loader';

// Base References
import { RoutedBaseView } from '../Base/View';

// Utility References
import { ObjectHelper } from 'Utility/IndexOfHelpers';

// App References
import { HighlightUpdate, IHighlightUpdateProps, ModalNames } from 'App/IndexOfModels';
import {
    ISupplyRequestListingDetailsProps,
    SignalRHubEvents as HubEvents,
    SupplyRequestListingDetails
} from 'App/IndexOfModels';

class DetailsView extends RoutedBaseView {

    render() {

        const { appActions, appState, history, match } = this.props;

        const enquiryFormModal = appActions.pageActions.createModalService(ModalNames.EnquiryFormModal, appState.page.pageModals);

        const highlightUpdateProps: IHighlightUpdateProps = {
            propActions: {
                pageActions: appActions.pageActions,
                refreshAction: (args) => {

                    appActions.supplyRequestListingActions.getAsync(listingDetailsProps.propData.supplyRequestListingId);
                    appActions.bidHistoryActions.getTemplateAsync(listingDetailsProps.propData.supplyRequestListingId);
                },
                shouldHighlightUpdate: (event, args) => {

                    if (event !== HubEvents.supplyRequestListingUpdated &&
                        event !== HubEvents.supplyRequestUpdated ||
                        ObjectHelper.isUndefinedOrNull(appState.supplyRequestListing.viewModel)) {
                        return false;
                    }

                    if (event === HubEvents.supplyRequestListingUpdated) {
                        return appState.supplyRequestListing.viewModel.supplyRequestListingId === args.id;
                    }

                    if (event === HubEvents.supplyRequestUpdated) {
                        return appState.supplyRequestListing.viewModel.supplyRequestId === args.id;
                    }

                    return false;
                }
            },
            propData: {
                pageState: appState.page
            }
        };

        const listingDetailsProps: ISupplyRequestListingDetailsProps = {
            propActions: {
                bidHistoryActions: appActions.bidHistoryActions,
                closeAuctionAsync: appActions.supplyRequestListingActions.closeAuctionAsync,
                enquiryActions: appActions.enquiryActions,
                get: appActions.supplyRequestListingActions.getAsync,
                pageActions: appActions.pageActions,
                relistAuctionAsync: appActions.supplyRequestListingActions.relistAuctionAsync,
                resetAll: appActions.supplyRequestListingActions.resetAll,
                uploadInvoice: appActions.supplyRequestListingActions.uploadInvoiceAsync,
                uploadPurchaseOrder: appActions.supplyRequestListingActions.uploadPurchaseOrderAsync
            },
            propData: {
                bidhistoryState: appState.bidHistory,
                enquiryModal: enquiryFormModal,
                enquiryState: appState.enquiry,
                history,
                listingDetails: appState.supplyRequestListing.viewModel,
                pageState: appState.page,
                supplyRequestListingId: parseInt(match.params.id)
            }
        };

        return (
            <>
                <div className="col-sm-12">
                        <HighlightUpdate {...highlightUpdateProps} />
                </div>

                <SupplyRequestListingDetails {...listingDetailsProps} />
            </>
        );
    }
}

const HotView = hot(module)(DetailsView);

export {
    HotView as DealerSupplyListingDetailsView
};
