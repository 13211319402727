// 3rd Party References
import * as React from 'react';
import { Translation } from '../Displays/TranslationDisplay';

// Utility References
//import {  } from '../../IndexOfActions';
//import {  } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
//import {  } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

export interface IPanelHeadingWithContentProps extends React.HTMLProps<PanelHeadingWithContent> {
    id?: string;
    role?: string;
    titleContent: JSX.Element;
}

export class PanelHeadingWithContent extends React.Component<IPanelHeadingWithContentProps, {}> {

    render() {

        const { id, role, titleContent } = this.props;

        const panelHeadingProps = {
            className: 'panel-heading clearfix',
            id,
            role
        };

        if (!!this.props.children) {

            return (
                <div {...panelHeadingProps}>
                    <h3 className="panel-title"><Translation>{titleContent}</Translation></h3>
                    <div className="btn-toolbar pull-right">{this.props.children}</div>
                </div>
            );

        } else {

            return (
                <div {...panelHeadingProps}>
                    <h3 className="panel-title"><Translation>{titleContent}</Translation></h3>
                </div>
            );
        }
    }
}
