// 3rd Party References
import moment from 'moment';
import * as React from 'react';

// Utility References
import { Handler } from '../../IndexOfActions';
import {
    ISimpleDateInputProps, ISimpleTimeInputProps,
    SimpleDateInput, SimpleTimeInput
} from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
import { DateHelper } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
import { DateJoiner } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

export interface ISimpleDateTimeInputProps extends React.Props<SimpleDateTimeInput> {
    dateFormat?: string;
    isClearable?: boolean;
    isDisabled?: boolean;
    isHidden?: boolean;
    maxDate?: moment.Moment | Date;
    minDate?: moment.Moment | Date;
    onBlurCallback?: Handler.InputFocusEvent;
    onChangeCallback: Handler.DateProperty;
    placeholder?: string;
    propertyName?: string;
    showTimeSelect?: boolean;
    tabIndex?: number;
    value: Date;
}

export class SimpleDateTimeInput extends React.Component<ISimpleDateTimeInputProps, {}> {

    render() {

        const { dateFormat, isClearable, isDisabled, isHidden, maxDate, minDate, onBlurCallback, onChangeCallback, placeholder, propertyName, showTimeSelect, tabIndex, value } = this.props;

        const dateInputProps: ISimpleDateInputProps = {
            dateFormat: dateFormat || DateHelper.standardNativeDateTimeFormatPattern(),
            isClearable,
            isDisabled,
            isHidden,
            maxDate,
            minDate,
            onBlurCallback,
            onChangeCallback,
            placeholder,
            propertyName,
            showTimeSelect,
            tabIndex,
            value
        };

        return (
            <div className="simple-date-time-input">
                <div className="date-wrapper col-xs-7"><SimpleDateInput {...dateInputProps} /></div>
            </div>
        );
    }
}
