// 3rd Party References
import * as React from 'react';

// Utility References
//import {  } from '../../IndexOfActions';
//import {  } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
//import {  } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
import { DateConverter } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

export enum DateWithTimeFormats {
    IsoFormatDisplay,
    LongDateAnd24HrTimeDisplay,
    StandardDateAnd12HrTimeDisplay,
    StandardDateAnd24HrTimeDisplay
}

export interface IDateWithTimeDisplayProps {
    displayOnFail?: string;
    format?: DateWithTimeFormats;
    value: Date;
}

const dateWithTimeDisplay = (props: IDateWithTimeDisplayProps): JSX.Element => {

    const { displayOnFail, format, value } = props;

    let displayValue: string;
    const defaultValue = displayOnFail ? displayOnFail : 'No Date';

    switch (format) {

        case DateWithTimeFormats.LongDateAnd24HrTimeDisplay:
            displayValue = DateConverter(value, defaultValue).toLongDateAnd24HrTimeDisplay();
            break;

        case DateWithTimeFormats.IsoFormatDisplay:
            displayValue = DateConverter(value, defaultValue).toIsoFormatDisplay();
            break;

        case DateWithTimeFormats.StandardDateAnd12HrTimeDisplay:
            displayValue = DateConverter(value, defaultValue).toStandardDateAnd12HrTimeDisplay();
            break;

        case DateWithTimeFormats.StandardDateAnd24HrTimeDisplay:
        default:
            displayValue = DateConverter(value, defaultValue).toStandardDateAnd24HrTimeDisplay();
            break;
    }

    return (
        <time>
            {displayValue}
        </time>
    );
};

export {
    dateWithTimeDisplay as DateWithTimeDisplay,
};
