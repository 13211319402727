// 3rd Party
import * as React from 'react';

// Base References
import { BaseView } from '../Base/View';

// Utility References
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    ISimpleSelectSortItemOptionProps,
    SimpleSelectSortItemOption
} from 'Utility/IndexOfComponents';
import { ObjectHelper } from 'Utility/IndexOfHelpers';

// App References
import {
    HighlightUpdate,
    IHighlightUpdateProps,
    IPageActions,
    IPageState,
    ISupplyRequestListingActions,
    ISupplyRequestListingDealerFilterProps as IFilterProps,
    ISupplyRequestListingListProps as IListProps,
    SignalRHubEvents as HubEvents,
    SupplyRequestListingDealerFilter,
    SupplyRequestListingDefaultOptions,
    SupplyRequestListingList
} from 'App/IndexOfModels';
import { AppUrls } from 'App/IndexOfUrls';

class View extends BaseView<{}> {

    componentDidMount() {

        const { appActions, appState } = this.props;

        this.initialiseSearch(appActions.supplyRequestListingActions, appActions.pageActions, appState.page);
    }

    private initialiseSearch = (actions: ISupplyRequestListingActions, pageActions: IPageActions, page: IPageState, reset?: boolean) => {

        let updatedOptions = SupplyRequestListingDefaultOptions(null);

        actions.resetAll();
        actions.getToolsAsync();

        updatedOptions = pageActions.initialiseStickyOptions(reset, updatedOptions, page.currentOptions);

        actions.receiveOptions(updatedOptions);
        actions.searchAsync(updatedOptions);
    }

    render() {

        const { appActions, appState } = this.props;

        const filterProps: IFilterProps = {
            propActions: {
                resetForm: () => {
                    this.initialiseSearch(appActions.supplyRequestListingActions, appActions.pageActions, appState.page);
                },
                search: appActions.supplyRequestListingActions.searchAsync,
                updateOptions: appActions.supplyRequestListingActions.receiveOptions
            },
            propData: {
                options: appState.supplyRequestListing.options,
                pageState: appState.page,
                tools: appState.supplyRequestListing.tools
            }
        };

        const highlightUpdateProps: IHighlightUpdateProps = {
            propActions: {
                pageActions: appActions.pageActions,
                refreshAction: (args) => {

                    appActions.supplyRequestListingActions.searchAsync(appState.supplyRequestListing.options);
                },
                shouldHighlightUpdate: (event, args) => {

                    if (event !== HubEvents.supplyRequestListingUpdated &&
                        event !== HubEvents.supplyRequestUpdated ||
                        ObjectHelper.isUndefinedOrNull(appState.supplyRequestListing.collection)) {
                        return false;
                    }

                    return true;
                }
            },
            propData: {
                pageState: appState.page
            }
        };

        const listProps: IListProps = {
            propActions: {
                search: appActions.supplyRequestListingActions.searchAsync,
                updateOptions: appActions.supplyRequestListingActions.receiveOptions
            },
            propData: {
                collection: appState.supplyRequestListing.collection,
                detailsUrlFunc: AppUrls.SupplyRequestUrls.listingDetails,
                options: appState.supplyRequestListing.options,
                pageState: appState.page
            }
        };

        const sortOptionSelectProps: ISimpleSelectSortItemOptionProps = {
            items: appState.supplyRequestListing.tools && appState.supplyRequestListing.tools.sortOptions,
            optionsModel: appState.supplyRequestListing.options,
            optionsUpdateFunc: appActions.supplyRequestListingActions.receiveOptions,
            searchFunc: appActions.supplyRequestListingActions.searchAsync,
            value: appState.supplyRequestListing.options.sortOptions
        };

        return (
            <>
                <div className="col-sm-12">
                    <HighlightUpdate {...highlightUpdateProps} />
                </div>

                <div className="col-sm-3 mb-3">
                    <SupplyRequestListingDealerFilter {...filterProps}/>
                </div>

                <div className="col-sm-9 mb-3">
                    <Card>
                        <CardHeader>
                            <div className="row">
                                <div className="col-sm-7">
                                    <CardTitle text="Supply Requests" />
                                </div>
                                <div className="col-sm-5">
                                    <SimpleSelectSortItemOption {...sortOptionSelectProps} />
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <SupplyRequestListingList {...listProps}/>
                        </CardBody>
                    </Card>
                </div>
            </>
        );
    }
}

export {
    View as DealerListingListView
};
