// Utility References
import { ExceptionHelper } from 'Utility/IndexOfHelpers';
import { IApiException, IApiExceptionGroup, IGenericDictionary } from 'Utility/IndexOfInterfaces';
import { Notification } from 'Utility/IndexOfServices';

const Exception = ExceptionHelper.ApiException;

interface IBiddingExceptions extends IGenericDictionary<IApiException> {
    BiddingClosed: IApiException;
    Ended: IApiException;
    NotMinimumUnderCurrentBid: IApiException;
    NotStarted: IApiException;
}

const exceptions: IBiddingExceptions = {
    BiddingClosed: Exception('Bid cannot be accepted because the bidding for this auction has closed.', Notification.error.handledException),
    Ended: Exception('Bid cannot be accepted because the auction has ended.', Notification.error.handledException),
    NotMinimumUnderCurrentBid: Exception('Bid cannot be accepted because it is not less than minimum value under the current best bid.', Notification.error.handledException),
    NotStarted: Exception('Bid cannot be accepted because the auction has not yet started.', Notification.error.handledException)
};

const exceptionGroup: IApiExceptionGroup = {
    area: 'BidHistory',
    exceptions
};

export {
    exceptionGroup as BiddingExceptionGroup,
    exceptions as BiddingExceptions
};
