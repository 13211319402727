// 3rd Party
import * as React from 'react';
import { hot } from 'react-hot-loader';

// Base References
import { BaseView } from '../Base/View';

// App References
import { IApiImpersonationViewModel } from 'App/IndexOfInterfaces';

class View extends BaseView<{}> {

    componentDidMount() {

        const { appActions } = this.props;

        const urlSearchParams = new URLSearchParams(window.location.search.substring(1));

        const impersonationViewModel: IApiImpersonationViewModel = {
            impersonationToken: urlSearchParams.get('impersonationToken'),
            username: urlSearchParams.get('username')
        };

        setTimeout(() => {

            appActions.impersonationActions.impersonateAsync(impersonationViewModel);
        }, 2000);

    }

    render() {

        return (
            <div className="col-sm-12 mb-3">
                <h1>MVR Admin Impersonation</h1>
                <p>You will be redirected for impersonation shortly...</p>
            </div>
        );
    }
}

export {
    View as ImpersonateView
};
