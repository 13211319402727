// 3rd Party References
import * as React from 'react';

// Utility References
//import {  } from '../../../IndexOfComponents';
//import {  } from '../../../IndexOfEnums';
import { ObjectHelper, TranslationHelper } from '../../../IndexOfHelpers';
import { IApiItemOptionViewModel } from '../../../IndexOfInterfaces';
//import {  } from '../../../IndexOfModels';
//import {  } from '../../../IndexOfServices';

// Local References
// ...

type OnChangeCallback = (selectedValue: string | number) => void;

interface IGenericDropdownProps {
    displayName: string;
    isDisabled?: boolean;
    isRequired?: boolean;
    items: IApiItemOptionViewModel[];
    onChangeCallback: OnChangeCallback;
    propertyName?: string;
    selectClassName?: string;
    selectableEmptyValue?: string;
    selectedValue: string;
}

export default class GenericDropdown extends React.Component<IGenericDropdownProps, {}> {

    private handleOnChange = (onChangeCallback: (selectedValue: string) => void) => (event: React.FormEvent<HTMLSelectElement>): void => {

        let selectedValue = (event.target as HTMLSelectElement).value;

        if (selectedValue === undefined || selectedValue === '') {
            selectedValue = null;
        }

        onChangeCallback(selectedValue);
    }

    private getTranslatedDisplayName = (displayName: string): string => {

        return TranslationHelper.translate(displayName);
    }

    private getTranslatedPlaceholder = (displayName: string): string => {

        const placeholder = `Update ${displayName}`;
        return TranslationHelper.translate(placeholder);
    }

    private getOptions = (isDisabled: boolean, selectedValueEquivalentOfEmpty: boolean, items: IApiItemOptionViewModel[], validationPrompt: string, selectableEmptyValue?: string): JSX.Element[] => {

        if (isDisabled) {
            return [<option key={'placeholder'} value="" disabled></option>];
        }

        if (!items || items.length === 0) {
            return [<option key={'placeholder'} value="" disabled>No Options Available</option>];
        }

        const options = items.map(
            (item: IApiItemOptionViewModel) => {
                return <option key={item.value} value={item.value}>{item.text}</option>;
            }
        );

        if (selectableEmptyValue) {

            const emptyOption = (<option key={'placeholder'} value="" className="option-empty-value">{selectableEmptyValue}</option>);
            options.unshift(emptyOption);
        }
        else if (selectedValueEquivalentOfEmpty) {
            const emptyOption = (<option key={'placeholder'} value="" disabled>{validationPrompt}</option>);
            options.unshift(emptyOption);
        }

        return options;
    }

    render() {

        const { displayName, isDisabled, isRequired, items, onChangeCallback, propertyName, selectClassName, selectableEmptyValue, selectedValue } = this.props;

        const translatedDisplayText = this.getTranslatedDisplayName(displayName);

        const validationPrompt = `Please select ${translatedDisplayText}`;
        const placeholder = this.getTranslatedPlaceholder(displayName);

        const selectedValueEquivalentOfEmpty = (selectedValue === null || selectedValue === undefined);
        const newSelectedValue = selectedValueEquivalentOfEmpty ? '' : selectedValue;

        let isDisabledValue = isDisabled;
        if (!items) { isDisabledValue = true; }

        let selectProps = {
            autoComplete: 'off',
            className: 'form-control',
            disabled: isDisabledValue,
            id: '',
            onChange: this.handleOnChange(onChangeCallback),
            placeholder,
            ref: '',
            required: isRequired,
            title: validationPrompt,
            value: newSelectedValue
        };

        if (propertyName) {

            const propertyNameAttrs = {
                id: propertyName,
                ref: propertyName
            };

            selectProps = ObjectHelper.merge(selectProps, propertyNameAttrs);
        }

        if (selectClassName) {

            selectProps = ObjectHelper.merge(selectProps, { className: selectClassName });
        }

        const hasValue = selectedValue !== null && selectedValue !== undefined && selectedValue !== selectableEmptyValue;

        if (isDisabled) {

            selectProps.className += ' disabled';

        } else if (hasValue) {

            selectProps.className += ' active';
        }

        const options = this.getOptions(isDisabled, selectedValueEquivalentOfEmpty, items, validationPrompt, selectableEmptyValue);

        return (
            <select {...selectProps} >
                {options}
            </select>
        );
    }
}
