//3rd Party References
import * as React from 'react';

//Utility References
//import {  } from '../../IndexOfActions';
import { SaveIcon } from '../../IndexOfComponents';
import { ButtonSize, ButtonStyle } from '../../IndexOfEnums';
import { AnimationHelper, ButtonHelper, ObjectHelper } from '../../IndexOfHelpers';
import { IButtonOptions } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
import { IControlService } from '../../IndexOfServices';

export interface ISaveButtonProps extends React.Props<SaveButton> {
    buttonOptions?: IButtonOptions;
    /* Phase Out */
    buttonSize?: ButtonSize;
    buttonText?: string;
    cssClass?: string;
    /* End */
    isDisabled?: boolean;
    onClick: (controlService?: IControlService) => void;
    processing: boolean;
    tabIndex?: number;
}

export default class SaveButton extends React.Component<ISaveButtonProps, {}> {

    private primaryInput: HTMLButtonElement;

    public setFocus = (scroll?: number): void => {
        AnimationHelper.setFocus(this.primaryInput, scroll);
    }

    render() {

        const { buttonOptions, /**/ buttonSize, buttonText, cssClass /**/, isDisabled, onClick, processing, tabIndex } = this.props;

        const defaultButtonOptions: IButtonOptions = {
            buttonSize: buttonSize || ButtonSize.Md, // Simplify once buttonSize prop phased out.
            buttonStyle: ButtonStyle.Success,
            buttonText: buttonText || 'Save' // Simplify once buttonText prop phased out.
        };

        let appliedClassName = ButtonHelper.getButtonClasses(defaultButtonOptions, buttonOptions);

        if (cssClass) {

            appliedClassName = appliedClassName + ' ' + cssClass; // Simplify once cssClass prop phased out.
        }

        const buttonProps = {
            className: appliedClassName,
            disabled: ButtonHelper.getButtonIsDisabledState(isDisabled, buttonOptions),
            onClick: ButtonHelper.getButtonOnClickHandler(onClick, buttonOptions),
            ref: (component: HTMLButtonElement) => { this.primaryInput = component; },
            role: 'button',
            tabIndex,
            type: 'button'
        };

        const buttonTextDisplay = ButtonHelper.getButtonText(defaultButtonOptions, buttonOptions);

        const saveButton = (
            <button {...buttonProps}>
                <SaveIcon processing={processing} /> {buttonTextDisplay}
            </button>
        );

        if (!ObjectHelper.isUndefinedOrNull(buttonOptions) && !ObjectHelper.isUndefinedOrNull(buttonOptions.modelState)) {
            return (
                <div className={`btn-group ${buttonOptions.alignRight && 'pull-right'}`}>
                    <button disabled className="btn btn-danger">Errors in form</button>
                    {saveButton}
                </div>
            );
        }

        return saveButton;
    }
}
