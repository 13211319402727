// 3rd Party
import * as React from 'react';
import { hot } from 'react-hot-loader';

// Base References
import { BaseView } from '../Base/View';

// App References
import {
    INewRegistrationFormProps,
    NewRegistrationForm
} from 'App/IndexOfModels';

const appGlobals = (window as any).appGlobals;

class View extends BaseView<{}> {

    render() {

        const { appActions, appState } = this.props;

        const registrationFormProps: INewRegistrationFormProps = {
            propActions: {
                getTemplate: appActions.registrationActions.getTemplateAsync,
                onSave: appActions.registrationActions.saveAsync,
                pageActions: appActions.pageActions,
                receiveUpsert: appActions.registrationActions.receiveUpsert,
                userNameCollisionCheck: appActions.registrationActions.usernameCollisionCheckAsync
            },
            propData: {
                pageState: appState.page,
                termsVersion: appGlobals.termsVersion,
                upsert: appState.registration.upsert,
                userNameCollision: appState.registration.usernameCollision
            }
        };

        return (
            <NewRegistrationForm {...registrationFormProps} />
        );
    }
}

const HotView = hot(module)(View);

export {
    HotView as RegisterView
};
