// 3rd Party References
import * as React from 'react';

// Utility References
//import {  } from '../../IndexOfActions';
//import {  } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
import { ObjectHelper } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

export interface IGridFilterPanelProps { }

export interface IGridFilterPanelState {
    expanded: boolean;
}

export class GridFilterPanel extends React.Component<IGridFilterPanelProps, IGridFilterPanelState>{

    constructor(props: IGridFilterPanelProps) {
        super(props);

        this.state = {
            expanded: false
        };
    }

    public handleExpandClick = (): void => {

        const updatedState = ObjectHelper.deepClone(this.state as IGridFilterPanelState);
        updatedState.expanded = !updatedState.expanded;

        this.setState(updatedState);
    }

    render() {

        const { expanded } = this.state;

        return (
            <div className={`panel-body grid-body grid-filter-panel ${!expanded ? 'collapsed-panel' : ''}`}>
                {this.props.children}
            </div>
        );
    }
}
