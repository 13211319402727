// Utility References
import { Api, IApi, IApiProgressFunctions } from 'Utility';

// App References
import { AppService, AuthenticationService, LocalStorageService } from 'App/IndexOfServices';

export class Actions {

    public api: IApi;

    private appService = AppService();

    private authenticationCallback = (callback: (bearerToken: string) => void): void => {

        AuthenticationService.authenticateWithBearerTokenCallback((bearerToken: string) => {
            callback(bearerToken);
        });
    }

    constructor(private apiProgressFunctions: IApiProgressFunctions) {
        this.api = Api(this.authenticationCallback,
            LocalStorageService.clearAll,
            apiProgressFunctions);
    }
}
