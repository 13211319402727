//3rd Party References
import * as React from 'react';

//Utility References
import { Handler } from '../../IndexOfActions';
import { CancelIcon } from '../../IndexOfComponents';
import { ButtonSize, ButtonStyle } from '../../IndexOfEnums';
import { AnimationHelper, ButtonHelper } from '../../IndexOfHelpers';
import { IButtonOptions } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

export interface ICancelButtonProps extends React.Props<CancelButton> {
    buttonOptions?: IButtonOptions;
    /* Phase Out */
    buttonSize?: ButtonSize;
    buttonText?: string;
    cssClass?: string;
    /* End */
    isDisabled?: boolean;
    isHidden?: boolean;
    onClick: Handler.Action;
    processing?: boolean;
    tabIndex?: number;
}

export default class CancelButton extends React.Component<ICancelButtonProps, {}> {

    private primaryInput: HTMLButtonElement;

    public setFocus = (scroll?: number): void => {
        AnimationHelper.setFocus(this.primaryInput, scroll);
    }

    render() {

        const { buttonOptions, /**/ buttonSize, buttonText, cssClass /**/, isDisabled, isHidden, onClick, processing, tabIndex } = this.props;

        const defaultButtonOptions: IButtonOptions = {
            buttonSize: buttonSize || ButtonSize.Md, // Simplify once buttonSize prop phased out.
            buttonStyle: ButtonStyle.Warning,
            buttonText: buttonText || 'Cancel' //Simplify once buttonText prop phased out.
        };

        let appliedClassName = ButtonHelper.getButtonClasses(defaultButtonOptions, buttonOptions);

        if (cssClass) {

            appliedClassName = appliedClassName + ' ' + cssClass; //Simplify once cssClass prop phased out.
        }

        if (isHidden) {
            appliedClassName += ' hidden';
        }

        const cancelButtonProps = {
            className: appliedClassName,
            disabled: isDisabled,
            onClick: ButtonHelper.getButtonOnClickHandler(onClick, buttonOptions),
            ref: (component: HTMLButtonElement) => { this.primaryInput = component; },
            role: 'button',
            tabIndex,
            type: 'button'
        };

        const buttonTextDisplay = ButtonHelper.getButtonText(defaultButtonOptions, buttonOptions);

        return (
            <button {...cancelButtonProps}>
                {buttonTextDisplay} <CancelIcon processing={processing} />
            </button>
        );
    }
}
