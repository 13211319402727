//3rd Party References
import * as React from 'react';

//Utility References
//import {  } from '../../IndexOfActions';
import { SaveIcon } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
import { AnimationHelper, TranslationHelper } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

export interface ISaveSubmitButtonProps extends React.Props<SaveSubmitButton> {
    buttonText?: string;
    cssClass?: string;
    disabled?: boolean;
    processing?: boolean;
    tabIndex?: number;
}

/**
 * TODOxUI: Deprecated. Use SaveButton.
 */
export default class SaveSubmitButton extends React.Component<ISaveSubmitButtonProps, {}> {

    private primaryInput: HTMLButtonElement;

    public setFocus = (scroll?: number): void => {
        AnimationHelper.setFocus(this.primaryInput, scroll);
    }

    render() {

        const { buttonText, cssClass, disabled, processing, tabIndex } = this.props;

        let className = 'btn btn-success';
        if (cssClass) { className += ` ${cssClass}`; }

        const saveButtonProps = {
            className,
            disabled,
            ref: (component: HTMLButtonElement) => { this.primaryInput = component; },
            role: 'button',
            tabIndex,
            type: 'submit'
        };

        const buttonTextToUse = buttonText || 'Save';
        const buttonTextDisplay = TranslationHelper.translate(buttonTextToUse);

        return (
            <button {...saveButtonProps}>
                <SaveIcon processing={processing} /> {buttonTextDisplay}
            </button>
        );
    }
}
