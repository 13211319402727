// 3rd Party References
import * as React from 'react';
import { hot } from 'react-hot-loader';

// Utility References
import { Handler } from 'Utility/IndexOfActions';
import {
    DangerButton,
    FormGroupWrapper,
    IFormGroupWrapperProps,
    ISimpleButtonProps,
    ISimpleStringInputProps,
    KeyIcon,
    SimpleStringInput
} from 'Utility/IndexOfComponents';
import { ButtonStyle } from 'Utility/IndexOfEnums';
import { FormControlHelper, FormHelper, ObjectHelper } from 'Utility/IndexOfHelpers';
import { IContentWidths, IRequiredField } from 'Utility/IndexOfInterfaces';
import { ModelUpdater } from 'Utility/IndexOfModels';

// App References
import {
    IApiForgottenPasswordViewModel as IViewModel
} from 'App/IndexOfInterfaces';
import {
    IPageActions,
    IPageState
} from 'App/IndexOfModels';

interface IPropActions {
    onSubmit: Handler.Action1<IViewModel>;
    pageActions: IPageActions;
    receiveForgottenPassword: Handler.Action1<IViewModel>;
}

interface IPropData {
    forgottenPassword: IViewModel;
    pageState: IPageState;
}

interface IForgottenPasswordFormProps {
    propActions: IPropActions;
    propData: IPropData;
}

class Form extends React.Component<IForgottenPasswordFormProps, {}> {

    private getFormPropertyNames = (viewModel: IViewModel) => {

        return {
            username: ObjectHelper.getPropertyName(() => viewModel.username)
        };
    }

    private handleOnSubmit = (propActions: IPropActions, propData: IPropData, viewModel: IViewModel): void => {

        const { pageActions: { clearAll, addError } } = propActions;

        if (FormHelper.areRequiredPropertiesValid(clearAll, addError, this.getRequiredFieldsForValidation(viewModel))) {

            propActions.onSubmit(viewModel);
        }
    }

    private isViewModelValid = (viewModel: IViewModel): boolean => {

        const hasViewModel = !ObjectHelper.isUndefinedOrNull(viewModel);

        return hasViewModel;
    }

    private getRequiredFieldsForValidation(viewModel: IViewModel): IRequiredField[] {

        const formProperties = this.getFormPropertyNames(viewModel);

        return [ { errorMessage: 'Please enter a username.', propertyName: formProperties.username, value: viewModel.username } ];
    }

    render() {

        const { propActions, propData } = this.props;

        const viewModel = propData.forgottenPassword;

        if (!this.isViewModelValid(viewModel)) {
            return null;
        }

        const formProperties = this.getFormPropertyNames(viewModel);

        const formGroupWrapperPropsDefaults = {
            contentWidths: FormControlHelper.getContentWidths(
                'children',
                'error',
                'input-wrapper',
                'sr-only')
        };

        const formGroupWrapperProps = (
            displayName: string,
            propertyName: string,
            contentWidths?: IContentWidths,
            isRequired?: boolean): IFormGroupWrapperProps => {

            return FormControlHelper.getFormGroupWrapperProps(
                contentWidths || formGroupWrapperPropsDefaults.contentWidths,
                this.props.propData.pageState.modelState,
                displayName,
                propertyName,
                isRequired || false);
        };

        const modelUpdater = ModelUpdater(propData.forgottenPassword, propActions.receiveForgottenPassword);

        const submitButtonProps: ISimpleButtonProps = {
            buttonOptions: {
                buttonStyle: ButtonStyle.DangerOutline,
                buttonText: 'Reset Password'
            },
            onClick: () => {

                this.handleOnSubmit(propActions, propData, viewModel);
            },
            processing: propData.pageState.processing
        };

        const userNameProps: ISimpleStringInputProps = {
            maxLength: 100,
            onChangeCallback: modelUpdater.for<string>((value, model) => {

                model.username = value;

                return model;
            }),
            placeholder: 'Enter your username to reset the password',
            value: viewModel.username
        };

        return (
            <form className="form-password-reset col-md-4">

                <FormGroupWrapper {...formGroupWrapperProps('Username', formProperties.username, null, true)}>
                    <SimpleStringInput {...userNameProps} />
                </FormGroupWrapper>

                <DangerButton {...submitButtonProps}>
                    <KeyIcon />
                </DangerButton>
            </form>
        );
    }
}

const HotForm = hot(module)(Form);

export {
    HotForm as ForgottenPasswordForm,
    IForgottenPasswordFormProps
};
