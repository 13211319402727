// 3rd Party References
import * as React from 'react';

interface ICardProps extends React.Props<ICardProps> {
    borderColour?: string;
    className?: string;
}

const Card = (props: ICardProps): JSX.Element => {

    const { borderColour, className } = props;

    const borderCssName = borderColour ? `border-${borderColour}` : '';

    return (
        <div className={`card ${borderCssName} ${className}`}>
            {props.children}
        </div>
    );
};

export {
    Card,
    ICardProps
};
