// 3rd Party References
import * as React from 'react';

// Utility References
import { TranslationHelper } from 'Utility/IndexOfHelpers';
import { DateConverter } from 'Utility/IndexOfModels';

export enum DateFormats {
    DayOfWeekDisplay,
    MonthNameDisplay,
    ShortMonthYearDisplay,
    StandardDateAnd12HrTimeDisplay,
    StandardDateAnd24HrTimeDisplay,
    StandardDateDisplay
}

export interface IDateDisplayProps {
    displayOnFail?: string;
    format?: DateFormats;
    useUtc?: boolean;
    value: Date;
}

const dateDisplay = (props: IDateDisplayProps): JSX.Element => {

    const { displayOnFail, format, useUtc, value} = props;

    let displayValue: string;
    const defaultValue = displayOnFail ? displayOnFail : TranslationHelper.translate('No Date');

    switch (format) {

        case DateFormats.DayOfWeekDisplay:
            displayValue = DateConverter(value, defaultValue, useUtc).toDayOfWeekDisplay();
            break;

        case DateFormats.MonthNameDisplay:
            displayValue = DateConverter(value, defaultValue, useUtc).toMonthNameDisplay();
            break;

        case DateFormats.ShortMonthYearDisplay:
            displayValue = DateConverter(value, defaultValue, useUtc).toShortMonthYearDisplay();
            break;

        case DateFormats.StandardDateAnd12HrTimeDisplay:
            displayValue = DateConverter(value, defaultValue, useUtc).toStandardDateAnd12HrTimeDisplay();
            break;

        case DateFormats.StandardDateAnd24HrTimeDisplay:
            displayValue = DateConverter(value, defaultValue, useUtc).toStandardDateAnd24HrTimeDisplay();
            break;

        case DateFormats.StandardDateDisplay:
        default:
            displayValue = DateConverter(value, defaultValue, useUtc).toStandardDateDisplay();
            break;
    }

    const elementTitle = DateConverter(value, '', useUtc).toExtendedDateAnd24HrTimeDisplay();

    return (
        <time title={elementTitle}>
            {displayValue}
        </time>
    );
};

export {
    dateDisplay as DateDisplay
};
