// 3rd Party References
import { Action, handleActions } from 'redux-actions';

// Utility References
import { ReduxHelper } from 'Utility';

// App References
import {
    IApiLoginToolsViewModel as ITools,
    IApiLoginUpsertViewModel as IUpsert,
    IApiLoginViewModel as IViewModel
} from 'App/IndexOfInterfaces';

import {
    LoginAreaQueryActionTypes as QueryActionTypes
} from 'App/IndexOfModels';

interface ILoginState {
    tools: ITools;
    upsert: IUpsert;
    login: IViewModel;
}

const initialState = (): ILoginState => {
    return {
        login: null,
        tools: null,
        upsert: {
            model: {} as IViewModel,
            tools: null
        }
    };
};

const actionWrapper = ReduxHelper.actionWrapper;

type Payload = boolean | ITools | IUpsert | IViewModel;

const loginReducer = handleActions<ILoginState, Payload>({

    [QueryActionTypes.receive.upsert]: (state: ILoginState, action: Action<IUpsert>): ILoginState => {
        return actionWrapper(state, action, (newState: ILoginState) => {
            newState.upsert = action.payload;
        });
    }

}, initialState());

export {
    ILoginState,
    loginReducer as LoginReducer
};
