const actions = {
    LINKEDTOOLS: 'LinkedTools',
    SEARCH: 'Search',
    TEMPLATE: 'Template',
    TOOLS: 'Tools'
};

const fileTypes = {
    Excel: 'EXCEL',
    PDF: 'PDF'
};

const httpVerbs = {
    DELETE: 'DELETE',
    GET: 'GET',
    PATCH: 'PATCH',
    POST: 'POST',
    PUT: 'PUT'
};

export {
    actions as Actions,
    fileTypes as FileTypes,
    httpVerbs as HttpVerbs
};
