// 3rd Party References
import * as React from 'react';

// Utility References
import { TranslationHelper } from 'Utility/IndexOfHelpers';

// Internal References
import GridLinkButton from '../Internal/GridLinkButton';

export interface IGridRowSelectLinkButtonProps {
    buttonLabelOverride?: string;
    hideText?: boolean;
    href: string;
    isDisabled?: boolean;
    targetNewTab?: boolean;
}

export default class GridRowSelectLinkButton extends React.Component<IGridRowSelectLinkButtonProps, {}> {

    render() {

        const { buttonLabelOverride, hideText, href, isDisabled, targetNewTab } = this.props;

        const buttonProps = {
            buttonLabelOverride,
            cssButtonClass: 'btn-primary',
            cssIconClass: 'fa-ok',
            hideText,
            href,
            isDisabled,
            standardLabel: TranslationHelper.translate('Select'),
            targetNewTab
        };

        return (
            <GridLinkButton {...buttonProps} />
        );
    }
}
