// 3rd Party References
import * as React from 'react';

const CardHeader = (props: React.Props<null>): JSX.Element => {

    return (
        <div className="card-header">
            {props.children}
        </div>
    );
};

export {
    CardHeader
};
