// 3rd Party References
import * as React from 'react';

// Utility References
import { LayoutHelper, TranslationHelper } from 'Utility/IndexOfHelpers';
import { IGridColumnNameSet } from 'Utility/IndexOfInterfaces';

// Local References
interface IGridSimpleColumnLabelProps extends React.Props<GridSimpleColumnLabel> {
    gridColumnNameSet: IGridColumnNameSet;
    key: string;
}

export default class GridSimpleColumnLabel extends React.Component<IGridSimpleColumnLabelProps, {}> {

    render() {

        const { gridColumnNameSet } = this.props;

        let thStyles = {};

        const columnTypeClass = LayoutHelper.getColumnTypeClass(gridColumnNameSet.columnType);
        const columnStyleWidth = LayoutHelper.getColumnStyleWidthFromSet(gridColumnNameSet);

        if (columnStyleWidth) {
            thStyles = columnStyleWidth;
        }

        const tableHeaderClass = `grid-simple-column-label ${columnTypeClass}`;

        return (
            <th className={tableHeaderClass} style={thStyles}>
                <div>
                    {TranslationHelper.translate(gridColumnNameSet.labelText)}
                </div>
            </th>
        );
    }
}
