// 3rd Party References
import * as React from 'react';

// Utility References
import { CalloutStyle } from 'Utility/IndexOfEnums';

interface ICalloutProps extends React.Props<ICalloutProps> {
    borderColour?: string;
    calloutStyle?: CalloutStyle;
    className?: string;
}

const Callout = (props: ICalloutProps): JSX.Element => {

    const { borderColour, calloutStyle, className } = props;

    const borderCssName = borderColour ? `border-${borderColour}` : '';

    let calloutStyleClass = '';

    switch (calloutStyle) {
        case CalloutStyle.Danger:
            calloutStyleClass = 'bd-callout-danger';
            break;
        case CalloutStyle.Dark:
            calloutStyleClass = 'bd-callout-dark';
            break;
        case CalloutStyle.Info:
            calloutStyleClass = 'bd-callout-info';
            break;
        case CalloutStyle.Light:
            calloutStyleClass = 'bd-callout-light';
            break;
        case CalloutStyle.Muted:
            calloutStyleClass = 'bd-callout-muted';
            break;
        case CalloutStyle.Primary:
            calloutStyleClass = 'bd-callout-primary';
            break;
        case CalloutStyle.Secondary:
            calloutStyleClass = 'bd-callout-secondary';
            break;
        case CalloutStyle.Success:
            calloutStyleClass = 'bd-callout-success';
            break;
        case CalloutStyle.Warning:
            calloutStyleClass = 'bd-callout-warning';
            break;
        case CalloutStyle.White:
            calloutStyleClass = 'bd-callout-white';
            break;
        default:
            calloutStyleClass = 'bd-callout-primary';
    }

    return (
        <div className={`bd-callout ${borderCssName} ${calloutStyleClass} ${className}`}>
            {props.children}
        </div>
    );
};

export {
    Callout,
    ICalloutProps
};
