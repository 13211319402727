// 3rd Party References
import { Action, handleActions } from 'redux-actions';

// Utility References
import { ReduxHelper } from 'Utility';
import { ObjectHelper, SelectHelper } from 'Utility/IndexOfHelpers';

// App References
import {
    IApiSupplyRequestCollection as ICollection,
    IApiSupplyRequestOptions as IOptions,
    IApiSupplyRequestToolsViewModel as ITools,
    IApiSupplyRequestUpsertViewModel as IUpsert
} from 'App/IndexOfInterfaces';
import {
    SupplyRequestActionTypes as ActionTypes,
    SupplyRequestDefaultOptions as DefaultOptions,
    SupplyRequestQueryActionTypes as QueryActionTypes
} from 'App/IndexOfModels';

interface IState {
    collection: ICollection;
    options: IOptions;
    tools: ITools;
    upsert: IUpsert;
}

const initialState = (): IState => {

    return {
        collection: null,
        options: DefaultOptions(),
        tools: null,
        upsert: null
    };
};

const actionWrapper = ReduxHelper.actionWrapper;
type Payload = ICollection | IOptions | ITools | IUpsert | void;

const reducer = handleActions<IState, Payload>({

    [ActionTypes.receive.linkedTools]: (state: IState, action: Action<ITools>): IState => {
        return actionWrapper(state, action, (newState: IState) => {

            if (!ObjectHelper.isUndefinedOrNull(newState.upsert)) {
                newState.upsert.tools = action.payload;
            }
        });
    },

    [QueryActionTypes.receive.tools]: (state: IState, action: Action<ITools>): IState => {
        return actionWrapper(state, action, (newState: IState) => {
            newState.tools = action.payload;
        });
    },

    [QueryActionTypes.receive.collection]: (state: IState, action: Action<ICollection>): IState => {
        return actionWrapper(state, action, (newState: IState) => {
            newState.collection = action.payload;
        });
    },

    [QueryActionTypes.receive.options]: (state: IState, action: Action<IOptions>): IState => {
        return actionWrapper(state, action, (newState: IState) => {
            newState.options = action.payload;
        });
    },

    [QueryActionTypes.receive.upsert]: (state: IState, action: Action<IUpsert>): IState => {
        return actionWrapper(state, action, (newState: IState) => {
            newState.upsert = action.payload;
        });
    },

    [QueryActionTypes.reset.all]: (): IState => {
        return initialState();
    }

}, initialState());

export {
    reducer as SupplyRequestReducer,
    IState as ISupplyRequestState
};
