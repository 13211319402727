// Utility References
import { Constants } from 'App/IndexOfModels';
import { ObjectHelper } from 'Utility/IndexOfHelpers';
import { IRequiredField } from 'Utility/IndexOfInterfaces';

export function CreateValidatableDocumentFields(filePropFunc: any, mustUploadFile = true): IRequiredField[] {

    const requiredFields: IRequiredField[] = [];

    const filePropName = ObjectHelper.getPropertyName(filePropFunc);

    const file = filePropFunc();

    if (mustUploadFile){

        requiredFields.push({
            errorMessage: 'Please choose document file to be uploaded.',
            propertyName: filePropName,
            value: file,
            validate: (value): boolean => !ObjectHelper.isUndefinedOrNull(value)
        });
    }

    if (file) {

        requiredFields.push({
            errorMessage: `The document size must be less than ${Constants.UploadDocumentFileSize / 1000000}MB.`,
            propertyName: filePropName,
            value: file.size,
            validate: (value): boolean => value <= Constants.UploadDocumentFileSize
        });

        requiredFields.push({
            errorMessage: 'The document must be in pdf or jpeg format.',
            propertyName: filePropName,
            value: file.type,
            validate: (value): boolean => value === Constants.PDFType || value === Constants.JPEGType,
        });
    }

    return requiredFields;
}
