 import * as React from 'react';
 import { Modal as RModal } from 'react-bootstrap';

 class Body extends React.Component<{}, {}> {

    render() {
        return (
            <RModal.Body>
                {this.props.children}
            </RModal.Body>
        );
    }
}

 class Footer extends React.Component<{}, {}> {

    render() {
        return (
            <RModal.Footer>
                {this.props.children}
            </RModal.Footer>
        );
    }
}

 class Header extends React.Component<{}, {}> {

    render() {
        return (
            <RModal.Header closeButton>
                {this.props.children}
            </RModal.Header>
        );
    }
}

 class Title extends React.Component<{ }, {}> {

    render() {

        return (
            <RModal.Title id="contained-modal-title-lg">{this.props.children}</RModal.Title>
        );
    }
}

 export interface IModalProps {
    dialogClassName: string;
    onHide: Function;
    show: boolean;
    size?: string;
}

 export class MyModal extends React.Component<IModalProps, {}> {

    render() {

        return (
            <RModal {...this.props as any}>
                {this.props.children}
            </RModal>
        );
    }
}

 const modal = {
    Body,
    Footer,
    Header,
    Title
};

 export { modal as Modal };
