// 3rd Party References
import { Action, handleActions } from 'redux-actions';

// Utility References
import { ReduxHelper } from 'Utility';

// App References
import { IApiResetPasswordViewModel } from 'App/IndexOfInterfaces';

// Models
import { ResetPasswordActionTypes as ActionTypes } from './Models';

interface IState {
    resetPassword: IApiResetPasswordViewModel;
}

const initialState = (): IState => {

    return {
        resetPassword: {
            password: null,
            passwordConfirmation: null,
            token: null,
            userId: null
        }
    };
};

const actionWrapper = ReduxHelper.actionWrapper;

type Payload = IApiResetPasswordViewModel;

const reducer = handleActions<IState, Payload>({

    [ActionTypes.receive.resetPassword]: (state: IState, action: Action<IApiResetPasswordViewModel>): IState => {
        return actionWrapper(state, action, (newState: IState) => {

            newState.resetPassword = action.payload;
        });
    },

    [ActionTypes.reset.resetForm]: (): IState => {
        return initialState();
    }

}, initialState());

export {
    IState as IResetPasswordState,
    reducer as ResetPasswordReducer
};
