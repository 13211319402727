// 3rd Party References
import * as moment from 'moment';
import * as React from 'react';
import ReactDatePicker from 'react-datepicker';

// Utility References
import { Handler } from '../../../IndexOfActions';
//import {  } from '../../../IndexOfComponents';
//import {  } from '../../../IndexOfEnums';
import { DateHelper } from '../../../IndexOfHelpers';
//import {  } from '../../../IndexOfInterfaces';
import { IGridFilterContext } from '../../../IndexOfModels';
//import {  } from '../../../IndexOfServices';

//Local References
import ClearButton from './ClearButton';

type UpdateExpr = <TFilter, TValue>(filter: TFilter, value: TValue) => void;

interface IDateFilterProps extends React.Props<DateFilter> {
    gridFilterContext: IGridFilterContext;
    isDisabled?: boolean;
    key: string | number;
    label: string;
    updateExpr: UpdateExpr;
    value: Date;
}

export default class DateFilter extends React.Component<IDateFilterProps, {}> {

    private handleChange = (updateFunc: Handler.DateValue) => (newValue: Date): void => {

        const adjusted = moment(newValue).utc().add(moment(newValue).utcOffset(), 'm');
        updateFunc(moment(adjusted).toDate());
    }

    private reset = (updateFunc: Handler.DateValue) => () => {

        updateFunc(null);
    }

    render() {

        const { gridFilterContext, isDisabled, label, updateExpr, value } = this.props;

        const updateFunc = gridFilterContext.filterHandler(updateExpr);

        const inputValue = (!!value) ? value : null;

        const reactDatePickerProps = {
            className: 'form-control grid-date-filter',
            dateFormat: 'DD/MM/YYYY',
            disabled: isDisabled,
            onChange: this.handleChange(updateFunc),
            popoverAttachment: 'bottom center',
            popoverTargetAttachment: 'top right',
            placeholderText: label,
            selected: inputValue
        };

        if (!!inputValue) {
            reactDatePickerProps.className += ' active';
        }

        return (
            <th>
                <div className="input-group">
                    <ReactDatePicker {...reactDatePickerProps} />
                    <span className="input-group-btn">
                        <ClearButton isDisabled={isDisabled} resetClickHandler={this.reset(updateFunc)} value={value} />
                    </span>
                </div>
            </th>
        );
    }
}
