// 3rd Party References
import * as React from 'react';
import { hot } from 'react-hot-loader';
import { debounce } from 'underscore';

// Utility References
import { Handler } from 'Utility/IndexOfActions';
import {
    Card,
    CardBody,
    CardHeader,
    FormGroupWrapper,
    IFormGroupWrapperProps,
    IModelStateErrorProps,
    ISaveButtonProps,
    ISimpleCheckboxInputProps,
    ISimpleEmailInputProps,
    ISimpleMultiSelectNumberProps,
    ISimpleStringInputProps,
    ModelStateError,
    SaveButton,
    SimpleCheckboxInput,
    SimpleEmailInput,
    SimpleMultiSelectNumber,
    SimpleStringInput
} from 'Utility/IndexOfComponents';
import { FormControlHelper, FormHelper, ObjectHelper } from 'Utility/IndexOfHelpers';
import { IContentWidths, IRequiredField } from 'Utility/IndexOfInterfaces';
import { ModelUpdater } from 'Utility/IndexOfModels';

// App References
import {
    IApiRegistrationUpsertViewModel as IUpsert,
    IApiRegistrationViewModel as IViewModel
} from 'App/IndexOfInterfaces';
import {
    Constants,
    IPageActions,
    IPageState
} from 'App/IndexOfModels';

interface IPropActions {
    getTemplate: Handler.Action;
    onSave: Handler.Action1<IViewModel>;
    pageActions: IPageActions;
    receiveUpsert: Handler.Action1<IUpsert>;
    userNameCollisionCheck: Handler.Action1<string>;
}

interface IPropData {
    pageState: IPageState;
    termsVersion: string;
    upsert: IUpsert;
    userNameCollision: boolean;
}

interface INewRegistrationFormProps extends React.Props<Form> {
    propActions: IPropActions;
    propData: IPropData;
}

class Form extends React.Component<INewRegistrationFormProps, {}> {

    private debouncedCollisionCheck: Function;

    private getFormPropertyNames = (viewModel: IViewModel) => {

        return {
            addressLine1: ObjectHelper.getPropertyName(() => viewModel.addressLine1),
            addressLine2: ObjectHelper.getPropertyName(() => viewModel.addressLine2),
            businessNumber: ObjectHelper.getPropertyName(() => viewModel.reference),
            city: ObjectHelper.getPropertyName(() => viewModel.city),
            companyName: ObjectHelper.getPropertyName(() => viewModel.companyName),
            companyTelephone: ObjectHelper.getPropertyName(() => viewModel.companyTelephone),
            contactEmail: ObjectHelper.getPropertyName(() => viewModel.contactEmail),
            contactFirstName: ObjectHelper.getPropertyName(() => viewModel.contactFirstName),
            contactLastName: ObjectHelper.getPropertyName(() => viewModel.contactLastName),
            contactTelephone: ObjectHelper.getPropertyName(() => viewModel.contactTelephone),
            franchises: ObjectHelper.getPropertyName(() => viewModel.franchises),
            password: ObjectHelper.getPropertyName(() => viewModel.password),
            passwordConfirmation: ObjectHelper.getPropertyName(() => viewModel.passwordConfirmation),
            postCode: ObjectHelper.getPropertyName(() => viewModel.postCode),
            state: ObjectHelper.getPropertyName(() => viewModel.state),
            termsAccepted: ObjectHelper.getPropertyName(() => viewModel.termsAccepted),
            username: ObjectHelper.getPropertyName(() => viewModel.username)
        };
    }

    componentDidMount() {

        const { propActions } = this.props;

        propActions.getTemplate();

        this.debouncedCollisionCheck = debounce(propActions.userNameCollisionCheck, 500);
    }

    componentDidUpdate(prevProps) {

        const { propActions, propData } = this.props;

        const { pageActions: { clearAll, addError } } = propActions;

        const viewModel = propData.upsert && propData.upsert.model;

        if (prevProps.propData.userNameCollision !== propData.userNameCollision) {

            FormHelper.areRequiredPropertiesValid(clearAll, addError, this.getUsernameValidationInfo(viewModel, propData));
        }
    }

    componentWillUnmount() {

        const { propActions } = this.props;

        propActions.pageActions.clearAll();
    }

    private isViewModelValid = (viewModel: IViewModel): boolean => {

        const hasViewModel = !ObjectHelper.isUndefinedOrNull(viewModel);

        return hasViewModel;
    }

    private handleOnSave = (propActions: IPropActions, propData: IPropData, viewModel: IViewModel): void => {

        const { pageActions: { clearAll, addError } } = propActions;

        if (FormHelper.areRequiredPropertiesValid(
            clearAll,
            addError,
            [...this.getUsernameValidationInfo(viewModel, propData), ...this.getMainValidationInfo(viewModel)]
        )) {

            propActions.onSave(viewModel);
        }
    }

    private getMainValidationInfo(viewModel: IViewModel): IRequiredField[] {

        const formProperties = this.getFormPropertyNames(viewModel);

        const fieldsWithRequirements: IRequiredField[] = [
            { errorMessage: 'Please enter your address.', propertyName: formProperties.addressLine1, value: viewModel.addressLine1 },
            { errorMessage: 'Please enter your business number.', propertyName: formProperties.businessNumber, value: viewModel.reference },
            { errorMessage: 'Please enter your city.', propertyName: formProperties.city, value: viewModel.city },
            { errorMessage: 'Please enter your company name.', propertyName: formProperties.companyName, value: viewModel.companyName },
            { errorMessage: 'Please enter your company telephone number.', propertyName: formProperties.companyTelephone, value: viewModel.companyTelephone },
            { errorMessage: 'Please enter your contact email.', propertyName: formProperties.contactEmail, value: viewModel.contactEmail },
            { errorMessage: 'Please enter your first name.', propertyName: formProperties.contactFirstName, value: viewModel.contactFirstName },
            { errorMessage: 'Please enter your last name.', propertyName: formProperties.contactLastName, value: viewModel.contactLastName },
            { errorMessage: 'Please enter your telephone number.', propertyName: formProperties.contactTelephone, value: viewModel.contactTelephone },
            { errorMessage: 'Please enter your password.', propertyName: formProperties.password, value: viewModel.password },
            { errorMessage: 'Please confirm your password.', propertyName: formProperties.passwordConfirmation, value: viewModel.passwordConfirmation },
            { errorMessage: 'Please enter your post code.', propertyName: formProperties.postCode, value: viewModel.postCode },
            { errorMessage: 'Please confirm that you\'re happy with our terms and conditions.', propertyName: formProperties.termsAccepted, value: null, validate: (): boolean => viewModel.termsAccepted },
        ];

        if (viewModel.contactEmail) {

            fieldsWithRequirements.push({
                errorMessage: 'The email address is not valid.',
                propertyName: formProperties.contactEmail,
                value: viewModel.contactEmail,
                validate: (value): boolean => value && (value as string).match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) !== null
            });
        }

        return fieldsWithRequirements;
    }

    private getUsernameValidationInfo(viewModel: IViewModel, propData: IPropData): IRequiredField[] {

        const formProperties = this.getFormPropertyNames(viewModel);

        return [
            {
                errorMessage: 'This username already exists.',
                propertyName: formProperties.username,
                value: null,
                validate: (): boolean => !propData.userNameCollision
            },
            {
                errorMessage: 'Your username cannot contain special characters such as ! @ # $ % ^ & *.',
                propertyName: formProperties.username,
                value: viewModel.username,
                validate: (value): boolean => value && (value as string).match(/[!@#$%^&*]/g) === null
            },
            {
                errorMessage: 'Your username cannot contain spaces.',
                propertyName: formProperties.username,
                value: viewModel.username,
                validate: (value): boolean => value && (value as string).match(/\s/g) === null
            },
            {
                errorMessage: 'Please enter a username.',
                propertyName: formProperties.username,
                value: viewModel.username
            }
        ];
    }

    render() {

        const { propActions, propData } = this.props;

        const viewModel = propData.upsert && propData.upsert.model;

        if (!this.isViewModelValid(viewModel)) {
            return null;
        }

        const formProperties = this.getFormPropertyNames(viewModel);

        const formGroupWrapperPropsDefaults = {
            contentWidths: FormControlHelper.getContentWidths(
                'children',
                'error',
                'col-xs-12 col-md-6',
                'label')
        };

        const formGroupWrapperProps = (
            displayName: string,
            propertyName: string,
            contentWidths?: IContentWidths,
            isRequired?: boolean): IFormGroupWrapperProps => {

            return FormControlHelper.getFormGroupWrapperProps(

                contentWidths || formGroupWrapperPropsDefaults.contentWidths,
                this.props.propData.pageState.modelState,
                displayName,
                propertyName,
                isRequired || false);
        };

        const updateModel = (upsert: IUpsert, value: boolean | Date | number | string | string[], propertyName: string, namespace?: string): IUpsert => {

            const property = namespace ? upsert.model[namespace] : upsert.model;

            propActions.pageActions.removeEntry(property[propertyName]);
            property[propertyName] = value;

            return upsert;
        };

        const genericSimpleStringInputProps = (propertyName: string, value: string, maxLength: number = 100, namespace?: string, isDisabled?: boolean): ISimpleStringInputProps => {
            return {
                isDisabled,
                maxLength,
                onChangeCallback: modelUpdater.for<string>((value, model) => {
                    return updateModel(model, value, propertyName, namespace);
                }),
                value
            };
        };

        const modelUpdater = ModelUpdater(propData.upsert, propActions.receiveUpsert);

        const threeColumnWidths = FormControlHelper.getContentWidths(
            'children',
            'error',
            'col-xs-12 col-md-4',
            'label');

        const contactEmailProps: ISimpleEmailInputProps = {
            onChangeCallback: modelUpdater.for<string>((value, model) => {

                model.model.contactEmail = value;

                return model;
            }),
            value: viewModel.contactEmail
        };

        const franchiseFormWrapperProps = {

            ...formGroupWrapperProps('Manufacturers', formProperties.franchises, null, true)

        };

        const franchiseProps: ISimpleMultiSelectNumberProps = {
            showTextAsLabel: true,
            items: propData.upsert.tools.franchises,
            onSelectCallback: modelUpdater.for<number[]>((values, model) => {

                model.model.franchises = [];

                if (!ObjectHelper.isUndefinedOrNull(values) && values.length > 0) {
                    model.model.franchises = values.map(x => x);
                }

                return model;
            }),
            propertyName: formProperties.franchises,
            value: viewModel.franchises
        };

        const passwordProps = {
            ...genericSimpleStringInputProps(formProperties.password, viewModel.password, 100),
            ...{ password: true }
        };

        const passwordConfirmProps: ISimpleStringInputProps = {
            maxLength: 100,
            onBlurCallback: () => {

                if (viewModel.passwordConfirmation !== viewModel.password) {
                    propActions.pageActions.addError(formProperties.password, 'The passwords entered don\'t match.');
                } else {
                    propActions.pageActions.clearAll();
                }
            },
            onChangeCallback: modelUpdater.for<string>((value, model) => {

                model.model.passwordConfirmation = value;

                return model;
            }),
            password: true,
            value: viewModel.passwordConfirmation
        };

        const saveButtonProps: ISaveButtonProps = {
            buttonOptions: { alignRight: true, buttonText: 'Register' },
            onClick: () => {

                const clonedModel = ObjectHelper.deepClone(viewModel);

                this.handleOnSave(propActions, propData, clonedModel);
            },
            processing: propData.pageState.processing
        };

        const termsProps: ISimpleCheckboxInputProps = {
            id: 'terms',
            onChangeCallback: modelUpdater.for<boolean>((value, model) => {

                if (value) {
                    model.model.termsAcceptedVersion = propData.termsVersion;
                } else {
                    model.model.termsAcceptedVersion = '';
                }

                model.model.termsAccepted = value;

                return model;
            }),
            value: viewModel.termsAccepted
        };

        const termsStateErrorProps: IModelStateErrorProps = {
            propertyName: formProperties.termsAccepted,
            modelState: propData.pageState.modelState
        };

        const userNameProps: ISimpleStringInputProps = {
            maxLength: 100,
            onChangeCallback: modelUpdater.for<string>((value, model) => {

                model.model.username = value;

                if (value && value.length >= 3) {

                    const { pageActions: { clearAll, addError } } = propActions;

                    this.debouncedCollisionCheck(value);

                    FormHelper.areRequiredPropertiesValid(clearAll, addError, this.getUsernameValidationInfo(model.model, propData));
                }

                return model;
            }),
            value: viewModel.username
        };

        return (
            <div id="registerForm" className="row">
                <div className="col-sm-12">
                    <Card>
                        <CardBody>
                            <div className="form-horizontal">
                                <div className="form-row">
                                    <FormGroupWrapper {...franchiseFormWrapperProps}>
                                        <SimpleMultiSelectNumber {...franchiseProps} />
                                    </FormGroupWrapper>

                                    <FormGroupWrapper {...formGroupWrapperProps('ABN', formProperties.businessNumber, null, true)}>
                                        <SimpleStringInput {...genericSimpleStringInputProps(formProperties.businessNumber, viewModel.reference, 11)} />
                                    </FormGroupWrapper>
                                </div>

                                <div className="form-row">
                                    <FormGroupWrapper {...formGroupWrapperProps('Company Name', formProperties.companyName, null, true)}>
                                        <SimpleStringInput {...genericSimpleStringInputProps(formProperties.companyName, viewModel.companyName, 50)} />
                                    </FormGroupWrapper>

                                    <FormGroupWrapper {...formGroupWrapperProps('Company Telephone', formProperties.companyTelephone, null, true)}>
                                        <SimpleStringInput {...genericSimpleStringInputProps(formProperties.companyTelephone, viewModel.companyTelephone, 20)} />
                                    </FormGroupWrapper>
                                </div>

                                <div className="form-row">
                                    <FormGroupWrapper {...formGroupWrapperProps('Address Line 1', formProperties.addressLine1, null, true)}>
                                        <SimpleStringInput {...genericSimpleStringInputProps(formProperties.addressLine1, viewModel.addressLine1, 100)} />
                                    </FormGroupWrapper>

                                    <FormGroupWrapper {...formGroupWrapperProps('Address Line 2', formProperties.addressLine2, null, false)}>
                                        <SimpleStringInput {...genericSimpleStringInputProps(formProperties.addressLine2, viewModel.addressLine2, 100)} />
                                    </FormGroupWrapper>
                                </div>

                                <div className="form-row">
                                    <FormGroupWrapper {...formGroupWrapperProps('City', formProperties.city, threeColumnWidths, true)}>
                                        <SimpleStringInput {...genericSimpleStringInputProps(formProperties.city, viewModel.city, 50)} />
                                    </FormGroupWrapper>

                                    <FormGroupWrapper {...formGroupWrapperProps('State', formProperties.state, threeColumnWidths, false)}>
                                        <SimpleStringInput {...genericSimpleStringInputProps(formProperties.state, viewModel.state, 50)} />
                                    </FormGroupWrapper>

                                    <FormGroupWrapper {...formGroupWrapperProps('Post Code', formProperties.postCode, threeColumnWidths, true)}>
                                        <SimpleStringInput {...genericSimpleStringInputProps(formProperties.postCode, viewModel.postCode, 20)} />
                                    </FormGroupWrapper>
                                </div>

                                <div className="form-row">
                                    <FormGroupWrapper {...formGroupWrapperProps('Contact First Name', formProperties.contactFirstName, null, true)}>
                                        <SimpleStringInput {...genericSimpleStringInputProps(formProperties.contactFirstName, viewModel.contactFirstName, 50)} />
                                    </FormGroupWrapper>

                                    <FormGroupWrapper {...formGroupWrapperProps('Contact Last Name', formProperties.contactLastName, null, true)}>
                                        <SimpleStringInput {...genericSimpleStringInputProps(formProperties.contactLastName, viewModel.contactLastName, 50)} />
                                    </FormGroupWrapper>
                                </div>

                                <div className="form-row">
                                    <FormGroupWrapper {...formGroupWrapperProps('Contact Telephone', formProperties.contactTelephone, null, true)}>
                                        <SimpleStringInput {...genericSimpleStringInputProps(formProperties.contactTelephone, viewModel.contactTelephone, 20)} />
                                    </FormGroupWrapper>

                                    <FormGroupWrapper {...formGroupWrapperProps('Contact Email', formProperties.contactEmail, null, true)}>
                                        <SimpleEmailInput {...contactEmailProps} />
                                    </FormGroupWrapper>
                                </div>

                                <Card className="login-details">
                                    <CardHeader>
                                        <h3>Login Details</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="form-row">
                                            <FormGroupWrapper {...formGroupWrapperProps('Username', formProperties.username, null, true)}>
                                                <SimpleStringInput {...userNameProps} />
                                            </FormGroupWrapper>
                                        </div>

                                        <div className="form-row">
                                            <FormGroupWrapper {...formGroupWrapperProps('Password', formProperties.password, null, true)}>
                                                <SimpleStringInput {...passwordProps} />
                                            </FormGroupWrapper>

                                            <FormGroupWrapper {...formGroupWrapperProps('Confirm Password', formProperties.password, null, true)}>
                                                <SimpleStringInput {...passwordConfirmProps} />
                                            </FormGroupWrapper>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>

                            <br />

                            <div className="row">
                                <div className="col-md-6">
                                    <SimpleCheckboxInput {...termsProps} />
                                    <label className="terms-label" htmlFor="terms">I have read and accept the <a href="/terms-and-conditions">Terms and Conditions</a></label>
                                    <div>
                                        <ModelStateError {...termsStateErrorProps} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <SaveButton {...saveButtonProps} />
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div>
        );
    }
}

const HotForm = hot(module)(Form);

export {
    HotForm as NewRegistrationForm,
    INewRegistrationFormProps
};
