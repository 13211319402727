// 3rd Party References
import * as React from 'react';

// Utility References
import { OkCircleIcon, RemoveIcon } from 'Utility/IndexOfComponents';
import { CommonDictionary } from 'Utility/IndexOfHelpers';

interface IAuthorisedDisplayProps {
    value: boolean;
}

const authorisedDisplay = (props: IAuthorisedDisplayProps): JSX.Element => {

    const { value } = props;

    return (
        value
            ? <span className="text-success"><OkCircleIcon /> {CommonDictionary.Authorised}</span>
            : <span className="text-danger"><RemoveIcon /> {CommonDictionary.Unauthorised}</span>
    );
};

export {
    authorisedDisplay as AuthorisedDisplay,
    IAuthorisedDisplayProps
};
