// 3rd Party References
import * as React from 'react';
import * as TagsInput from 'react-tagsinput';

// Utility References
import { Handler } from 'Utility/IndexOfActions';

interface ISimpleTagInputProps {
    onChangeCallback: Handler.Action1Optional<string[]>;
    placeholderText?: string;
    tagList: string[];
}

class SimpleTagInput extends React.Component<ISimpleTagInputProps, {}> {

    render() {

        const { onChangeCallback, placeholderText, tagList } = this.props;

        const inputProps = {
            placeholder: placeholderText
        };

        return (
            <TagsInput value={tagList} onChange={onChangeCallback} inputProps={inputProps} />
        );
    }
}

export {
    ISimpleTagInputProps,
    SimpleTagInput
};
