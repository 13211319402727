//3rd Party References
import * as React from 'react';

//Utility References
import { Handler } from 'Utility/IndexOfActions';
import { ItemOptionHelper, ObjectHelper, TranslationHelper } from 'Utility/IndexOfHelpers';
import { IApiItemOptionViewModel } from 'Utility/IndexOfInterfaces';

export interface ISimpleCheckboxGridStringProps {
    enforceUniqueOptions?: boolean;
    gridClassName?: string;
    hasSelectAll?: boolean;
    inputClassName?: string;
    isDisabled?: boolean;
    itemClassName?: string;
    items: IApiItemOptionViewModel[];
    onSelectCallback: Handler.Action2Optional<string[], string>;
    propertyName?: string;
    value: string[];
}

interface ISimpleCheckboxGridStringState {
    allSelected: boolean;
}

export default class SimpleCheckboxGridString extends React.Component<ISimpleCheckboxGridStringProps, ISimpleCheckboxGridStringState> {

    constructor(props: ISimpleCheckboxGridStringProps) {
        super(props);

        this.state = {
            allSelected: false
        };
    }

    private handleOnSelectAll = (onSelectCallback: Handler.Action2Optional<string[], string>, items: IApiItemOptionViewModel[], selected: boolean, propertyName: string) => (): void => {

        if (selected) {
            onSelectCallback(items.map(x => x.value), propertyName);
        } else {
            onSelectCallback([], propertyName);
        }

        this.setState({ allSelected: selected });
    }

    private handleOnSelect = (onSelectCallback: Handler.Action2Optional<string[], string>, items: IApiItemOptionViewModel[], value: string, values: string[], propertyName: string) => (): void => {

        const selectedItem = items.filter(x => x.value === value)[0];
        let updatedValues = ObjectHelper.deepClone(values);

        if (!updatedValues) {
            updatedValues = [];
        }

        if (updatedValues.some(x => x === selectedItem.value)) {

            updatedValues = [];

            values.forEach(x => {
                if (x !== selectedItem.value) {
                    updatedValues.push(x);
                }
            });

        } else {
            updatedValues.push(selectedItem.value);
        }

        onSelectCallback(updatedValues, propertyName);
    }

    private getItemOptionElements = (allSelected: boolean, enforceUniqueOptions: boolean, hasSelectAll: boolean, isDisabled: boolean, itemClassName: string, items: IApiItemOptionViewModel[], onSelectCallback: Handler.Action2Optional<string[], string>, propertyName: string, values: string[]): JSX.Element[] => {

        if (!items) {
            return null;
        }

        if (enforceUniqueOptions === true) {

            ItemOptionHelper(items).assertAllItemsUnique();
        }

        const selectAllElement = (
            <div className="checkbox-item col-sm-12" key={'first'}>
                <span className="multiselect-all">
                    <label className="checkbox">
                        <input type="checkbox" checked={allSelected} onChange={this.handleOnSelectAll(onSelectCallback, items, !allSelected, propertyName)} value="multiselect-all" /> {TranslationHelper.CommonDictionary.SelectAll}
                    </label>
                </span>
            </div>
        );

        const options: JSX.Element[] = [];

        if (hasSelectAll){
            options.push(selectAllElement);
        }

        items.forEach((item: IApiItemOptionViewModel, index: number) => {

            const isChecked = ObjectHelper.isUndefinedOrNull(values) ? false : values.some(x => x === item.value);

            const itemElement = (
                <div className={itemClassName ? itemClassName : 'checkbox-item col-sm-6 col-md-3'} key={index}>
                    <span>
                        <label className="checkbox" htmlFor={`checkbox-${index}`}>
                            <input id={`checkbox-${index}`} type="checkbox" checked={isChecked} onChange={this.handleOnSelect(onSelectCallback, items, item.value, values, propertyName)} value={item.value} /> {TranslationHelper.translate(item.text)}
                        </label>
                    </span>
                </div>
            );

            options.push(itemElement);
        });

        return options;
    }

    render() {

        const { enforceUniqueOptions, gridClassName, hasSelectAll, isDisabled, itemClassName, items, onSelectCallback, propertyName, value } = this.props;
        const { allSelected } = this.state;

        const optionElements = this.getItemOptionElements(allSelected, enforceUniqueOptions, hasSelectAll, isDisabled, itemClassName, items, onSelectCallback, propertyName, value);

        return (
            <div className={gridClassName ? gridClassName : 'checkbox-grid row'}>
                {optionElements}
            </div>
        );
    }
}
