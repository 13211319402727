// 3rd Party
import * as React from 'react';

// Base References
import { BaseView } from '../Base/View';

// Utility References
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle
} from 'Utility/IndexOfComponents';

// App References
import {
    IApiAccountDetailsViewModel as IViewModel
} from 'App/IndexOfInterfaces';
import {
    AccountDetailsForm,
    AccountPreferencesForm,
    AccountSecurityForm,
    IAccountDetailsFormProps,
    IAccountPreferencesFormProps
} from 'App/IndexOfModels';

class View extends BaseView<{}> {

    render() {

        const { appActions, appState } = this.props;

        const accountDetailsFormProps: IAccountDetailsFormProps = {
            propActions: {
                getAccountDetails: appActions.acountDetailsActions.getAccountDetails,
                resetAll: appActions.acountDetailsActions.resetAll
            },
            propData: {
                accountDetails: appState.accountDetails.accountDetails,
                pageState: appState.page
            }
        };

        const accountPreferencesFormProps: IAccountPreferencesFormProps = {
            propActions: {
                getAccountDetails: appActions.acountDetailsActions.getAccountDetails,
                getNotificationPreferenceOptions: appActions.acountDetailsActions.getNotificationPreferenceOptions,
                receiveAccountDetails: appActions.acountDetailsActions.receiveAccountDetails,
                resetAll: appActions.acountDetailsActions.resetAll,
                updatePreferences: appActions.acountDetailsActions.updatePreferences
            },
            propData: {
                accountDetails: appState.accountDetails.accountDetails,
                notificationPreferenceOptions: appState.accountDetails.notificationPreferenceOptions,
                pageState: appState.page
            }
        };

        return (
            <div id="account-tab" className="tab-pane active show">
                <div className="row">
                    <div className="col-md-6">
                        <Card>
                            <CardHeader>
                                <CardTitle text="Personal Information"/>
                            </CardHeader>
                            <CardBody>
                                <AccountDetailsForm {...accountDetailsFormProps}/>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-md-6">
                        <div className="row mb-4">
                            <div className="col-sm-12">
                                <Card>
                                    <CardHeader>
                                        <CardTitle text="Security"/>
                                    </CardHeader>
                                    <CardBody>
                                        <AccountSecurityForm />
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-sm-12">
                                <Card>
                                    <CardHeader>
                                        <CardTitle text="Notification Preferences"/>
                                    </CardHeader>
                                    <CardBody>
                                        <AccountPreferencesForm {...accountPreferencesFormProps}/>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export {
    View as AccountView
};
