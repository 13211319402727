//3rd Party References
import * as React from 'react';

//Utility References
//import {  } from '../../IndexOfActions';
//import {  } from '../../IndexOfComponents';
import { ButtonStyle } from '../../IndexOfEnums';
import { AnimationHelper, ButtonHelper, ObjectHelper } from '../../IndexOfHelpers';
import { IButtonOptions } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

export interface ICustomLinkButtonProps extends React.Props<CustomLinkButton> {
    buttonOptions: IButtonOptions;
    href: string;
    isDisabled?: boolean;
    targetNewTab?: boolean;
}

export default class CustomLinkButton extends React.Component<ICustomLinkButtonProps, {}> {

    private primaryInput: HTMLAnchorElement;

    public setFocus = (scroll?: number): void => {
        AnimationHelper.setFocus(this.primaryInput, scroll);
    }

    render() {

        const { buttonOptions, children, href, isDisabled, targetNewTab } = this.props;

        const defaultButtonOptions: IButtonOptions = {
            buttonStyle: ButtonStyle.Default,
            buttonText: '-'
        };

        let anchorProps = {
            className: ButtonHelper.getButtonClasses(defaultButtonOptions, buttonOptions),
            href,
            onClick: undefined,
            ref: (component: HTMLAnchorElement) => { this.primaryInput = component; },
            role: 'button',
            target: '_self'
        };

        if (isDisabled) {

            const disabledProps = {
                className: anchorProps.className + ' disabled',
                onClick: (event: React.MouseEvent<HTMLAnchorElement>) => { event.preventDefault(); }
            };

            anchorProps = ObjectHelper.merge(anchorProps, disabledProps);
        }

        if (targetNewTab) {
            anchorProps = ObjectHelper.merge(anchorProps, { target: '_blank' });
        }

        const buttonTextDisplay = ButtonHelper.getButtonText(defaultButtonOptions, buttonOptions);

        return (
            <a {...anchorProps}>
                {children}{children && ' '}{buttonTextDisplay}
            </a>
        );
    }
}
