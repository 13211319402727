// Utility References
import { ExceptionHelper } from 'Utility/IndexOfHelpers';
import { IApiException, IApiExceptionGroup, IGenericDictionary } from 'Utility/IndexOfInterfaces';
import { Notification } from 'Utility/IndexOfServices';

const Exception = ExceptionHelper.ApiException;

interface IResetPasswordExceptions extends IGenericDictionary<IApiException> {
    ResetLinkExpired: IApiException;
    ResetPasswordIdentical: IApiException;
    ResetTokenInvalid: IApiException;
}

const exceptions: IResetPasswordExceptions = {
    ResetLinkExpired: Exception('Your reset password link has expired, please request another reset password link.', Notification.error.handledException),
    ResetPasswordIdentical: Exception('Your new password cannot be the same as your old password, please choose something different.', Notification.error.handledException),
    ResetTokenInvalid: Exception('Invalid reset password link has been used, please request another reset password link.', Notification.error.handledException)
};

const exceptionGroup: IApiExceptionGroup = {
    area: 'ResetPassword',
    exceptions
};

export {
    exceptionGroup as ResetPasswordExceptionGroup,
    exceptions as ResetPasswordExceptions
};
