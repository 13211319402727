// 3rd Party References
import { Action, handleActions } from 'redux-actions';

// Utility References
import { ReduxHelper } from 'Utility';

// App References
import { IApiAccountDetailsViewModel } from 'App/IndexOfInterfaces';
import { IApiNotificationPreferenceToolsViewModel } from 'App/Interfaces/NotificationPreferenceInterfaces';

// Models
import { AccountDetailsActionTypes as ActionTypes } from './Models';

interface IState {
    accountDetails: IApiAccountDetailsViewModel;
    notificationPreferenceOptions: IApiNotificationPreferenceToolsViewModel;
}

const initialState = (): IState => {
    return {
        accountDetails: {
            address: null,
            city: null,
            contactTelephone: null,
            email: null,
            firstName: null,
            lastName: null,
            notificationPreferences: [],
            postCode: null,
            primaryContact: null,
            state: null
        },
        notificationPreferenceOptions: {
            notificationTypes: []
        }
    };
};

const actionWrapper = ReduxHelper.actionWrapper;

type Payload = IApiAccountDetailsViewModel | IApiNotificationPreferenceToolsViewModel | void;

const reducer = handleActions<IState, Payload>({

    [ActionTypes.receive.accountDetails]: (state: IState, action: Action<IApiAccountDetailsViewModel>): IState => {
        return actionWrapper(state, action, (newState: IState) => {
            return newState.accountDetails = action.payload;
        });
    },

    [ActionTypes.receive.notificationPreferenceOptions]: (state: IState, action: Action<IApiNotificationPreferenceToolsViewModel>): IState => {
        return actionWrapper(state, action, (newState: IState) => {
            return newState.notificationPreferenceOptions = action.payload;
        });
    },

    [ActionTypes.reset.all]: (): IState => {
        return initialState();
    }

}, initialState());

export {
    IState as IAccountDetailsState,
    reducer as AccountDetailsReducer
};
