// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from '../../IndexOfActions';
import { ISimpleStringInputProps, SimpleStringInput } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
//import {  } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

export interface ISimpleV5CheckDigitInputProps extends React.Props<SimpleV5CheckDigitInput> {
    isDisabled?: boolean;
    onBlurCallback?: Handler.InputFocusEvent;
    onChangeCallback: Handler.StringProperty;
    onKeyPressCallback?: Handler.InputKeyboardEvent;
    placeholder?: string;
    propertyName?: string;
    tabIndex?: number;
    value: string;
}

export default class SimpleV5CheckDigitInput extends React.Component<ISimpleV5CheckDigitInputProps, {}> {

    private primaryInput: SimpleStringInput;

    public setFocus = (scroll?: number): void => {
        this.primaryInput.setFocus(scroll);
    }

    render() {

        const { isDisabled, onBlurCallback, onChangeCallback, onKeyPressCallback, placeholder, propertyName, tabIndex, value } = this.props;

        const simpleStringInputProps: ISimpleStringInputProps = {
            isDisabled,
            maxLength: 1,
            onBlurCallback,
            onChangeCallback,
            onKeyPressCallback,
            placeholder,
            propertyName,
            ref: (component: SimpleStringInput) => { this.primaryInput = component; },
            tabIndex,
            uppercase: true,
            value
        };

        return <SimpleStringInput {...simpleStringInputProps} />;
    }
}
