// 3rd Party
import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';

// Utility References
import { IApiProgressFunctions } from 'Utility';
import { Handler, NavigationActions } from 'Utility/IndexOfActions';
import { Notification } from 'Utility/IndexOfServices';

// App References
import {
    AllExceptionNotifier,
    IApiImpersonationViewModel,
    IAuthenticationInfoViewModel as IAuthViewModel
} from 'App/IndexOfInterfaces';
import {
    Constants,
    GenericQueryActions,
    LoginAreaQueryActionTypes as QueryActionTypes
} from 'App/IndexOfModels';

import { LocalStorageService, UserApi } from 'App/IndexOfServices';

import { AppUrls } from 'App/IndexOfUrls';

interface IImpersonationActions {
    impersonateAsync: Handler.Action1<IApiImpersonationViewModel>;
}

class ImpersonationActions extends GenericQueryActions<null, null, null, null> {

    public impersonateAsync = (impersonateViewModel: IApiImpersonationViewModel) => (dispatch: Handler.AnyForDispatch): void => {

        LocalStorageService.clearAll();

        const onSuccess = (authInfo: IAuthViewModel) => {

            Notification.success.authenticated();
            LocalStorageService.setAccessToken(authInfo.tokenInfo);
            LocalStorageService.setUserInfo(authInfo.user);

            window.history.pushState(null, null, '/');

            // will sort out other actions after
            if (LocalStorageService.isInRole(Constants.RoleNames.Insurer)) {
                NavigationActions.HardRedirectPageWithoutNotification(AppUrls.DashboardUrls.running, 0);
            }
            else {
                NavigationActions.HardRedirectPageWithoutNotification(AppUrls.SupplyRequestUrls.listings, 0);
            }
        };

        this.api.callWithPayload(UserApi.impersonate(), impersonateViewModel, onSuccess, this.allExceptionNotifier);
    }
}

const actionDispatcherFactory = (dispatch: Dispatch<void>, progressFunctions: IApiProgressFunctions): IImpersonationActions => {

    const notificationsTitle = 'Impersonation';
    const actions = new ImpersonationActions(progressFunctions, AllExceptionNotifier(notificationsTitle), null, QueryActionTypes);

    return {
        impersonateAsync: (impersonateViewModel: IApiImpersonationViewModel) => {
            dispatch(actions.impersonateAsync(impersonateViewModel));
        }
    };
};

export {
    actionDispatcherFactory as ImpersonationActionsDispatcherFactory,
    IImpersonationActions
};
