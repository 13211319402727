//3rd Party References
import * as React from 'react';

//Utility References
import { Handler } from 'Utility/IndexOfActions';
import { AnimationHelper, CommonDictionary, ItemOptionHelper, ObjectHelper, StringHelper, TranslationHelper } from 'Utility/IndexOfHelpers';
import { IApiItemOptionViewModel } from 'Utility/IndexOfInterfaces';

export interface ISimpleMultiSelectStringProps {
    alwaysUsePromptText?: boolean;
    displayName?: string;
    inputClassName?: string;
    isDisabled?: boolean;
    isFilter?: boolean;
    items: IApiItemOptionViewModel[];
    onBlurCallback?: Handler.SelectFocusEvent;
    onSelectCallback: Handler.Action2Optional<string[], string>;
    onKeyPressCallback?: Handler.SelectKeyboardEvent;
    propertyName?: string;
    promptText?: string;
    showTextAsLabel?: boolean;
    tabIndex?: number;
    value: string[];
}

interface ISimpleMultiSelectStringState {
    allSelected: boolean;
    componentWidth: number;
    listOpen: boolean;
}

export default class SimpleMultiSelectString extends React.Component<ISimpleMultiSelectStringProps, ISimpleMultiSelectStringState> {

    private primaryInput: HTMLSelectElement;
    private dropDownNode: React.RefObject<HTMLDivElement>;
    private dropDownMenu: React.RefObject<HTMLDivElement>;

    constructor(props: ISimpleMultiSelectStringProps) {
        super(props);

        this.dropDownNode = React.createRef();
        this.dropDownMenu = React.createRef();

        this.state = {
            allSelected: false,
            componentWidth: 0,
            listOpen: false
        };
    }

    componentDidMount() {

        const componentWidth = this.dropDownNode.current.clientWidth;

        const clonedState = ObjectHelper.deepClone(this.state as ISimpleMultiSelectStringState);
        clonedState.componentWidth = componentWidth;

        this.setState(clonedState);
    }

    public setFocus = (scroll?: number): void => {
        AnimationHelper.setFocus(this.primaryInput, scroll);
    }

    private openList = (): void => {

        const clonedState = ObjectHelper.deepClone(this.state as ISimpleMultiSelectStringState);
        clonedState.listOpen = true;

        this.setState(clonedState);

        document.addEventListener('click', this.closeList, false);
    }

    private closeList = (e: Event): void => {

        const targetElement = (e.target as Node);

        if (this.dropDownMenu.current.contains(targetElement)) {
            return;
        }

        const clonedState = ObjectHelper.deepClone(this.state as ISimpleMultiSelectStringState);
        clonedState.listOpen = false;

        this.setState(clonedState);

        document.removeEventListener('click', this.closeList, false);
    }

    private handleOnSelectAll = (onSelectCallback: Handler.Action2Optional<string[], string>, items: IApiItemOptionViewModel[], selected: boolean, propertyName: string) => (): void => {

        if (selected) {
            onSelectCallback(items.map(x => x.value), propertyName);
        } else {
            onSelectCallback([], propertyName);
        }

        const clonedState = ObjectHelper.deepClone(this.state as ISimpleMultiSelectStringState);
        clonedState.allSelected = selected;

        this.setState(clonedState);
    }

    private handleOnSelect = (onSelectCallback: Handler.Action2Optional<string[], string>, items: IApiItemOptionViewModel[], value: string, values: string[], propertyName: string) => (): void => {

        const selectedItem = items.filter(x => x.value === value)[0];
        let updatedValues = ObjectHelper.deepClone(values);

        if (!updatedValues) {
            updatedValues = [];
        }

        if (updatedValues.some(x => x === selectedItem.value)) {

            updatedValues = [];

            values.forEach(x => {
                if (x !== selectedItem.value) {
                    updatedValues.push(x);
                }
            });

        } else {
            updatedValues.push(selectedItem.value);
        }

        onSelectCallback(updatedValues, propertyName);
    }

    private getTranslatedDisplayName = (displayName: string): string => {

        return TranslationHelper.translate(displayName);
    }

    private getItemOptionsSelectedText = (defaultText: string, items: IApiItemOptionViewModel[], values: string[], showTextAsLabel?: boolean): string => {

        if (!items || items.length === 0) {
            return TranslationHelper.CommonDictionary.NoOptionsAvailable;
        }

        if (!values || values.length === 0) {
            return defaultText;
        }

        if (!showTextAsLabel) {
            return values.join(', ');
        }

        const selectedItemsText: string[] = [];

        values.forEach((value) => {

            const selectedItem = items.filter(x => x.value === value)[0];

            if (selectedItem) {
                selectedItemsText.push(selectedItem.text);
            }
        });

        return selectedItemsText.join(', ');
    }

    private getItemOptionElements = (allSelected: boolean, items: IApiItemOptionViewModel[], onSelectCallback: Handler.Action2Optional<string[], string>, propertyName: string, values: string[]): JSX.Element[] => {

        if (!items) {
            return null;
        }

        ItemOptionHelper(items).assertAllItemsUnique();

        const selectAllElement = (
            <li className="dropdown-item" key={'first'}>
                <span className="multiselect-all">
                    <label className="checkbox">
                        <input type="checkbox" checked={allSelected} onChange={this.handleOnSelectAll(onSelectCallback, items, !allSelected, propertyName)} value="multiselect-all" /> {TranslationHelper.CommonDictionary.SelectAll}
                    </label>
                </span>
            </li>
        );

        const options = [selectAllElement];

        items.forEach((item: IApiItemOptionViewModel, index: number) => {

            const isChecked = ObjectHelper.isUndefinedOrNull(values) ? false : values.some(x => x === item.value);

            const itemElement = (
                <li className={`dropdown-item ${isChecked ? 'active' : ''}`} onClick={this.handleOnSelect(onSelectCallback, items, item.value, values, propertyName)} key={index}>
                    <span>
                        <label className="checkbox">
                            <input type="checkbox" checked={isChecked} onChange={this.handleOnSelect(onSelectCallback, items, item.value, values, propertyName)} value={item.value} /> {TranslationHelper.translate(item.text)}
                        </label>
                    </span>
                </li>
            );

            options.push(itemElement);
            }
        );

        return options;
    }

    render() {

        const { alwaysUsePromptText, displayName, isDisabled, isFilter, items, /*onBlurCallback,*/ onSelectCallback, /*onKeyPressCallback,*/ propertyName, promptText, showTextAsLabel, /*tabIndex,*/ value } = this.props;
        const { allSelected, componentWidth, listOpen } = this.state;

        let translatedDisplayText: string;
        let title: string;

        const translatedPrompt = TranslationHelper.translate(promptText);

        if (!displayName && !propertyName) {

            translatedDisplayText = null;
            title = promptText ? translatedPrompt : CommonDictionary.PleaseSelect;

        } else {

            const displayNameToUse = displayName || StringHelper.toLabelFormat(propertyName);
            translatedDisplayText = this.getTranslatedDisplayName(displayNameToUse);
            title = promptText ? translatedPrompt : `${CommonDictionary.PleaseSelect} ${translatedDisplayText}`;
        }

        const defaultText = isFilter && !alwaysUsePromptText ? CommonDictionary.Any : title;

        const selectText = this.getItemOptionsSelectedText(defaultText, items, value, showTextAsLabel);
        const optionElements = this.getItemOptionElements(allSelected, items, onSelectCallback, propertyName, value);

        return (
            <div className="multiselect-native-select">
                <div className="dropdown" ref={this.dropDownNode}>
                    <button type="button" className="btn btn-secondary dropdown-toggle multiselect text-left" disabled={isDisabled} onClick={this.openList}>
                        <span className="multiselect-selected-text text-wrap">{selectText}</span>{' '}<i className="fa fa-option-horizontal"></i>
                    </button>
                    <div className={`dropdown-menu ${listOpen ? 'show' : ''}`} ref={this.dropDownMenu}>
                        <ul className="multiselect-container list-unstyled" style={{ width: componentWidth }}>
                            {optionElements}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
