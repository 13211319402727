//3rd Party References
import * as React from 'react';

// Local References
import { RenderInBody } from '../../Components/UIElements/RenderInBody';

export interface IProcessingBarProps extends React.Props<ProcessingBar> {
    processing: boolean;
}

export class ProcessingBar extends React.Component<IProcessingBarProps, {}> {

    private processingClass = 'processing';

    componentWillReceiveProps(nextProps: IProcessingBarProps){

        if (nextProps.processing){
            document.documentElement.classList.add(this.processingClass);
        } else {
            document.documentElement.classList.remove(this.processingClass);
        }
    }

    componentWillUnmount(){
        document.body.classList.remove(this.processingClass);
    }

    render() {

        const { processing } = this.props;

        if (processing) {

            const progressBarStyle = {
                width: '100%'
            };

            const ariaValueNow = 40;
            const ariaValueMin = 0;
            const ariaValueMax = 100;

            return (
                <RenderInBody>
                    <div style={{ left: '0', position: 'absolute', right: '0', top: '-15px' }}>
                        <div className="progress">
                            <div className="progress-bar progress-bar-success progress-bar-striped active"
                                role="progressbar"
                                aria-valuenow={ariaValueNow}
                                aria-valuemin={ariaValueMin}
                                aria-valuemax={ariaValueMax}
                                style={progressBarStyle}>
                            </div>
                        </div>
                        <div className="cssload-fond">
                            <div className="cssload-container-general">
                                <div className="cssload-internal"><div className="cssload-ballcolor cssload-ball_1"></div></div>
                                <div className="cssload-internal"><div className="cssload-ballcolor cssload-ball_2"></div></div>
                                <div className="cssload-internal"><div className="cssload-ballcolor cssload-ball_3"></div></div>
                                <div className="cssload-internal"><div className="cssload-ballcolor cssload-ball_4"></div></div>
                            </div>
                        </div>
                    </div>
                </RenderInBody>
            );
        }

        return null;
    }
}
