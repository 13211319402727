// 3rd Party References
import { Action, handleActions } from 'redux-actions';

// Utility References
import { ReduxHelper } from 'Utility';

// App References
import { IApiChangePasswordViewModel } from 'App/IndexOfInterfaces';

// Models
import { ChangePasswordActionTypes as ActionTypes } from './Models';

interface IState {
    changePassword: IApiChangePasswordViewModel;
}

const initialState = (): IState => {
    return {
        changePassword: {
            newPassword: null,
            newPasswordConfirmation: null,
            oldPassword: null
        }
    };
};

const actionWrapper = ReduxHelper.actionWrapper;

type Payload = IApiChangePasswordViewModel;

const reducer = handleActions<IState, Payload>({

    [ActionTypes.receive.changePassword]: (state: IState, action: Action<IApiChangePasswordViewModel>): IState => {
        return actionWrapper(state, action, (newState: IState) => {
            newState.changePassword = action.payload;
        });
    },

    [ActionTypes.reset.resetForm]: (): IState => {
        return initialState();
    }

}, initialState());

export {
    IState as IChangePasswordState,
    reducer as ChangePasswordReducer
};
