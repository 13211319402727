//3rd Party References
import * as React from 'react';

//Utility References
import { Handler } from 'Utility/IndexOfActions';
import { OkCircleIcon } from 'Utility/IndexOfComponents';
import { ButtonStyle } from 'Utility/IndexOfEnums';
import { AnimationHelper, ButtonHelper } from 'Utility/IndexOfHelpers';
import { IButtonOptions, ICommonButtonProps } from 'Utility/IndexOfInterfaces';

interface IAuthoriseButtonProps extends ICommonButtonProps {
    buttonOptions?: IButtonOptions;
    isDisabled?: boolean;
    onClick: Handler.Action;
    processing?: boolean;
    tabIndex?: number;
}

class AuthoriseButton extends React.Component<IAuthoriseButtonProps, {}> {

    private primaryInput: HTMLButtonElement;

    public setFocus = (scroll?: number): void => {
        AnimationHelper.setFocus(this.primaryInput, scroll);
    }

    render() {

        const { buttonOptions, isDisabled, onClick, processing, tabIndex } = this.props;

        const defaultButtonOptions: IButtonOptions = {
            buttonStyle: ButtonStyle.Success,
            buttonText: 'Authorise'
        };

        const buttonProps = {
            className: ButtonHelper.getButtonClasses(defaultButtonOptions, buttonOptions),
            disabled: isDisabled,
            onClick: ButtonHelper.getButtonOnClickHandler(onClick, buttonOptions),
            ref: (component: HTMLButtonElement) => { this.primaryInput = component; },
            role: 'button',
            tabIndex,
            type: 'button'
        };

        const buttonTextDisplay = ButtonHelper.getButtonText(defaultButtonOptions, buttonOptions);

        return (
            <button {...buttonProps}>
                <OkCircleIcon processing={processing} /> {buttonTextDisplay}
            </button>
        );
    }
}

export {
    IAuthoriseButtonProps,
    AuthoriseButton
};
