// 3rd Party
import * as React from 'react';
import { hot } from 'react-hot-loader';

// Base References
import { BaseView } from '../Base/View';

// App References
import {
    IResetPasswordFormProps,
    ResetPasswordForm
} from 'App/IndexOfModels';

class View extends BaseView<{}> {

    render() {

        const { appActions, appState } = this.props;

        const resetFormProps: IResetPasswordFormProps = {
            propActions: {
                onSubmit: appActions.resetPasswordActions.submit,
                pageActions: appActions.pageActions,
                receiveResetPassword: appActions.resetPasswordActions.receiveResetPassword
            },
            propData: {
                resetPassword: appState.resetPassword.resetPassword,
                pageState: appState.page
            }
        };

        return (
            <>
                <div className="col-sm-12 mb-3">
                    <h1>Reset Password</h1>
                    <p>Please enter your new password</p>
                </div>

                <ResetPasswordForm {...resetFormProps} />
            </>
        );
    }
}

export {
    View as ResetPasswordView
};
