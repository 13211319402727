// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from '../../../IndexOfActions';
//import {  } from '../../../IndexOfComponents';
//import {  } from '../../../IndexOfEnums';
//import {  } from '../../../IndexOfHelpers';
import { IApiItemOptionViewModel  } from '../../../IndexOfInterfaces';
import { IGridFilterContext } from '../../../IndexOfModels';
//import {  } from '../../../IndexOfServices';

// Local References
import GenericDropdown from '../Internal/GenericDropdown';

type UpdateExpr = <TFilter, TValue>(filter: TFilter, value: TValue) => void;

interface IYesNoFilterProps extends React.Props<YesNoFilter> {
    gridFilterContext: IGridFilterContext;
    isDisabled?: boolean;
    key: string | number;
    label: string;
    updateExpr: UpdateExpr;
    value?: boolean;
}

export default class YesNoFilter extends React.Component<IYesNoFilterProps, {}> {

    private yesNoOptions: IApiItemOptionViewModel[] = [];

    constructor(props: IYesNoFilterProps) {
        super(props);

        this.yesNoOptions = [
            { text: 'Yes', value: 'true' },
            { text: 'No', value: 'false' }
        ];
    }

    private handleChange = (updateFunc: Handler.BooleanValue) => (selectedValue: string): void => {

        const valueAsBoolean = this.getValueAsBoolean(selectedValue);
        updateFunc(valueAsBoolean);
    }

    private reset = (updateFunc: Handler.BooleanValue) => () => {

        updateFunc(null);
    }

    private getValueAsBoolean = (value?: string): boolean => {
        if (value === null) {
            return null;
        }

        return value === 'true';
    }

    private getValueAsString = (value?: boolean): string => {
        if (value === null || value === undefined) {
            return null;
        }

        return value ? 'true' : 'false';
    }

    render() {

        const { gridFilterContext, isDisabled, label, updateExpr, value } = this.props;

        const updateFunc = gridFilterContext.filterHandler(updateExpr);

        const selectedValueAsString = this.getValueAsString(value);

        const dropdownProps = {
            displayName: label,
            isDisabled,
            isRequired: false,
            items: this.yesNoOptions,
            onChangeCallback: this.handleChange(updateFunc),
            propertyName: label ? label + 'Filter' : 'YesNoFilter',
            selectClassName: 'form-control grid-filter',
            selectableEmptyValue: 'Any',
            selectedValue: selectedValueAsString
        };

        return (
            <th>
                <GenericDropdown {...dropdownProps} />
            </th>
        );
    }
}
