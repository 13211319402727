// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from '../../IndexOfActions';
//import {  } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
import { KeyboardEventHelper } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

// Local References
import InternalCommonIntegerInput, { IInternalCommonIntegerInputProps } from './Internal/InternalCommonIntegerInput';

export interface ISimplePositiveIntegerInputProps extends React.Props<SimplePositiveIntegerInput> {
    appendedInputClassName?: string;
    disallowZero?: boolean;
    isDisabled?: boolean;
    isHidden?: boolean;
    max?: number;
    onBlurCallback?: Handler.InputFocusEvent;
    onChangeCallback: Handler.NumberProperty;
    onKeyPressCallback?: Handler.InputKeyboardEvent;
    placeholder?: string;
    propertyName?: string;
    tabIndex?: number;
    value: number;
}

export class SimplePositiveIntegerInput extends React.Component<ISimplePositiveIntegerInputProps, {}> {

    private primaryInput: InternalCommonIntegerInput;

    public setFocus = (scroll?: number): void => {
        this.primaryInput.setFocus(scroll);
    }

    private handleOnKeyPress = (onKeyPressCallback?: Handler.InputKeyboardEvent) => (event: React.KeyboardEvent<HTMLInputElement>) => {

        if (!KeyboardEventHelper.isNumericOrEnterKeyPress(event)) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (onKeyPressCallback) {
            onKeyPressCallback(event);
        }
    }

    private handleOnChange = (disallowZero: boolean, onChangeCallback: Handler.NumberProperty) => (value: number, propertyName?: string) => {

        if (value === null || value === undefined) {

            onChangeCallback(null, propertyName);

        } else if (value <= 0) {

            if (disallowZero) {

                onChangeCallback(null, propertyName);

            } else {

                onChangeCallback(0, propertyName);
            }

        } else {

            onChangeCallback(value, propertyName);
        }
    }

    render() {

        const { appendedInputClassName, disallowZero, isDisabled, isHidden, max, onBlurCallback, onChangeCallback, onKeyPressCallback, placeholder, propertyName, tabIndex, value } = this.props;

        const commonInputProps: IInternalCommonIntegerInputProps = {
            appendedInputClassName,
            isDisabled,
            isHidden,
            max: max || 999999999,
            onBlurCallback,
            onChangeCallback: this.handleOnChange(disallowZero, onChangeCallback),
            onKeyPressCallback: this.handleOnKeyPress(onKeyPressCallback),
            placeholder,
            propertyName,
            ref: (component: InternalCommonIntegerInput) => { this.primaryInput = component; },
            tabIndex,
            value
        };

        return <InternalCommonIntegerInput {...commonInputProps} />;
    }
}
