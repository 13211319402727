//3rd Party References
import * as React from 'react';

//Utility References
import { Handler } from '../../../IndexOfActions';
//import {  } from '../../../IndexOfComponents';
//import {  } from '../../../IndexOfEnums';
import { AnimationHelper } from '../../../IndexOfHelpers';
//import {  } from '../../../IndexOfInterfaces';
//import {  } from '../../../IndexOfModels';
//import {  } from '../../../IndexOfServices';

export interface IInternalCommonMixedInputProps extends React.Props<InternalCommonMixedInput> {
    appendedInputClassName?: string;
    isDisabled: boolean;
    isHidden: boolean;
    max: number;
    onBlurCallback: Handler.InputFocusEvent;
    onChangeCallback: Handler.InputFormEvent;
    onKeyPressCallback: Handler.InputKeyboardEvent;
    placeholder: string;
    propertyName: string;
    tabIndex: number;
    value: string;
}

//Type is Number, Value is String.
export default class InternalCommonMixedInput extends React.Component<IInternalCommonMixedInputProps, {}> {

    private primaryInput: HTMLInputElement;

    public setFocus = (scroll?: number): void => {
        AnimationHelper.setFocus(this.primaryInput, scroll);
    }

    private handleOnChange = (max: number, onChangeCallback: Handler.InputFormEvent) => (event: React.FormEvent<HTMLInputElement>) => {

        const value = (event.target as HTMLInputElement).value;

        if (!!max && parseInt(value, 10) > max) {
            event.preventDefault();
        } else {
            onChangeCallback(event);
        }
    }

    render() {

        const { appendedInputClassName, isDisabled, isHidden, max, onBlurCallback, onChangeCallback, onKeyPressCallback, placeholder, propertyName, tabIndex, value } = this.props;

        let inputClassName = isHidden ? 'form-control hidden' : 'form-control';

        if (appendedInputClassName) {
            inputClassName = inputClassName + ' ' + appendedInputClassName;
        }

        const inputProps = {
            autoComplete: 'off',
            className: inputClassName,
            disabled: isDisabled,
            id: propertyName,
            max,
            onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
                if (onBlurCallback) {
                    onBlurCallback(event);
                }
            },
            onChange: this.handleOnChange(max, onChangeCallback),
            onKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => {
                if (onKeyPressCallback) {
                    onKeyPressCallback(event);
                }
            },
            placeholder,
            ref: (component: HTMLInputElement) => { this.primaryInput = component; },
            tabIndex,
            type: 'number',
            value
        };

        return <input {...inputProps}/>;
    }
}
