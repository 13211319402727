// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from 'Utility/IndexOfActions';

export interface IGridDataRowProps {
    className?: string;
    // ReSharper disable once RedundantQualifier
    dataPoints: Handler.DataPoints;
    hasActions?: boolean;
    key: number;
    rowId: number;
}

export default class GridDataRow extends React.Component<IGridDataRowProps, {}> {

    private getChildElements = (): JSX.Element => {

        return (
            <div className="btn-toolbar pull-right" role="group" aria-label="related actions">
                {this.props.children}
            </div>
        );
    }

    render() {

        const { className, dataPoints, hasActions, rowId } = this.props;

        const childElements = this.props.children ? this.getChildElements() : null;
        const containsActions = hasActions === undefined || hasActions === null || hasActions;

        return (
            <tr className={`grid-data-row ${className}`}>
                {dataPoints.map((dataPoint: string | number | JSX.Element, index: number) => <td key={index}>{dataPoint}</td>)}
                {containsActions && <td key={rowId + 'actions'} className="actions-column-data"> {childElements} </td>}
            </tr>
        );
    }
}
