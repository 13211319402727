// 3rd Party References
import * as React from 'react';
import Countdown from 'react-countdown-now';

interface ITimeRemainingCountdownProps {
    endText?: string;
    timerEndDate: Date;
}

class TimeRemainingCountdown extends React.Component<ITimeRemainingCountdownProps, {}> {

    render() {

        const { endText, timerEndDate } = this.props;

        const Completed = (): JSX.Element => <span>{`${endText || 'Time has run out'}`}</span>;

        const renderer = ({ days, hours, minutes, seconds, completed }) => {

            const Days = (): JSX.Element => <span>{days} Day{days !== 1 && 's'}</span>;

            const Hours = (): JSX.Element => <span>{hours} Hour{hours !== 1 && 's'}</span>;

            const Minutes = (): JSX.Element => <span>{minutes} Minute{minutes !== 1 && 's'}</span>;

            const Seconds = (): JSX.Element => <span>{seconds} Second{seconds !== 1 && 's'}</span>;

            const TimeRemaining = (): JSX.Element =>
                <>
                    {
                        days > 0 && <Days />
                    }
                    {
                        days === 0 && hours > 0 && <Hours />
                    }
                    {
                        days === 0 && hours === 0 && minutes > 0 && <Minutes />
                    }
                    {
                        days === 0 && hours === 0 && minutes === 0 && seconds > 0 && <Seconds />
                    }
                </>;

            return (
                completed ? <Completed /> : <TimeRemaining />
            );
        };

        return (
            <>
                <Countdown date={timerEndDate} renderer={renderer} />
            </>
        );
    }
}

export {
    ITimeRemainingCountdownProps,
    TimeRemainingCountdown
};
