// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from '../../IndexOfActions';
import { ISimplePositiveIntegerInputProps, SimplePositiveIntegerInput } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
//import {  } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

export interface ISimpleLotNumberInputProps extends React.Props<SimpleLotNumberInput> {
    isDisabled?: boolean;
    isHidden?: boolean;
    onBlurCallback?: Handler.InputFocusEvent;
    onChangeCallback: Handler.NumberProperty;
    onKeyPressCallback?: Handler.InputKeyboardEvent;
    placeholder?: string;
    propertyName?: string;
    tabIndex?: number;
    value: number;
}

export default class SimpleLotNumberInput extends React.Component<ISimpleLotNumberInputProps, {}> {

    private primaryInput: SimplePositiveIntegerInput;

    public setFocus = (scroll?: number): void => {
        this.primaryInput.setFocus(scroll);
    }

    render() {

        const { isDisabled, isHidden, onBlurCallback, onChangeCallback, onKeyPressCallback, placeholder, propertyName, tabIndex, value } = this.props;

        const inputProps: ISimplePositiveIntegerInputProps = {
            appendedInputClassName: 'simple-lot-number-input',
            disallowZero: true,
            isDisabled,
            isHidden,
            max: 999999999,
            onBlurCallback,
            onChangeCallback,
            onKeyPressCallback,
            placeholder,
            propertyName,
            ref: (component: SimplePositiveIntegerInput) => { this.primaryInput = component; },
            tabIndex,
            value
        };

        return <SimplePositiveIntegerInput {...inputProps} />;
    }
}
