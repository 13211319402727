// 3rd Party References
import * as React from 'react';

// Utility References
//import {  } from '../../IndexOfActions';
import { FileIcon, OkIcon, RemoveIcon, SaveExcelIcon, SavePdfIcon } from '../../IndexOfComponents';
import { ButtonSize, ButtonStyle } from '../../IndexOfEnums';
//import {  } from '../../IndexOfServices';

// Internal References
import { IInternalSimpleButtonProps, ISimpleButtonProps, SimpleButton } from './Internal/SimpleButton';

class DangerButton extends React.Component<ISimpleButtonProps, {}> {

    render() {

        return (
            <SimpleButton buttonStyle={ButtonStyle.Danger} {...this.props}>
                {this.props.children}
            </SimpleButton>
        );
    }
}

class ExcelButton extends React.Component<ISimpleButtonProps, {}> {

    render() {

        const { buttonOptions, buttonSize, buttonText, isDisabled, onClick, processing } = this.props;

        // for legacy purpose, set the default to be small
        const newButtonSize = buttonSize ? buttonSize : ButtonSize.Sm;

        const buttonProps = {
            buttonOptions,
            buttonSize: newButtonSize,
            buttonStyle: ButtonStyle.Info,
            buttonText: buttonText ? buttonText : 'Excel',
            isDisabled,
            onClick,
            processing
        };

        return (
            <SimpleButton {...buttonProps}>
                <SaveExcelIcon processing={processing} />
            </SimpleButton>
        );
    }
}

class FileDownloadButton extends React.Component<ISimpleButtonProps, {}> {

    render() {

        const { buttonOptions, buttonSize, buttonText, isDisabled, onClick, processing } = this.props;

        // for legacy purpose, set the default to be small
        const newButtonSize = buttonSize ? buttonSize : ButtonSize.Sm;

        const buttonProps = {
            buttonOptions,
            buttonSize: newButtonSize,
            buttonStyle: ButtonStyle.Info,
            buttonText: buttonText ? buttonText : 'Download',
            isDisabled,
            onClick,
            processing
        };

        return (
            <SimpleButton {...buttonProps}>
                <FileIcon processing={processing} />
            </SimpleButton>
        );
    }
}

class InfoButton extends React.Component<ISimpleButtonProps, {}> {

    render() {

        return (
            <SimpleButton buttonStyle={ButtonStyle.Info} {...this.props}>
                {this.props.children}
            </SimpleButton>
        );
    }
}

class LinkButton extends React.Component<ISimpleButtonProps, {}> {

    render() {

        const { buttonText, isDisabled, onClick } = this.props;

        const buttonProps = {
            buttonSize: ButtonSize.Sm,
            buttonStyle: ButtonStyle.Link,
            buttonText,
            isDisabled,
            onClick
        };

        return (
            <SimpleButton {...buttonProps}>
                {this.props.children}
            </SimpleButton>
        );
    }
}

class PdfButton extends React.Component<ISimpleButtonProps, {}> {

    render() {

        const { appendedClassName, buttonOptions, buttonSize, buttonText, isDisabled, onClick, processing } = this.props;

        // for legacy purpose, set the default to be small
        const newButtonSize = buttonSize ? buttonSize : ButtonSize.Sm;

        const buttonProps: IInternalSimpleButtonProps = {
            appendedClassName,
            buttonOptions,
            buttonSize: newButtonSize,
            buttonStyle: ButtonStyle.Info,
            buttonText: buttonText ? buttonText : 'PDF',
            isDisabled,
            onClick,
            processing
        };

        return (
            <SimpleButton {...buttonProps}>
                <SavePdfIcon processing={processing} />
            </SimpleButton>
        );
    }
}

class PrimaryButton extends React.Component<ISimpleButtonProps, {}> {

    render() {

        return (
            <SimpleButton buttonStyle={ButtonStyle.Primary} {...this.props}>
                {this.props.children}
            </SimpleButton>
        );
    }
}

class SuccessButton extends React.Component<ISimpleButtonProps, {}> {

    render() {

        return (
            <SimpleButton buttonStyle={ButtonStyle.Success} {...this.props}>
                {this.props.children}
            </SimpleButton>
        );
    }
}

class WarningButton extends React.Component<ISimpleButtonProps, {}> {

    render() {

        return (
            <SimpleButton buttonStyle={ButtonStyle.Warning} {...this.props}>
                {this.props.children}
            </SimpleButton>
        );
    }
}

interface IYesNoButtonProps extends ISimpleButtonProps {
    value: boolean;
}

class YesNoButton extends React.Component<IYesNoButtonProps, {}> {

    render() {

        return (
            <SimpleButton buttonStyle={this.props.value ? ButtonStyle.Info : ButtonStyle.Danger} buttonText={this.props.value ? 'Yes' : 'No'} {...this.props}>
                {this.props.value ? <OkIcon /> : <RemoveIcon />}
            </SimpleButton>
        );
    }
}

export {
    DangerButton,
    ExcelButton,
    FileDownloadButton,
    InfoButton,
    LinkButton,
    PdfButton,
    PrimaryButton,
    SuccessButton,
    WarningButton,
    YesNoButton
};

export {
    ISimpleButtonProps,
    IYesNoButtonProps
};
