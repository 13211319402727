// 3rd Party
import * as React from 'react';

// Utility References
import { IGenericDictionary } from '../../../IndexOfInterfaces';

import GridLinkButton, { IGridLinkButtonProps } from '../Internal/GridLinkButton';

type MouseEvent = (event: React.MouseEvent<HTMLAnchorElement>) => void;

export interface IGridHeadAddLinkButtonProps extends IGenericDictionary<string | boolean | MouseEvent> {
    ariaDescribedBy?: string;
    buttonLabel: string;
    href: string;
    isDisabled?: boolean;
    onMouseOut?: MouseEvent;
    onMouseOver?: MouseEvent;
    targetNewTab?: boolean;
}

export default class GridHeadAddLinkButton extends React.Component<IGridHeadAddLinkButtonProps, {}> {

    render() {

        const { ariaDescribedBy, buttonLabel, href, isDisabled, targetNewTab, onMouseOut, onMouseOver } = this.props;

        const formattedUrl = href.indexOf('#') > -1
            ? href
            : `#/${href}`;

        const buttonProps: IGridLinkButtonProps = {
            ariaDescribedBy,
            cssButtonClass: 'btn-success',
            cssIconClass: 'fa-plus',
            href: formattedUrl,
            isDisabled,
            onMouseOut,
            onMouseOver,
            standardLabel: buttonLabel,
            targetNewTab
        };

        return (
            <GridLinkButton {...buttonProps} />
        );
    }
}
