// 3rd Party
import * as React from 'react';
import { hot } from 'react-hot-loader';
import { Link } from 'react-router-dom';

// Utility References
import {
    Card,
    CardBody,
    ISimpleButtonProps,
    ITimeRemainingCountdownProps,
    PrimaryButton,
    TimeRemainingCountdown
} from 'Utility/IndexOfComponents';

// App References
import { toCurrencyDisplay } from 'App/Helpers/CurrencyHelper';
import {
    IApiSupplyRequestListingSummaryViewModel as IViewModel
} from 'App/IndexOfInterfaces';
import {
    Constants,
    IPageState,
    SupplyRequestListingInvoiceAlert as InvoiceAlert
 } from 'App/IndexOfModels';
import { LocalStorageService } from 'App/IndexOfServices';
import { DateHelper, ObjectHelper } from 'Utility/IndexOfHelpers';

interface IPropActions {
}

interface IPropData {
    detailsUrlFunc: (id?: number | string) => string;
    listItem: IViewModel;
    pageState: IPageState;
}

interface IListItemProps {
    propActions: IPropActions;
    propData: IPropData;
}

class ListItem extends React.Component<IListItemProps, {}>{

    render() {

        const { propData } = this.props;

        const { listItem } = propData;

        const isInsurer = LocalStorageService.isInRole(Constants.RoleNames.Insurer);
        const isDealer = !isInsurer;

        const isAwaitingInvoiceDealer = listItem.isAwaitingInvoice && isDealer;
        const isAwaitingInvoiceInsurer = listItem.isAwaitingInvoice && isInsurer;
        const isRunning = listItem.isActive && isInsurer;
        const isDeclined = listItem.isDeclined && isInsurer;
        const isInsurerProvisional = listItem.isProvisional && isInsurer;
        const isSourced = listItem.isSourced && isInsurer;
        const isBidding = listItem.isActive && isDealer;
        const isDealerProvisional = listItem.isProvisional && isDealer;
        const isWon = isDealer && listItem.isWon;
        const isLost = isDealer && !listItem.isWon && (listItem.isSourced || listItem.isDeclined || listItem.isRemoved || listItem.isAwaitingInvoice);
        const isArchived = isInsurer && listItem.isRemoved;
        const bidAmount = listItem.bestCurrentOffer != null ? listItem.bestCurrentOffer.bidAmount : 0;

        const ListItemImage = (): JSX.Element =>

            <div className="col-md-3 d-flex align-items-center">
                {
                    // TODOxUI - set up to show default image once available
                    listItem.marketingImageUrl ?
                    <img style={{width: '100%'}} src={listItem.marketingImageUrl} /> :
                    <i>No image has been uploaded.</i>
                }
            </div>;

        const MainSummary = (): JSX.Element =>

            <div className="col-md-3 d-flex flex-column justify-content-center">
                <div className="p-1">Claim #: {listItem.claimReference}</div>
                <div className="p-1">{listItem.manufacturerName} {listItem.modelName} {listItem.derivativeName}</div>
                <div className="p-1">{listItem.fuelTypeName} - {listItem.transmissionTypeName}</div>
                <div className="p-1">{listItem.colourPreferences.filter(x => !ObjectHelper.isUndefinedOrNull(x)).join(', ')}</div>
            </div>;

        const bestCurrentOffer = listItem.bestCurrentOffer && bidAmount;

        const currentOfferText = bestCurrentOffer > 0 ? `Lowest Offer: ${toCurrencyDisplay(bestCurrentOffer)}` : `No offers yet`;

        const countdownProps: ITimeRemainingCountdownProps = {
            timerEndDate: listItem.endDate
        };

        const RunningSummary = (): JSX.Element =>

            <div className="col-md-4 d-flex flex-column justify-content-center">
                <div className="p-1">{currentOfferText}</div>
                {
                    listItem.numberOfBids > 0 &&
                    <div className="p-1">Number of Offers: {listItem.numberOfBids}</div>
                }
                <div className="p-1">Location: {listItem.location}, {listItem.postCode}</div>
                <div className="p-1">
                    Time remaining: <TimeRemainingCountdown {...countdownProps}/>
                </div>
            </div>;

        const SourcedSummary = (): JSX.Element =>

            <div className="col-md-4 d-flex flex-column justify-content-center">
                <div className="p-1">Requested: {DateHelper.formatDate(listItem.requestDateTime, DateHelper.standardMomentDateFormatPattern())}</div>
                <div className="p-1">Accepted: {DateHelper.formatDate(listItem.closeDate, DateHelper.standardMomentDateFormatPattern())}</div>
                <div className="p-1">Supplying Dealer: {listItem.supplyingDealerName}</div>
                <div className="p-1">Price: {toCurrencyDisplay(listItem.hammerPrice)}</div>
            </div>;

        const DeclinedSummary = (): JSX.Element =>

            <div className="col-md-4 d-flex flex-column justify-content-center">
                <div className="p-1">Requested: {DateHelper.formatDate(listItem.requestDateTime, DateHelper.standardMomentDateFormatPattern())}</div>
                <div className="p-1">Declined: {DateHelper.formatDate(listItem.closeDate, DateHelper.standardMomentDateFormatPattern())}</div>
                <div className="p-1">Number of Offers: {listItem.numberOfBids}</div>
                {
                    listItem.bestCurrentOffer &&
                    <div className="p-1">Lowest Offer: {toCurrencyDisplay(bidAmount)}</div>
                }
            </div>;

        const BiddingSummary = (): JSX.Element =>

            <div className="col-md-4 d-flex flex-column justify-content-center">
                <div className="p-1">{currentOfferText}</div>
                {
                    (listItem.userCurrentBid && listItem.userCurrentBid.bidAmount > 0) &&
                    <div className="p-1"><strong>Your Offer: {toCurrencyDisplay(listItem.userCurrentBid.bidAmount)}</strong></div>
                }
                <div className="p-1">Location: {listItem.location}, {listItem.postCode}</div>
                <div className="p-1">
                    Time remaining: <TimeRemainingCountdown {...countdownProps}/>
                </div>
            </div>;

        const DealerProvisionalSummary = (): JSX.Element =>

            <div className="col-md-4 d-flex flex-column justify-content-center">
                <div className="p-1">Finished: {DateHelper.formatDate(listItem.endDate, DateHelper.standardMomentDateFormatPattern())}</div>
                <div className="p-1">Number of Offers: {listItem.numberOfBids}</div>
                <div className="p-1">Lowest Offer: {toCurrencyDisplay(bidAmount)}</div>
                <div className="p-1"><strong>Your Offer: {toCurrencyDisplay(listItem.userCurrentBid.bidAmount)}</strong></div>
            </div>;

        const InsurerProvisionalSummary = (): JSX.Element =>

            <div className="col-md-4 d-flex flex-column justify-content-center">
                <div className="p-1">Requested: {DateHelper.formatDate(listItem.requestDateTime, DateHelper.standardMomentDateFormatPattern())}</div>
                <div className="p-1">Finished: {DateHelper.formatDate(listItem.endDate, DateHelper.standardMomentDateFormatPattern())}</div>
                <div className="p-1">Number of Offers: {listItem.numberOfBids}</div>
                <div className="p-1">Lowest Offer: {toCurrencyDisplay(bidAmount)}</div>
            </div>;

        const WonSummary = (): JSX.Element =>

            <div className="col-md-4 d-flex flex-column justify-content-center">
                <div className="p-1">Finished: {DateHelper.formatDate(listItem.endDate, DateHelper.standardMomentDateFormatPattern())}</div>
                <div className="p-1">Accepted: {DateHelper.formatDate(listItem.closeDate, DateHelper.standardMomentDateFormatPattern())}</div>
                <div className="p-1">Location: {listItem.location}, {listItem.postCode}</div>
                <div className="p-1"><strong>Your Supply Price: {toCurrencyDisplay(listItem.userCurrentBid.bidAmount)}</strong></div>
            </div>;

        const LostSummary = (): JSX.Element =>

            <div className="col-md-4 d-flex flex-column justify-content-center">
                <div className="p-1">Finished: {DateHelper.formatDate(listItem.endDate, DateHelper.standardMomentDateFormatPattern())}</div>
                <div className="p-1">Lowest Offer: {toCurrencyDisplay(bidAmount)}</div>
                <div className="p-1"><strong>Your Offer {toCurrencyDisplay(listItem.userCurrentBid.bidAmount)}</strong></div>
            </div>;

        const ArchivedSummary = (): JSX.Element =>

            <div className="col-md-4 d-flex flex-column justify-content-center">
                <div className="p-1">Requested: {DateHelper.formatDate(listItem.requestDateTime, DateHelper.standardMomentDateFormatPattern())}</div>
                <div className="p-1">Number of Offers: {listItem.numberOfBids}</div>
                {
                    listItem.bestCurrentOffer &&
                    <div className="p-1">Lowest Offer: {toCurrencyDisplay(bidAmount)}</div>
                }
            </div>;

        const dashboardLinkButton = (buttonText: string, linkUrl: string): JSX.Element => {

            const dashboardButtonProps: ISimpleButtonProps = {
                appendedClassName: 'btn-block',
                buttonText,
                isDisabled: propData.pageState.processing,
                onClick: () => { return; }
            };

            return (
                <div className="row mb-2 justify-content-end">
                    <div className="col-md-11">
                        <Link to={linkUrl}>
                            <PrimaryButton {...dashboardButtonProps}/>
                        </Link>
                    </div>
                </div>
            );
        };

        const ButtonSection = (): JSX.Element =>

            <div className="col-md-2">
                {dashboardLinkButton('View', this.props.propData.detailsUrlFunc(listItem.supplyRequestListingId))}
            </div>;

        return (
            <Card>
                <CardBody>

                    <InvoiceAlert showDealer={isAwaitingInvoiceDealer && isWon} showInsurer={isAwaitingInvoiceInsurer} />

                    <div className="row align-items-center">

                        <ListItemImage />

                        <MainSummary />

                        {isRunning && <RunningSummary />}

                        {isDealerProvisional && <DealerProvisionalSummary />}

                        {isInsurerProvisional && <InsurerProvisionalSummary />}

                        {(isSourced || isAwaitingInvoiceInsurer) && <SourcedSummary />}

                        {isDeclined && <DeclinedSummary />}

                        {isBidding && <BiddingSummary />}

                        {isWon && <WonSummary />}

                        {isLost && <LostSummary />}

                        {isArchived && <ArchivedSummary />}

                        <ButtonSection />

                    </div>

                </CardBody>
            </Card>
        );
    }
}

const HotListItem = hot(module)(ListItem);

export {
    HotListItem as SupplyRequestListingListItem,
    IListItemProps as ISupplyRequestListingListItemProps
};
