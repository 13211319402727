// 3rd Party References
import * as React from 'react';

// Utility References
//import {  } from '../../../IndexOfComponents';
//import {  } from '../../../IndexOfEnums';
import { TranslationHelper } from '../../../IndexOfHelpers';
//import {  } from '../../../IndexOfInterfaces';
//import {  } from '../../../IndexOfModels';
//import {  } from '../../../IndexOfServices';

const gridEmptyRow = () => {

    const text = TranslationHelper.translate('NO RESULTS');

    return (
        <tr className="grid-data-row no-content" key="empty">
            <td colSpan={20}>{text}</td>
        </tr>
    );
};

export { gridEmptyRow as GridEmptyRow };
