// 3rd Party References
import * as React from 'react';
import * as Autosuggest from 'react-autosuggest';

// Utility References
import { Handler } from 'Utility/IndexOfActions';
import { TranslationHelper } from 'Utility/IndexOfHelpers';
import { IApiItemOptionViewModel } from 'Utility/IndexOfInterfaces';

export interface ISimpleAutoSuggestInputProps extends React.Props<SimpleAutoSuggestInput> {
    clearSuggestions: Handler.Action;
    getSuggestions: Handler.Action1<Autosuggest.SuggestionsFetchRequestedParams>;
    onChangeCallback: Handler.StringProperty;
    onClearCallback?: Handler.Action;
    onClickCallback: Handler.Action1<IApiItemOptionViewModel>;
    placeholder: string;
    propertyName?: string;
    suggestions: IApiItemOptionViewModel[];
    uppercase?: boolean;
    value: string;
}

type AutosuggestProps = Autosuggest.SuggestionsFetchRequestedParams;

export { AutosuggestProps as ISuggestionsFetchRequestedParams };

const renderSuggestion = (suggestion: IApiItemOptionViewModel) => (
    <div>
        {suggestion.text}
    </div>
);

export class SimpleAutoSuggestInput extends React.Component<ISimpleAutoSuggestInputProps, { selected: boolean }> {

    constructor(props: ISimpleAutoSuggestInputProps) {
        super(props);

        this.state = {
            selected: false
        };
    }

    public reset = (): void => {
        this.setState({ selected: false });
    }

    private handleOnChange = (onChangeCallback: Handler.StringProperty, propertyName: string, uppercase?: boolean) => (event: React.FormEvent<HTMLInputElement>) => {

        const newValue = (event.target as HTMLInputElement).value;
        const amendedValue = newValue && (uppercase ? newValue.toUpperCase() : newValue);

        onChangeCallback(amendedValue, propertyName);
    }

    private handleOnSelect = (onClickCallback: Handler.Action1<IApiItemOptionViewModel>) => (value: string | Object): string => {

        const obj = value as IApiItemOptionViewModel;

        onClickCallback(obj);

        this.setState({ selected: true });

        return obj.text;
    }

    private handleOnClear = (onClearCallback: Handler.Action) => (event: React.MouseEvent<HTMLSpanElement>): void => {

        onClearCallback();
        this.setState({ selected: false });
    }

    render() {

        const { selected } = this.state;
        const { clearSuggestions, getSuggestions, onChangeCallback, onClearCallback, onClickCallback, placeholder, propertyName, suggestions, uppercase, value } = this.props;

        const inputProps: Autosuggest.InputProps<string | Object> = {
            className: 'form-control',
            onChange: this.handleOnChange(onChangeCallback, propertyName, uppercase),
            onBlur: (e: React.MouseEvent<HTMLInputElement>) => {
                e.preventDefault();
            },
            placeholder: TranslationHelper.translate(placeholder),
            value
        };

        const showClearOption = selected && onClearCallback;

        if (showClearOption) {

            return (
                <>
                    <input className="form-control" disabled type="text" value={value} />
                    <i className="fa fa-remove fa-remove--autosuggest" onClick={this.handleOnClear(onClearCallback)} />
                </>
            );
        }

        return (
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={getSuggestions}
                onSuggestionsClearRequested={clearSuggestions}
                getSuggestionValue={this.handleOnSelect(onClickCallback)}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps} />
        );
    }
}
