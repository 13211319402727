// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from 'Utility/IndexOfActions';
import { ButtonStyle } from 'Utility/IndexOfEnums';
import { AnimationHelper, ButtonHelper } from 'Utility/IndexOfHelpers';
import { IButtonOptions } from 'Utility/IndexOfInterfaces';

interface ISimpleToggleButtonProps {
    falseButtonOptions: IButtonOptions;
    isDisabled?: boolean;
    onChangeCallback: Handler.BooleanProperty;
    propertyName?: string;
    trueButtonOptions: IButtonOptions;
    value: boolean;
}

class SimpleToggleButton extends React.Component<ISimpleToggleButtonProps, {}> {

    private primaryInput: HTMLButtonElement;

    public setFocus = (scroll?: number): void => {
        AnimationHelper.setFocus(this.primaryInput, scroll);
    }

    render() {

        const { falseButtonOptions, isDisabled, onChangeCallback, propertyName, trueButtonOptions, value } = this.props;

        const defaultFalseButtonOptions: IButtonOptions = {
            buttonStyle: value === false ? ButtonStyle.Warning : ButtonStyle.Default
        };

        const defaultTrueButtonOptions: IButtonOptions = {
            buttonStyle: value ? ButtonStyle.Success : ButtonStyle.Default
        };

        const falseButtonProps = {
            className: ButtonHelper.getButtonClasses(defaultFalseButtonOptions),
            disabled: isDisabled,
            onClick: () => {
                onChangeCallback(false, propertyName);
            },
            ref: (component: HTMLButtonElement) => { this.primaryInput = component; },
            role: 'button',
            type: 'button'
        };

        const trueButtonProps = {
            className: ButtonHelper.getButtonClasses(defaultTrueButtonOptions),
            disabled: isDisabled,
            onClick: () => {
                onChangeCallback(true, propertyName);
            },
            ref: (component: HTMLButtonElement) => { this.primaryInput = component; },
            role: 'button',
            type: 'button'
        };

        const falseButtonTextDisplay = ButtonHelper.getButtonText(falseButtonOptions);
        const trueButtonTextDisplay = ButtonHelper.getButtonText(trueButtonOptions);

        return (
            <div className="btn-group" role="group">
                <button {...falseButtonProps}>{falseButtonTextDisplay}</button>
                <button {...trueButtonProps}>{trueButtonTextDisplay}</button>
            </div>
        );
    }
}

export {
    ISimpleToggleButtonProps,
    SimpleToggleButton
};
