// 3rd Party
import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';

// Utility References
import { IApiProgressFunctions } from 'Utility';
import { Handler, NavigationActions } from 'Utility/IndexOfActions';
import { IAjaxInfo } from 'Utility/IndexOfInterfaces';
import { Notification } from 'Utility/IndexOfServices';

import { Actions } from 'App/Models/Base/Actions';

// App References
import {
    AllExceptionNotifier,
    IApiResetPasswordViewModel as IViewModel
} from 'App/IndexOfInterfaces';
import { UserApi } from 'App/IndexOfServices';
import { AppUrls } from 'App/IndexOfUrls';

// State
import { IResetPasswordState as IState } from './State';

// Model References
import { ResetPasswordActionTypes as ActionTypes } from './Models';

interface IActions {
    clear: Handler.Action;
    submit: Handler.Action1<IViewModel>;
    receiveResetPassword: Handler.Action1<IViewModel>;
}

class ResetPasswordActions extends Actions {

    constructor(apiProgressFunctions: IApiProgressFunctions, public allExceptionNotifier: Handler.Action1<IAjaxInfo>) {
        super(apiProgressFunctions);
    }

    public clear = createAction(ActionTypes.reset.resetForm);

    public receiveResetPassword = createAction(
        ActionTypes.receive.resetPassword, (viewModel: IViewModel) => viewModel
    );

    public submit = (viewModel: IViewModel) => (dispatch: Handler.AnyForDispatch): void => {

        const onSuccess = () => {

            Notification.success.recordUpdated(`Your password has now been updated.`, 'Reset Password');

            dispatch(this.clear());

            NavigationActions.HardRedirectPageWithoutNotification(AppUrls.LoginUrls.area, 0);
        };

        this.api.callWithPayload(UserApi.resetPassword(), viewModel, onSuccess, this.allExceptionNotifier);
    }
}

const actionsFactory = (dispatch: Dispatch<IState>, progressFunctions: IApiProgressFunctions): IActions => {

    const notificationsTitle = 'Reset Password';
    const actions = new ResetPasswordActions(progressFunctions, AllExceptionNotifier(notificationsTitle));

    return {
        clear: () => {
            dispatch(actions.clear());
        },
        receiveResetPassword: (viewModel: IViewModel) => {
            dispatch(actions.receiveResetPassword(viewModel));
        },
        submit: (viewModel: IViewModel) => {
            dispatch(actions.submit(viewModel));
        }
    };
};

export {
    actionsFactory as ResetPasswordActionsDispatcherFactory,
    IActions as IResetPasswordActions
};
