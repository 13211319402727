// 3rd Party References
import * as React from 'react';

interface ISimpleReadOnlyInputProps {
    appendedInputClassName?: string;
    value: number | string;
}

const simpleReadOnlyInput = (props: ISimpleReadOnlyInputProps): JSX.Element => {

    const { appendedInputClassName, value } = props;

    const inputProps = {
        className: `form-control ${appendedInputClassName || ''}`,
        readOnly: true,
        type: 'text',
        value
    };

    return <input {...inputProps}/>;
};

export {
    ISimpleReadOnlyInputProps,
    simpleReadOnlyInput as SimpleReadOnlyInput
};
