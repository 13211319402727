// 3rd Party References
import * as React from 'react';
import {
    Redirect,
    Route,
    RouteProps
} from 'react-router-dom';

interface IPrivateRouteProps extends RouteProps {
    isAuthenticated: boolean;
    redirectTo: string;
}

const privateRoute = (props: IPrivateRouteProps): JSX.Element => {

    const { isAuthenticated, redirectTo } = props;

    return (
        isAuthenticated ? <Route {...props} /> : <Redirect to={redirectTo} />
    );
};

export {
    privateRoute as AuthenticatedRoute
};
