//3rd Party References
import * as React from 'react';

//Utility References
//import {  } from '../../../IndexOfComponents';
//import {  } from '../../../IndexOfEnums';
//import {  } from '../../../IndexOfHelpers';
import { IApiItemOptionViewModel } from '../../../IndexOfInterfaces';
//import {  } from '../../../IndexOfModels';
//import {  } from '../../../IndexOfServices';

// Local References
import GenericDropdown from './GenericDropdown';

type OnChangeCallback = (selectedValue: number) => void;

interface IGenericNumberDropdownProps {
    displayName: string;
    isDisabled?: boolean;
    isRequired: boolean;
    items: IApiItemOptionViewModel[];
    onChangeCallback: OnChangeCallback;
    propertyName?: string;
    selectClassName?: string;
    selectableEmptyValue?: string;
    selectedValue: number;
}

export default class GenericNumberDropdown extends React.Component<IGenericNumberDropdownProps, {}> {

    private handleOnChange = (onChangeCallback: OnChangeCallback) => (selectedValue: string): void => {

        if (selectedValue === undefined || selectedValue === null) {

            onChangeCallback(null);

        } else {

            const selectedValueAsNumber = parseInt(selectedValue, 10);
            onChangeCallback(selectedValueAsNumber);
        }
    }

    render() {

        const { displayName, isDisabled, isRequired, items, onChangeCallback, propertyName, selectClassName, selectableEmptyValue, selectedValue } = this.props;

        const selectedValueAsString = (selectedValue === null || selectedValue === undefined) ? null : selectedValue.toString();

        const selectProps = {
            displayName,
            isDisabled,
            isRequired,
            items,
            onChangeCallback: this.handleOnChange(onChangeCallback),
            propertyName,
            selectClassName,
            selectableEmptyValue,
            selectedValue: selectedValueAsString
        };

        return (
            <GenericDropdown {...selectProps} />
        );
    }
}
