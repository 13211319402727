// 3rd Party References
import * as React from 'react';

// Utility References
//import {  } from '../../../IndexOfComponents';
import { GridColumnWidth } from '../../../IndexOfEnums';
import { LayoutHelper } from '../../../IndexOfHelpers';
import { IApiSortOptions, IGridColumnNameSet } from '../../../IndexOfInterfaces';
import { IGridFilterContext } from '../../../IndexOfModels';
//import {  } from '../../../IndexOfServices';

// Local References
import GridSimpleColumnLabel from './GridSimpleColumnLabel';
import GridSortableColumnLabel from './GridSortableColumnLabel';

interface IGridLabelRowProps {
    actionsColumnWidth?: GridColumnWidth | GridColumnWidth[];
    columnNameSets: IGridColumnNameSet[];
    currentSortOptions?: IApiSortOptions;
    gridFilterContext?: IGridFilterContext;
}

export default class GridLabelRow extends React.Component<IGridLabelRowProps, {}> {

    private getActionsColumns = (actionsColumnWidth: GridColumnWidth | GridColumnWidth[]): JSX.Element[] => {

        if (!actionsColumnWidth) {
            return null;
        }

        const widths: GridColumnWidth[] = actionsColumnWidth instanceof Array ? actionsColumnWidth : [actionsColumnWidth];

        return widths.map((x: GridColumnWidth, index: number) => {

            const style = LayoutHelper.getColumnStyleWidth(x);
            return <th className="actions-column-label" key={index} style={style}></th>;

        });
    }

    render() {

        const { actionsColumnWidth, columnNameSets, currentSortOptions, gridFilterContext } = this.props;

        const gridColumnLabels = columnNameSets.map(
            (gridColumnNameSet: IGridColumnNameSet) => {

                if (gridColumnNameSet.nonSortableColumn || !currentSortOptions || !gridFilterContext) {

                    return <GridSimpleColumnLabel gridColumnNameSet={gridColumnNameSet} key={gridColumnNameSet.fieldName} />;
                }

                const partialGridColumnLabelProps = {
                    currentSortOptions,
                    gridColumnNameSet,
                    gridFilterContext
                };

                return <GridSortableColumnLabel {...partialGridColumnLabelProps} key={gridColumnNameSet.fieldName} />;
            }
        );

        const actionColumns = this.getActionsColumns(actionsColumnWidth);

        return (
            <tr className="grid-label-row" key={'grid-label-row'}>
                {gridColumnLabels}
                {actionColumns}
            </tr>
        );
    }
}
