// 3rd Party References
import * as React from 'react';
import { hot } from 'react-hot-loader';

// Utility References
import { Handler } from 'Utility/IndexOfActions';
import {
    Card,
    CardBody,
    CardHeader,
    FormGroupWrapper,
    GenericLabel,
    IFormGroupWrapperProps,
    ISimpleButtonProps,
    ISimpleCheckboxGridStringProps,
    ISimpleCheckboxInputProps,
    ISimpleDateInputProps,
    ISimpleNumericInputProps,
    ISimpleSelectStringProps,
    ISimpleTextAreaProps,
    PrimaryButton,
    SimpleCheckboxGridString,
    SimpleCheckboxInput,
    SimpleDateInput,
    SimpleFileUploadInput,
    SimpleNumericInput,
    SimpleSelectString,
    SimpleTextArea
} from 'Utility/IndexOfComponents';
import { DateHelper, FormControlHelper, FormHelper, ObjectHelper } from 'Utility/IndexOfHelpers';
import { IRequiredField } from 'Utility/IndexOfInterfaces';
import { ModelUpdater } from 'Utility/IndexOfModels';

// App References
import { toCurrencyDisplay } from 'App/Helpers/CurrencyHelper';
import { CreateValidatableDocumentFields } from 'App/Helpers/DocumentUploadValidationHelper';
import {
    IApiBidHistoryCurrentBidViewModel,
    IApiBidHistoryUpsertViewModel as IUpsert,
    IApiBidHistoryViewModel as IViewModel
} from 'App/IndexOfInterfaces';
import { Constants, IPageActions, IPageState } from 'App/IndexOfModels';

interface IPropActions {
    onBidSubmitted?: Handler.Action;
    pageActions: IPageActions;
    receiveUpsert: Handler.Action1<IUpsert>;
    save: Handler.ActionAsync1<IViewModel, void>;
}

interface IPropData {
    currentUserBestOffer: IApiBidHistoryCurrentBidViewModel;
    minUndercutValue: number;
    pageState: IPageState;
    terms: string;
    upsert: IUpsert;
}

interface IProxyBidFormProps {
    propActions: IPropActions;
    propData: IPropData;
}

interface IProxyBidFormState {
    showTerms: boolean;
}

class Form extends React.Component<IProxyBidFormProps, IProxyBidFormState> {

    private quotationDocumentFile: SimpleFileUploadInput;

    constructor(props: IProxyBidFormProps){
        super(props);

        this.state = {
            showTerms: false
        };
    }

    private calculateFinalDriveAwayPrice = (viewModel: IViewModel): number => {

        const { accessoriesOptionsTotal, discountAmount, gst, preDelivery, recommendedRetailPrice, registration12Months, stampDuty } = viewModel;

        return recommendedRetailPrice
                - (discountAmount || 0)
                + (gst || 0)
                + (accessoriesOptionsTotal || 0)
                + (preDelivery || 0)
                + (registration12Months || 0)
                + (stampDuty || 0);
    }

    private isUpsertValid = (upsert: IUpsert): boolean => {

        return !ObjectHelper.isUndefinedOrNull(upsert) && !ObjectHelper.isUndefinedOrNull(upsert.model) && !ObjectHelper.isUndefinedOrNull(upsert.tools);
    }

    private getFormPropertyNames = (viewModel: IViewModel) => {

        return {
            accessoriesOptionsTotal: ObjectHelper.getPropertyName(() => viewModel.accessoriesOptionsTotal),
            buildDate: ObjectHelper.getPropertyName(() => viewModel.buildDate),
            complianceDate: ObjectHelper.getPropertyName(() => viewModel.complianceDate),
            dealerComments: ObjectHelper.getPropertyName(() => viewModel.dealerComments),
            deliveryTimeFrame: ObjectHelper.getPropertyName(() => viewModel.deliveryTimeFrame),
            discountAmount: ObjectHelper.getPropertyName(() => viewModel.discountAmount),
            gst: ObjectHelper.getPropertyName(() => viewModel.gst),
            quotationDocument: ObjectHelper.getPropertyName(() => viewModel.quotationDocument),
            preDelivery: ObjectHelper.getPropertyName(() => viewModel.preDelivery),
            registration12Months: ObjectHelper.getPropertyName(() => viewModel.registration12Months),
            stampDuty: ObjectHelper.getPropertyName(() => viewModel.stampDuty),
            termsAccepted: ObjectHelper.getPropertyName(() => viewModel.termsAccepted)
        };
    }

    private getRequiredFieldsForValidation(viewModel: IViewModel): IRequiredField[] {

        const formProperties = this.getFormPropertyNames(viewModel);

        const requiredFields: IRequiredField[] = [
            {
                errorMessage: 'Please select delivery time frame',
                propertyName: formProperties.deliveryTimeFrame,
                value: viewModel.deliveryTimeFrame
            },
            {
                errorMessage: 'Please specify build date',
                propertyName: formProperties.buildDate,
                value: viewModel.buildDate,
                validate: (value): boolean => DateHelper.toMoment(new Date(value.toString())).year() !== 0
            },
            {
                errorMessage: 'Please specify compliance date',
                propertyName: formProperties.complianceDate,
                value: viewModel.complianceDate,
                validate: (value): boolean => DateHelper.toMoment(new Date(value.toString())).year() !== 0
            },
            {
                errorMessage: 'You must accept terms and conditions',
                propertyName: formProperties.termsAccepted,
                value: viewModel.termsAccepted,
                validate: (): boolean => viewModel.termsAccepted
            },
            {
                errorMessage: 'Please enter discount amount',
                propertyName: formProperties.discountAmount,
                value: viewModel.discountAmount,
                validate: (value): boolean => !ObjectHelper.isUndefinedOrNull(value)
            },
            {
                errorMessage: 'Please enter accessories/options total amount',
                propertyName: formProperties.accessoriesOptionsTotal,
                value: viewModel.accessoriesOptionsTotal,
                validate: (value): boolean => !ObjectHelper.isUndefinedOrNull(value)
            },
            {
                errorMessage: 'Please enter GST amount. Must be greater than zero',
                propertyName: formProperties.gst,
                value: viewModel.gst,
                validate: (value): boolean => !ObjectHelper.isUndefinedOrNull(value) && value > 0
            },
            {
                errorMessage: 'Please enter Pre-delivery amount',
                propertyName: formProperties.preDelivery,
                value: viewModel.preDelivery,
                validate: (value): boolean => !ObjectHelper.isUndefinedOrNull(value)
            },
            {
                errorMessage: 'Please enter Registration 12 Months amount. Must be greater than zero',
                propertyName: formProperties.registration12Months,
                value: viewModel.registration12Months,
                validate: (value): boolean => !ObjectHelper.isUndefinedOrNull(value) && value > 0
            },
            {
                errorMessage: 'Please enter Stamp Duty amount. Must be greater than zero',
                propertyName: formProperties.stampDuty,
                value: viewModel.stampDuty,
                validate: (value): boolean => !ObjectHelper.isUndefinedOrNull(value) && value > 0
            }
        ];

        const validatableDocumentFields = CreateValidatableDocumentFields(() => viewModel.quotationDocument);

        return requiredFields.concat(validatableDocumentFields);
    }

    private handleOnSubmit = (propActions: IPropActions, propData: IPropData, viewModel: IViewModel): void => {

        const { pageActions: { clearAll, addError } } = propActions;

        if (FormHelper.areRequiredPropertiesValid(clearAll, addError, this.getRequiredFieldsForValidation(viewModel))) {

            propActions.save(viewModel).then(() => {

                if (!ObjectHelper.isUndefinedOrNull(propActions.onBidSubmitted)){

                    propActions.onBidSubmitted();
                }

                this.quotationDocumentFile.reset();
            });
        }
    }

    render() {

        const { propActions, propData } = this.props;

        const { upsert } = propData;

        if (!this.isUpsertValid(upsert)) {
            return null;
        }

        const { model: viewModel, tools } = upsert;

        const formProperties = this.getFormPropertyNames(upsert.model);

        const buildDateFormGroupWrapperProps = (): IFormGroupWrapperProps => {

            return FormControlHelper.getFormGroupWrapperProps(
                FormControlHelper.getContentWidths(
                    'children',
                    'error',
                    'input-wrapper',
                    'label'
                ),
                propData.pageState.modelState,
                'Build date',
                formProperties.buildDate,
                true
            );
        };

        const complianceDateFormGroupWrapperProps = (): IFormGroupWrapperProps => {

            return FormControlHelper.getFormGroupWrapperProps(
                FormControlHelper.getContentWidths(
                    'children',
                    'error',
                    'input-wrapper',
                    'label'
                ),
                propData.pageState.modelState,
                'Compliance date',
                formProperties.complianceDate,
                true
            );
        };

        const deliveryTimeFrameFormGroupWrapperProps = (): IFormGroupWrapperProps => {

            return FormControlHelper.getFormGroupWrapperProps(
                FormControlHelper.getContentWidths(
                    'children',
                    'error',
                    'input-wrapper',
                    'label'
                ),
                propData.pageState.modelState,
                'Delivery Time Frame',
                formProperties.deliveryTimeFrame,
                true
            );
        };

        const quotationDocumentFormGroupWrapperProps = (): IFormGroupWrapperProps => {

            return FormControlHelper.getFormGroupWrapperProps(
                FormControlHelper.getContentWidths(
                    'children',
                    'error',
                    'input-wrapper',
                    'label'
                ),
                this.props.propData.pageState.modelState,
                'Quotation Document',
                formProperties.quotationDocument,
                true
            );
        };

        const twoColRequiredFormGroupWrapperProps = (labelText: string, propertyName: string): IFormGroupWrapperProps => {

            return FormControlHelper.getFormGroupWrapperProps(
                FormControlHelper.getContentWidths(
                    'children',
                    'error',
                    'col-sm-6',
                    'label'
                ),
                propData.pageState.modelState,
                labelText,
                propertyName,
                true
            );
        };

        const dealerCommentsFormGroupWrapperProps = (): IFormGroupWrapperProps => {

            return FormControlHelper.getFormGroupWrapperProps(
                FormControlHelper.getContentWidths(
                    'children',
                    'error',
                    'input-wrapper',
                    'sr-only'
                ),
                this.props.propData.pageState.modelState,
                'Dealer comments',
                formProperties.dealerComments,
                false
            );
        };

        const termsAcceptedFormGroupWrapperProps = (): IFormGroupWrapperProps => {

            return FormControlHelper.getFormGroupWrapperProps(
                FormControlHelper.getContentWidths(
                    'children',
                    'error',
                    'input-wrapper',
                    'sr-only'
                ),
                this.props.propData.pageState.modelState,
                'Terms Accepted',
                formProperties.termsAccepted,
                true
            );
        };

        const modelUpdater = ModelUpdater(upsert, propActions.receiveUpsert);

        const buildDateInputProps: ISimpleDateInputProps = {
            dateFormat: 'MM/yyyy',
            isDisabled: propData.pageState.processing,
            onChangeCallback: modelUpdater.for<Date>((value, upsert) => {

                upsert.model.buildDate = DateHelper.toUtc(value);
                return upsert;
            }),
            showMonthYearPicker: true,
            value: DateHelper.isDefaultDotNetDateValue(upsert.model.buildDate) ? null : upsert.model.buildDate
        };

        const complianceDateInputProps: ISimpleDateInputProps = {
            dateFormat: 'MM/yyyy',
            isDisabled: propData.pageState.processing,
            onChangeCallback: modelUpdater.for<Date>((value, upsert) => {

                upsert.model.complianceDate = DateHelper.toUtc(value);
                return upsert;
            }),
            showMonthYearPicker: true,
            value: DateHelper.isDefaultDotNetDateValue(upsert.model.complianceDate) ? null : upsert.model.complianceDate
        };

        const genericDriveAwayPriceInputProps = (propertyName: string, value: number, namespace?: string, isDisabled?: boolean): ISimpleNumericInputProps => {
            return {
                isDisabled,
                onChangeCallback: modelUpdater.for<number>((value, upsert) => {

                    return updateModel(upsert, value, propertyName, namespace);
                }),
                value
            };
        };

        const updateModel = (upsert: IUpsert, value: boolean | Date | number | string | string[], propertyName: string, namespace?: string): IUpsert => {

            const property = namespace ? upsert.model[namespace] : upsert.model;

            propActions.pageActions.removeEntry(property[propertyName]);
            property[propertyName] = value;

            return upsert;
        };

        const supplyColourGridProps: ISimpleCheckboxGridStringProps = {
            gridClassName: 'checkbox-grid',
            hasSelectAll: false,
            isDisabled: propData.pageState.processing,
            itemClassName: 'checkbox-item col-sm-12',
            items: tools.supplyColourOptions,
            onSelectCallback: modelUpdater.for<string[]>((values, upsert) => {

                upsert.model.supplyingColourNames = [];

                if (!ObjectHelper.isUndefinedOrNull(values) && values.length > 0) {
                    upsert.model.supplyingColourNames = values.map(x => x);
                }

                return upsert;
            }),
            value: upsert.model.supplyingColourNames
        };

        const dealerCommentsInputProps: ISimpleTextAreaProps = {
            onChangeCallback: modelUpdater.for<string>((value, upsert) => {

                upsert.model.dealerComments = value;
                return upsert;
            }),
            placeholder: 'Comment further around delivery, colour, options, etc.',
            value: upsert.model.dealerComments
        };

        const deliveryTimeFrameSelectProps: ISimpleSelectStringProps = {
            items: tools.deliveryTimeFrameOptions,
            onChangeCallback: modelUpdater.for<string>((value, upsert) => {

                upsert.model.deliveryTimeFrame = value;
                return upsert;
            }),
            value: viewModel.deliveryTimeFrame
        };

        const termsAcceptedCheckboxInputProps: ISimpleCheckboxInputProps = {
            onChangeCallback: modelUpdater.for<boolean>((value, upsert) => {

                upsert.model.termsAccepted = value;
                return upsert;
            }),
            value: upsert.model.termsAccepted
        };

        const submitButtonProps: ISimpleButtonProps = {
            appendedClassName: 'btn-block',
            buttonText: 'Submit Your Quote',
            isDisabled: propData.pageState.processing,
            onClick: () => {

                upsert.model.userUtcTime = DateHelper.toUtc(DateHelper.now());

                const clonedModel = ObjectHelper.deepClone(upsert.model);
                clonedModel.quotationDocument = this.quotationDocumentFile.getFiles()[0];

                this.handleOnSubmit(propActions, propData, clonedModel);
            }
        };

        const finalDriveAwayPrice = this.calculateFinalDriveAwayPrice(viewModel);

        return (
            <Card borderColour="gray">
                <CardBody>
                    <form className="col-sm-12">
                        <div className="row mb-2">
                            <div className="col-sm-12">
                                <p><strong>Please enter your most competitive price including all accessories and options as listed above.</strong></p>
                            </div>
                        </div>
                        {
                            propData.minUndercutValue &&
                            <div className="row mb-2">
                                <div className="col-sm-12">
                                    Mininum undercut value: <strong>{toCurrencyDisplay(propData.minUndercutValue)}</strong>
                                </div>
                            </div>
                        }
                        {
                            propData.currentUserBestOffer &&
                            propData.currentUserBestOffer.bidAmount > 0 &&
                            <div className="row">
                                <div className="col-sm-12">
                                    Your best price: <strong>{toCurrencyDisplay(propData.currentUserBestOffer.bidAmount)}</strong>
                                </div>
                            </div>
                        }
                        <div className="row mb-4">
                            <div className="col-sm-12">
                                RRP (excluding GST): <strong>{toCurrencyDisplay(viewModel.recommendedRetailPrice)}</strong>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-4">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <FormGroupWrapper {...deliveryTimeFrameFormGroupWrapperProps()}>
                                            <SimpleSelectString {...deliveryTimeFrameSelectProps} />
                                        </FormGroupWrapper>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-sm-12">
                                        <FormGroupWrapper {...quotationDocumentFormGroupWrapperProps()}>
                                            <SimpleFileUploadInput accept={`${Constants.PDFType}, ${Constants.JPEGType}`} ref={(component) => this.quotationDocumentFile = component} />
                                        </FormGroupWrapper>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <FormGroupWrapper {...buildDateFormGroupWrapperProps()}>
                                            <SimpleDateInput {...buildDateInputProps} />
                                        </FormGroupWrapper>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <FormGroupWrapper {...complianceDateFormGroupWrapperProps()}>
                                            <SimpleDateInput {...complianceDateInputProps} />
                                        </FormGroupWrapper>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-5">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <GenericLabel displayName="Can Supply Colour:" fieldName="supplyColourIds" />
                                        <SimpleCheckboxGridString {...supplyColourGridProps} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <FormGroupWrapper {...dealerCommentsFormGroupWrapperProps()}>
                                            <SimpleTextArea {...dealerCommentsInputProps} />
                                        </FormGroupWrapper>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-sm-7">
                                <div className="row mb-2">
                                    <FormGroupWrapper {...twoColRequiredFormGroupWrapperProps('Discount off RRP ($)', formProperties.discountAmount)}>
                                        <SimpleNumericInput {...genericDriveAwayPriceInputProps(formProperties.discountAmount, upsert.model.discountAmount)} />
                                    </FormGroupWrapper>
                                    <FormGroupWrapper {...twoColRequiredFormGroupWrapperProps('Options/Accessories Total ($)', formProperties.accessoriesOptionsTotal)}>
                                        <SimpleNumericInput {...genericDriveAwayPriceInputProps(formProperties.accessoriesOptionsTotal, upsert.model.accessoriesOptionsTotal)}/>
                                    </FormGroupWrapper>
                                </div>
                                <div className="row mb-2">
                                    <FormGroupWrapper {...twoColRequiredFormGroupWrapperProps('Pre-Delivery ($)', formProperties.preDelivery)}>
                                        <SimpleNumericInput {...genericDriveAwayPriceInputProps(formProperties.preDelivery, upsert.model.preDelivery)}/>
                                    </FormGroupWrapper>
                                    <FormGroupWrapper {...twoColRequiredFormGroupWrapperProps('Registration 12 Months ($)', formProperties.registration12Months)}>
                                        <SimpleNumericInput {...genericDriveAwayPriceInputProps(formProperties.registration12Months, upsert.model.registration12Months)} />
                                    </FormGroupWrapper>
                                </div>
                                <div className="row">
                                    <FormGroupWrapper {...twoColRequiredFormGroupWrapperProps('Stamp Duty ($)', formProperties.stampDuty)}>
                                        <SimpleNumericInput {...genericDriveAwayPriceInputProps(formProperties.stampDuty, upsert.model.stampDuty)} />
                                    </FormGroupWrapper>
                                    <FormGroupWrapper {...twoColRequiredFormGroupWrapperProps('GST ($)', formProperties.gst)}>
                                        <SimpleNumericInput {...genericDriveAwayPriceInputProps(formProperties.gst, upsert.model.gst)} />
                                    </FormGroupWrapper>
                                </div>
                            </div>
                            <div className="col-sm-5">
                                <Card borderColour="gray" className="listing-bid-summary">
                                    <CardHeader>
                                        <h4>Quote Summary</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <dl className="row mb-0">
                                            <dd className="col-sm-7">RRP (excluding GST):</dd>
                                            <dt className="col-sm-5 text-right">{toCurrencyDisplay(viewModel.recommendedRetailPrice || 0)}</dt>

                                            <dd className="col-sm-7">Discount off RRP:</dd>
                                            <dt className="col-sm-5 text-right">-{toCurrencyDisplay(viewModel.discountAmount || 0)}</dt>

                                            <dd className="col-sm-7">Options/Accessories Total:</dd>
                                            <dt className="col-sm-5 text-right">{toCurrencyDisplay(viewModel.accessoriesOptionsTotal || 0)}</dt>

                                            <dd className="col-sm-7">Pre-Delivery:</dd>
                                            <dt className="col-sm-5 text-right">{toCurrencyDisplay(viewModel.preDelivery || 0)}</dt>

                                            <dd className="col-sm-7">Registration 12 Months:</dd>
                                            <dt className="col-sm-5 text-right">{toCurrencyDisplay(viewModel.registration12Months || 0)}</dt>

                                            <dd className="col-sm-7">Stamp Duty:</dd>
                                            <dt className="col-sm-5 text-right">{toCurrencyDisplay(viewModel.stampDuty || 0)}</dt>

                                            <dd className="col-sm-7">GST:</dd>
                                            <dt className="col-sm-5 text-right">{toCurrencyDisplay(viewModel.gst || 0)}</dt>
                                        </dl>
                                        <hr />
                                        <dl className="row mb-0 final-price-row">
                                            <dd className="col-sm-7"><h5>Final Drive Away Price:</h5></dd>
                                            <dt className="col-sm-5 text-right"><h5>{toCurrencyDisplay(finalDriveAwayPrice)}</h5></dt>
                                        </dl>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-12">
                                <span>Final Price to include all options / accessories / rego / TAC / Stamp Duty / and be inclusive of GST = Drive Away Price </span>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-sm-12">
                                <i><span>This insurer has their own terms and conditions that you must agree to before bidding:</span></i>
                                <div className="terms-text mt-4">
                                    <SimpleTextArea value={propData.terms} isDisabled onChangeCallback={null} rowCount={6} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-5">
                                <FormGroupWrapper {...termsAcceptedFormGroupWrapperProps()}>
                                    <label className="checkbox">
                                        <SimpleCheckboxInput {...termsAcceptedCheckboxInputProps} /> Accept Insurer Terms
                                    </label>
                                </FormGroupWrapper>
                            </div>
                            <div className="col-sm-3 offset-sm-4">
                                <PrimaryButton {...submitButtonProps}/>
                            </div>
                        </div>
                    </form>
                </CardBody>
            </Card>
        );
    }
}

export {
    Form as ProxyBidForm,
    IProxyBidFormProps
};
