//3rd Party References
import * as React from 'react';

//Utility References
import { Handler } from 'Utility/IndexOfActions';
import { IApiItemOptionViewModel } from 'Utility/IndexOfInterfaces';

import InternalCommonSelect, { IInternalCommonSelectProps } from './Internal/InternalCommonSelect';

export interface ISimpleSelectBooleanProps {
    displayName?: string;
    inputClassName?: string;
    isDisabled?: boolean;
    isFilter?: boolean;
    onBlurCallback?: Handler.SelectFocusEvent;
    onChangeCallback: Handler.BooleanProperty;
    onKeyPressCallback?: Handler.SelectKeyboardEvent;
    placeholder?: string;
    propertyName?: string;
    tabIndex?: number;
    value: boolean;
}

export default class SimpleSelectBoolean extends React.Component<ISimpleSelectBooleanProps, {}> {

    private primaryInput: InternalCommonSelect;

    public setFocus = (scroll?: number): void => {
        this.primaryInput.setFocus(scroll);
    }

    private getOptions = (): IApiItemOptionViewModel[] => {
        return [
            { text: 'Yes', value: true.toString() },
            { text: 'No', value: false.toString() }
        ].map(
            (option: IApiItemOptionViewModel) => ({ text: option.text, value: option.value })
            );
    }

    private handleOnChange = (onChangeCallback: Handler.BooleanProperty, propertyName?: string) => (value: string) => {

        if (value === undefined || value === null || value === '') {

            onChangeCallback(null, propertyName);

        } else {

            switch (value.toLowerCase()) {
                case 'true':
                    onChangeCallback(true, propertyName);
                    break;
                case 'false':
                    onChangeCallback(false, propertyName);
                    break;
                default:
                    onChangeCallback(null, propertyName);
                    break;
            }
        }
    }

    render() {

        const { displayName, inputClassName, isDisabled, isFilter, onBlurCallback, onChangeCallback, onKeyPressCallback, placeholder, propertyName, tabIndex, value } = this.props;

        const selectedValueAsString: string = value === undefined || value === null ? null : value.toString();
        const items = this.getOptions();

        const selectProps: IInternalCommonSelectProps = {
            displayName,
            inputClassName,
            isDisabled,
            isFilter,
            items,
            onBlurCallback,
            onChangeCallback: this.handleOnChange(onChangeCallback, propertyName),
            onKeyPressCallback,
            promptText: placeholder,
            propertyName,
            ref: (component: InternalCommonSelect) => { this.primaryInput = component; },
            tabIndex,
            value: selectedValueAsString
        };

        return <InternalCommonSelect {...selectProps} />;
    }
}
