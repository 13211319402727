// 3rd Party
import { Action, handleActions } from 'redux-actions';

// Utility References
import { ReduxHelper } from 'Utility';
import { ObjectHelper } from 'Utility/IndexOfHelpers';
import { IApiFilterBase, IGenericDictionary, INotFound, IUtilityPageState } from 'Utility/IndexOfInterfaces';

// App References
import { ISignalRUpdateMessage } from 'App/IndexOfInterfaces';

const actionTypes = {
    decrement: 'page.decrement',
    increment: 'page.increment',
    toggleModal: 'page.toggleModal',
    addError: 'page.errors.add',
    addErrors: 'page.errors.add.many',
    clearAll: 'page.errors.clear',
    closeModals: 'page.modals.close',
    receive: {
        currentOptions: 'page.receive.currentOptions',
        signalRUpdateMessage: 'page.receive.signalr.updateMessage'
    },
    reset: {
        currentOptions: 'page.reset.currentOptions',
        signalRUpdateMessage: 'page.reset.signalr.updateMessage'
    },
    removeEntry: 'page.errors.remove'
};

const hubEvents = {
    clientUpdated: 'ReceiveClientUpdate',
    supplyRequestListingUpdated: 'ReceiveSupplyRequestListingUpdate',
    supplyRequestUpdated: 'ReceiveSupplyRequestUpdate'
};

interface IPageState extends IUtilityPageState {
    signalRUpdateMessage: ISignalRUpdateMessage;
}

export interface IModelState extends IGenericDictionary<string[]> { }

const initialState = () => {
    return {
        currentOptions: null,
        lockedControls: {},
        pageModals: {},
        processing: false,
        signalRUpdateMessage: null,
        threadCount: 0
    };
};

const actionWrapper = ReduxHelper.actionWrapper;

type Payload = IApiFilterBase | IModelState | void | string | number;

const pageReducer = handleActions<IPageState, Payload>({

    [actionTypes.addError]: (state: IPageState, action: Action<IModelState>): IPageState => {

        return actionWrapper(state, action, (newState: IPageState) => {

            if (action.payload) {

                newState.modelState = !!state.modelState ? ObjectHelper.merge(state.modelState, action.payload) : action.payload;
            }

        });
    },

    [actionTypes.clearAll]: (state: IPageState, action: Action<void>): IPageState => {
        return actionWrapper(state, action, (newState: IPageState) => {

            newState.modelState = null;
        });
    },

    [actionTypes.closeModals]: (state: IPageState, action: Action<void>): IPageState => {

        return actionWrapper(state, action, (newState: IPageState) => {

            for (const pageModal in newState.pageModals) {
                if (newState.pageModals.hasOwnProperty(pageModal)) {
                    ObjectHelper.setToObject(newState.pageModals, pageModal, false);
                }
            }

        });
    },

    [actionTypes.decrement]: (state: IPageState, action: Action<void>): IPageState => {

        return actionWrapper(state, action, (newState: IPageState) => {
            newState.threadCount--;
            newState.processing = newState.threadCount > 0;
        });
    },

    [actionTypes.increment]: (state: IPageState, action: Action<void>): IPageState => {

        return actionWrapper(state, action, (newState: IPageState) => {
            newState.threadCount++;
            newState.processing = newState.threadCount > 0;
        });
    },

    [actionTypes.receive.currentOptions]: (state: IPageState, action: Action<IApiFilterBase>): IPageState => {
        return actionWrapper(state, action, (newState: IPageState) => {
            newState.currentOptions = action.payload;
        });
    },

    [actionTypes.receive.signalRUpdateMessage]: (state: IPageState, action: Action<ISignalRUpdateMessage>): IPageState => {
        return actionWrapper(state, action, (newState: IPageState) => {
            newState.signalRUpdateMessage = action.payload;
        });
    },

    [actionTypes.removeEntry]: (state: IPageState, action: Action<string>): IPageState => {

        return actionWrapper(state, action, (newState: IPageState) => {

            let modelState = ObjectHelper.deepClone(state.modelState);

            if (modelState) {

                delete modelState[action.payload];

                if (Object.keys(modelState).length === 0 && modelState.constructor === Object) {
                    modelState = null;
                }
            }

            newState.modelState = modelState;
        });
    },

    [actionTypes.reset.currentOptions]: (state: IPageState, action: Action<void>): IPageState => {
        return actionWrapper(state, action, (newState: IPageState) => {
            newState.currentOptions = null;
        });
    },

    [actionTypes.reset.signalRUpdateMessage]: (state: IPageState, action: Action<void>): IPageState => {
        return actionWrapper(state, action, (newState: IPageState) => {
            newState.signalRUpdateMessage = null;
        });
    },

    [actionTypes.toggleModal]: (state: IPageState, action: Action<string>): IPageState => {

        return actionWrapper(state, action, (newState: IPageState) => {

            let pageModals = state.pageModals;

            if (!pageModals) {
                pageModals = {};
            }

            if (pageModals[action.payload]) {
                ObjectHelper.setToObject(pageModals, action.payload, !pageModals[action.payload]);
            }
            else {
                ObjectHelper.setToObject(pageModals, action.payload, true);
            }

            newState.pageModals = pageModals;
        });
    }

}, initialState());

export {
    actionTypes as ActionTypes,
    IPageState,
    pageReducer as PageReducer,
    hubEvents as SignalRHubEvents
};
