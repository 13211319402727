// 3rd Party References
import * as React from 'react';

// Utility References
import { Translation } from 'Utility/IndexOfComponents';
import { StatusIndicator } from 'Utility/IndexOfEnums';
import { StringHelper } from 'Utility/IndexOfHelpers';

const Label = (props: { prefixClassName: string, statusIndicator: StatusIndicator, text: string }): JSX.Element => {

    let className = 'label label-';
    const prefixClassName = !StringHelper.isUndefinedNullOrEmpty(props.prefixClassName)
        ? props.prefixClassName
        : '';

    switch (props.statusIndicator) {
        case StatusIndicator.Danger:
            className = `${className}danger`;
            break;
        case StatusIndicator.Info:
            className = `${className}info`;
            break;
        case StatusIndicator.Success:
            className = `${className}success`;
            break;
        case StatusIndicator.Warning:
            className = `${className}warning`;
            break;
    }

    className = `${className} ${prefixClassName}`;

    return (
        <span className={className}><Translation>{props.text}</Translation></span>
    );
};

const GetLabel = (prefixClassName: string, statusIndicator: StatusIndicator, text: string): JSX.Element => {
    return (
        <Label prefixClassName={prefixClassName} statusIndicator={statusIndicator} text={text} />
    );
};

const DangerLabel = (props: { text: string, prefixClassName?: string }): JSX.Element => {
    return GetLabel(props.prefixClassName, StatusIndicator.Danger, props.text);
};

const InfoLabel = (props: { text: string, prefixClassName?: string }): JSX.Element => {
    return GetLabel(props.prefixClassName, StatusIndicator.Info, props.text);
};

const SplitLabel = (props: { leftText: string, rightText: string, prefixClassName?: string }): JSX.Element => {
    return (
        <>
            {GetLabel('label--left', StatusIndicator.Info, props.leftText)}
            {GetLabel('label--right', StatusIndicator.Warning, props.rightText)}
        </>
    );
};

const SuccessLabel = (props: { text: string, prefixClassName?: string }): JSX.Element => {
    return GetLabel(props.prefixClassName, StatusIndicator.Success, props.text);
};

const WarningLabel = (props: { text: string, prefixClassName?: string }): JSX.Element => {
    return GetLabel(props.prefixClassName, StatusIndicator.Warning, props.text);
};

export {
    DangerLabel,
    InfoLabel,
    SplitLabel,
    SuccessLabel,
    WarningLabel
};
