// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from 'Utility/IndexOfActions';
import { ISimpleButtonProps, PanelHeading, PanelHeadingWithContent, PrimaryButton } from 'Utility/IndexOfComponents';
import { ButtonSize } from 'Utility/IndexOfEnums';
import { ObjectHelper, TranslationHelper } from 'Utility/IndexOfHelpers';

export interface IGridHeaderProps {
    filterToggleClickCallback?: Handler.Action;
    gridName?: string;
    titleContent?: JSX.Element;
}

interface IGridHeaderState {
    toggled: boolean;
}

export default class GridHeader extends React.Component<IGridHeaderProps, IGridHeaderState> {

    constructor(props: IGridHeaderProps) {
        super(props);

        this.state = {
            toggled: false
        };
    }

    private handleFilterToggleClick = (toggleClickCallback: Handler.Action) => (): void => {

        const updatedState = ObjectHelper.deepClone(this.state as IGridHeaderState);
        updatedState.toggled = !updatedState.toggled;

        this.setState(updatedState);

        toggleClickCallback();
    }

    render() {

        const { gridName, titleContent, filterToggleClickCallback } = this.props;
        const { toggled } = this.state;

        if (titleContent) {
            return <PanelHeadingWithContent titleContent={titleContent}>{this.props.children}</PanelHeadingWithContent>;
        }

        let children = this.props.children;

        if (filterToggleClickCallback) {

            const buttonProps: ISimpleButtonProps = {
                buttonSize: ButtonSize.Sm,
                key: 'filterButton',
                onClick: this.handleFilterToggleClick(filterToggleClickCallback)
            };

            children = [
                this.props.children,
                <PrimaryButton {...buttonProps}>
                    <i className={toggled ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}></i> {TranslationHelper.translate('Filters')}
                </PrimaryButton>
            ];
        }

        return (
            <PanelHeading panelTitle={gridName}>
                {children}
            </PanelHeading>
        );
    }
}
