// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from '../../IndexOfActions';
import { BlockedIcon } from '../../IndexOfComponents';
//import {  } from '../../IndexOfEnums';
import { AnimationHelper } from '../../IndexOfHelpers';
//import {  } from '../../IndexOfInterfaces';
//import {  } from '../../IndexOfModels';
//import {  } from '../../IndexOfServices';

// Local References
// ..

export interface ISimpleTagInputProps extends React.Props<SimpleTagInput> {
    isDisabled?: boolean;
    isHidden?: boolean;
    onBlurCallback?: Handler.InputFocusEvent;
    onChangeCallback: Handler.BooleanProperty;
    propertyName?: string;
    tabIndex?: number;
    value: boolean;
}

export class SimpleTagInput extends React.Component<ISimpleTagInputProps, {}> {

    private primaryInput: HTMLInputElement;

    public setFocus = (scroll?: number): void => {
        AnimationHelper.setFocus(this.primaryInput, scroll);
    }

    render() {

        const { isDisabled, isHidden, onBlurCallback, onChangeCallback, propertyName, tabIndex, value } = this.props;

        const inputProps = {
            disabled: isDisabled,
            id: propertyName,
            isHidden,
            onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
                if (onBlurCallback) {
                    onBlurCallback(event);
                }
            },
            onChange: (event: React.FormEvent<HTMLInputElement>) => {

                const newValue = (event.target as HTMLInputElement).checked;
                onChangeCallback(newValue, propertyName);
            },
            ref: (component: HTMLInputElement) => { this.primaryInput = component; },
            tabIndex,
            type: 'checkbox',
            checked: value
        };

        const labelWithInput = isDisabled ? <BlockedIcon /> : <label><input {...inputProps}/></label>;

        return <div className="simple-tag-input">{labelWithInput}</div>;
    }
}
