// 3rd Party
import * as React from 'react';

// Base References
import { IAppProps } from '../Base/View';

// Utility References
import { ObjectHelper } from 'Utility/IndexOfHelpers';

// App References
import {
    HighlightUpdate,
    IHighlightUpdateProps,
    IPageActions,
    IPageState,
    ISupplyRequestListingActions,
    ISupplyRequestListingDashboardFilterProps as IFilterProps,
    ISupplyRequestListingListProps as IListProps,
    SignalRHubEvents as HubEvents,
    SupplyRequestListingDashboardFilter,
    SupplyRequestListingDefaultOptions,
    SupplyRequestListingList
} from 'App/IndexOfModels';
import { AppUrls } from 'App/IndexOfUrls';

interface IDashboardListingListViewProps extends IAppProps {
    statusEndpoint: string;
}

class View extends React.Component<IDashboardListingListViewProps, {}> {

    componentDidMount() {

        const { appActions, appState } = this.props;

        this.initialiseSearch(appActions.supplyRequestListingActions, this.props.statusEndpoint, appActions.pageActions, appState.page);
    }

    private initialiseSearch = (actions: ISupplyRequestListingActions, listingEndpoint: string, pageActions: IPageActions, page: IPageState, reset?: boolean) => {

        let updatedOptions = SupplyRequestListingDefaultOptions(listingEndpoint);

        actions.resetAll();

        updatedOptions = pageActions.initialiseStickyOptions(reset, updatedOptions, page.currentOptions);

        actions.receiveOptions(updatedOptions);
        actions.searchStrictAsync(updatedOptions);
    }

    render() {

        const { appActions, appState } = this.props;

        const filterProps: IFilterProps = {
            propActions: {
                resetForm: () => {
                    this.initialiseSearch(appActions.supplyRequestListingActions, this.props.statusEndpoint, appActions.pageActions, appState.page);
                },
                search: appActions.supplyRequestListingActions.searchStrictAsync,
                updateOptions: appActions.supplyRequestListingActions.receiveOptions
            },
            propData: {
                options: appState.supplyRequestListing.options,
                pageState: appState.page,
                tools: appState.supplyRequestListing.tools
            }
        };

        const highlightUpdateProps: IHighlightUpdateProps = {
            propActions: {
                pageActions: appActions.pageActions,
                refreshAction: (args) => {

                    appActions.supplyRequestListingActions.searchStrictAsync(appState.supplyRequestListing.options);
                },
                shouldHighlightUpdate: (event, args) => {

                    if (event !== HubEvents.supplyRequestListingUpdated &&
                        event !== HubEvents.supplyRequestUpdated ||
                        ObjectHelper.isUndefinedOrNull(appState.supplyRequestListing.collection)) {
                        return false;
                    }

                    return appState.supplyRequestListing.collection.results.some((item) => {

                        if (event === HubEvents.supplyRequestListingUpdated) {
                            return item.supplyRequestListingId === args.id;
                        }

                        if (event === HubEvents.supplyRequestUpdated) {
                            return item.supplyRequestId === args.id;
                        }
                        return item.supplyRequestListingId === args.id;
                    });
                }
            },
            propData: {
                pageState: appState.page
            }
        };

        const listProps: IListProps = {
            propActions: {
                search: appActions.supplyRequestListingActions.searchStrictAsync,
                updateOptions: appActions.supplyRequestListingActions.receiveOptions
            },
            propData: {
                collection: appState.supplyRequestListing.collection,
                options: appState.supplyRequestListing.options,
                pageState: appState.page,
                detailsUrlFunc: AppUrls.DashboardUrls.listingDetails
            }
        };

        return (
            <>
                <HighlightUpdate {...highlightUpdateProps} />

                <SupplyRequestListingDashboardFilter {...filterProps}/>

                <SupplyRequestListingList {...listProps}/>
            </>
        );
    }
}

export {
    View as DashboardListingListView,
    IDashboardListingListViewProps
};
