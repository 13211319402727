// 3rd Party References
import * as React from 'react';

// Utility References
import { Handler } from '../../../IndexOfActions';
//import {  } from '../../../IndexOfComponents';
import { DaysOfWeek } from '../../../IndexOfEnums';
//import {  } from '../../../IndexOfHelpers';
import { IApiItemOptionViewModel } from '../../../IndexOfInterfaces';
import { IGridFilterContext } from '../../../IndexOfModels';
//import {  } from '../../../IndexOfServices';

// Local References
import GenericNumberDropdown from '../Internal/GenericNumberDropdown';

type UpdateExpr = <TFilter, TValue>(filter: TFilter, value: TValue) => void;

interface IDaysOfWeekFilterProps extends React.Props<DaysOfWeekFilter> {
    gridFilterContext: IGridFilterContext;
    isDisabled?: boolean;
    key: string | number;
    label?: string;
    updateExpr: UpdateExpr;
    value: number;
}

export default class DaysOfWeekFilter extends React.Component<IDaysOfWeekFilterProps, {}> {

    private daysOfTheWeek: IApiItemOptionViewModel[] = [];

    constructor(props: IDaysOfWeekFilterProps) {
        super(props);

        const daysOfWeekEnum = DaysOfWeek;

        this.daysOfTheWeek = Object.keys(daysOfWeekEnum)
            .filter(value => isNaN(parseInt(value, 10)))
            .map((dayOfWeek: string, value: number): IApiItemOptionViewModel => ({ text: dayOfWeek, value: value.toString() }));
    }

    private handleChange = (updateFunc: Handler.NumberValue) => (selectedValue: number): void => {

        updateFunc(selectedValue);
    }

    private reset = (updateFunc: Handler.NumberValue) => () => {

        updateFunc(null);
    }

    render() {

        const { gridFilterContext, isDisabled, label, updateExpr, value } = this.props;

        const updateFunc = gridFilterContext.filterHandler(updateExpr);

        const dropdownProps = {
            displayName: label ? label : 'Day',
            isDisabled,
            isRequired: false,
            items: this.daysOfTheWeek,
            onChangeCallback: this.handleChange(updateFunc),
            propertyName: label ? label + 'Filter' : 'DayOfWeekFilter',
            selectClassName: 'form-control grid-filter',
            selectableEmptyValue: 'Any',
            selectedValue: value
        };

        return (
            <th>
                <GenericNumberDropdown {...dropdownProps} />
            </th>
        );
    }
}
