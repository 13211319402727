// 3rd Party References
import * as React from 'react';

// Utility References
import { IModalService } from 'Utility/IndexOfServices';

// Local References
import { IInternalPromptModalProps, InternalPromptModal } from './Internal/InternalPromptModal';

type OnClose = (isConfirmed: boolean) => void;

export interface IDeletePromptModalProps extends React.Props<DeletePromptModal> {
    modalService: IModalService;
    onClose: OnClose;
}

export class DeletePromptModal extends React.Component<IDeletePromptModalProps, {}> {

    render() {

        const { modalService, onClose } = this.props;

        const internalPrompModalProps: IInternalPromptModalProps = {
            bodyText: 'Are you sure you want to delete this item?',
            modalService,
            okButtonClass: 'btn-danger',
            okButtonText: 'Delete',
            onClose,
            titleText: 'Confirm Deletion'
        };

        return (
            <InternalPromptModal {...internalPrompModalProps} />
        );
    }
}
