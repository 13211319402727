// 3rd Party
import * as React from 'react';
import { hot } from 'react-hot-loader';
import { Redirect, Switch } from 'react-router-dom';

// Utility References
import {
    AuthenticatedRoute,
    ITabItemProps,
    ITabMenuProps,
    TabMenu
} from 'Utility/IndexOfComponents';
import { ObjectHelper } from 'Utility/IndexOfHelpers';

// Service References
import { LocalStorageService } from 'App/IndexOfServices';

// Base References
import { BaseView } from '../Base/View';

// App References
import { AccountView, DashboardListingListView, SupplyRequestListView } from 'App/IndexOfViews';
import { DashboardUrls } from 'App/Navigation/Urls/DashboardUrls';
import { LoginUrls } from 'App/Navigation/Urls/LoginUrls';

// Model References
import { Constants } from 'App/IndexOfModels';

interface IDashboardTabItemProps extends ITabItemProps {
    statusEndpoint: string;
}

interface IDashboardTabMenuProps extends ITabMenuProps { }

class View extends BaseView<{}> {

    render() {

        const dealerTabList: IDashboardTabItemProps[] = [
            {
                navLinkProps: {
                    to: DashboardUrls.bidding
                },
                statusEndpoint: 'Bidding',
                text: 'Bidding'
            },
            {
                navLinkProps: {
                    to: DashboardUrls.provisional
                },
                statusEndpoint: 'ProvisionalDealer',
                text: 'Awaiting Review'
            },
            {
                navLinkProps: {
                    to: DashboardUrls.won
                },
                statusEndpoint: 'Won',
                text: 'Won'
            },
            {
                navLinkProps: {
                    to: DashboardUrls.lost
                },
                statusEndpoint: 'Lost',
                text: 'Lost'
            },
            {
                navLinkProps: {
                    to: DashboardUrls.account
                },
                statusEndpoint: 'Account',
                text: 'Account'
            }
        ];

        const dealerTabMenuProps: IDashboardTabMenuProps = {
            tabList: dealerTabList
        };

        const insurerTabList: IDashboardTabItemProps[] = [
            {
                navLinkProps: {
                    to: DashboardUrls.pending
                },
                statusEndpoint: 'Pending',
                text: 'Pending'
            },
            {
                navLinkProps: {
                    to: DashboardUrls.running
                },
                statusEndpoint: 'Running',
                text: 'Running'
            },
            {
                navLinkProps: {
                    to: DashboardUrls.provisional
                },
                statusEndpoint: 'ProvisionalInsurer',
                text: 'Awaiting Review'
            },
            {
                navLinkProps: {
                    to: DashboardUrls.sourced
                },
                statusEndpoint: 'Sourced',
                text: 'Sourced'
            },
            {
                navLinkProps: {
                    to: DashboardUrls.declined
                },
                statusEndpoint: 'Declined',
                text: 'Declined'
            },
            {
                navLinkProps: {
                    to: DashboardUrls.archived
                },
                statusEndpoint: 'Archived',
                text: 'Archived'
            },
            {
                navLinkProps: {
                    to: DashboardUrls.account
                },
                statusEndpoint: 'Account',
                text: 'Account'
            }
        ];

        const insurerTabMenuProps: ITabMenuProps = {
            tabList: insurerTabList
        };

        const isAuthenticated = !ObjectHelper.isUndefinedOrNull(LocalStorageService.getAccessToken());

        const isInsurer = LocalStorageService.isInRole(Constants.RoleNames.Insurer);

        const tabMenuProps = isInsurer ? insurerTabMenuProps : dealerTabMenuProps;

        const switchRouteElements: JSX.Element[] =
            tabMenuProps.tabList
                .filter(x => !(x.navLinkProps.to.toString() === DashboardUrls.account || x.navLinkProps.to.toString() === DashboardUrls.pending))
                .map((x: IDashboardTabItemProps) =>
                    <AuthenticatedRoute
                        exact path={x.navLinkProps.to.toString()}
                        isAuthenticated={isAuthenticated}
                        key={x.text}
                        redirectTo={LoginUrls.area}
                        render={() =>
                            <DashboardListingListView
                                key={x.text}
                                statusEndpoint={x.statusEndpoint}
                                {...this.props} />} />
                );

        return (
            <>
                <div className="col-sm-12 mb-3">
                    <h1>My Dashboard</h1>
                    <p>Manage your {isInsurer ? 'supply requests' : 'offers'}</p>
                </div>

                <div className="col-sm-12 mb-3">
                    <TabMenu {...tabMenuProps} />
                    <div className="tab-content mt-3">
                        <Switch>
                            <AuthenticatedRoute exact path={DashboardUrls.area}  isAuthenticated={isAuthenticated} redirectTo={LoginUrls.area} render={() => <Redirect to={isInsurer ? DashboardUrls.pending : DashboardUrls.bidding} />} />
                            {isInsurer &&  <AuthenticatedRoute exact path={DashboardUrls.pending} isAuthenticated={isAuthenticated} redirectTo={LoginUrls.area} render={() => <SupplyRequestListView {...this.props} />} />}
                            {switchRouteElements}
                            <AuthenticatedRoute exact path={DashboardUrls.account} isAuthenticated={isAuthenticated} redirectTo={LoginUrls.area} render={() => <AccountView {...this.props} />} />
                        </Switch>
                    </div>
                </div>
            </>
        );
    }
}

export {
    View as DashboardView
};
